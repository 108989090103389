<template>
    <div id='dashboard' class="view">
        <h1>Bienvenue {{user.firstname}}</h1>
        <div class="box">
            <div class="notification" v-if='isFirstLaunch'>
                <h3>Bienvenue sur votre espace</h3>
                <p style="margin-bottom: 0.5em">Pour publier votre menu, commencez par créer vos produits</p>
                <el-button type="primary" @click='displayWizard'>Démarrer !</el-button>
            </div>
            <el-row :gutter="10" id='dashboardCards'>
                <el-col :span="12" :xs="24">
                    <div class="dashboardCard" @click='editProducts'>
                        <h2>Gérer mes produits</h2>
                    </div>
                    <div class="dashboardCard" @click='editAssemblies' style="background-color: #00c294">
                        <h2>Gérer mes formules</h2>
                    </div>
                    <div class="dashboardCard" @click='editCards' style="background-color: #14a381">
                        <h2>Gérer mes cartes</h2>
                    </div>
                </el-col>
                <el-col :span="12" :xs="24">
                    <div class="dashboardCard" style='background-color: #ffc200;' @click='displayOrders'>
                        <h2>Afficher mes commandes</h2>
                    </div>
                    <div class="dashboardCard" style='background-color: #d2a000;' @click='displayPlaces'>
                        <h2>Gérer mes établissements</h2>
                    </div>
                </el-col>
            </el-row>
        </div>
        
    </div>
</template>

<script>
import Vuex from 'vuex'
import CardList from '@/views/Menus/CardList'
export default {
    name: 'Dashboard',
    components: {
        CardList
    },
    computed: {
        ...Vuex.mapState(['user', 'places', 'store']),
        ...Vuex.mapGetters(['cards']),
        isFirstLaunch() {
            return this.store.products && this.store.products.length === 0
        },
        place() {
            return this.places[0]
        },
        hasDefaultCard() {
            return this.cards.length === 1 && this.cards[0] && this.cards[0].slug === 'default'
        }
    },
    methods: {
        editProducts() {
            this.$router.push({name: 'app.menus', query: {tab: 'products'}})
        },
        editCards() {
            this.$router.push({name: 'app.menus', query: {tab: 'cards'}})
        },
        editCategories() {
            this.$router.push({name: 'app.menus', query: {tab: 'categories'}})
        },
        editAssemblies() {
            this.$router.push({name: 'app.menus', query: {tab: 'assemblies'}})
        },
        displayOrders() {
            this.$router.push({name: 'app.orders'})
        },
        displayPlaces() {
            this.$router.push({name: 'app.places'})
        },
        displayWizard() {
            this.$router.push({name: 'app.menu.wizard', query: {
                firstLaunch: this.isFirstLaunch ? '1' : '0',
                step: 'categories'
            }})
        }
    }
}
</script>

<style lang='scss'>
#dashboardCards {
    margin-bottom: 1em;
}
.dashboardCard {
    margin-top: 10px;
    background-color: #03e2ad;
    padding: 0.5em;
    cursor: pointer;
    text-align: center;
    color: white;
    border-radius: 5px;
    &:hover {
        box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.12), 0 0 6px 0 rgba(0, 0, 0, 0.04);
    }
    h3 {
        margin-block-start: 10px;
    }
}

</style>
