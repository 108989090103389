<template>
  <div class="view" id="profile">
    <el-row :gutter="10">
      <el-col :span="16" :xs="24">
      <h2>Votre Profil</h2>
        <div class="box">
          <user-profile-form :user='user' @save='updateUserProfile'></user-profile-form>
        </div>
      </el-col>
      <el-col :span="8" :xs="24">
        <h2>Votre offre</h2>
        <div class="boxs">
          <div class="notification is-success">
            <p>Vous bénéficier actuellement de l'offre <strong>Basique</strong>.</p>
            <p>Pour changer d'offre, merci de nous contacter.</p>
            <!-- <el-button type='primary' icon='el-icon-fa-calendar' style='margin-top: 1em' @click='view.rdv = true'>Prendre Rendez-Vous</el-button> -->
          </div>
        </div>
      </el-col>
      <rdv-modal
        :visible='view.rdv'
        @close='view.rdv = false'
      />
    </el-row>
  </div>
</template>

<script>
import Vuex from 'vuex'
import RDVModal from '@/components/RDVModal'
import UserProfileForm from '@/components/UserProfileForm'
export default {
  name: 'Profile',
  components: {
    'rdv-modal': RDVModal,
    UserProfileForm
  },
  computed: {
    ...Vuex.mapState(['user'])
  },
  data: () => ({
    view: {
      rdv: false
    }
  }),
  methods: {
    ...Vuex.mapActions(['updateProfile', 'getUser']),
    async updateUserProfile(data) {
      try {
        await this.updateProfile(data)
        this.$message({
          message: 'Modification enregistrée',
          type: 'success'
        })
      } catch (err) {
        this.$message({
          message: 'Une erreur est survenue, merci de réessayer',
          type: 'error'
        })
      }
    }
  },
  mounted() {
    this.getUser(true)
  }
}
</script>

<style>

</style>
