<template>
    <div id="login">
        <div id='llogo' style="background-image: url('/img/logov2.png');" @click='goToHome'></div>
        <h3>Connexion à votre espace</h3>
        <login-form @auth='handleAuth'>
            <span slot='footer'>
                <small><router-link :to="{name:'auth.register'}">Créer un compte</router-link></small>
            </span>
        </login-form>
    </div>
</template>

<script>
import Vuex from 'vuex'
import LoginForm from '@/components/LoginForm'
export default {
    name: 'Login',
    components: {
        LoginForm
    },
    computed: {
        ...Vuex.mapGetters(['$intercom'])
    },
    methods: {
        ...Vuex.mapActions(['getUser']),
        async handleAuth() {
            this.$router.push({name: 'app.home'})
        },
        goToHome() {
            this.$router.push('/')
        }
    },
    mounted() {
        // Hide intercom
        // this.$intercom('u')
    }
}
</script>

<style>
#login {
    text-align: center;
    width: 100%;
}

#llogo {
    width: 100%;
    margin: auto;
    height: 60px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
}
</style>
