<template>
  <router-view></router-view>
</template>

<script>
import Auth from '@/lib/Auth'
export default {
  name: 'Dispatcher',
  mounted() {
    if (Auth.isConnected()) {
      this.$router.push({name: 'app.home'})
    } else {
      this.$router.push({name: 'auth.login'})
    }
  }
}
</script>

<style>
</style>
