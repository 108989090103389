<template>
    <div id="menusCardList" v-loading.lock="loading">
        <el-button v-if='displayAddButton' type="primary" size="small" icon='el-icon-plus' @click="displayAddCard" style="float: right;">
            Créer une carte
        </el-button>
        <div v-if='!loading'>
            <h2 v-if='displayTitle'>Liste de vos cartes</h2>
            <div v-for='(cards, placeId) in cardsByPlaces' :key='placeId'>
                <h3><i class="el-icon-fa-building"></i>&nbsp;
                    <router-link tag="span" style="cursor: pointer; text-decoration: underline;" :to='{name: "app.places.edit", params: {id: placeId}}'>{{placesByIds[placeId]}}</router-link>
                </h3>
                <el-alert type="info" title="Aucune carte associée à cet établissement" v-if='cards.length === 0'></el-alert>
                <div class="cards-list">
                    <card-item
                        v-for='card in cards'
                        :key='card.id'
                        :card='card'
                        :clonable='true'
                        :sharable='true'
                        :editable='true'
                        :previewable='true'
                        :linkable='true'
                        :deletable='true'
                        @edit-index='handleCardIndex'
                        @clone='handleCardClone'
                        @share='handleCardShare'
                        @toogle='handleCardToogle'
                        @link='handleCardLink'
                        @preview='handleCardPreview'
                        @edit='handleCardEdit'
                        @drop='handleCardDelete'
                    />
                </div>
            </div>

            <div v-if='orphonCards.length > 0'>
                <h3>Cartes non associées</h3>
                <div class="cards-list">
                    <card-item
                        v-for='card in orphonCards'
                        :key='card.id'
                        :card='card'
                        :clonable='true'
                        :sharable='true'
                        :editable='true'
                        :previewable='true'
                        :linkable='true'
                        :deletable='true'
                        @edit-index='handleCardIndex'
                        @clone='handleCardClone'
                        @toogle='handleCardToogle'
                        @share='handleCardShare'
                        @link='handleCardLink'
                        @preview='handleCardPreview'
                        @edit='handleCardEdit'
                        @drop='handleCardDelete'
                    />
                </div>
            </div>
        </div>
        <div v-else>
            <h4>Chargement en cours ...</h4>
        </div>
        <!-- Modals -->

        <card-share-modal
            v-if='editingCard'
            :card='editingCard'
            :visible='view.shareCardModal'
            @close='view.shareCardModal = false'
        />
        <card-index-modal
            v-if='editingCard'
            :card='editingCard'
            :visible='view.indexCardModal'
            @close='view.indexCardModal = false'
        />
        <card-preview-modal
            v-if='editingCard'
            :card='editingCard'
            :visible='view.previewCardModal'
            @close='view.previewCardModal = false'
        />
        <card-link-modal
            v-if='editingCard'
            :card='editingCard'
            :visible='view.linkCardModal'
            @cardPlaceUpdated='handleCardPlaceUpdated'
            @close='view.linkCardModal = false'
        />
        <card-composer-modal
            v-if='editingCard'
            :card='editingCard'
            :visible='view.createCardModal'
            :products='products'
            :categories='categories'
            :assemblies='assemblies'
            @close='view.createCardModal = false'
            @save='handleCardCreated'
        />

    </div>
</template>

<script>
import Vuex from 'vuex'
import { error } from '@/lib/Tools'
import Card from '@/lib/entities/Card'
import CardItem from '@/components/Menu/CardItem'
import CardIndexModal from '@/components/Menu/CardIndexModal'
import CardShareModal from '@/components/Menu/CardShareModal'
import CardPreviewModal from '@/components/Menu/CardPreviewModal'
import CardLinkModal from '@/components/Menu/CardLinkModal.vue'
import CardComposerModal from '@/components/Menu/CardComposerModal'
export default {
    name: 'CardList',
    components: {
        CardItem,
        CardIndexModal,
        CardShareModal,
        CardPreviewModal,
        CardLinkModal,
        CardComposerModal,
    },
    props: {
        displayAddButton: {
            type: Boolean,
            required: false,
            default: () => true
        },
        displayTitle: {
            type: Boolean,
            required: false,
            default: () => true
        },
        deletable: {
            type: Boolean,
            required: false
        }
    },
    data: () => ({
        editingCard: null,
        cards: [],
        loading: true,
        view: {
            createCardModal: false,
            linkCardModal: false,
            shareCardModal: false,
            previewCardModal: false,
            indexCardModal: false
        },
        orphonCards: []
    }),
    computed: {
        ...Vuex.mapState(['store', 'places']),
        ...Vuex.mapGetters(['products', 'categories', 'assemblies']),
        placesByIds() {
            const places = {}
            for (const place of this.places) {
                places[place.id] = place.name
            }
            return places
        },
        cardsByPlaces() {
            const cardsByPlaces = {}
            for (const card of this.cards) {
                if (card.place) {
                    if (!cardsByPlaces[card.place.id]) {
                        cardsByPlaces[card.place.id] = []
                    }
                    cardsByPlaces[card.place.id].push(card)
                }
            }
            return cardsByPlaces
        }
    },
    methods: {
        ...Vuex.mapActions(['getCards']),
        updateOrphonsCards() {
            this.orphonCards = this.cards.filter((c) => {
                return c.place === null || c.place === undefined
            })
        },
        displayAddCard() {
            this.editingCard = new Card()
            this.editingCard.bootFromAssemblies(this.assemblies)
            this.editingCard.bootFromCategories(this.categories)
            this.view.createCardModal = true
        },
        handleCardPreview(card) {
            this.editingCard = card
            this.view.previewCardModal = true
        },
        handleCardLink(card) {
            this.editingCard = card
            this.view.linkCardModal = true
        },
        handleCardEdit(card) {
            this.$router.push({name: 'app.card.edit', params: { id: card.id }})
        },
        handleCardShare(card) {
            this.editingCard = card
            this.view.shareCardModal = true
        },
        handleCardSettings(card) {
            this.$router.push({name: 'app.card.settings', params: { id: card.id }})
        },
        handleCardIndex(card) {
            this.editingCard = card
            this.view.indexCardModal = true
        },
        handleCardClone(card) {
            card.clone().then(() => {
                this.$message({
                    message: 'Carte dupliquée avec succès',
                    type: 'success'
                })
                this.boot()
            })
            // this.store.addCard(newCard)
            // this.$router.push({name: 'app.card.edit', params: { id: newCard.id }})
        },
        handleCardToogle(card) {
            card.enabled = card.enabled === true ? false : true
            card.save().then(() => {
                this.$message({
                    message: 'Mise à jour de la carte réalisée avec succès',
                    type: 'success'
                })
            })
        },
        handleCardDelete(card) {
            this.$confirm('Supprimer définitivement cette carte ?', 'Confirmation', {
                type: 'warning',
                confirmButtonText: 'Oui',
                cancelButtonText: 'Annuler',
            })
            .then((_) => {
                this.store.deleteCard(card).then((success) => {
                    if (success) {
                        this.$message({
                            message: 'Carte supprimée avec succès',
                            type: 'success'
                        })
                        this.boot()
                    }
                })
            }).catch((err) => error(err))
        },
        handleCardCreated(card) {
            this.editingCard = null
            this.store.addCard(card)
            this.store.save({
                cards: [ card ]
            })
            this.$emit('update')
        },
        handleCardPlaceUpdated() {
            this.boot()
        },
        boot() {
            this.loading = true
            this.getCards().then((cards) => {
                this.cards = cards
                this.updateOrphonsCards()
                this.loading = false
            })
        }
    },
    mounted() {
        this.boot()
    }
}
</script>

<style>

</style>
