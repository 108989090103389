<template>
    <el-dialog
        :visible.sync="visible"
        :fullscreen='true'
        :before-close="closeModal">
        <template slot="title">
            <h2 class="subtitle" v-if='isEditing'><i class="fa fa-pencil"></i>&nbsp;Modification d'une carte</h2>
            <h2 class="subtitle" v-else><i class="fa fa-pencil"></i>&nbsp;Création d'une carte</h2>
        </template>
        <card-composer 
            :products='products'
            :card='card'
            :categories='categories'
            :assemblies='assemblies'
            :isEditing='isEditing'
        />
        <span slot="footer" class="dialog-footer">
            <el-button @click="closeModal" icon='el-icon-close'>Fermer</el-button>
            <el-button type="primary" :disabled='!isCardValid' @click="saveAndClose()" icon='el-icon-circle-check-outline'>Enregistrer</el-button>
        </span>
    </el-dialog>
</template>

<script>
import Card from '@/lib/entities/Card'
import CardComposer from '@/components/Menu/CardComposer'
export default {
    name: 'CardComposerModal',
    components: {
        CardComposer
    },
    props: {
        card: {
            type: Card,
            required: true
        },
        visible: {
            type: Boolean,
            required: true,
            default: () => false
        },
        products: {
            type: Array,
            required: true
        },
        categories: {
            type: Array,
            required: true
        },
        assemblies: {
            type: Array,
            required: true
        },
        isEditing: {
            type: Boolean,
            required: false,
            default: () => false
        }
    },
    computed: {
        isCardValid() {
            return this.card.name !== '' && this.card.categories.length > 0
        }
    },
    methods: {
        closeModal () {
            this.$emit('close')
        },
        saveAndClose() {
            this.$emit('save', this.card)
            this.closeModal()
        }
    }
}
</script>

<style>

</style>
