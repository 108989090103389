<template>
    <div class="product-sub-item" @click='handleClick' :draggable="draggable ? 'on' : 'off'" :class='{"is-draggable": draggable, "is-pricable": pricable}'>
        <div class='subitem'>
            <el-button v-if='draggable' size="mini" circle type="text" class="product-handle"><i class="el-icon-d-caret"></i></el-button>
            <div class="product-actions is-left" v-if='pickable'>
                <div class="product-item-picker">
                    <el-checkbox :checked="selected" @change='handleProductSelected'></el-checkbox>
                </div>
            </div>
            <div class="product-data">
                <div class="product-info">
                    <p style="font-weight: 600">{{product.name}}</p>
                    <!-- <small>{{product.description}}</small> -->
                </div>
            </div>
            <div class="product-actions is-right">
                <template v-if='pricable'>
                    <div class="subitem-vat" v-if='showVat' title='TVA'>
                        <div class="label">TVA</div>
                        <el-select size="small" :value='vat' @change='handleVatChanged'>
                            <el-option v-for='rate in vatsRates' :key='rate' :label='`${rate} %`' :value='rate'></el-option>
                        </el-select>
                    </div>
                    <div class="subitem-price" title="Supplément">
                        <!-- Supplément -->
                        <div class="label" v-if='!showPriceEditor' @click='displayPriceEditor'>
                            <span>{{extra|EUR}}</span>
                        </div>
                        <div v-else class="subitem-price-editor">
                            <el-button type="success" title='Enregister' circle size="mini" @click='handleExtraChanged' icon="el-icon-check"></el-button>
                            <el-input type="text" min="0" class="input" autofocus='autofocus' v-model='priceInput'></el-input>
                        </div>
                    </div>
                </template>
                
                <div class="subitem-stock" v-if='stockable' title="Stock">
                    <div class="label" v-if='!showStockEditor' @click='showStockEditor = true'>
                        <span>{{stock}}</span> Q.
                    </div>
                    <div v-else>
                        <el-button type="success" title='Enregister' circle size="mini" @click='handleStockChanged' icon="el-icon-check"></el-button>
                        <el-input type="text" min="0" step="1" class="input" v-model='stockInput' placeholder="Stock" style="width:80px"></el-input>
                    </div>
                </div>
                <div v-if='statuable' class="product-status">
                    <el-button @click="handleProductStatus" :type='status == "available" ? "success" : "light"' size="mini" title="Status" :icon='status == "available" ? "el-icon-fa-thumbs-up" : "el-icon-fa-thumbs-down"'>
                        <!-- <span v-if='status == "available"'>Désactiver</span>
                        <span v-else>Activer</span> -->
                    </el-button>
                </div>
                <div v-if='editable' class="product-edit">
                    <el-button @click="handleProductEdit" size="small" type="primary" title="Modifier" circle icon="el-icon-fa-edit"></el-button>
                </div>
                <div v-if='deletable' class="product-delete">
                    <el-button @click="handleProductDrop" type="dark" size="small" title="Supprimer" circle icon="el-icon-fa-times"></el-button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Vuex from 'vuex'
export default {
    name: 'ProductSubItem',
    props: {
        product: {
            type: Object,
            required: true
        },
        parent: {
            type: Object,
            required: true
        },
        editable: {
            type: Boolean,
            required: false,
            default: () => false
        },
        statuable: {
            type: Boolean,
            required: false,
            default: () => false
        },
        pickable: {
            type: Boolean,
            required: false,
            default: () => false
        },
        deletable: {
            type: Boolean,
            required: false,
            default: () => false
        },
        pricable: {
            type: Boolean,
            required: false,
            default: () => false
        },
        stockable: {
            type: Boolean,
            required: false,
            default: () => false
        },
        draggable: {
            type: Boolean,
            required: false,
            default: () => false
        },
        selected: {
            type: Boolean,
            required: false,
            default: () => false
        },
        showImage: {
            type: Boolean,
            required: false,
            default: () => true
        },
        showVariations: {
            type: Boolean,
            required: false,
            default: () => false
        },
        showItems: {
            type: Boolean,
            required: false,
            default: () => false
        },
        showOnlyPricableVariations: {
            type: Boolean,
            required: false,
            default: () => false
        },
        showVat: {
            type: Boolean,
            required: false,
            default: () => false
        },
        extra: {
            type: Number,
            required: false
        },
        vat: {
            type: Number,
            required: false
        },
        stock: {
            type: Number,
            required: false
        },
        status: {
            type: String,
            required: false,
            default: () => 'available'
        },
        extras: {
            type: Object,
            required: false,
            default: () => ({})
        }
    },
    data: () => ({
        priceInput: 0,
        stockInput: 0,
        showPriceEditor: false,
        showVatEditor: false,
        showStockEditor: false
    }),
    computed: {
        ...Vuex.mapState(['vatsRates']),
    },
    // watch: {
    //     price (p) {
    //         this.priceInput = parseFloat(p)
    //     }
    // },
    mounted() {
        this.priceInput = this.$props.extra || 0
        this.stockInput = this.$props.stock || 0
    },
    methods: {
        handleProductEdit() {
            if (this.editable) {
                this.$emit('edit', this.product)
            }
        },
        handleProductDrop() {
            this.$emit('drop', this.product)
        },
        handleProductSelected() {
            this.$emit('select', this.product, this.selected)
        },
        handleClick() {
            // if (this.pickable && !this.editable) {
            //     return this.handleProductSelected()
            // }
            if (this.editable && !this.deletable) {
                return this.edit()
            }
        },
        handleExtraChanged() {
            this.showPriceEditor = false
            const price = parseFloat(`${this.priceInput}`.replace(',','.').replace('\w',''))
            this.$emit('extraChanged', this.product, this.parent, price)
        },
        handleStockChanged() {
            this.showStockEditor = false
            const stock = parseInt(this.stockInput)
            this.$emit('stockChanged', this.product, stock)
        },
        handleProductStatus() {
            let newStatus
            if (this.status === 'available') {
                newStatus = 'unavailable'
            } else {
                newStatus = 'available'
            }
            this.$emit('statusChanged', this.product, newStatus)
        },
        displayPriceEditor() {
            this.showPriceEditor = true
        }
    }
}
</script>
<style lang="scss">
.subitem {
    position: relative;
    padding: 0.1em;
    display: flex;
    .is-draggable {
        cursor: drag;
    }
}
.subitem-price {
    max-width: 300px;
    min-width: 80px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 3px;
    font-size: 0.9em;
    border-radius: 3px;
    // border: 1px solid #515050;

    div.label {
        margin-left: 5px;
        text-align: right;
        font-weight: 600;
        span {
            cursor: pointer;
            text-decoration: underline dotted;
        }
    }
    .input {
        margin-left: 5px;
        width: 80px;
    }
    .el-select {
        width: 90px;
    }
}

.subitem-price-editor {
    margin-left: 5px;
}
</style>


