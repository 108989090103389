import slugify from 'slugify'
import Entity from '@/lib/entities/Entity'
import CardCategory from '@/lib/entities/CardCategory'
import Category from '@/lib/entities/Category'
import Product from '@/lib/entities/Product'
import Place from '@/lib/entities/Place'
import Assembly from '@/lib/entities/Assembly'
import CardProduct from '@/lib/entities/CardProduct'
import Store from '@/lib/entities/Store'
import Parse from 'parse'

export default class Card extends Entity {

    get products() {
        const products = []
        for (const cCategory of this.categories) {
            for (const product of cCategory.products) {
                products.push(product)
            }
        }
        return products
    }

    // @deprecated ?
    get avaiblableProducts() {
        const products = []
        for (const cCategory of this.categories) {
            for (const product of cCategory.getCategoryProducts()) {
                products.push(product)
            }
        }
        return products
    }

    public static boot(model: Parse.Object, store?: Store) {

        const card = new Card(model.get('name'))
        card.model = model
        card.setId(model.id)
        card.index = model.get('index')
        card.title = model.get('title')
        card.description = model.get('description')
        card.location = model.get('location')
        card.slug = model.get('slug')
        card.prices = model.get('prices') || {}
        card.extras = model.get('extras') || {}
        card.vats = model.get('vats') || {}
        card.enabled = model.get('enabled')
        card.createdAt = model.get('createdAt')

        if (model.has('settings')) {
            card.settings = model.get('settings')
        }

        if (store) {
            for (const c of model.get('categories')) {
                const category = store.categories.find((cat: Category) => cat.id === c.categoryId)
                const cardCategory = new CardCategory()
                cardCategory.setId(c.id)
                cardCategory.name = c.name
                if (category) {
                    cardCategory.category = category
                }
                cardCategory.vat = c.vat
                for (const p of c.products) {
                    const product = store.products.find((prod: Product) => prod.id === p.productId)
                    const assembly = store.assemblies.find((ass: Assembly) => ass.id === p.productId)
                    let cardProduct
                    if (product) {
                        cardProduct = new CardProduct(product)
                    } else if (assembly) {
                        cardProduct = new CardProduct(assembly)
                    } else {
                        // console.log('Unknown product', p)
                        continue
                    }
                    cardProduct.setId(p.id)
                    cardProduct.setPrice(p.price)
                    cardProduct.setVat(p.vat)
                    cardCategory.products.push(cardProduct)
                }
                card.addCategory(cardCategory)
            }

            if (model.has('place')) {
                const place = store.places.find((p) => p.id === model.get('place').id)
                if (place) {
                    card.setPlace(place)
                }
            }
        }

        /* @version 01/06/19 */
        if (model.has('status')) {
            card.status = model.get('status')
        } else {
            card.status = {}
        }
        if (model.has('stocks')) {
            card.stocks = model.get('stocks')
        } else {
            card.stocks = {}
        }

        return card
    }

    public slug: string = ''
    public index: number = 1
    public name: string = ''
    public title: string = ''
    public description?: string
    public location: string = ''
    public planning: any = {}
    public prices: any = {}
    public stocks: any = {}
    public status: any = {}
    public extras: any = {}
    public vats: any = {}
    public place?: Place|null
    public enabled: boolean = true
    public createdAt: Date
    public categories: CardCategory[]
    public settings: any = {
        displayHeader: true,
        displayAside: true
    }

    constructor(name?: string) {
        super()
        if (name) {
            this.name = name
        }
        this.categories = []
        this.prices = {}
        this.vats = {}
        this.status = {}
        this.stocks = {}
        this.extras = {}
        this.enabled = true
        this.createdAt = new Date()
    }

    public async clone() {
        const newCard = new Card(`${this.name} - Copie`)
        for (const category of this.categories) {
            newCard.addCategory(category)
        }
        newCard.prices = Object.assign({}, this.prices)
        newCard.vats = Object.assign({}, this.vats)
        newCard.stocks = Object.assign({}, this.stocks)
        newCard.extras = Object.assign({}, this.extras)
        newCard.settings = Object.assign({}, this.settings)
        await newCard.save()
        return newCard
    }

    public bootFromCategories(categories: Category[]) {
        this.categories = []
        for (const category of categories) {
            this.addCategory(new CardCategory(category))
        }
    }

    public bootFromAssemblies(assemblies: Assembly[]) {
        if (assemblies.length > 0) {
            const category = new Category('Formules')
            const cCategory = new CardCategory(category)
            for (const assembly of assemblies) {
                cCategory.addProduct(assembly)
            }
            this.addCategory(cCategory)
        }
    }

    public addCategory(category: CardCategory) {
        this.categories.push(category)
        for (const product of category.products) {
            if (!this.status[product.id]) {
                this.status[product.id] = 'available'
            }
        }
    }

    public dropCategory(category: CardCategory) {
        const index = this.categories.indexOf(category)
        if (index > -1) {
            this.categories.splice(index, 1)
        }
    }

    public setPlace(place: Place|null) {
        // Removing existing place
        if (this.place) {
            this.place.dropCard(this)
        }
        this.place = place
        if (this.place) {
            this.place.addCard(this)
        }
    }

    public toJson() {
        return {
            id: this.id,
            name: this.name,
            categories: this.categories.map((category) => category.toJson())
        }
    }

    public async save() {
        if (!this.model) {
            const user = Parse.User.current()
            if (!user) {
                throw new Error('UNAUTHENTICATED')
            }
            this.model = new Parse.Object('Card')
            this.model.set('merchant', user.get('merchant'))
        }
        this.model.set('name', this.name)
        this.model.set('title', this.title)
        this.model.set('index', parseInt(`${this.index}`))
        this.model.set('description', this.description)
        this.model.set('location', this.location)
        this.model.set('planning', this.planning)
        this.model.set('settings', this.settings)
        this.model.set('enabled', this.enabled)

        /* @version 01/06/19 */
        this.model.set('stocks', this.stocks)
        // this.model.set('status', this.status) // @version 2021-01-04
        this.model.set('prices', this.prices)
        this.model.set('extras', this.extras)

        /* @version 08/06/19 */
        if (this.place) {
            this.model.set('place', this.place.model)
        } else {
            this.model.set('place', null)
        }

        this.model.set('vats', this.vats)
        this.model.set('categories', this.categories.map((c) => c.toJson()))
        this.model.set('slug', slugify(this.name, { lower: true }))
        await this.model.save()
    }

    public getProductById(id: string) {
        for (const category of this.categories) {
            for (const product of category.products) {
                if (product.id === id) {
                    return product.product
                }
            }
        }
    }

    public async delete() {
        if (this.model) {
            this.model.destroy()
        }
    }
}
