<template>
    <div class='view' v-loading.lock='loading'>
        <h1>Commandes</h1>
        <div class="box" v-if='orders.length === 0'>
            <h3>Aucune commande à ce jour</h3>
        </div>
        <div v-if='loading'>
            <h4>Chargement en cours...</h4>
        </div>
        <el-tabs v-else v-model="tab" type="border-card" @tab-click="handleTabSelected">
            <el-tab-pane name='pending'>
                <span slot="label"><i class="el-icon-fa-clock-o"></i> En attente</span>
                <el-alert v-if='Object.values(pendingOrders).length == 0' type="success" title="Aucune commande en attente" :closable='false' show-icon></el-alert>
                <div v-for='(orders, place) in pendingOrders' :key='"p-"+place'>
                    <h4>{{placesHash[place]}}</h4>
                    <div class='orders-list'>
                        <order-item v-for='order in orders' :key='order.id' :order='order'
                            @print='handleOrderPrint'
                            @rejected='handleOrderRejected'
                            @accepted='handleOrderAccepted'>
                        </order-item>
                    </div>
                </div>
            </el-tab-pane>
            <el-tab-pane name='processing'>
                <span slot="label"><i class="el-icon-fa-gear"></i> En cours</span>
                <el-alert v-if='Object.values(processingOrders).length == 0' type="success" title="Aucune commande en cours" :closable='false' description="Toutes les commandes ont été traitées" show-icon></el-alert>
                <div v-for='(orders, place) in processingOrders' :key='"p-"+place'>
                    <h4>{{placesHash[place]}}</h4>
                    <div class='orders-list'>
                        <order-item v-for='order in orders' :key='order.id' :order='order'
                            @print='handleOrderPrint'
                            @canceled='handleOrderCanceled'
                            @completed='handleOrderCompleted'>
                        </order-item>
                    </div>
                </div>
            </el-tab-pane>
            <el-tab-pane name='completed'>
                <span slot="label"><i class="el-icon-fa-thumbs-up"></i> Terminées</span>
                <el-alert v-if='Object.values(completedOrders).length == 0' type="success" title="Aucune commande terminée" :closable='false' description="Toutes les commandes ont été traitées" show-icon></el-alert>
                <div class='orders-table' v-else>
                    <orders-table :placesHash='placesHash' :orders='completedOrders' :detailable='true'></orders-table>
                </div>
            </el-tab-pane>
            <el-tab-pane name='lost'>
                <span slot="label"><i class="el-icon-fa-thumbs-down"></i> Abandon</span>
                <el-alert v-if='Object.values(lostOrders).length == 0'  type="success" title="Aucune commande abandonnée" :closable='false' show-icon></el-alert>
                <div class='orders-table' v-else>
                    <orders-table :placesHash='placesHash' :orders='lostOrders'></orders-table>
                </div>
            </el-tab-pane>
        </el-tabs>
    </div>
</template>

<script>
import { printOrder } from '@/lib/Api'
import Vuex from 'vuex'
import OrderItem from '@/components/Order/OrderItem'
import OrdersTable from '@/components/Order/OrdersTable'
export default {
    name: 'Ordering',
    components: {
        OrderItem,
        OrdersTable
    },
    computed: {
        ...Vuex.mapState(['places', 'orders', '_orders']),
        ordersList() {
            const orders = []
            for (const oid of this.orders) {
                orders.push(this._orders[oid])
            }
            return orders
        },
        pendingOrders() {
            return this.listOrders('pending')
        },
        processingOrders() {
            return this.listOrders('processing')
        },
        completedOrders() {
            return this.listOrders('completed')
        },
        lostOrders() {
            return this.listOrders('canceled,rejected')
        },
        placesHash() {
            const hash = {}
            for (const place of this.places) {
                hash[place.id] = place.name
            }
            return hash
        }
    },
    data: () => ({
        tab: 'pending',
        loading: true,
        subscription: null
    }),
    mounted() {
        this.loading = true
        this.getOrders().then(() => {
            this.loading = false
        })
        this.$root.$on('order.created', () => {
            this.getOrders()
        })
    },
    methods: {
        ...Vuex.mapActions(['getOrders', 'updateOrderStatus']),
        listOrders(status) {
            const orders = {}
            const statusFragments = status.split(',')
            const filteredOrders = this.ordersList
                .filter((order) => statusFragments.includes(order.status))
                .sort((a, b) => {
                    return a.createdAt - b.createdAt
                })
            for (const order of filteredOrders) {
                if (!orders[order.place]) {
                    orders[order.place] = []
                }
                orders[order.place].push(order)
            }
            return orders
        },
        handleTabSelected(tab) {
            this.$router.push({ query : {tab: tab.name} })
        },
        handleOrderAccepted(order) {
            this.updateOrderStatus({status: 'processing', order})
            this.$message({
                message: `Commande #${order.number} acceptée`,
                type: 'success'
            })
        },
        handleOrderCompleted(order) {
            this.updateOrderStatus({status: 'completed', order})
            this.$message({
                message: `Commande #${order.number} terminée`,
                type: 'success'
            })
        },
        handleOrderRejected(order) {
            this.updateOrderStatus({status: 'rejected', order})
            this.$message({
                message: `Commande #${order.number} refusée`,
                type: 'success'
            })
        },
        handleOrderCanceled(order) {
            this.updateOrderStatus({status: 'canceled', order})
            this.$message({
                message: `Commande #${order.number} annulée`,
                type: 'success'
            })
        },
        handleOrderPrint(order) {
            printOrder(order)
        }
    }
}
</script>

<style>
.orders-list {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    flex-wrap: wrap;
}
@media only screen and (max-width: 730px) {
    .orders-list {
        flex-direction: column;
        align-items: center;
    }
}
</style>
