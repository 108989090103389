import Parse from 'parse'
import Entity from '@/lib/entities/Entity'
import Card from './Card'
export default class Place extends Entity {

    get address() {
        return [
            this.location.default,
            this.location.street,
            this.location.zipcode,
            this.location.city
        ].join(' ').trim()
    }

    public static async boot(model: Parse.Object) {
        const place = new Place()
        place.setModel(model)
        place.id = model.id
        place.name = model.get('name')
        place.slug = model.get('slug')
        place.description = model.get('description')
        place.createdAt = model.get('createdAt')
        place.phone = model.get('phone')
        place.email = model.get('email')
        place.hours = model.get('hours')
        place.index = model.get('index') || 1000
        if (model.has('enabled')) {
            place.enabled = model.get('enabled')
        } else {
            place.enabled = true
        }

        if (model.has('settings')) {
            place.settings = model.get('settings')
        }
        if (model.has('colors')) {
            place.colors = model.get('colors')
        }
        if (model.has('social')) {
            place.social = model.get('social')
        }
        if (model.has('location')) {
            place.location = model.get('location')
        }
        if (model.has('logoUrl')) {
            place.logoUrl = model.get('logoUrl')
        }
        if (model.has('locations')) {
            place.locations = model.get('locations')
        }

        place.status = model.get('status') || 'open'

        /** @fixeme should be relation('users') */
        const teamQuery = model.relation('team').query()
        const teammates = await teamQuery.find()
        for (const t of teammates) {
            place.team.push(t.id)
        }

        return place
    }

    declare public id: any
    public name: string = ''
    public slug: string = ''
    public description: string = ''
    public email: any
    public index: number
    public enabled: boolean
    public phone?: string
    public logoUrl?: string
    public status: string = 'open'
    public hours?: any[]
    public colors: any = {
        headerBackground: '#bbb',
        secondaryColor: '#ggg'
    }
    public settings: any = {}
    public location: any = {
        default: null,
        street: '',
        zipcode: '',
        city: ''
    }
    public locations: any[]
    public social: any = {
        website: '',
        facebook: '',
        twitter: '',
        instagram: ''
    }
    public cards: Card[]
    public cardsObjs: Parse.Object[]
    public team: any[]
    public createdAt: Date

    constructor() {
        super()
        this.cards = []
        this.cardsObjs = []
        this.team = []
        this.hours = []
        this.locations = []
        this.enabled = true
        this.createdAt = new Date()
        this.index = 1000
    }

    public addCard(card: Card) {
        if (this.cards.indexOf(card) === -1) {
            this.cards.push(card)
            if (card.model) {
                this.cardsObjs.push(card.model)
            }
        }
    }

    public dropCard(card: Card) {
        const index = this.cards.indexOf(card)
        if (index > -1) {
            this.cards.splice(index, 1)
        }
    }

    public async save() {
        if (!this.model) {
            this.model = new Parse.Object('Place')
        }
        // const query = new Parse.Query('Place')
        // const model = await query.get(this.id)
        this.model.set('name', this.name)
        this.model.set('description', this.description)
        this.model.set('email', this.email)
        this.model.set('phone', this.phone)
        this.model.set('location', this.location)
        this.model.set('social', this.social)
        this.model.set('colors', this.colors)
        this.model.set('settings', this.settings)
        this.model.set('hours', this.hours)
        this.model.set('locations', this.locations)
        this.model.set('status', this.status)
        this.model.set('enabled', this.enabled)
        this.model.set('index', this.index)

        /*
        const cardsRelation = this.model.relation('cards')
        // Removing all olds cards
        for (const cr of this.cardsObjs) {
            // cardsRelation.query()
            cardsRelation.remove(cr)
        }

        console.log("Saving place, cards are:", this.cards)
        // Adding new cards
        for (const card of this.cards) {
            const query = new Parse.Query('Card')
            const cardObj = await query.get(card.id)
            cardsRelation.add(cardObj)
        }
        */
        await this.model.save()
    }

    public delete() {
        if (this.model) {
            this.model.destroy()
        }
    }
}
