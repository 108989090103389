<template>
    <el-dialog
        :visible.sync="visible"
        :append-to-body='false'
        :modal='false'
        :fullscreen='true'
        :before-close="closeModal">
            <template slot="title">
                <h2 class="subtitle">{{title}}</h2>
            </template>
            <div class="products-picker-search">
                <el-input v-model="query" placeholder="Rechercher" icon="el-icon-fa-search"></el-input>
            </div>
            <div id='productPickerList'>
                <product-item
                    v-for='product in filteredProducts'
                    :ref='product.id'
                    :key='product.id'
                    :product='product'
                    :pickable='true'
                    :showItems='false'
                    @select='handleProductSelected'
                />
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button @click="closeModal" icon='el-icon-close'>Fermer</el-button>
                <el-button type="primary" @click="saveAndClose()" icon='el-icon-circle-check-outline'>Ajouter</el-button>
            </span>
    </el-dialog>
</template>

<script>
import ProductItem from '@/components/Menu/ProductItem'
import ProductPicker from '@/components/Menu/ProductPicker'
export default {
    name: 'ProductPickerModal',
    components: {
        ProductPicker,
        ProductItem
    },
    props: {
        products: {
            type: Array,
            required: true
        },
        visible: {
            type: Boolean,
            required: true
        },
        multiple: {
            type: Boolean,
            required: false,
            default: () => false
        },
        title: {
            type: String,
            required: false,
            default: () => 'Sélectionner les produits à ajouter'
        }
    },
    data: () => ({
        selectedProducts: [],
        selectedProductsIds: {},
        query: ''
    }),
    computed: {
        filteredProducts() {
            if (this.query.length === 0) {
                return this.products
            }
            return this.products.filter((p) => p.name.match(new RegExp(this.query, 'i')))
        }
    },
    mounted() {
        this.selectedProducts = []
        this.selectedProductsIds = {}
    },
    methods: {
        closeModal() {
            this.selectedProducts = []
            this.selectedProductsIds = {}
            this.$emit('close')
        },
        handleProductSelected(product, selected) {
            if (this.multiple) {
                const index = this.selectedProducts.indexOf(product)
                if (index === -1) {
                    this.selectedProducts.push(product)
                    this.$set(this.selectedProductsIds, product.id, true)
                } else {
                    this.selectedProducts.splice(index, 1)
                    this.$set(this.selectedProductsIds, product.id, false)
                }

            } else {
                this.$emit('select', product, selected)
            }
        },
        saveAndClose() {
            for (const product of this.selectedProducts) {
                this.$emit('select', product, true)
            }
            this.closeModal()
        }
    }
}
</script>

<style lang='scss'>
#productPickerList {
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: start;
    /* align-items: start;
    overflow-x: auto;
    overflow-y: inherit; */

    // .product-picker + .product-picker {
    //     margin-left: 10px;
    // }

    .product-item {
        width: 100%;
    }
}
.products-picker-search {
    margin-bottom: 1em;
}
</style>
