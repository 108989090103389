<template>
    <div id='assembliesWizard'>
        <h2>Formules</h2>
        <p>Composez vos différentes formules</p>
        <div id='assembliesWizardList'>
            <template v-if='assemblies.length > 0'>
                <assembly-item
                    v-for='(assembly, $index) in assemblies'
                    :key='$index'
                    :assembly='assembly'
                    :editable='true'
                    :deletable='true'
                    @edit='handleAssemblyEdit'
                    @drop='handleAssemblyDrop'
                />
            </template>
            <template v-else>
                <p class="info"><small>Aucune formule définie</small></p>
            </template>
        </div>
        <div @click="displayAssemblyModal" class='inline-button'>
            <el-button circle size="small" type="primary" icon='el-icon-fa-cubes'></el-button>
            Créer une formule
        </div>
        <!-- <el-button type='primary' @click='displayAssemblyModal' icon='el-icon-fa-cubes'>Créer une formule</el-button> -->
        <assembly-modal v-if='editingAssembly'
            :products='products'
            :categories='categories'
            :visible='view.modal'
            :isEditing='isEditing'
            :assembly='editingAssembly'
            @save='handleSaveAssembly'
            @close='handleCloseModal'
        />
    </div>
</template>

<script>
import Product from '@/lib/entities/Product'
import Category from '@/lib/entities/Category'
import Assembly from '@/lib/entities/Assembly'
import AssemblyModal from '@/components/Menu/AssemblyModal'
import AssemblyItem from '@/components/Menu/AssemblyItem'
export default {
    name: 'AssembliesWizard',
    components: {
        AssemblyModal,
        AssemblyItem
    },
    props: {
        products: {
            type: Array,
            required: true
        },
        categories: {
            type: Array,
            required: true
        },
        assemblies: {
            type: Array,
            required: true
        }
    },
    data: () => ({
        editingAssembly: null,
        isEditing: false,
        view: {
            modal: false
        }
    }),
    methods: {
        displayAssemblyModal() {
            this.view.modal = true
            this.editingAssembly = new Assembly()
        },
        handleSaveAssembly(assembly) {
            this.$emit('save', assembly)
            this.editingAssembly = null
        },
        handleCloseModal() {
            this.view.modal = false
            this.isEditing = false
            setTimeout(() => {
                this.editingAssembly = null
            }, 1000)
        },
        handleAssemblyEdit(assembly) {
            this.editingAssembly = assembly
            this.isEditing = true
            this.view.modal = true
        },
        handleAssemblyDrop(assembly) {
            this.$emit('drop', assembly)
        }
    }
}
</script>

<style lang='scss'>
#assembliesWizard {
    margin-bottom: 1em;
}
#assembliesWizardList {
    margin-bottom: 2em;
}
</style>
