<template>
  <div class="box">
    <h1>Paramètres</h1>
  </div>
</template>

<script>
export default {
  name: 'Settings'
}
</script>

<style>

</style>
