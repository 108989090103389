<template>
    <el-dialog
        :visible.sync="visible"
        width='55%'
        title='Horaires'
        :before-close="close"
        >
            <place-hours-editor @change='save' :hour='hour'/>
            <span slot="footer" class="dialog-footer">
                <el-button @click="close" icon='el-icon-close'>Fermer</el-button>
            </span>
    </el-dialog>
</template>

<script>
import PlaceHoursEditor from '@/components/Place/PlaceHoursEditor'
export default {
    name: 'PlaceHoursEditorModal',
    props: {
        visible: {
            type: Boolean,
            required: true
        },
        hour: {
            type: Object,
            required: false
        }
    },
    components: {
        PlaceHoursEditor
    },
    methods: {
        close() {
            this.$emit('close')
        },
        save() {
            this.$emit('save')
        }
    }
}
</script>

<style>

</style>
