<template>
    <el-dialog
        :visible.sync="visible"
        :append-to-body='false'
        :modal='false'
        :fullscreen='true'
        :before-close="closeModal">
            <template slot="title">
                <h2 class="subtitle"><i class="fa fa-pencil"></i>&nbsp;Editeur de variations</h2>
            </template>
            <product-variation-editor
                :product='product'
                :existing-variation='variation'
                :initial-step='step'
                @save='saveAndClose'
            />
            <span slot="footer" class="dialog-footer">
                <!-- <el-button @click="closeModal" icon='el-icon-close'>Fermer</el-button> -->
            </span>
    </el-dialog>
</template>

<script>
import Product from '@/lib/entities/Product'
import ProductVariationEditor from './ProductVariationEditor'
import ProductVariation from '@/lib/entities/ProductVariation'
export default {
    name: 'ProductVariationModal',
    components: {
        ProductVariationEditor
    },
    data: () => ({
        step: 0
    }),
    props: {
        product: {
            type: Product,
            required: true
        },
        variation: {
            type: ProductVariation,
            required: false
        },
        visible: {
            type: Boolean,
            required: false,
            default: () => true
        }
    },
    methods: {
        closeModal() {
            this.$emit('close')
        },
        saveAndClose(variation) {
            this.$emit('save', this.product, variation)
            this.closeModal()
        }
    }
}
</script>

<style lang='scss'>
</style>
