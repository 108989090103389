<template>
    <el-table id="ordersTable" :data='filteredOrders' style="width: 100%">
        <el-table-column prop="createdAt" label="Date" width="180"></el-table-column>
        <el-table-column prop="number" label="Numéro" width="180"></el-table-column>
        <el-table-column prop="total" label="Total" width="180"></el-table-column>
        <el-table-column prop="customer.firstname" label="Client" width="180"></el-table-column>
        <el-table-column prop="customer.phone" label="Téléphone" width="180"></el-table-column>
        <el-table-column prop="place" label="Etablissement" width="180"></el-table-column>
        <el-table-column prop="card" label="Carte" width="180">
            <template slot-scope="scope">
                {{cardsHash[scope.row.card]}}
            </template>
        </el-table-column>
        <el-table-column v-if='detailable' prop="place" label="Actions" width="80">
            <template slot-scope="scope">
                <el-button type='primary' size='small' @click='openOrderDetails(scope.row)' title='Afficjher' icon='el-icon-fa-eye' circle></el-button>
            </template>
        </el-table-column>
    </el-table>
</template>

<script>
import Vuex from 'vuex'
export default {
    name: 'OrdersTable',
    props: {
        orders: {
            type: Object,
            required: true
        },
        placesHash: {
            type: Object,
            required: true
        },
        detailable: {
            type: Boolean,
            required: false,
            default: () => false
        }
    },
    computed: {
        ...Vuex.mapGetters(['cards']),
        filteredOrders() {
            const orders = []
            for (const place in this.orders) {
                const placeOrders = this.orders[place]
                for (const e of placeOrders) {
                    orders.push({
                        comment: e.comment,
                        completedAt: e.completedAt.format('DD/MM/Y'),
                        createdAt: e.createdAt.format('DD/MM/Y'),
                        index: e.createdAt.unix(),
                        customer: e.customer,
                        id: e.id,
                        card: e.card,
                        items: e.items,
                        number: e.number,
                        settings: e.settings,
                        status: e.status,
                        total: Number(e.total).toFixed(2) + ' €',
                        place: this.placesHash[place]
                    })
                }
            }
            return orders.sort((a, b) => {
                return b.index - a.index
            })
        },
        cardsHash() {
            const h = {}
            for (const c of this.cards) {
                h[c.id] = c.name
            }
            return h
        }
    },
    methods: {
        openOrderDetails(order) {
            this.$router.push({name: 'app.orders.details', params: {id: order.id}})
        }
    }
}
</script>

<style>

</style>
