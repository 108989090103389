<template>
  <div id='asideMenu'>
    <el-menu
      router
      @open="handleOpen"
      @close="handleClose">
      <el-menu-item index="0" id='brand' :route='{name: "app.home"}'>
        <img src="/img/logo_white.png" width="35px">
      </el-menu-item>
      <el-menu-item
        v-for='(item, $index) in items'
        :key='$index'
        :class='[collapse ? "is-collapsed" : ""]'
        :index='item.id'
        :route='item.route'
        :title='item.label'>
          <i :class="item.icon"></i>
          <span>{{item.label}}</span>
      </el-menu-item>
      <el-submenu index="1">
        <template slot="title">
          <i class="el-icon-location"></i>
          <span>Pilot</span>
        </template>
        <el-menu-item @click='openStats' :class='[collapse ? "is-collapsed" : ""]' title="Statistiques" v-if='displayStats' index="stats" :route='{name: "app.home"}'>
          <i class="el-icon-fa-area-chart"></i>
          <span>Statistiques</span>
        </el-menu-item>
        <el-menu-item @click='openCRM' :class='[collapse ? "is-collapsed" : ""]' title="Fichier Clients" v-if='displayStats' index="customers" :route='{name: "app.home"}'>
          <i class="el-icon-fa-user"></i>
          <span>Fichier Clients</span>
        </el-menu-item>
      </el-submenu>
      <el-menu-item @click='openVouchers' :class='[collapse ? "is-collapsed" : ""]' title="Codes Promo" v-if='displayVouchers' index="vouchers" :route='{name: "app.home"}'>
        <i class="el-icon-fa-percent"></i>
        <span>Codes promos</span>
      </el-menu-item>
      <el-menu-item @click='openRefund' :class='[collapse ? "is-collapsed" : ""]' title="Remboursements" v-if='displayStats' index="refund" :route='{name: "app.home"}'>
        <i class="el-icon-fa-refresh"></i>
        <span>Remboursements</span>
      </el-menu-item>
    </el-menu>
    <div id="toogleBtn" role="button" @click="toggleCollapse" :class='[collapse ? "is-collapsed" : ""]'>
      <i :class='[collapse ? "el-icon-fa-chevron-right" : "el-icon-fa-chevron-left"]'></i>
    </div>
  </div>
</template>

<script>
import Vuex from 'vuex'
import urls from '../lib/urls'
export default {
  name: 'AsideMenu',
  props: {
    collapse: {
      type: Boolean,
      required: false,
      default: () => false
    },
    items: {
      type: Array,
      required: true
    }
  },
  computed: {
    ...Vuex.mapState(['user']),
    displayStats() {
      return ['merchant'].includes(this.user.role)
    },
    displayVouchers() {
      return ['merchant'].includes(this.user.role)
    }
  },
  methods: {
    toggleCollapse() {
      this.$emit('collapse')
    },
    handleOpen() {
      //
    },
    handleClose() {
      //
    },
    openCRM() {
      window.open(urls.CRM, '_blank')
      return false
    },
    openStats() {
      window.open(urls.STATS, '_blank')
      return false
    },
    openVouchers() {
      window.open(urls.VOUCHERS, '_blank')
      return false
    },
    openRefund() {
      window.open(urls.REFUND, '_blank')
      return false
    }
  }
}
</script>

<style lang="scss">
#asideMenu {
  overflow: hidden;
  height: 100vh;
  font-family: "League Spartan", 'Avenir', Arial, Helvetica, sans-serif;
  display: flex;
  flex-flow: column;
  justify-content: space-between;
  
  .el-menu-item, .el-submenu__title {
    transition: color 500ms ease;
    padding: 0 15px !important;
    span {
      transition: opacity 250ms ease;
      opacity: 1;
    }

    &.is-collapsed {
      span {
        opacity: 0;
        visibility: hidden;
      }
    }
    
    &.is-active {
      color: #03E2AD;
    }
    
    &:hover, &:focus {
      outline: none;
      background-color: #0a0f1d;
    }
    i[class^="el-icon-"] {
      font-size: 18px !important;
      margin-bottom: 5px;
    }
  }

  .el-submenu {
     .el-menu-item {
       font-size: 12px;
       padding: none;
     }
  }
}

#toogleBtn {
  cursor: pointer;
  padding: 15px;
  color: white;
  font-size: 2em;
  text-align: right;
  transition: all 250ms ease;
  &.is-collapsed {
    text-align: center;
  }
}

.avatar-container {
  padding: 1em 2em;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    width: 100%;
    height: 100%;

    &:hover {
      border-color: #409EFF;
    }

    .avatar-upload-icon {
      width: 100%;
      height: 100%;
      padding: relative;
    }
  }

  .avatar-image {
    border-radius: 300px;
    width: 150px;
    height: 150px;
    background-color: orange;
  }

  .avatar-name {
    margin-top: 10px;
    b {
      font-weight: 800;
    }
  }
}


</style>
