<template>
    <el-dropdown @command='handleNavigation' :hide-on-click='true' trigger='click'>
        <span class="el-dropdown-link">
            <i class="el-icon-menu"></i>
        </span>
        <el-dropdown-menu slot="dropdown">
            <el-dropdown-item v-for='(item, $index) in items' :key='$index' :command='item.route'>
                <i :class="item.icon"></i>&nbsp;{{item.label}}
            </el-dropdown-item>
            <li @click='openStats' title="Statistiques" v-if='displayStats' index="stats" class="el-dropdown-menu__item">
                <i class="el-icon-fa-area-chart"></i>
                <span>&nbsp;Statistiques</span>
            </li>
            <li @click='openCRM' title="Fichier Clients" v-if='displayStats' index="customers" class="el-dropdown-menu__item">
                <i class="el-icon-fa-user"></i>
                <span>&nbsp;Fichier Clients</span>
            </li>
            <li @click='openVouchers' title="Codes Promo" v-if='displayVouchers' index="vouchers" class="el-dropdown-menu__item">
                <i class="el-icon-fa-percent"></i>
                <span>&nbsp;Codes promos</span>
            </li>
            <li @click='openRefund' title="Remboursements" v-if='displayStats' index="refund" class="el-dropdown-menu__item">
                <i class="el-icon-fa-refresh"></i>
                <span>&nbsp;Remboursements</span>
            </li>
        </el-dropdown-menu>
    </el-dropdown>
</template>

<script>
import Vuex from 'vuex'
import urls from '../lib/urls'
export default {
    name: 'SmallNav',
    props: {
        items: {
            required: true,
            type: Array
        }
    },
    methods: {
        handleNavigation(route) {
            this.$router.push(route)
        },
        openCRM() {
            window.open(urls.CRM, '_blank')
            return false
        },
        openStats() {
            window.open(urls.STATS, '_blank')
            return false
        },
        openVouchers() {
            window.open(urls.VOUCHERS, '_blank')
            return false
        },
        openRefund() {
            window.open(urls.REFUND, '_blank')
            return false
        }
    },
    computed: {
        ...Vuex.mapState(['user']),
        displayStats() {
            return ['merchant'].includes(this.user.role)
        },
        displayVouchers() {
            return ['merchant'].includes(this.user.role)
        }
    }
}
</script>

<style>

</style>
