<template>
  <el-form :model="register" status-icon :rules="rules" ref="registerForm">
    <el-form-item prop="email">
      <el-input type="email" v-model="register.email" autocomplete="off" placeholder='Votre adresse email'></el-input>
    </el-form-item>
    <el-form-item prop="phone">
      <el-input type="tel" v-model="register.phone" autocomplete="off" placeholder="Votre numéro de téléphone"></el-input>
    </el-form-item>
    <el-form-item prop="company">
      <el-input type="text" v-model="register.companyName" placeholder="Le nom de votre établissement"></el-input>
    </el-form-item>
    <el-form-item>
      <el-button type="primary" @click="submitForm()">Continuer</el-button>
    </el-form-item>
  </el-form>
</template>

<script>
export default {
  name: 'RegisterForm',
  data: () => ({
    register: {
      email: '',
      phone: '',
      companyName: ''
    },
    rules: {
      email: [
        { required: true, type: 'email', message: 'Merci de saisir votre adresse email', trigger: 'blur' }
      ],
      phone: [
        { required: true, message: 'Merci de saisir votre numéro de téléphone', trigger: 'change' }
      ]
    }
  }),
  methods: {
    submitForm() {
      this.$refs.registerForm.validate((valid) => {
        if (valid) {
          this.$emit('valid', {...this.register})
        }
      })
    }
  }
}
</script>

<style>

</style>
