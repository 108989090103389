/**
 * @todo Sentry
 * @param err
 */
export const error = (err: Error | string) => {
    // if (process.env.NODE_ENV !== 'production') {
    //     // @lint-ignore
    //     console.error(err)
    // }
    console.error(err)
}

/**
 * 
 * @param message
 * @param args
 */
export const debug = (message: any, args = {}) => {
    if (process.env.NODE_ENV !== 'production') {
        // @lint-ignore
        console.log(message, args)
    }
}

interface Timeslot {
    start: string,
    end: string,
    key: string
}

export const getOverlaps = (timeslots: Timeslot[], step: number = 1) => {
    const slots: any = {}
    const overlaps: any[] = []
    for (const t of timeslots) {
        const start = Number(t.start.split(':').join(''))
        const end = Number(t.end.split(':').join(''))
        for (let i = start; i < end; i = i + step) {
            const min = i / 100
            const h = Math.floor(min)
            const m = i - Math.floor(min) * 100
            if (m >= 0 && m < 60) {
                if (slots[i]) {
                    overlaps.push(`${h}:${m}`)
                } else {
                    slots[i] = 1
                }
            }
        }
    }
    return overlaps
}

export const generateTimeslots = (duration: string, from: string, to: string, MAX_LENGTH = 70) => {
    const fHour = (date: Date) => {
        const f_hours = date.getHours()
        const f_minutes = date.getMinutes()
        return [`${f_hours}`, `${f_minutes}`.padStart(2, '0')].join(':')
    }
    let [h, m] = duration.split(':')
    let hours = Number(h)
    let minutes = Number(m)
    minutes += hours * 60

    const [s_h, s_m] = from.split(':')
    const [e_h, e_m] = to.split(':')
    const stop = new Date(0)
    stop.setHours(Number(e_h))
    stop.setMinutes(Number(e_m))

    const cursor = new Date(0)
    cursor.setHours(Number(s_h))
    cursor.setMinutes(Number(s_m))

    const timeslots = []
    let i = 0
    let _from = new Date(cursor)
    let _end = new Date(cursor)
    while (_end < stop) {
        _from = new Date(cursor)
        _from.setMinutes(_from.getMinutes() + minutes * i)
        _end = new Date(_from)
        _end.setMinutes(_from.getMinutes() + minutes)
        if (_end < stop) {
            timeslots.push({
                start: fHour(_from),
                end: fHour(_end)
            })
        } else {
            timeslots.push({
                start: fHour(_from),
                end: fHour(stop)
            })
            break
        }

        i++
        if (i === MAX_LENGTH) {
            break
        }
    }
    return timeslots
}

export const parseAddress = (value: string) => {
    if (!value) {
        return {
            street: '',
            city: '',
            zipcode: ''
        }
    }
    const zipRe = new RegExp(/\d{5}/, 'giu')
    const matches = zipRe.exec(value)
    let zipcode = ''
    if (matches) {
        zipcode = matches[0]
    }
    const fragments = value.split(zipcode)

    const address = {
        street: fragments[0] ? fragments[0].trim() : null,
        city: fragments[1] ? fragments[1].trim() : null,
        zipcode
    }

    return address
}

/**
 * Encode a file into base64
 * @param file
 */
export const base64FromFile = (file: File): Promise<string> => {
    const bufferPromise: Promise<string> = new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.readAsArrayBuffer(file)
        reader.onload = () => {
            // @ts-ignore
            const bytes = Array.from(new Uint8Array(reader.result))
            // @ts-ignore
            const base64StringFile = btoa(bytes.map((item) => String.fromCharCode(item)).join(''))
            resolve(base64StringFile)
        }
        reader.onerror = (err) => reject(err)
    })

    return bufferPromise
}

export const ensureNumber = (value: any) => {
    return Number(typeof value === 'string' ? value.replace(/,/, '.').replace(/[a-z][A-Z]/g, '') : value)
}
