import shortid from 'shortid'
import Store from './Store'
import Parse from 'parse'
export default class Entity {

    public id: string
    public model?: Parse.Object
    protected __type?: string
    protected store?: Store

    constructor() {
        this.id = shortid.generate()
        this.__type = `${this.constructor.name}`
    }

    public setId(id: string) {
        this.id = id
    }

    public setStore(store: Store) {
        this.store = store
    }

    public setModel(model: Parse.Object) {
        this.model = model
        this.id = model.id
    }
}
