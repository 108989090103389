<template>
    <div class="view">
        <h2>Création d'un équipier</h2>
        <div class="box">
            <user-form @updated='handleUserUpdated'></user-form>
        </div>
        <el-button type="info" @click="$router.back()">Annuler</el-button>
        <el-button type="primary" :disabled='!formIsValid' @click="performCreateUser" icon='el-icon-circle-check-outline'>Enregistrer</el-button>
    </div>
</template>

<script>
import Vuex from 'vuex'
import UserForm from '@/components/Team/UserForm'
import { error } from '@/lib/Tools'
export default {
    name: 'TeamEdit',
    components: {
        UserForm
    },
    data: () => ({
        user: {}
    }),
    computed: {
        formIsValid() {
            return this.user.firstname
                && this.user.lastname
                && this.user.email
                && this.user.password
        }
    },
    methods: {
        ...Vuex.mapActions(['createUser']),
        handleUserUpdated(userData) {
            this.user = userData
        },
        async performCreateUser() {
            try {
                await this.createUser(this.user)
                this.$message({
                    message: 'Equipier créé avec succès',
                    type: 'success'
                })
                this.$router.push({name: 'app.team'})
            } catch (err) {
                this.$message({
                    message: 'Une erreur est survenue, merci de réessayer',
                    type: 'error'
                })
                error(err)
            }
        }
    }
}
</script>

<style>

</style>
