<template>
    <div id="categoryFormModal" class="modal" :class="{'is-active': visible}">
        <div class="modal-background"></div>
        <div class="modal-content">
            <category-form
                :editingCategory='editingCategory'
                @save='saveCategory'
                @update='updateCategory' 
                @delete='deleteCategory'>
                <template slot="title">
                    <h2 class="subtitle" v-if='editingCategory'>Modification d'une catégorie</h2>
                    <h2 class="subtitle" v-else>Création d'une catégorie</h2>
                </template>
                <template slot="footer">
                    <button class="button is-light" @click='closeModal'>
                        <span class="icon is-small"><i class="fa fa-times-circle"></i></span>
                        <span>Fermer</span>
                    </button>
                </template>
            </category-form>
        </div>
    </div>
</template>

<script>
import CategoryForm from './CategoryForm'
export default {
    name: 'CategoryFormModal',
    components: {
        CategoryForm
    },
    props: {
        editingCategory: {
            type: Object,
            required: false
        },
        visible: {
            type: Boolean,
            required: true,
            default: () => false
        }
    },
    methods: {
        saveCategory (category) {
            this.$emit('save', category)
            this.closeModal()
        },
        updateCategory (oldCategory, newCategory) {
            this.$emit('update', oldCategory, newCategory)
            this.closeModal()
        },
        deleteCategory (category) {
            this.$emit('delete', category)
        },
        closeModal () {
            this.$emit('close')
        }
    }
}
</script>

<style>

</style>
