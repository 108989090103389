<template>
    <el-dialog
        :visible.sync="visible"
        width='65%'
        title='Aperçu de la carte'
        :before-close="closeModal"
        >
            <div v-if='!loading'>
                <iframe v-if='menuUrl' frameborder="0" height="500px" width="100%" :src='menuUrl'></iframe>
                <el-alert type='warning' title='' v-else class='notification'>
                    <span slot='title'>Cette carte n'est associée à aucun établissement</span>
                </el-alert>
            </div>
            <div v-else>
                Chargement...
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button @click="closeModal" icon='el-icon-close'>Fermer</el-button>
            </span>
    </el-dialog>
</template>

<script>
import Card from '@/lib/entities/Card'
import Vuex from 'vuex'
import { error } from '@/lib/Tools'
export default {
    name: 'CardPreviewModal',
    components: {},
    props: {
        card: {
            type: Card,
            required: true
        },
        visible: {
            type: Boolean,
            required: true
        }
    },
    data: () => ({
        loading: true
    }),
    computed: {
        ...Vuex.mapState(['integrations']),
        menuUrl() {
            if (this.integrations && this.integrations.cards[this.card.id]) {
                return this.integrations.cards[this.card.id].find(int => int.type === 'iframe').url + "?ts=" + Date.now()
            }
            return null
        }
    },
    methods: {
        ...Vuex.mapActions(['getIntegrations']),
        closeModal() {
            this.$emit('close')
        }
    },
    mounted() {
        this.getIntegrations()
        .then(() => this.loading = false)
        .catch((err) => {
            this.loading = false
            error(err)
        })
    }
}
</script>

<style>

</style>
