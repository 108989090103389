import Parse from 'parse'
import User from '@/lib/entities/User'

const Auth = {
    mapRole(role: string) {
        const ROLES_MAPPER: any = {
            merchant: 'Gérant',
            teamate: 'Equipier'
        }
        return ROLES_MAPPER[role] ? ROLES_MAPPER[role] : '-'
    },
    isConnected(): boolean {
        if (Parse.User.current()) {
            return true
        }
        return false
    },
    async login(email: string, password: string) {
        const user = await Parse.User.logIn(email, password)
        const sessionToken = user.getSessionToken()
        let token = null
        const result = await Parse.Cloud.run('getToken')
        if (result.token) {
            token = result.token
        }
        return { sessionToken, token }
    },
    async getUser(remote: boolean = false) {
        let user
        if (remote) {
            // @todo fetch user from backend
            user = Parse.User.current()
        } else {
            user = Parse.User.current()
        }
        if (user) {
            return new User(user)
        }
        return false
    },
    logout() {
        try {
            window.localStorage.clear()
        } catch (err) {
            //
        }
        Parse.User.logOut()
    },
    async register(data: any) {
        const {__model, ...lead} = data
        await Parse.Cloud.run('registerMerchant', lead)
        const { email, password } = lead
        return Auth.login(email, password)
    },
    async resetPassword(email: string) {
        await Parse.User.requestPasswordReset(email)
    }
}

export default Auth
