<template>
    <el-form id="hoursForm" label-width="150px" label-position='left'>
        <el-form-item label="Jour">
            <el-select v-model="hour.day" placeholder="Jour">
                <el-option value='Lundi'>Lundi</el-option>
                <el-option value='Mardi'>Mardi</el-option>
                <el-option value='Mercredi'>Mercredi</el-option>
                <el-option value='Jeudi'>Jeudi</el-option>
                <el-option value='Vendredi'>Vendredi</el-option>
                <el-option value='Samedi'>Samedi</el-option>
                <el-option value='Dimanche'>Dimanche</el-option>
            </el-select>
        </el-form-item>
        <el-form-item label="Heure d'ouverture">
            <el-time-select
                placeholder="Heure d'ouverture"
                v-model="hour.opensAt"
                :picker-options="{
                    start: '00:00',
                    step: '00:15',
                    end: '23:59'
                }">
            </el-time-select>
        </el-form-item>
        <el-form-item label="Heure de fermeture">
            <el-time-select
                placeholder="Heure de fermeture"
                v-model="hour.closesAt"
                :picker-options="{
                    start: '00:00',
                    step: '00:15',
                    end: '24:00',
                    minTime: hour.opensAt
                }">
            </el-time-select>
        </el-form-item>
        <!-- <el-form-item>
            <el-checkbox v-model="hour.isClosed">Fermé</el-checkbox>
        </el-form-item> -->
    </el-form>
</template>

<script>
export default {
    name: 'PlaceHoursEditor',
    props: {
        hour: {
            type: Object,
            required: false,
            default: () => ({
                day: '',
                opensAt: '',
                closesAt: ''
            })
        }
    }
}
</script>

<style>

</style>
