<template>
    <el-form label-width="300px" id='placeOrderingForm' label-position='right'>
        <el-row :gutter="10">
            <el-col :span="12" :xs="24">
                <!-- <h4>Gestion des commandes</h4> -->
                <!-- <el-form-item>
                    <span slot='label'>
                        Acceptation automatique
                        <el-tooltip effect="dark" placement="right">
                            <div slot='content'>Accepter automatiquement les nouvelles commandes lorsqu'elles sont passées pendant les horaires d'ouverture</div>
                            <i class="el-icon-question"></i>
                        </el-tooltip>
                     </span>
                    <el-switch v-model="settings.processing.autoAccept"></el-switch>
                </el-form-item>
                <el-form-item>
                    <span slot='label'>
                        Envoi automatique
                        <el-tooltip effect="dark" placement="right">
                            <div slot='content'>Envoyer les commandes automatiquement</div>
                            <i class="el-icon-question"></i>
                        </el-tooltip>
                    </span>
                    <el-switch v-model="settings.processing.autoComplete"></el-switch>
                </el-form-item>
                <div v-if='settings.processing.autoComplete'>
                    <el-form-item>
                        <span slot='label'>Délai avant envoi automatique (minutes)</span>
                        <el-input-number placeholder="Délai" v-model="settings.processing.autoCompleteTimer" :min='1' :max='30'></el-input-number>
                    </el-form-item>
                </div> -->
                <!-- <el-form-item>
                    <span slot='label'>Une question à poser lors de la commande</span>
                    <el-switch v-model="settings.ordering.askForGuests"></el-switch>
                </el-form-item>
                <div v-if='settings.ordering.askForGuests'>
                    <el-form-item>
                        <span slot='label'>Question</span>
                        <el-input placeholder="Combien de couverts désirez-vous ?" v-model="settings.ordering.askForGuestsMessage"></el-input>
                    </el-form-item>
                </div>-->
                <div id="roomServiceForm">
                    <h4>Service en salle</h4>
                    <el-form-item>
                        <span slot='label'>Proposer le service en salle</span>
                        <el-switch v-model="settings.ordering.roomService"></el-switch>
                    </el-form-item>
                </div>
                <div id="deliveryForm">
                    <h4>Livraison</h4>
                    <el-form-item>
                        <span slot='label'>Proposer la livraison</span>
                        <el-switch v-model="settings.ordering.delivery"></el-switch>
                    </el-form-item>
                    <el-form-item>
                        <span slot='label'>Distance minimale pour la livraison</span>
                        <el-input v-model="settings.ordering.deliveryRange"></el-input>
                    </el-form-item>
                    <el-form-item>
                        <span slot='label'>Montant minimum pour la livraison</span>
                        <el-input v-model="settings.ordering.deliveryMinAmount"></el-input>
                    </el-form-item>
                    <div v-if='settings.ordering.delivery'>
                        <div class="is-secondary" style="margin-top: 20px; margin-bottom: 10px">Zones de livraison</div>
                        <p style="text-align: left; margin-top: 20px">
                            <!-- <small><i class="el-icon-fa-info-circle"></i>&nbsp;Les frais de livraison peuvent être configurés dans l'onglet <router-link :to="{name: 'app.places.edit', params: {id: place.id}, query: {tab: 'settings'}}">Paramètres</router-link></small><br> -->
                            <small><i class="el-icon-fa-info-circle"></i>&nbsp;Les distances sont calculées à partir de l'adresse de votre établissement</small>
                        </p>
                        <el-table style='width: 100%' :data='settings.ordering.deliveryZones'>
                            <el-table-column
                                prop="min"
                                label="Dist. min"
                                width="100">
                                <template slot-scope="scope">
                                    {{scope.row.min}}km
                                </template>
                            </el-table-column>
                            <el-table-column
                                prop="max"
                                label="Dist. max"
                                width="100">
                                <template slot-scope="scope">
                                    {{scope.row.max}}km
                                </template>
                            </el-table-column>
                            <el-table-column
                                prop="min_amount"
                                label="Min. Commande"
                                width="140">
                                <template slot-scope="scope">
                                    {{scope.row.min_amount}} €
                                </template>
                            </el-table-column>
                            <el-table-column
                                prop="price"
                                label="Frais"
                                width="100">
                                <template slot-scope="scope">
                                    {{scope.row.price}} €
                                </template>
                            </el-table-column>
                            <el-table-column>
                                <template slot-scope="scope">
                                    <el-button type='primary' size='small' @click.native.prevent="editZone(scope.row, scope.$index)" title='Modifier cette zone' icon='el-icon-fa-edit' circle></el-button>
                                    <el-button type='dark' size='small' @click.native.prevent="deleteZone(scope.$index)" title='Supprimer cette zone' icon='el-icon-fa-times' circle></el-button>
                                </template>
                            </el-table-column>
                        </el-table>
                        
                        <p style="margin-top: 10px;">
                            <el-button type="primary" size="small" icon='el-icon-plus' @click="displayDeliveryZoneModal">
                                Ajouter une zone
                            </el-button>
                        </p>
                        
                        
                        <!-- <el-form-item>
                            <span slot='label'>Permettre de commander pour un autre jour</span>
                            <el-switch v-model="settings.ordering.allowSchedulingInDelivery"></el-switch>
                        </el-form-item> -->
                        <div class="is-secondary" style="margin-top: 20px;">Créneaux de livraison</div>
                        <p style="text-align: left; margin-bottom: 20px; margin-top: 10px;">
                            <small><i class="el-icon-fa-info-circle"></i>&nbsp;Les clients pourront se faire livrer aux créneaux définis ci-dessous.</small>
                        </p>
                        <el-alert v-if='overlaping' type="warning" show-icon :closable='false' title="Vos créneaux se chevauchent"></el-alert>
                        <div class="place-timeslots">
                            <div class="place-timeslot" v-for='(t, $t) in delivery_timeslots' :key='$t'>
                                <div class="place-timeslot-label">
                                    <i class="el-icon-fa-clock-o"></i>&nbsp;{{t.start}} - {{t.end}}
                                </div>
                                <div class="place-timeslot-slots" v-if='t.slots > 0'>
                                    <i class="el-icon-fa-cubes"></i> {{t.slots}}
                                </div>
                                <div class="place-timeslot-actions">
                                    <el-button type='primary' size='small' @click='editTimeslot(t, $t, "delivery_timeslots")' title='Modifier ce créneau' icon='el-icon-fa-edit' circle></el-button>
                                    <el-button type='dark' size='small' @click='dropTimeslot($t, "delivery_timeslots")' title='Supprimer ce créneau' icon='el-icon-fa-times' circle></el-button>
                                </div>
                            </div>
                            <div style="margin-top: 1em;">
                                <el-button type="primary" size="small" icon='el-icon-plus' @click="displayAddTimeslotModal('delivery_timeslots')">
                                    Ajouter un créneau
                                </el-button>
                            </div>
                        </div>
                    </div>
                </div>
            </el-col>
            <el-col :span="12" :xs="24">
                <!-- <h4>Commandes en ligne</h4>
                <el-form-item>
                    <span slot='label'>Accepter les commandes en direct</span>
                    <el-switch v-model="settings.ordering.directOrdering" disabled></el-switch>
                </el-form-item>
                <div v-if='settings.ordering.directOrdering'>
                    <div class="is-secondary">Horaires d'ouverture de la commande en ligne</div>
                    <place-hours-table :hours='settings.ordering.hours'/>
                    <p style="text-align: left; margin-top: 20px">
                        <small><i class="el-icon-fa-info-circle"></i>&nbsp;La prise de commande en ligne ne sera possible que pendant les créneaux ci-dessus.<br>Si aucun créneau n'est défini, la commande en ligne sera possible 24h/24.</small>
                    </p>
                </div> -->
                
                <!-- <p style="text-align: left;">
                    <small><i class="el-icon-fa-info-circle"></i>&nbsp;Les frais de livraison peuvent être configurés dans l'onglet <router-link :to="{name: 'app.places.edit', params: {id: place.id}, query: {tab: 'settings'}}">Paramètres</router-link></small>
                </p> -->
                <!-- <div v-if='settings.ordering.directOrdering'>
                    <div class="is-secondary">Créneaux de commande</div>
                    <el-alert v-if='overlaping' type="warning" show-icon :closable='false' title="Vos créneaux se chevauchent"></el-alert>
                    <div class="place-timeslots">
                        <div class="place-timeslot" v-for='(t, $t) in timeslots' :key='$t'>
                            <div class="place-timeslot-label">
                                <i class="el-icon-fa-clock-o"></i>&nbsp;{{t.start}} - {{t.end}}
                            </div>
                            <div class="place-timeslot-slots" v-if='t.slots > 0'>
                                <i class="el-icon-fa-cubes"></i> {{t.slots}}
                            </div>
                            <div class="place-timeslot-actions">
                                <el-button type='primary' size='small' @click='editTimeslot(t, $t, "timeslots")' title='Modifier ce créneau' icon='el-icon-fa-edit' circle></el-button>
                                <el-button type='dark' size='small' @click='dropTimeslot($t, "timeslots")' title='Supprimer ce créneau' icon='el-icon-fa-times' circle></el-button>
                            </div>
                        </div>
                        <div style="margin-top: 1em;">
                            <el-button type="primary" size="small" icon='el-icon-plus' @click="view.timeslotModal = true">
                                Ajouter un créneau
                            </el-button>
                        </div>
                    </div>
                </div> -->
                <h4>Click'n Collect</h4>
                <el-form-item>
                    <span slot='label'>Proposer le click'n collect</span>
                    <el-switch v-model="settings.ordering.preOrdering"></el-switch>
                </el-form-item>
                <el-form-item>
                    <span slot='label'>Montant minimum de commande</span>
                    <el-input v-model="settings.ordering.minAmount"></el-input>
                </el-form-item>
                <div v-if='settings.ordering.preOrdering'>
                    <!-- <el-form-item>
                        <span slot='label'>Permettre de commander pour un autre jour</span>
                        <el-switch v-model="settings.ordering.allowScheduling"></el-switch>
                    </el-form-item> -->
                    <el-form-item>
                        <span slot='label'>Activer l'option "Dès que possible"</span>
                        <el-switch v-model="settings.ordering.directOrdering"></el-switch>
                    </el-form-item>
                    <p style="text-align: left; margin-bottom: 20px; margin-top: 10px;">
                        <small><i class="el-icon-fa-info-circle"></i>&nbsp;L'option "Dès que possible" permet aux clients de ne pas avoir à sélectionner de créneaux de retraits.</small>
                    </p>
                    <div class="is-secondary" style="margin-top: 20px;">Créneaux de retraits des commandes</div>
                    <p style="text-align: left; margin-bottom: 20px; margin-top: 10px;">
                        <small><i class="el-icon-fa-info-circle"></i>&nbsp;Les clients pourront retirer leurs commandes aux créneaux définis ci-dessous.</small>
                    </p>
                    <el-alert v-if='overlaping' type="warning" show-icon :closable='false' title="Vos créneaux se chevauchent"></el-alert>
                    <div class="place-timeslots">
                        <div class="place-timeslot" v-for='(t, $t) in timeslots' :key='$t'>
                            <div class="place-timeslot-label">
                                <i class="el-icon-fa-clock-o"></i>&nbsp;{{t.start}} - {{t.end}}
                            </div>
                            <div class="place-timeslot-slots" v-if='t.slots > 0'>
                                <i class="el-icon-fa-cubes"></i> {{t.slots}}
                            </div>
                            <div class="place-timeslot-actions">
                                <el-button type='primary' size='small' @click='editTimeslot(t, $t, "timeslots")' title='Modifier ce créneau' icon='el-icon-fa-edit' circle></el-button>
                                <el-button type='dark' size='small' @click='dropTimeslot($t, "timeslots")' title='Supprimer ce créneau' icon='el-icon-fa-times' circle></el-button>
                            </div>
                        </div>
                        <div style="margin-top: 1em;">
                            <el-button type="primary" size="small" icon='el-icon-plus' @click="displayAddTimeslotModal('timeslots')">
                                Ajouter un créneau
                            </el-button>
                        </div>
                    </div>
                </div>
            </el-col>
        </el-row>
        <place-timeslot-modal
            :index='edititingTimeslotIndex'
            :timeslots='timeslots'
            :timeslot='editingTimeslot'
            :type='editingTimeslotType'
            @save='handleTimeslotCreated'
            :visible='view.timeslotModal'
            @close='handleTimeslotModalClosed'>
        </place-timeslot-modal>
        <delivery-zone-modal
            v-if='currentZone'
            :visible='view.deliveryZoneModal'
            :zone='currentZone'
            :zones='settings.ordering.deliveryZones'
            @close='view.deliveryZoneModal = false'
            @save='handleDeliveryZoneModalClosed'
        />
    </el-form>
</template>

<script>
import { getOverlaps } from '@/lib/Tools'
import Place from '@/lib/entities/Place'
import PlaceHoursTable from '@/components/Place/PlaceHoursTable'
import PlaceTimeslotModal from '@/components/Place/PlaceTimeslotModal'
import DeliveryZoneModal from '@/components/Place/DeliveryZoneModal'
export default {
    name: 'PlaceOrderingForm',
    components: {
        PlaceTimeslotModal,
        DeliveryZoneModal,
        PlaceHoursTable
    },
    props: {
        place: {
            type: Object,
            required: true
        }
    },
    computed: {
        delivery_timeslots() {
            return this.settings.ordering.delivery_timeslots
        },
        timeslots() {
            return this.settings.ordering.timeslots
        },
        overlaping() {
            const overlaps = getOverlaps(this.settings.ordering.timeslots)
            return overlaps.length > 0
        }
    },
    data: () => ({
        currentZone: null,
        edititingTimeslotIndex: null,
        editingTimeslotType: '',
        editingTimeslot: {
            start: '',
            end: '',
            slots: 0
        },
        view: {
            timeslotModal: false,
            deliveryZoneModal: false
        },
        // Default settings
        settings: {
            processing: {},
            ordering: {
                hours: [],
                directOrdering: true,
                preOrdering: false,
                allowScheduling: false,
                timeslots: [],
                delivery_timeslots: [],
                deliveryZones: [],
                delivery: false,
                vouchers: false,
                deliveryRange: 0,
                deliveryMinAmount: 0,
                askForGuests: true,
                askForGuestsMessage: 'Combien de couverts désirez-vous ?'
            },
            fees: {
                delivery: 0
            }
        }
    }),
    mounted() {
        this.settings.processing = {
            ...this.settings.processing,
            ...this.place.settings.processing,
        }
        this.settings.ordering = {
            ...this.settings.ordering,
            ...this.place.settings.ordering,
        }

        this.$watch('settings', (settings) => {
            this.$emit('settingsUpdated', settings)
        }, {
            deep: true
        })
    },
    methods: {
        handleTimeslotModalClosed() {
            this.edititingTimeslotIndex = null
            this.editingTimeslot = {
                start: '',
                end: '',
                slots: 0
            }
            this.view.timeslotModal = false
        },
        handleTimeslotCreated (timeslot, index) {
            const type = timeslot.type
            if (index) {
                this.settings.ordering[type][index] = timeslot
            } else {
                if (this.settings.ordering[type].indexOf(timeslot) === -1) {
                    this.settings.ordering[type].push(timeslot)
                }
            }
            this.edititingTimeslotIndex = null
            this.editingTimeslotType = ''
            this.view.timeslotModal = false
            this.editingTimeslot = {
                start: '',
                end: '',
                slots: 0
            }
        },
        dropTimeslot(index, type) {
            this.settings.ordering[type].splice(index, 1)
        },
        editTimeslot(timeslot, index, type) {
            this.edititingTimeslotIndex = index
            this.editingTimeslotType = type
            this.editingTimeslot = {...timeslot}
            this.view.timeslotModal = true
        },
        displayAddTimeslotModal(type) {
            this.editingTimeslotType = type
            this.editingTimeslot = {
                start: '',
                end: '',
                slots: 0,
                fresh: true,
                type
            }
            this.view.timeslotModal = true
        },
        displayDeliveryZoneModal() {
            this.currentZone = {
                index: null,
                min: 0,
                max: 10,
                price: 0,
                min_amount: 0
            }
            this.view.deliveryZoneModal = true
        },
        handleDeliveryZoneModalClosed(zone, existing) {
            if (existing === false) {
                this.settings.ordering.deliveryZones.push(zone)
            } else {
                this.$set(this.settings.ordering.deliveryZones, zone.index, zone)
            }
            this.currentZone = {
                index: null,
                min: 0,
                max: 10,
                price: 0,
                min_amount: 0
            }
            this.view.deliveryZoneModal = false
        },
        editZone(zone, index) {
            this.currentZone = Object.assign({}, zone)
            this.currentZone.index = index
            this.view.deliveryZoneModal = true
        },
        deleteZone(index) {
            this.settings.ordering.deliveryZones.splice(index, 1)
        }
    }
}
</script>
