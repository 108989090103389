import Entity from '@/lib/entities/Entity'
import Category from '@/lib/entities/Category'
import Store from '@/lib/entities/Store'
import ProductVariation from '@/lib/entities/ProductVariation'
import Parse from 'parse'

export default class Product extends Entity {

  // GETTERS -- SETTERS
  get hasVariablePrice() {
    return this.settings.variablePrice || false
  }

  set hasVariablePrice(val) {
    this.settings.variablePrice = val
  }

  /**
   * Boot a new product from database
   * @param model DB model
   * @param store Object store
   */
  public static boot(model: Parse.Object, store: Store) {

    const product = new Product()
    product.setStore(store)
    product.setModel(model)
    product.name = model.get('name')
    product.description = model.get('description')
    product.vat = model.get('vat')
    product.image = model.get('image')
    product.tags = model.get('tags')
    product.status = model.get('status')
    product.settings = model.get('settings')
    product.ingredients = model.get('ingredients')

    if (model.has('variations')) {
      product.variations = model.get('variations').map((data: any) => {
        const variation = new ProductVariation(data)
        variation.setProduct(product)
        return variation
      })
    } else {
      product.variations = []
    }

    const modelCategory = model.get('category')
    if (modelCategory) {
      const category = store.categories.find((cat: Category) => cat.id === modelCategory.id)
      if (category) {
        product.setCategory(category)
        category.addProduct(product)
      }
    }
    return product
  }

  public name: string = ''
  public description: string = ''
  public sku: string = ''
  public image: string = ''
  public vat: number = 10
  public tags: string[] = []
  public ingredients: string[] = []
  public properties: any[] = []
  public status = 'available'
  public override = false
  public settings: any = {}
  public category?: Category
  public variations: ProductVariation[] = []

  constructor(props?: any) {
    super()
    if (props) {
      this.name = props.name
      this.description = props.description
      this.sku = props.sku
      this.image = props.image
      this.vat = props.vat
      this.tags = props.tags || []
      this.ingredients = props.ingredients || []
      this.variations = props.variations || []
      this.properties = props.properties || []
      this.category = props.category || null
      this.settings = props.settings || {}
    }
  }

  public setCategory(category: Category) {
    if (this.category && this.category !== category) {
      this.category.deleteProduct(this)
    }
    if (this.vat === undefined) {
      this.vat = category.vat
    }
    this.category = category
  }

  public addTag(tag: string) {
    this.tags.push(tag)
  }

  public removeTag(tag: string) {
    const index = this.tags.indexOf(tag)
    if (index > -1) {
      this.tags.splice(index, 1)
    }
  }

  public addProperty(property: {
    key: string,
    value: string
  }) {
    this.properties.push(property)
  }

  public dropProperty(property: any) {
    const index = this.properties.indexOf(property)
    if (index > -1) {
      this.properties.splice(index, 1)
    }
  }

  public hasVariation(variation: ProductVariation): Boolean {
    if (!variation.id) return false
    for (const i in this.variations) {
      if (this.variations[i].id == variation.id) {
        return true
      }
    }
    return false
  }

  public updateVariation(variation: ProductVariation) {
    for(const i in this.variations) {
      if (this.variations[i].id == variation.id) {
        this.variations[i] = variation
        break
      }
    }
  }

  public addVariation(variation: ProductVariation) {
    if (!this.hasVariation(variation)) {
      variation.setProduct(this)
      this.variations.push(variation)
    }
  }

  public dropVariation(variation: ProductVariation) {
    const index = this.variations.indexOf(variation)
    if (index > -1) {
      this.variations.splice(index, 1)
    }
  }

  public isExternal() {
    return this.settings && this.settings.externalId
  }

  public hasValidProps(): boolean {
    return this.name.length > 0
  }

  public delete() {
    if (this.category) {
      this.category.deleteProduct(this)
    }

    if (this.model) {
      this.model.destroy()
    }
  }

  public clone() {
    return new Product(this)
  }

  public async save() {
    
    if (!this.model) {
      this.model = new Parse.Object('Product')
    }
    if (this.store) {
      this.model.set('merchant', this.store.merchant)
    }
    this.model.set('name', this.name)
    this.model.set('description', this.description)
    this.model.set('sku', this.sku)
    this.model.set('vat', this.vat)
    if (this.category && this.category.model) {
      this.model.set('category', this.category.model)
    }
    this.model.set('tags', this.tags)
    this.model.set('ingredients', this.ingredients)
    this.model.set('properties', this.properties)
    this.model.set('variations', this.variations.map(function (variation) {
      delete(variation.product)
      return variation
    }))
    this.model.set('status', this.status)
    this.model.set('image', this.image)
    this.model.set('settings', this.settings)
    await this.model.save()
    this.setId(this.model.id)
  }

}
