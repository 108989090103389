<template>
    <el-form label-width="120px">
        <el-form-item label="Prénom">
            <el-input v-model='model.firstname' :value='user.firstname'></el-input>
        </el-form-item>
        <el-form-item label="Nom">
            <el-input v-model='model.lastname' :value='user.lastname'></el-input>
        </el-form-item>
        <el-form-item label="Email">
            <el-input v-model='model.email' disabled></el-input>
        </el-form-item>
        <el-form-item label="Téléphone">
            <el-input v-model='model.phone' :value='user.phone'></el-input>
        </el-form-item>
        <el-form-item label="Rôle">
            <el-input v-model='model.role' disabled></el-input>
        </el-form-item>
        <el-form-item>
            <el-button type="primary" @click='updateUserProfile'>Mettre à jour</el-button>
        </el-form-item>
    </el-form>
</template>

<script>
import Auth from '@/lib/Auth'
import User from '@/lib/entities/User'
export default {
    name: 'UserProfileForm',
    props: {
        user: {
            type: User
        }
    },
    data: () => ({
        model: {
            id: null,
            firstname: '',
            lastname: '',
            email: '',
            phone: '',
            role: null,
            mobilePhone: null
        }
    }),
    mounted() {
        this.model.id = this.user.id
        this.model.firstname = this.user.firstname
        this.model.lastname = this.user.lastname
        this.model.email = this.user.email
        this.model.phone = this.user.phone
        this.model.role = Auth.mapRole(this.user.role)
        this.model.mobilePhone = this.user.mobilePhone
    },
    methods: {
        updateUserProfile() {
            this.$emit('save', this.model)
        }
    }
}
</script>

<style>

</style>
