<template>
    <div id='p404'>
        <h1>Cette page n'existe pas</h1>
        <div>
            <router-link class="back" :to='{name: "app.home"}'>Retour</router-link>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Page404'
}
</script>

<style lang='scss'>
#p404 {
    background: rgb(3,226,173);
    background: linear-gradient(135deg, rgba(3,226,173,1) 0%, rgba(5,171,132,1) 100%);
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    h1 {
        font-family: 'League Spartan';
        color: white;
    }

    .back {
        color: white;
        font-weight: 600;
        text-decoration: none;
    }
}
</style>
