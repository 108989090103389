import Vue from 'vue'
import Vuex from 'vuex'
import Auth from '@/lib/Auth'
// import { categories, products } from '@/lib/entities/mock'
import * as Api from '@/lib/Api'
import Card from '@/lib/entities/Card'
import User from '@/lib/entities/User'
import Merchant from '@/lib/entities/Merchant'
import Place from '@/lib/entities/Place'
import Product from '@/lib/entities/Product'
import Category from '@/lib/entities/Category'
import Store from '@/lib/entities/Store'
import Assembly from '@/lib/entities/Assembly'
import router from '../router'


Vue.use(Vuex)

/** @todo */
const handleError = (err: any) => {
    if (err.code === 209) {
        router.push({name: 'auth.login'})
    }
}
let token = null
try {
    token = window.localStorage.getItem('token')
} catch (err) {
    token = null
}

export default new Vuex.Store({
    state: {
        token,
        lead: {} as any,
        vatsRates: [0, 2.1, 5.5, 6, 10, 20, 21], // @version 2021-12-01 ++ 6, 21, @version 2022-07-01 ++ 2.1
        notifications: [],
        integrations: {},
        store: new Store(),
        user: {} as Partial<User>,
        merchant: {} as Partial<Merchant>,
        places: [] as Place[],
        orders: [] as string[],
        team: [],
        _orders: {} as any,
        editingCard: {} as any,
        editingCardStatus: {} as any
    },
    getters: {
        $intercom(state) {
            // @ts-ignore
            return window.Intercom
        },
        has_external_integrations(state, getters) {
            if (state.merchant.settings) {
                return state.merchant.settings.hasOwnProperty('pongo_id') && state.merchant.settings.pongo_id !== null && state.merchant.settings.pongo_id != ''
            }
            return false
        },
        categories(state, getters) {
            if (state.store) {
                return state.store.categories
            }
            return []
        },
        products(state, getters) {
            if (state.store) {
                return state.store.products
            }
            return []
        },
        assemblies(state, getters) {
            if (state.store) {
                return state.store.assemblies
            }
            return []
        },
        cards(state, getters) {
            if (state.store) {
                return state.store.cards
            }
            return []
        },
        pendingOrders(state, getters) {
            const orders = []
            for (const oid of state.orders) {
                orders.push(state._orders[oid])
            }
            return orders.filter((o) => o.status === 'pending')
        },
        stats(state, getters) {
            return {
                sales: {
                    total: 0,
                    today: 0
                },
                orders: {
                    total: 0,
                    today: 0
                },
                averages: {
                    ticket: 0,
                    order: 0
                },
                customers: {
                    total: 0
                }
            }
        }
    },
    mutations: {
        SET_TOKEN(state, authToken) {
            state.token = authToken
            try {
                window.localStorage.setItem('token', authToken)
            } catch (err) {
                //
            }
        },
        SET_LEAD(state, lead) {
            state.lead = lead
        },
        UPDATE_LEAD(state, lead) {
            state.lead = { ...state.lead, ...lead }
        },
        SET_USER(state, user) {
            state.user = user
        },
        SET_NOTIFICATIONS(state, notifications) {
            if (notifications) {
                state.notifications = notifications
            }
        },
        SET_INTEGRATIONS(state, integrations) {
            state.integrations = integrations
        },
        UPDATE_MERCHANT(state, merchant) {
            state.merchant = merchant
        },
        SET_MERCHANT(state, merchant) {
            state.merchant = new Merchant(merchant)
        },
        SET_CARDS(state, cards) {
            state.store.setCards(cards)
        },
        SET_PRODUCTS(state, products) {
            state.store.setProducts(products)
        },
        DELETE_PRODUCT(state, product) {
            state.store.deleteProduct(product)
        },
        SET_CATEGORIES(state, categories: Category[]) {
            state.store.setCategories(categories)
        },
        DELETE_CATEGORY(state, category) {
            state.store.deleteCategory(category)
        },
        DELETE_CARD(state, card) {
            state.store.deleteCard(card)
        },
        SET_ASSEMBLIES(state, assemblies) {
            state.store.setAssemblies(assemblies)
        },
        DELETE_ASSEMBLY(state, assembly: Assembly) {
            state.store.deleteAssembly(assembly)
        },
        UPDATE_PROFILE(state, user) {
            state.user.firstname = user.firstname
            state.user.lastname = user.lastname
            state.user.email = user.email
            state.user.phone = user.phone
        },
        SET_STORE(state, store) {
            state.places = store.places
            state.store = store
        },
        SET_PLACES(state, places) {
            state.places = places
        },
        ADD_PLACE(state, place) {
            // The place is already added in the store
            state.places = state.store.places
        },
        SET_TEAM(state, team) {
            state.team = team
        },
        UPDATE_PLACE(state, place) {
            state.places = [
                ...state.places.filter((p) => p.id !== place.id),
                place
            ]
        },
        SET_ORDERS(state, orders) {
            for (const order of orders) {
                if (state.orders.indexOf(order.id) === -1) {
                    state.orders.push(order.id)
                }
                Vue.set(state._orders, order.id, order)
            }
        },
        UPDATE_ORDER_STATUS(state, { order, status}) {
            const Order = state._orders[order.id]
            Order.status = status
        },
        SET_EDITING_CARD(state, card) {
            state.editingCard = card
        },
        SET_EDITING_CARD_STATUS(state, status) {
            state.editingCardStatus = status
        },
        UPDATE_PRODUCT_STATUS(state, {productId, status}) {
            Vue.set(state.editingCardStatus, productId, status)
        }
    },
    actions: {
        setToken(context, token) {
            context.commit('SET_TOKEN', token)
        },
        async storeLeadData(context, data) {
            const lead = await Api.updateLead(data)
            context.commit('SET_LEAD', lead)
        },
        async saveOnboardingProgress(context, data) {
            const lead = await Api.updateLead(data)
            context.commit('UPDATE_LEAD', lead)
        },
        async getOnboardingQuestions(context) {
            const questions = await Api.getOnboardingQuestions()
            return questions
        },
        async storeCards(context, cards) {
            context.commit('SET_CARDS', cards)
        },
        async storeProducts(context, products) {
            context.commit('SET_PRODUCTS', products)
        },
        async deleteCategory(context, category) {
            context.commit('DELETE_CATEGORY', category)
        },
        async storeCategories(context, categories) {
            context.commit('SET_CATEGORIES', categories)
        },
        async storeAssemblies(context, assemblies) {
            context.commit('SET_ASSEMBLIES', assemblies)
        },
        async deleteAssembly(context, assembly) {
            // context.commit('DELETE_ASSEMBLY', assembly)
            return context.state.store.deleteAssembly(assembly)
        },
        async deleteCard(context, card) {
            context.commit('DELETE_CARD', card)
        },
        async deleteProduct(context, product) {
            context.commit('DELETE_PRODUCT', product)
        },
        async updateProfile(context, user) {
            await Api.updateProfile(user)
            context.commit('UPDATE_PROFILE', user)
        },
        async updateMerchant(context, merchant) {
            await Api.updateMerchant(merchant)
            context.commit('UPDATE_MERCHANT', merchant)
        },
        async updatePlace(context, place: Place) {
            await place.save()
            context.commit('UPDATE_PLACE', place)
        },
        async createPlace(context, placeData: any) {
            const place = await context.state.store.createPlace(placeData)
            context.commit('ADD_PLACE', place)
            return place
        },
        async handleRemotePlaceUpdated(context, { placeId, status }) {
            const place = context.state.places.find((p) => p.id === placeId)
            if (place) {
                place.status = status
                context.commit('UPDATE_PLACE', place)
            }
        },
        async handleRemoteCardProductUpdated(context, { cardId, productId, status, price, stock }) {
            const card = context.state.store.cards.find((c) => c.id === cardId)
            if (card) {
                const product = card.getProductById(productId)
                if (status) {
                    card.status[productId] = status
                }
                /* Disabled so far but working */
                // if (stock) {
                //     card.stocks[productId] = stock
                // }
                // if (price) {
                //     card.prices[productId] = price
                // }
                return product
            }
        },
        async registerUser(context, data) {
            const { token } = await Auth.register(data)
            context.commit('SET_TOKEN', token)
        },
        async getUser(context) {
            const user = await Auth.getUser()
            context.commit('SET_USER', user)
        },
        async getMerchant(context) {
            const merchant = await Api.getMerchant()
            context.commit('SET_MERCHANT', merchant)
        },
        async getPlaces(context) {
            const places = await context.state.store.getPlaces()
            context.commit('SET_PLACES', places)
        },
        async getCards(context, id) {
            const cards = await context.state.store.getCards()
            return cards
        },
        async getCard(context, id) {
            const card = await Api.getCard(id, context.state.store)
            return card
        },
        async getOrders(context) {
            const orders = await Api.getOrders()
            context.commit('SET_ORDERS', orders)
        },
        async updateOrderStatus(context, { order, status }) {
            context.commit('UPDATE_ORDER_STATUS', { order, status })
            await Api.updateOrder(order, status)
        },
        async storeMerchantLogo(context, {filename, file}) {
            return await Api.storeMerchantLogo(filename, file)
        },
        async storePlaceLogo(context, {filename, file, placeId}) {
            return await Api.storePlaceLogo(filename, file, placeId)
        },
        async storeProductImage(context, {filename, file, productId}) {
            return await Api.storeProductImage(filename, file, productId)
        },
        async storeAssemblyImage(context, {filename, file, assemblyId}) {
            return await Api.storeProductImage(filename, file)
        },
        async getStore(context) {
            const store = await Store.boot()
            context.commit('SET_STORE', store)
        },
        async saveStore(context, { cards, products, categories, assemblies }) {
            await context.state.store.save({ cards, products, categories, assemblies })
        },
        async getProviders(context) {
            return Api.getProviders()
        },
        async getIntegrations(context) {
            const integrations = await Api.getIntegrations()
            context.commit('SET_INTEGRATIONS', integrations)
        },
        async getTeam(context) {
            const team = await Api.getTeam()
            if (team) {
                context.commit('SET_TEAM', team)
            }
        },
        async createUser(context, user) {
            await Api.createUser({
                firstname: user.firstname,
                lastname: user.lastname,
                email: user.email,
                phone: user.phone,
                companyRole: user.role,
                password: user.password,
                placesIds: user.placesIds
            })
        },
        async updateUser(context, user) {
            await Api.updateUser({
                id: user.id,
                firstname: user.firstname,
                lastname: user.lastname,
                email: user.email,
                phone: user.phone,
                companyRole: user.role,
                placesIds: user.placesIds
            })
        },
        subscribe(context) {
            return Api.subscribeToEventStream(context.state.token)
        },
        getAssembly(context, id) {
            return context.state.store.getAssembly(id)
        },
        getAssemblies(context) {
            return context.state.store.getAssemblies()
        },
        refreshIntegrations(context) {
            return Api.refreshIntegrations()
        }
    },
})
