<template>
    <div class="product-item" @click='handleClick' :draggable="draggable ? 'on' : 'off'" :class='{"is-draggable": draggable, "is-pricable": pricable}'>
        <div class='product'>
            <el-button v-if='draggable' size="mini" circle type="text" class="product-handle"><i class="el-icon-d-caret"></i></el-button>
            <div class="product-actions is-left" v-if='pickable'>
                <div class="product-item-picker">
                    <el-checkbox :checked="selected" @change='handleProductSelected'></el-checkbox>
                </div>
            </div>
            <div class="product-data">
                <div class="product-image" v-if='showImage' @click='handleProductEdit'>
                    <img :src="product.image" v-if='product.image'>
                </div>
                <div class="product-info">
                    <p style="font-weight: 600">{{product.name}}  <span class="optional" v-if='optional'>(Option)</span></p>
                    <small>{{product.description}}</small>
                </div>
            </div>
            <div class="product-actions is-right">
                <template v-if='pricable'>
                    <div class="item-vat" v-if='showVat' title='TVA'>
                        <div class="label">TVA</div>
                        <el-select size="small" :value='vat' @change='handleVatChanged'>
                            <el-option v-for='rate in vatsRates' :key='rate' :label='`${rate} %`' :value='rate'></el-option>
                        </el-select>
                    </div>
                    <div class="item-price" title="Prix">
                        <div class="label" v-if='!showPriceEditor' @click='displayPriceEditor'>
                            <span>{{price|EUR}}</span> TTC
                        </div>
                        <div v-else>
                            <el-button type="success" title='Enregister' circle size="mini" @click='handlePriceChanged' icon="el-icon-check"></el-button>
                            <el-input type="text" min="0" class="input" autofocus='autofocus' v-model='priceInput'></el-input>
                        </div>
                    </div>
                </template>
                
                <div class="item-stock" v-if='stockable' title="Stock">
                    <div class="label" v-if='!showStockEditor' @click='showStockEditor = true'>
                        <span>{{stock}}</span> Q.
                    </div>
                    <div v-else>
                        <el-button type="success" title='Enregister' circle size="mini" @click='handleStockChanged' icon="el-icon-check"></el-button>
                        <el-input type="text" min="0" step="1" class="input" v-model='stockInput' placeholder="Stock" style="width:80px"></el-input>
                    </div>
                </div>
                <div v-if='statuable' class="product-status">
                    <el-button @click="handleProductStatus" :type='status == "available" ? "success" : "light"' size="small" title="Status" :icon='status == "available" ? "el-icon-fa-thumbs-up" : "el-icon-fa-thumbs-down"'>
                        <!-- <span v-if='status == "available"'>Désactiver</span>
                        <span v-else>Activer</span> -->
                    </el-button>
                </div>
                <div v-if='editable' class="product-edit">
                    <el-button @click="handleProductEdit" size="small" type="primary" title="Modifier" circle icon="el-icon-fa-edit"></el-button>
                </div>
                <div v-if='deletable' class="product-delete">
                    <el-button @click="handleProductDrop" type="light" size="small" title="Supprimer" circle icon="el-icon-fa-times"></el-button>
                </div>
            </div>
        </div>
        <div class='product-variations' v-if='showVariations'>
            <product-variation-item
                v-for='variation in displayableVariations'
                :key='variation.id'
                :pricable='pricable'
                :stockable='stockable'
                :statuable='statuable'
                :expandable='true'
                :price='prices[variation.id]'
                :prices='variationsPrices(variation.id)'
                :status='variationsStatus(variation.id)'
                :cardStatus='variationsOptionStatus(variation.id)'
                @priceChanged='handleVariationPriceChanged'
                @statusChanged='handleVariationStatusChanged'
                @itemStatusChanged='handleItemVariationStatusChanged'
                :variation='variation'/>
        </div>
        <div class='assembly-items' v-if='showItems'>
            <div v-for='(item, $si) in sortedSubItems' :key='$si'>
                <p class="f6">
                    <span v-if='item.category'>{{item.category.name}}</span>
                    <span v-else>Sans catégorie</span>
                    <span class="optional" v-if='product.product.optionals[item.id]'>(Option)</span>
                </p>
                <div class="product-item-products">
                    <product-sub-item
                        v-for='(subproduct, $pi) in item.products' :key='$pi'
                        :product='subproduct'
                        :status='cardStatus[subproduct.id]'
                        :pricable='pricable'
                        :parent='product'
                        :statuable='statuable'
                        :optional='product.product.optionals[item.id]'
                        :extra='extras[product.id+"-"+subproduct.id]'
                        @statusChanged='handleSubItemStatusChanged'
                        @extraChanged='handleSubItemExtraChanged'
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Vuex from 'vuex'
import ProductSubItem from '@/components/Menu/ProductSubItem'
import ProductVariationItem from '@/components/Menu/ProductVariationItem'
export default {
    name: 'ProductItem',
    components: {
        ProductVariationItem,
        ProductSubItem
    },
    props: {
        product: {
            type: Object,
            required: true
        },
        editable: {
            type: Boolean,
            required: false,
            default: () => false
        },
        statuable: {
            type: Boolean,
            required: false,
            default: () => false
        },
        pickable: {
            type: Boolean,
            required: false,
            default: () => false
        },
        deletable: {
            type: Boolean,
            required: false,
            default: () => false
        },
        pricable: {
            type: Boolean,
            required: false,
            default: () => false
        },
        stockable: {
            type: Boolean,
            required: false,
            default: () => false
        },
        draggable: {
            type: Boolean,
            required: false,
            default: () => false
        },
        selected: {
            type: Boolean,
            required: false,
            default: () => false
        },
        optional: {
            type: Boolean,
            required: false,
            default: () => false
        },
        showImage: {
            type: Boolean,
            required: false,
            default: () => true
        },
        showVariations: {
            type: Boolean,
            required: false,
            default: () => false
        },
        showItems: {
            type: Boolean,
            required: false,
            default: () => false
        },
        showOnlyPricableVariations: {
            type: Boolean,
            required: false,
            default: () => false
        },
        showVat: {
            type: Boolean,
            required: false,
            default: () => false
        },
        price: {
            type: Number,
            required: false
        },
        vat: {
            type: Number,
            required: false
        },
        stock: {
            type: Number,
            required: false
        },
        status: {
            type: String,
            required: false,
            default: () => 'available'
        },
        prices: {
            type: Object,
            required: false,
            default: () => ({})
        },
        vats: {
            type: Object,
            required: false,
            default: () => ({})
        },
        extras: {
            type: Object,
            required: false,
            default: () => ({})
        },
        cardStatus: {
            type: Object,
            required: false,
            default: () => ({})
        }
    },
    data: () => ({
        priceInput: 0,
        stockInput: 0,
        showPriceEditor: false,
        showVatEditor: false,
        showStockEditor: false
    }),
    computed: {
        ...Vuex.mapState(['vatsRates']),
        displayableVariations() {
            if (this.product.variations) {
                const variations = this.product.variations
                if (this.showOnlyPricableVariations) {
                    return variations.filter((variation) => variation.settings.pricable)
                }
                return variations
            }
            return []
        },
        sortedSubItems() {
            try {
                const indexes = this.product.product.indexes
                if (!indexes || Object.keys(indexes).length === 0) {
                    return this.product.product.items
                }
                const hash = []
                for (const categoryId in indexes) {
                    hash.push({
                        categoryId,
                        index: indexes[categoryId]
                    })
                }
                const sortedHash = hash.sort((a, b) => {
                    if (a.index > b.index) {
                        return 1
                    }
                    if (b.index > a.index) {
                        return -1
                    }
                    return 0
                })
                const items = this.product.product.items
                const sortedItems = []
                for (const el of sortedHash) {
                    const item = items.find((i) => i.category.id === el.categoryId)
                    if (item !== undefined) {
                        sortedItems.push(item)
                    }
                }
                return sortedItems
            } catch (err) {
                return this.product.product.items
            }
        }
    },
    // watch: {
    //     price (p) {
    //         this.priceInput = parseFloat(p)
    //     }
    // },
    mounted() {
        this.priceInput = this.$props.price || 0
        this.stockInput = this.$props.stock || 0
    },
    methods: {
        handleProductEdit() {
            if (this.editable) {
                this.$emit('edit', this.product)
            }
        },
        handleProductDrop() {
            this.$emit('drop', this.product)
        },
        handleProductSelected() {
            this.$emit('select', this.product, this.selected)
        },
        handleClick() {
            // if (this.pickable && !this.editable) {
            //     return this.handleProductSelected()
            // }
            if (this.editable && !this.deletable) {
                return this.edit()
            }
        },
        handlePriceChanged() {
            this.showPriceEditor = false
            const price = parseFloat(`${this.priceInput}`.replace(',', '.').replace('\w', ''))
            this.$emit('priceChanged', this.product, price, null)
        },
        handleStockChanged() {
            this.showStockEditor = false
            const stock = parseInt(this.stockInput)
            this.$emit('stockChanged', this.product, stock)
        },
        handleProductStatus() {
            let newStatus
            if (this.status === 'available') {
                newStatus = 'unavailable'
            } else {
                newStatus = 'available'
            }
            this.$emit('statusChanged', this.product, newStatus)
        },
        handleVariationStatusChanged(variation, status) {
            this.$emit('itemStatusChanged', variation.id, status)
        },
        handleItemVariationStatusChanged(variation, option, status) {
            this.$emit('itemStatusChanged', variation.id + "|" + option, status)
        },
        handleVariationPriceChanged(variation, price, options) {
            price = parseFloat(`${price}`.replace(',', '.').replace('\w', ''))
            this.$emit('priceChanged', variation, price, options)
        },
        handleSubItemExtraChanged(item, parent, price) {
            price = parseFloat(`${price}`.replace(',', '.').replace('\w', ''))
            this.$emit('itemExtraChanged', item, parent, price)
        },
        handleSubItemStatusChanged(item, status) {
            this.$emit('statusChanged', item, status)
        },
        handleVatChanged(vat) {
            vat = parseFloat(`${vat}`.replace(',', '.').replace('\w', ''))
            this.showVatEditor = false
            this.$emit('vatChanged', this.product, vat)
        },
        displayPriceEditor() {
            this.showPriceEditor = true
        },
        variationsPrices(id) {
            let prices = {}
            for (const key in this.prices) {
                const [vid, variation] = key.split('|')
                if (id == vid) {
                    prices[variation] = this.prices[key]
                }
            }
            return prices
        },
        variationsStatus(id) {
            for (const key in this.cardStatus) {
                const [variation, option] = key.split('|')
                if (variation == id && option == undefined) {
                    return this.cardStatus[key]
                }
            }
            return "available"
        },
        variationsOptionStatus(id) {
            let status = {}
            for (const key in this.cardStatus) {
                const [variation, option] = key.split('|')
                if (variation == id) {
                    status[option] = this.cardStatus[key]
                }
            }
            return status
        },
    }
}
</script>
<style lang="scss">
.product-item {
    background-color: #fbfbfb;
    border-radius: 3px;
}

.product-item:hover {
    background-color: #efefef;
}

.product {
    position: relative;
    padding: 0.5em 0em 0em 0.5em;
    display: flex;
    .is-draggable {
        cursor: drag;
    }
}

@media only screen and (max-width: 991px) {
    .product.is-pricable {
        flex-direction: column;
        .product-actions {
            margin-top: 10px;
        }
    }
}
@media only screen and (max-width: 500px) {
    .product {
        flex-direction: column;
    }
}


.product-item + .product-item {
    border-top: 1px dashed #dedede;
}

.product-delete {
    margin-left: 15px;
}


.product-data {
    display: flex;
    flex-grow: 1;
    flex-basis: 50%;
}

.product-item-picker {
    display: flex;
    align-items: center;
    margin-right: 15px;
}

.product-info {
    p {
        margin-top: 0px;
        margin-bottom: 0px;
    }
    margin-left: 15px;
    flex-grow: 1;
}

.product-image {
    width: 40px;
    height: 40px;
    background-color: white;
    box-shadow: 0 2px 3px rgba(10, 10, 10, 0.1), 0 0 0 1px rgba(10, 10, 10, 0.1);
    border-radius: 3px;
}
.product-image img {
    width: 100%;
    height: 100%;
    border-radius: 3px;
}

.item-price, .item-vat, .item-stock {
    max-width: 150px;
    min-width: 80px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 3px;
    border-radius: 3px;
    cursor: pointer;
    div.label {
        // &:before {
        //     content: '\f040';
        //     font-family: 'FontAwesome';
        //     margin-right: 4px; 
        //     font-size: 0.8em;
        // }
        font-size: 1.3em;
        text-align: right;
        font-weight: 600;
        span {
            text-decoration: underline dotted;
        }
    }
    .input {
        margin-left: 5px;
        width: 80px;
    }
    .el-select {
        width: 90px;
    }
}

.product-actions {
    display: flex;
    align-items: center;
    div + div {
        margin-left: 5px;
    }
    &.is-left {
        flex-basis: 5%;
        flex-shrink: 100;
    }
    &.is-right {
        flex-basis: 50%;
        justify-content: flex-end;
    }
}

.product-variations {
    margin-left: 1em;
    margin-bottom: 0.5em;
}

.product-handle {
    position: absolute;
    left: -20px;
    top: 15px;
    cursor: grab;
}

.assembly-items {
    margin-left: 20px;
    padding-left: 10px;
    margin-bottom: 10px;
    border-left: 1px solid #dedede;
}
</style>


