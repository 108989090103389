<template>
    <div class="stats-renderer" style="padding: 1.5em; border-radius: 3px;">
        <h2 class="subtitle tc" v-if='from && to'>Statistiques des commandes du {{from.toLocaleDateString()}} au {{to.toLocaleDateString()}}</h2>
        <el-row :gutter="10">
            <el-col :span='16' :xs='24'>
                <card title='Statistiques'>
                    <div class="cf flex justify-between">
                        <dl class="db dib-l w-auto-l lh-title mr2-l">
                            <dd class="f4 ttu fw4 ml0 branded tc">CA</dd>
                            <dd class="f2 fw2 ml0 tc">{{aggregate.sales|EUR}}</dd>
                        </dl>
                        <dl class="db dib-l w-auto-l lh-title mr2-l">
                            <dd class="f4 ttu fw4 ml0 branded tc">Ticket moyen</dd>
                            <dd class="f2 b fw2 ml0 tc">{{aggregate.average|EUR}}</dd>
                        </dl>
                        <dl class="db dib-l w-auto-l lh-title mr2-l">
                            <dd class="f4 ttu fw4 ml0 branded tc">Panier moyen</dd>
                            <dd class="f2 b fw2 ml0 tc">{{aggregate.arpu|EUR}}</dd>
                        </dl>
                    </div>
                    <div class="cf flex justify-between mt2">
                        <dl class="db dib-l w-auto-l lh-title mr2-l">
                            <dd class="f4 ttu fw4 ml0 branded tc">Ventes</dd>
                            <dd class="f2 b fw2 ml0 tc">{{aggregate.count}}</dd>
                        </dl>
                        <dl class="db dib-l w-auto-l lh-title mr2-l">
                            <dd class="f4 ttu fw4 ml0 branded tc">Clients</dd>
                            <dd class="f2 b fw2 ml0 tc">{{aggregate.customers}}</dd>
                        </dl>
                        <dl class="db dib-l w-auto-l lh-title mr2-l">
                            <dd class="f4 ttu fw4 ml0 branded tc">Paniers</dd>
                            <dd class="f2 b fw2 ml0 tc">{{aggregate.guests}}</dd>
                        </dl>
                    </div>
                </card>
                <card title='Ventes' class="mt3" v-if='hasOrders'>
                    <div class="flex justify-around">
                        <div class="tc">
                            <div class="f4 ttu fw4 ml0 branded tc">Produit le + vendu</div>
                            <h1 class="f3 b fw3 ml0 tc">{{bestProduct.label}} <strong>({{bestProduct.count}})</strong></h1>
                        </div>
                        <div class="tc">
                            <div class="f4 ttu fw4 ml0 tc" style="color: #ff4560">Produit le - vendu</div>
                            <h1 class="f3 b fw3 ml0 tc">{{worstProduct.label}} <strong>({{worstProduct.count}})</strong></h1>
                        </div>
                    </div>
                </card>
                <card title='Produits' class="mt3">
                    <apexchart v-if='hasOrders' type="bar" width="600" :options="productsOptions" :series="productsData" />
                </card>
                <card title='Affluence' class="mt3">
                    <apexchart v-if='hasOrders' type="area" width="600" :options="flowOptions" :series="flowData" />
                </card>
            </el-col>
            <el-col :span='8' :xs='24'>
                <card title='Moyens de paiements'>
                    <apexchart type="pie" width="380" :options="paymentTypesOptions" :series="paymentTypesData" />
                    <div class="mt1">
                        <table class="collapse ba br2 b--black-10 w-100">
                            <thead>
                                <tr>
                                    <th class="pv2 ph3 tl f6 fw6 ttu">Type</th>
                                    <th class="tr f6 ttu fw6 pv2 ph3 tr">Nombre</th>
                                    <th class="tr f6 ttu fw6 pv2 ph3 tr">Total</th>
                                    <th class="tr f6 ttu fw6 pv2 ph3 tr">%</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for='(pt, pl) in aggregate.paymentsTypes' :key='pl' class="striped--near-white">
                                    <th class="pv2 ph3 b">{{pl}}</th>
                                    <td class="pv2 ph3 tr">{{pt.count}}</td>
                                    <td class="pv2 ph3 tr">{{pt.total|EUR}}</td>
                                    <td class="pv2 ph3 tr">{{Math.round(pt.ratio * 10000) / 100}}%</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </card>
                <card title="Type de commandes" class="mt3">
                    <apexchart v-if='hasOrders' type="pie" width="380" :options="orderingOptions" :series="orderingData" />
                </card>
                <card title="Commandes par créneaux" class="mt3">
                    <apexchart v-if='hasOrders' type="radar" height="380" :options="timeslotOptions" :series="timeslotData"/>
                </card>
                <card title="Commandes par status" class="mt3">
                    <table class="collapse ba br2 b--black-10 w-100">
                        <thead>
                            <tr>
                                <th class="pv2 ph3 tl f6 fw6 ttu ">Statut</th>
                                <th class="tr f6 ttu fw6 pv2 ph3 tr">Nombre</th>
                                <th class="tr f6 ttu fw6 pv2 ph3 tr">Total</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for='(st, sl) in aggregate.status' :key='sl' class="striped--near-white">
                                <th class="pv2 ph3 b">{{sl}}</th>
                                <td class="pv2 ph3 tr">{{st.count}}</td>
                                <td class="pv2 ph3 tr">{{st.total|EUR}}</td>
                            </tr>
                        </tbody>
                    </table>
                </card>
            </el-col>
        </el-row>
        <el-row :gutter='10'>
            <el-col :span="24">
                <card title='Historique des commandes' class="mt3">
                    <orders-table v-if='orders' :orders='orders'></orders-table>
                </card>
            </el-col>
        </el-row>
    </div>
</template>

<script>
import Vuex from 'vuex'
import Card from '@/components/Stats/Card'
import OrdersTable from '@/components/Stats/OrdersTable'
import VueApexCharts from 'vue-apexcharts'
export default {
    name: 'StatsRenderer',
    props: ['orders', 'aggregate', 'logo', 'to', 'from', 'placeId'],
    components: {
        Card,
        OrdersTable,
        apexchart: VueApexCharts
    },
    computed: {
        ...Vuex.mapState(['merchant', 'places']),
        hasOrders() {
            return this.orders.length > 0
        },
        orderingOptions() {
            return {
                labels: Object.keys(this.aggregate.pickupTypes),
                chart: {
                    title: 'Répartion par type de commande',
                },
                responsive: [{
                    breakpoint: 480,
                    options: {
                        chart: {
                            width: 200
                        },
                        legend: {
                            position: 'bottom'
                        }
                    }
                }],
                tooltip: {
                    y: {
                        formatter: (l) => Number(l * this.aggregate.sales).toFixed(2).toLocaleString('fr-FR') + ' €',
                        title: {
                            formatter: (seriesName, b) => seriesName,
                        }
                    },
                },
            }
        },
        orderingData() {
            const values = []
            for (const i in this.aggregate.pickupTypes) {
                values.push(this.aggregate.pickupTypes[i].ratio)
            }
            return values
        },
        timeslotData() {
            let data = []
            for (const i in this.aggregate.timeslots) {
                data.push(this.aggregate.timeslots[i])
            }
            if (this.placeId) {
                const timeslots = []
                const p = this.places.find(place => place.id === this.placeId)
                if (p) {
                    for (const t of p.settings.ordering.timeslots) {
                        data.push(0)
                    }
                }
            }
            return [
                {
                    name: 'Commandes',
                    data
                }
            ]
        },
        timeslotOptions() {
            let labels = []
            for (const t in this.aggregate.timeslots) {
                labels.push(t)
            }
            if (this.placeId) {
                const timeslots = []
                const p = this.places.find(place => place.id === this.placeId)
                if (p) {
                    for (const t of p.settings.ordering.timeslots) {
                        const ts = `${t.start}-${t.end}`
                        if (timeslots.indexOf(ts) == -1) {
                            timeslots.push(ts)
                        }
                    }
                    labels = [...labels, ...timeslots]
                }
            }
            return {
                labels,
                chart: {
                    zoom: {
                        enabled: false
                    },
                    toolbar: {
                        show: false
                    },
                },
                yaxis: {
                    show: false,
                    labels: {
                        show: false
                    }
                },
                colors: ['#03E2AD']
            }
        },
        paymentTypesData() {
            const data = []
            for (const i in this.aggregate.paymentsTypes) {
                data.push(this.aggregate.paymentsTypes[i].ratio)
            }
            return data
        },
        paymentTypesOptions() {
            const labels = []
            for(const lbl in this.aggregate.paymentsTypes) {
                labels.push(lbl)
            }
            return {
                labels,
                chart: {
                    title: 'Répartion par moyen de paiement',
                },
                responsive: [{
                    breakpoint: 480,
                    options: {
                        chart: {
                            width: 200
                        },
                        legend: {
                            position: 'bottom'
                        }
                    }
                }],
                tooltip: {
                    y: {
                        formatter: (l) => Number(l * this.aggregate.sales).toFixed(2).toLocaleString('fr-FR') + " €",
                        title: {
                            formatter: (seriesName, b) => seriesName,
                        },
                    },
                },
            }
        },
        productsData() {
            return [
                {
                    name: 'Ventes',
                    data: this.products.sort((a, b) => b.count - a.count).slice(0, 10).map(p => p.count)
                }
            ]
        },
        products() {
            const products = []
            for (const item in this.aggregate.items) {
                products.push({
                    label: item,
                    count: this.aggregate.items[item]
                })
            }
            return products
        },
        productsOptions() {
            let categories = this.products.sort((a, b) => b.count - a.count).map(p => p.label)
            if (categories.length > 0) {
                categories = categories.slice(0, 10)
            }
            return {
                chart: {
                    height: 450,
                    type: 'bar',
                    title: 'Ventes',
                },
                colors: ['#03E2AD'],
                xaxis: {
                    categories
                }
            }
        },
        bestProduct() {
            return this.products.sort((a, b) => b.count - a.count)[0]
        },
        worstProduct() {
            return this.products.sort((a, b) => a.count - b.count)[0]
        },
        flowOptions() {
            return {
                chart: {
                    stacked: false,
                    zoom: {
                        type: 'x',
                        enabled: true,
                        autoScaleYaxis: true
                    },
                    toolbar: {
                        show: false
                    },
                },
                plotOptions: {
                    line: {
                        // curve: 'smooth',
                    }
                },
                dataLabels: {
                    enabled: false
                },

                markers: {
                    size: 0,
                    style: 'full',
                },
                //colors: ['#0165fc'],
                fill: {
                    type: 'gradient',
                    gradient: {
                        shadeIntensity: 1,
                        inverseColors: false,
                        opacityFrom: 0.5,
                        opacityTo: 0,
                        stops: [0, 90, 100]
                    },
                },
                yaxis: {
                    // labels: {
                    //     formatter: function (val) {
                    //         return (val / 1000000).toFixed(0);
                    //     },
                    // },
                    // title: {
                    //     text: 'Price'
                    // },
                },
                xaxis: {
                    type: 'datetime',
                    labels: {
                        formatter: function (val) {
                            return new Date(val).toLocaleDateString()
                        }
                    }
                },
                tooltip: {
                    shared: false,
                    x: {
                        formatter: function (val) {
                            return new Date(val).toLocaleDateString()
                        }
                    }
                }
            }
        },
        flowData() {
            return [
                {
                    name: 'Commandes',
                    data: Object.values(this.aggregate.periods).map(v => [v.timestamp * 1000, v.count])
                },
                {
                    name: 'Intentions',
                    data: Object.values(this.aggregate.periods).map(v => [v.timestamp * 1000, v.hits])
                }
            ]
        }
    }
}
</script>

<style>
.branded {
    color: #03E2AD;
}
</style>