<template>
    <el-dialog
        :visible.sync="visible"
        :fullscreen='false'
        :before-close="closeModal">
            <template slot="title">
                <h2 class="subtitle"><i class="fa fa-calendar"></i>&nbsp;Demande de Rendez-vous</h2>
            </template>
            <el-form ref="rdvForm" :model="form" label-width="250px">
                <el-form-item label="Date et heure du rendez-vous souhaité">
                    <!-- <el-date-picker
                        v-model="form.date"
                        type="datetime"
                        placeholder="Date et heure"
                        :picker-options='options'
                        default-time="14:00:00">
                    </el-date-picker> -->
                    <el-col :span="11">
                        <el-date-picker type="date" :picker-options='dateOptions' placeholder="Date" v-model="form.date" style="width: 100%;"></el-date-picker>
                    </el-col>
                    <el-col class="line" :span="2" style='text-align: center;'>-</el-col>
                    <el-col :span="11">
                        <el-time-select :picker-options='timeOptions' placeholder="Heure" v-model="form.hour" style="width: 100%;"></el-time-select>
                    </el-col>
                </el-form-item>
                <el-form-item label="Commentaire">
                    <el-input type="textarea" v-model="form.comment"></el-input>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="closeModal" icon='el-icon-close'>Fermer</el-button>
                <el-button type="primary" :disabled='!formValid' @click="saveAndClose()" icon='el-icon-circle-check-outline'>Envoyer</el-button>
            </span>
    </el-dialog>
</template>

<script>
import { sendMeetingRequest } from '@/lib/Api'
import Vuex from 'vuex'

const defaultForm = () => ({
    date: null,
    hour: '09:30',
    comment: null
})
export default {
    name: 'RDVModal',
    props: {
        visible: {
            type: Boolean,
            required: true
        }
    },
    data: () => ({
        form: defaultForm(),
        dateOptions: {
            firstDayOfWeek: 1,
            disabledDate: (d) => {
                const now = new Date()
                if (d.getDay() === 0) {
                    return true
                }
                if (d < now) {
                    return true
                }
                return false
            }
        },
        timeOptions: {
            start: '09:30',
            end: '19:00',
            step: '00:30',
        }
    }),
    computed: {
        ...Vuex.mapState(['user']),
        formValid() {
            return this.form.date && this.form.hour
        }
    },
    methods: {
        closeModal() {
            this.$emit('close')
        },
        async saveAndClose() {
            if (this.formValid) {
                await sendMeetingRequest(this.form.date, this.form.hour, this.form.comment)
                this.closeModal()
                this.$message({
                    message: 'Merci pour votre demande, nous vous recontacterons sous peu',
                    type: 'success'
                })
                this.form = defaultForm()
            }
        }
    }
}
</script>

<style>
</style>
