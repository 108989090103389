<template>
    <el-form id='assemblyForm'>
        <el-row :gutter="10">
            <el-col :span="12" :xs="24">
                <div id='assemblyCategoriesSelector' style='margin-bottom: 2em'>
                    <h4><i class="el-icon-fa-list"></i> Composition</h4>
                    <p>
                        Sélectionner les catégories nécessaires pour composer votre formule<br>
                        <!-- <small>Seules les catégories contenants des produits sont affichées</small> -->
                    </p>
                    <div id='assemblyCategories'>
                        <category-picker
                            v-for='(category, $index) in filteredCategories'
                            :key='$index'
                            :index='selectedIndex[category.id]'
                            :category='category'
                            :selected='selectedCategories.indexOf(category.id) > -1'
                            @index='handleCategoryIndexChanged(category.id, $event)'
                            @select='handleCategorySelected'
                        />
                    </div>
                </div>
                <div id='assemblyInformations' style='margin-top: 2em'>
                    <h4><i class="el-icon-fa-edit"></i> Nom de la formule</h4>
                    <el-form-item style="margin-bottom: 9px;">
                        <el-input type="text"
                            ref='assemblyNameInput'
                            autofocus="on"
                            class="input-large"
                            size="large"
                            prefix-icon='el-icon-edit' 
                            placeholder='Nom de la formule'
                            :value='assemblyName'
                            @input='handleNameChanged'
                            required/>
                    </el-form-item>
                    <el-form-item style="margin-bottom: 9px;">
                        <el-input
                            type="text"
                            ref='assemblyDescriptionInput'
                            size="large"
                            prefix-icon='el-icon-edit'
                            placeholder='Description de la formule'
                            v-model='assembly.description'
                            required/>
                    </el-form-item>
                    <el-form-item label='TVA'>
                        <el-select v-model="assembly.vat" placeholder="TVA" size="small" width="80px">
                            <el-option v-for='rate in vatsRates' :key='rate' :label='`${rate} %`' :value='rate'></el-option>
                        </el-select>
                    </el-form-item>
                    <div class='assembly-image-block'>
                        <el-upload
                            ref='uploadField'
                            :action='""'
                            :auto-upload='false'
                            list-type="picture-card"
                            accept="image/*"
                            :headers='{"x-assembly-id": assembly.id}'
                            :on-change="handleImageChanged"
                            :show-file-list="false">
                                <img v-if="assembly.image" :src="assembly.image" class="product-image-preview" width="145px">
                                <i v-else class="el-icon-plus"></i>
                        </el-upload>
                        <div class='assembly-image-actions'>
                            <el-button type="primary" @click='triggerUploadField' size='small' icon='el-icon-upload'>Télécharger</el-button>
                            <!-- <el-button type="primary" @click='takePicture' size='small' icon='el-icon-fa-camera'>Prendre une photo</el-button> -->
                        </div>
                    </div>
                </div>
            </el-col>
            <el-col :span="12" :xs="24">
                <div id='assemblyProducts'>
                    <h4><i class="el-icon-fa-cubes"></i> Produits disponibles dans la formule</h4>
                    <p>
                        Sélectionner les produits à inclure dans votre formule
                    </p>
                    <template v-if='hasProducts'>
                        <div v-for='category in filteredProducts' :key='category.id' style="border: 1px solid #f2eded; padding: 3px; border-radius: 5px; margin-bottom: 3px">
                            <div style="display: flex; justify-content: space-between;">
                                <div style="display: flex; align-items: center;">
                                    <el-tooltip class="item" effect="dark" content="Ordre d'affichage" placement="top-start">
                                        <input class="index-selector" pattern="/\d+/" :value='indexes[category.id]' type="number" min="1" @change="handleCategoryIndexChanged(category.id, $event)">
                                    </el-tooltip>
                                    <h4>{{category.name}}</h4>
                                </div>
                                <div style="display: flex; align-items: center;">
                                    <el-checkbox :checked="optionals[category.id]" @change='handleCategoryOptional(category)'>
                                        Facultatif
                                    </el-checkbox>
                                    <el-tooltip class="item" effect="dark" content="Choix possible" placement="top-start">
                                        <input style="margin-left: 1em; padding: 5px; width: 50px;"
                                            type="number" min="0"
                                            pattern="/\d+/"
                                            placeholder="Choix possible"
                                            :value='multiples[category.id]'
                                            @change='handleCategoryMultiples(category, $event)'>
                                    </el-tooltip>
                                </div>
                            </div>
                            <p style="margin-top: 0px" v-if='category.products.length == 0'>Aucun produits dans cette catégorie</p>
                            <div class='assembly-group'>
                                <product-item
                                    v-for='(product, $index) in category.products'
                                    :key='$index'
                                    :product='product'
                                    :editable='false'
                                    :pickable='true'
                                    :selected='selectedProducts.indexOf(product.id) > -1'
                                    @select='handleProductSelected'/>
                            </div>
                        </div>
                    </template>
                    <template v-else>
                        <p class="notification info">Aucun produit à afficher</p>
                    </template>
                    <div id="productInfos" v-if="assembly.isExternal()">
                        <div class='block-header'>
                            <h4 style="margin-bottom: 10px"><i class="el-icon-fa-refresh"></i> Synchronisation <span v-if='assembly.settings.externalProvider'>avec {{assembly.settings.externalProvider}}</span></h4>
                        </div>
                        <div>
                            <el-alert :closable="false" show-icon icon="fa-info-circle" type="warning" :title="'Les informations de ce produit proviennent de ' + assembly.settings.externalProvider"></el-alert>
                            <div style="margin-left: 0px; margin-top: 10px;">
                                <el-checkbox v-model="assembly.settings.override">Ecraser ces informations avec celles de {{assembly.settings.externalProvider}} lors de la prochaine synchronisation ?</el-checkbox>
                            </div>
                        </div>
                    </div>
                </div>
            </el-col>
        </el-row>
    </el-form>
</template>

<script>
import Vuex from 'vuex'
import Assembly from '@/lib/entities/Assembly'
import ProductItem from '@/components/Menu/ProductItem'
import CategoryPicker from '@/components/Menu/CategoryPicker'
import slugify from 'slugify'
export default {
    name: 'AssemblyForm',
    components: {
        ProductItem,
        CategoryPicker
    },
    props: {
        products: {
            type: Array,
            required: true
        },
        categories: {
            type: Array,
            required: true
        },
        assembly: {
            type: Assembly,
            required: true
        }
    },
    data: () => ({
        image: null,
        currentAssembly: {},
        customAssemblyName: null,
        selectedIndex: {},
        optionals: {},
        indexes: {},
        multiples: {},
        selectedCategories: [],
        selectedProducts: []
    }),
    computed: {
        ...Vuex.mapState(['vatsRates']),
        filteredCategories() {
            // @version 2022-03-07 pour fix zelty, on retourne les categories même sans produits
            return this.categories
            // const categories = []
            // for (const category of this.categories) {
            //     if (category.name && category.products.length > 0) {
            //         categories.push(category)
            //     }
            // }
            // return categories
        },
        assemblyName() {
            if (this.customAssemblyName != null) {
                this.$emit('nameChanged', this.customAssemblyName)
                return this.customAssemblyName
            }
            const categories = []
            for (const id of this.selectedCategories) {
                const category = this.categories.find((cat) => cat.id === id)
                categories.push(category.name)
            }
            const name = categories.join(' + ')
            this.$emit('nameChanged', name)
            return name
        },
        hasProducts() {
            return Object.keys(this.filteredProducts).length > 0
        },
        filteredProducts() {
            const products = {}
            for (const id of this.selectedCategories) {
                const category = this.categories.find((cat) => cat.id === id)
                if (category) {
                    products[category.id] = {
                        id: category.id,
                        name: category.name,
                        products: []
                    }
                    for (const product of category.products) {
                        products[category.id].products.push(product)
                    }
                }
            }
            return products
        }
    },
    watch: {
        assembly(assembly) {
            this.attachAssembly(assembly)
        }
    },
    mounted() {
        this.attachAssembly(this.assembly)
        let i = 1
        for (const category of this.assembly.categories) {
            this.selectedIndex[category.id] = i
            i++
        }
        // Handle assembly name changes
        this.$on('nameChanged', (name) => {
            this.currentAssembly.setName(name)
        })
    },
    methods: {
        ...Vuex.mapActions(['storeAssemblyImage']),
        attachAssembly(assembly) {
            this.customAssemblyName = null
            this.selectedCategories = []
            this.selectedProducts = []
            this.indexes = assembly.indexes || {}
            this.optionals = assembly.optionals
            this.multiples = assembly.multiples
            this.currentAssembly = assembly
            this.customAssemblyName = assembly.name
            if (assembly.items.length > 0) {
                for (const i in assembly.items) {
                    const item = assembly.items[i]
                    this.selectedCategories.push(item.category.id)
                    for (const product of item.products) {
                        this.selectedProducts.push(product.id)

                        // Zelty fix
                        if (item.category.products.indexOf(product) == -1) {
                            item.category.products.push(product)
                        }
                        if (this.indexes.hasOwnProperty(item.category.id) == false) {
                            this.indexes[item.category.id] = parseInt(i) + 1
                        }
                    }
                }
            // Fix when updating a recently created assembly
            } else {
                for (const cat of assembly.categories) {
                    this.selectedCategories.push(cat.id)
                }
                for (const prod of assembly.products) {
                    this.selectedProducts.push(prod.id)
                }
            }
        },
        addProduct(product) {
            this.currentAssembly.addProduct(product)
        },
        removeProduct(product) {
            this.currentAssembly.removeProduct(product)
        },
        handleCategoryIndexChanged(categoryId, event) {
            const index = event.target.value
            this.$set(this.indexes, categoryId, Number(index))
        },
        handleCategorySelected(category) {
            const index = this.selectedCategories.indexOf(category.id)
            if (index === -1) {
                this.selectedCategories.push(category.id)
                this.currentAssembly.addCategory(category)
                for (const product of category.products) {
                    this.selectedProducts.push(product.id)
                    this.currentAssembly.addProduct(product)
                }
                this.optionals[category.id] = false
                this.$set(this.multiples, category.id, 1)
                this.$set(this.indexes, category.id, this.selectedCategories.length)
            } else {
                this.selectedCategories.splice(index, 1)
                this.currentAssembly.removeCategory(category)
                for (const product of category.products) {
                    const productIndex = this.selectedProducts.indexOf(product.id)
                    if (productIndex > -1 ) {
                        this.selectedProducts.splice(productIndex, 1)
                        this.currentAssembly.removeProduct(product)
                    }
                }
                if (this.optionals.hasOwnProperty(category.id)) {
                    delete this.optionals[category.id]
                }
                if (this.multiples.hasOwnProperty(category.id)) {
                    delete this.multiples[category.id]
                }
                if (this.indexes.hasOwnProperty(category.id)) {
                    delete this.indexes[category.id]
                }
            }

            this.selectedIndex = {}
            let i = 1
            for (const categoryId of this.selectedCategories) {
                this.selectedIndex[categoryId] = i
                i++
            }
        },
        assignCustomName() {
            this.customAssemblyName = `${this.assemblyName}`
        },
        handleNameChanged(value) {
            this.customAssemblyName = value
        },
        handleProductSelected(product) {
            const index = this.selectedProducts.indexOf(product.id)
            if (index === -1) {
                this.selectedProducts.push(product.id)
                this.currentAssembly.addProduct(product)
            } else {
                this.selectedProducts.splice(index, 1)
                this.currentAssembly.removeProduct(product)
            }
        },
        async handleImageChanged(file) {
            const reader = new FileReader()
            reader.onload = (e) => {
                this.image = e.target.result
            }
            reader.readAsDataURL(file.raw)
            const filename = slugify(file.name, { lower: true, remove: /[*+~.()'"!:@]/g})
            const { url } = await this.storeAssemblyImage({
                filename,
                file: file.raw,
                assembly: this.assembly.id
            })
            this.image = url
            this.assembly.image = url
        },
        triggerUploadField () {
            this.$refs.uploadField.$el.children[0].click()
        },
        handleCategoryOptional(category) {
            if (!this.optionals[category.id]) {
                this.optionals[category.id] = true
            } else {
                this.optionals[category.id] = false
            }
        },
        handleCategoryMultiples(category, event) {
            const value = event.target.value
            if (value.match(/\d+/)) {
                this.$set(this.multiples, category.id, parseInt(value))
            }
        }
    }
}
</script>

<style lang='scss'>
#assemblyCategories {
    display: flex;
    // overflow-x: scroll;
    overflow-y: hidden;
    padding: 1em 0;
    .category-picker + .category-picker {
        margin-left: 1em;
    }
}
#assemblyProductsAvailable {
    display: flex;
}
.index-selector {
    height: 30px;
    width: 50px;
    margin-right: 1em;
    text-align: center;
}
.assembly-group {
    // display: flex;
    // margin-left: 1em;
}
.info {
    color: white;
    background-color: #007AFF;
    padding: 1em;
    border: 1px solid #007AFF;
    border-radius: 5px;
    max-width: 350px;
}

.assembly-image-block {
    display: flex;
    width: 150px;
    flex-direction: column;
    // align-items: center;
    margin-bottom: 1em;
    
    .assembly-image-actions {
        margin-top: 1em;
        justify-content: center;
        display: flex;
        flex-direction: column;
        align-items: center;

        .el-button {
            width: 100%;
        }

        .el-button + .el-button {
            margin-top: 5px;
            margin-left: 0px !important;
        }
    }
}
</style>
