import { render, staticRenderFns } from "./DeliveryZoneModal.vue?vue&type=template&id=559e3872&"
import script from "./DeliveryZoneModal.vue?vue&type=script&lang=js&"
export * from "./DeliveryZoneModal.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports