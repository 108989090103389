<template>
    <div id='placeHoursTable'>
        <el-table style='width: 100%' :data='hours'>
            <el-table-column
                prop="day"
                label="Jour"
                width="100">
            </el-table-column>
            <el-table-column
                prop="opensAt"
                label="Heure d'ouverture"
                width="140">
            </el-table-column>
            <el-table-column
                prop="closesAt"
                label="Heure de fermeture"
                width="140">
            </el-table-column>
            <el-table-column>
                <template slot-scope="scope">
                    <el-button @click.native.prevent="editHour(scope.$index, hours)" type="text" size="small">Modifier</el-button>
                    <el-button @click.native.prevent="deleteHour(scope.$index, hours)" type="text" size="small">Supprimer</el-button>
                </template>
            </el-table-column>
        </el-table>
        <p style="margin-top: 10px;">
            <el-button type="primary" size="small" icon='el-icon-plus' @click="addHour">
                Ajouter
            </el-button>
        </p>
        <place-hours-editor-modal
            :visible='view.hourForm'
            :hour='currentHour'
            @close='view.hourForm = false'
            @save='view.hourForm = false'
        />
    </div>
</template>

<script>
import PlaceHoursEditorModal from '@/components/Place/PlaceHoursEditorModal'
export default {
    name: 'PlaceHoursTable',
    components: {
        PlaceHoursEditorModal
    },
    props: {
        hours: {
            type: Array,
            required: true
        }
    },
    data: () => ({
        currentHour: null,
        view: {
            hourForm: false
        },
        defaultHours: [
            {
                day: 'Lundi',
                opensAt: '09:00',
                closesAt: '20:00'
            },
            {
                day: 'Mardi',
                opensAt: '09:00',
                closesAt: '20:00'
            },
            {
                day: 'Mercredi',
                opensAt: '09:00',
                closesAt: '20:00'
            },
            {
                day: 'Jeudi',
                opensAt: '09:00',
                closesAt: '20:00'
            },
            {
                day: 'Vendredi',
                opensAt: '09:00',
                closesAt: '20:00'
            },
            {
                day: 'Samedi',
                opensAt: '09:00',
                closesAt: '20:00'
            },
            {
                day: 'Dimanche',
                opensAt: '09:00',
                closesAt: '20:00'
            },
        ],
    }),
    methods: {
        addHour() {
            const newIndex = this.hours.push({
                day: '',
                opensAt: null,
                closedAt: null
            })
            this.currentHour = this.hours[newIndex - 1]
            this.view.hourForm = true
        },
        deleteHour(row, hours) {
            hours.splice(row, 1)
        },
        editHour(row, hours) {
            this.currentHour = hours[row]
            this.view.hourForm = true
        },
    }
}
</script>

<style>

</style>
