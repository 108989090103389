<template>
    <div class="view">
        <h2>Modification d'un équipier</h2>
        <div class="box">
            <user-form @updated='handleUserUpdated' :editingUser='user'></user-form>
        </div>
        <el-button type="info" @click="$router.back()">Annuler</el-button>
        <el-button type="primary" @click="saveUserForm" icon='el-icon-circle-check-outline'>Enregistrer</el-button>
    </div>
</template>

<script>
import Vuex from 'vuex'
import UserForm from '@/components/Team/UserForm'
import { error } from '@/lib/Tools'
export default {
    name: 'TeamEdit',
    components: {
        UserForm
    },
    data: () => ({
        updatedUser: {}
    }),
    computed: {
        ...Vuex.mapState(['team']),
        user() {
            return this.team.find((u) => u.id === this.$route.params.id)
        }
    },
    methods: {
        ...Vuex.mapActions(['updateUser']),
        handleUserUpdated(user) {
            this.updatedUser = user
        },
        async saveUserForm() {
            try {
                await this.updateUser(this.updatedUser)
                this.$message({
                    message: 'Modification enregistrées avec succès',
                    type: 'success'
                })
                this.$router.push({name: 'app.team'})
            } catch (err) {
                this.$message({
                    message: 'Une erreur est survenue, merci de réessayer',
                    type: 'error'
                })
                error(err)
            }
        }
    }
}
</script>

<style>

</style>
