<template>
    <div id="camera">
        <video ref="player" autoplay style='width: 100%; margin-bottom: 1em;' :width='width' :height='height'></video>
        <el-button type="info" icon='el-icon-fa-camera' @click='takePicture'>Enregistrer</el-button>
        <canvas style='display: none;' ref="canvas" :width='width' :height='height'></canvas>
    </div>
</template>

<script>
export default {
    name: 'Camera',
    props: {
        width: {
            type: Number,
            required: false,
            default: () => 320
        },
        height: {
            type: Number,
            required: false,
            default: () => 320
        },
        enabled: {
            type: Boolean,
            required: false,
            default: () => true
        }
    },
    data: () => ({
        canvas: null,
        context: null
    }),
    mounted() {
        this.canvas = this.$refs.canvas
        this.context = this.canvas.getContext('2d')
        this.startCamera()
        this.$watch('enabled', (val) => {
            if (val === true) {
                // Attach the video stream to the video element and autoplay.
                this.startCamera()
            } else {
                this.stopCamera()
            }
        })
    },
    methods: {
        startCamera() {
            navigator.mediaDevices.getUserMedia({
                video: true,
            }).then((stream) => {
                this.$refs.player.srcObject = stream
            })
        },
        stopCamera() {
            if (this.$refs.player.srcObject) {
                this.$refs.player.srcObject.getVideoTracks().forEach((track) => track.stop())
            }
        },
        takePicture() {
            this.context.drawImage(this.$refs.player, 0, 0, this.width, this.height)
            const buffer = this.canvas.toDataURL()
            this.$emit('picture', buffer)
        }
    }
}
</script>

<style lang='scss'>
#camera {
    display: flex;
    flex-direction: column;
}
</style>
