import Product from '@/lib/entities/Product'
import Entity from '@/lib/entities/Entity'
import Store from '@/lib/entities/Store'
import RecommandationService from '@/lib/RecommandationService'
import Parse from 'parse'

export default class Category extends Entity {

  /**
   * 
   * @param model
   * @param store
   */
  public static boot(model: Parse.Object, store: Store) {
    const category = new Category(model.get('name'))
    category.setStore(store)
    category.setModel(model)
    if (model.has('description')) {
      category.setDescription(model.get('description'))
    }
    if (model.has('vat')) {
      category.setVat(model.get('vat'))
    }
    if (model.has('index')) {
      category.index = model.get('index')
    }
    category.settings = model.get('settings')
    return category
  }

  public index: number = 0
  public name: string
  public settings: any
  public products: Product[]
  public description?: string
  public vat: number
  public color?: string
  public fresh: boolean = false

  constructor(name: string) {
    super()
    this.name = name || ''
    this.products = []
    this.description = ''
    this.vat = 10
    this.color = ''
  }

  public setDescription(description: string) {
    this.description = description
  }

  public setVat(vat: number) {
    this.vat = vat
  }

  public async getSuggestedProducts(): Promise<Product[]> {
    return await RecommandationService.suggestProducts(this)
  }

  public addProduct(product: Product) {
    if (this.products.indexOf(product) === -1) {
      this.products.push(product)
    }
  }

  public deleteProduct(product: Product) {
    const index = this.products.indexOf(product)
    if (index > -1) {
      this.products.splice(index, 1)
    }
  }

  public async save() {
    if (!this.model) {
      this.model = new Parse.Object('Category')
    }

    if (this.store) {
      // this.model.setACL(this.store.acl)
      this.model.set('merchant', this.store.merchant)
    }

    this.model.set('name', this.name)
    this.model.set('description', this.description)
    this.model.set('vat', this.vat)
    this.model.set('color', this.color)
    this.model.set('index', this.index)
    this.model.set('settings', this.settings)
    // const products = this.model.relation('products')
    // for (const p of this.products) {
    //   if (p.model) {
    //     products.add(p.model)
    //   }
    // }
    await this.model.save()
    this.setId(this.model.id)
  }

  public delete() {
    if (this.model) {
      this.model.destroy()
    }
  }
}
