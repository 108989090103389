<template>
    <div id='products-wizard'>
        <h2>Vos Produits</h2>
        <div class="notification is-primary" v-if='displayTooltip' style="margin-bottom: 1em">
            Ajouter vos produits dans les catégories que vous venez de créer.
        </div>
        <div id="categories">
            <div v-for='(category, $index) in cleanedCategories' :key='$index' class="category-editor">
                <h3>{{category.name}}</h3>
                <div class="category-products">
                    <p v-if='category.products.length === 0' style='font-weight: 500'>
                        Aucun produit associé à cette catégorie
                    </p>
                    <div class='products-list'>
                        <product-item
                            v-for='(product, $index) in category.products'
                            :key='$index'
                            :product='product'
                            :editable='true'
                            :pricable='false'
                            :deletable='true'
                            @edit='editProduct'
                            @drop='deleteProduct'
                        />
                    </div>
                    <div @click="openAddProduct(category)" class='inline-button' style="margin-top: 0.5em;">
                        <el-button type="primary" size="small" circle icon='el-icon-plus'></el-button>
                        Créer un nouveau produit
                    </div>
                </div>
            </div>
        </div>
        
        <product-form-modal
            v-if='editingProduct !== null'
            @save='saveProduct'
            @close='closeModal'
            @categoryUpdated='handleProductCategoryUpdated'
            @suggestionSelected='createFromSuggestion'
            :product='editingProduct'
            :appendToBody='false'
            :categories='categories'
            :category='editingCategory'
            :existingProducts='products'
            :isEditing='isEditing'
            :library='library'
            :visible='view.modal'/>
    </div>
</template>

<script>

const DEFAULT_PRODUCT = () => ({
    name: '',
    sku: '',
    description: '',
    image: null,
    tags: [],
    properties: []
})

import Product from '@/lib/entities/Product'
import ProductItem from '@/components/Menu/ProductItem.vue'
import ProductFormModal from '@/components/Menu/ProductFormModal.vue'
export default {
    name: 'ProductsWizard',
    components: {
        ProductItem,
        ProductFormModal
    },
    props: {
        categories: {
            type: Array,
            required: true
        },
        products: {
            type: Array,
            required: true
        },
        library: {
            type: Array,
            required: true
        }
    },
    computed: {
        cleanedCategories() {
            return this.categories.filter((c) => c.name)
        },
        displayTooltip() {
            try {
                return window.localStorage.getItem(`_hide_products_tooltip`) !== '1'
            } catch (err) {
                return false
            }
        }
    },
    data: () => ({
        view: {
            modal: false
        },
        editingProduct: null,
        editingCategory: null,
        isEditing: false
    }),
    methods: {
        openAddProduct(category) {
            this.editingCategory = category
            this.editingProduct = new Product(DEFAULT_PRODUCT())
            this.editingProduct.isDirty = true
            this.isEditing = false
            this.openModal()
        },
        saveProduct(product, category) {
            this.$emit('save', product)
            this.editingProduct = null
        },
        deleteProduct(product) {
            this.$confirm('Supprimer ce produit ?', 'Confirmation', {
                type: 'warning',
                confirmButtonText: 'Oui',
                cancelButtonText: 'Annuler',
            })
            .then((_) => {
                this.$emit('drop', product)
                this.$message({
                    type: 'success',
                    message: 'Produit supprimé'
                })
            })
            .catch((err) => {
                error(err)
            })
        },
        editProduct(product) {
            this.editingProduct = product
            this.isEditing = true
            this.openModal()
        },
        createFromSuggestion(product, category) {
            this.editingProduct = product.clone()
            this.editingProduct.setCategory(category)
        },
        openModal() {
            this.view.modal = true
        },
        closeModal() {
            this.view.modal = false
            setTimeout(() => {
                this.editingProduct = null
            }, 1000)
        },
        handleProductCategoryUpdated(product, newCategory) {
            if (product.isDirty === true) {
                return
            }
            if (product.category) {
                product.category.deleteProduct(product)
            }
            newCategory.addProduct(product)
        }
    }
}
</script>

<style lang="scss">
.category-editor {
    margin-bottom: 2.5em;
}
.category-products {
    margin-left: 1em;
}

.products-list {
    margin-bottom: 1em;
}
</style>
