<template>
    <div class="product-picker" @click='selectProduct' :class="{'is-selected': selected}">
        <div v-if='product.image && showImage' :style="{'background-image': `url(${product.image})`}" class="product-picker-image"></div>
        <div class="product-picker-content">
            <h3>{{product.name}}</h3>
            <!-- <p>{{product.description}}</p> -->
        </div>
        <div class="product-picker-actions" v-if='!multiple'>
            <el-button type="text" class="button">Ajouter</el-button>
        </div>
        <div class="product-picker-actions" v-else>
            <el-button v-if='!selected' type="text" class="button">Sélectionner</el-button>
            <el-button v-else size="big" type="text" icon="el-icon-success"></el-button>
        </div>
    </div>
</template>

<script>
import Product from '@/lib/entities/Product'
import CardProduct from '@/lib/entities/CardProduct'
export default {
    name: 'ProductPicker',
    props: {
        product: {
            type: Product|CardProduct,
            required: true
        },
        showImage: {
            type: Boolean,
            required: false,
            default: () => true
        },
        multiple: {
            type: Boolean,
            required: false,
            default: () => false
        },
        initiallySelected: {
            type: Boolean,
            required: false,
            default: () => false
        }
    },
    data: () => ({
        selected: false
    }),
    mounted() {
        this.selected = this.initiallySelected
    },
    methods: {
        selectProduct() {
            this.selected = !this.selected
            this.$emit('select', this.product, this.selected)
        },
        reset() {
            Object.assign(this.$data, this.$options.data())
        }
    }
}
</script>

<style lang="scss" scoped>
.product-picker {
    cursor: pointer;
    display: flex;
    flex-direction: column;
    padding: 5px;
    border-radius: 4px;
    border: 1px solid #ebeef5;
    background-color: white;
    overflow: hidden;
    color: #303133;
    transition: 0.3s;
    position: relative;
    width: 150px;
    min-height: 250px;
    &.is-selected {
        border: 1px solid rgb(0, 122, 255);
    }
    
    &:hover {
        box-shadow: 0px 0px 3px rgba($color: #303133, $alpha: 0.3);
    }
}
.product-picker-image {
    height: 150px;
    width: 150px;
    background-size: contain;
}

.product-picker-content {
    width: 100%;
    text-align: center;
    flex-grow: 1;
    h3 {
        font-size: 1.1em;
        margin-block-start: 10px;
        margin-block-end: 0;
    }

    p {
        margin-block-start: 0px;
        margin-block-end: 0;
    }
}

.product-picker-actions {
    width: 100%;
    text-align: center;
    position: absolute;
    bottom: 0;
}
</style>
