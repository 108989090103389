<template>
    <div id='stats' class="view">
        <h1>Statistiques</h1>
        <stats-container v-loading.fullscreen.lock='loading' :aggregate='aggregate' :loading='loading' :orders='orders' @change='handleChange' @print='handlePrint' @download='handleDownload'></stats-container>
    </div>
</template>

<script>
import { getStats, getStatsPdf, getStatsExcel } from '@/lib/Api'
import axios from 'axios'
import Vuex from 'vuex'
import StatsContainer from '@/components/Stats/StatsContainer'
export default {
    name: 'Stats',
    components: {
        StatsContainer
    },
    computed: {
        ...Vuex.mapState(['merchant'])
    },
    data: () => ({
        aggregate: {},
        orders: [],
        loading: false
    }),
    methods: {
        displayWizard() {
            this.$router.push({name: 'app.menu.wizard'})
        },
        handleChange(data) {
            this.loading = true
            getStats(data).then((res) => {
                this.aggregate = res.aggregate
                this.orders = res.orders
                this.loading = false
            }).catch((err) => {
                this.loading = false
            })
        },
        handleDownload(data) {
            this.loading = true
            getStatsExcel(data).then((res) => {
                this.downloadFile(res, 'Commandes.xlsx', 'application/octet-stream')
                this.loading = false
            }).catch((err) => {
                this.loading = false
            })
        },
        handlePrint(data) {
            this.loading = true
            getStatsPdf(data).then((res) => {
                this.downloadFile(res, 'Statistiques.pdf', 'application/octet-stream')
                this.loading = false
            }).catch((err) => {
                this.loading = false
            })
        },
        downloadFile(data, filename, contentType) {
            const element = document.createElement('a')
            element.setAttribute('href', `data:${contentType};base64,` + data)
            element.setAttribute('download', filename)
            element.style.display = 'none'
            document.body.appendChild(element)
            element.click()
            document.body.removeChild(element)
        }
    }
}
</script>

<style>

</style>
