<template>
    <div id='productVariationEditor'>
        <el-form>
            <div id='productVariationStepper' class="hidden-sm-and-down">
                <el-steps :space="200" :active="step" simple finish-status="success">
                    <el-step title="Nom"></el-step>
                    <el-step title="Paramètres"></el-step>
                    <el-step title="Options" v-if='variation_settings.hasOptions'></el-step>
                </el-steps>
            </div>
            <div id='productVariationSteps'>
                <div v-if='step === 0'>
                    <div v-if='existingVariation == null'>
                        <el-form-item label='Sélectionner une variation existante' v-if='existingVariations.length > 0'>
                            <el-select v-model='copyFromExistingVariation' @change="handleExistingVariationSelected">
                                <el-option value='' label='----'></el-option>
                                <el-option v-for='(variation, $index) in existingVariations' :key='variation.id' :value='$index' :label='variation.label'></el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item :label='existingVariations.length > 0 ? "ou choisissez le type de la nouvelle variation" : "Type de variation"'>
                            <el-select v-model='variation_type' @change="handleVariationTypeChanged">
                                <el-option value='' label='----'></el-option>
                                <el-option v-for='(type, key) in types' :key='key' :value='key' :label='type.name'></el-option>
                            </el-select>
                        </el-form-item>
                    </div>
                    <el-form-item label='Nom de la variation *' v-show='displayVariationTitle'>
                        <el-input v-model='variation_label' placeholder='Ce nom sera affiché lors de la commande' required></el-input>
                    </el-form-item>
                    <el-alert type="error" show-icon :closable="false" v-if='existingVariation && existingVariation.externalId' title="Cette variation est gérée par une intégration externe" description="Toute modification risque d'entraîner des erreurs"></el-alert>
                </div>
                <div v-if='step === 1' id='productVariationsSettings'>
                    <h4>Paramètres de la variation</h4>
                    <div class="variation-options">
                        <el-form-item label='Cette variation est obligatoire'>
                            <el-switch v-model='variation_settings.required'></el-switch>
                        </el-form-item>
                        <el-form-item label='Cette variation comporte des options'>
                            <el-switch v-model='variation_settings.hasOptions'></el-switch>
                        </el-form-item>
                        <el-form-item label='Un seul choix parmi les options' v-show='variation_settings.hasOptions'>
                            <el-switch v-model='variation_settings.unique'></el-switch>
                        </el-form-item>
                        <el-form-item label='Cette variation est facturable'>
                            <el-switch v-model='variation_settings.pricable'></el-switch>
                        </el-form-item>
                        <el-form-item label='Position'>
                            <el-input-number v-model='variation_index' :min="-1"></el-input-number>
                        </el-form-item>
                        <!-- <el-form-item label='Cette variation est associée à un stock'>
                            <el-switch v-model='variation_settings.stockable'></el-switch>
                        </el-form-item> -->
                    </div>
                </div>
                <div v-if='step === 2'>
                    <h4>Sélectionnez les '{{variation_label.toLowerCase()}}' disponibles lors de la commande</h4>
                    <div class='options'>
                        <table class="collapse br2 w-100">
                            <tbody>
                                <tr v-for='option in displayableOptions' :key='option'>
                                    <td width="40"><el-checkbox v-model="selectedOptions[option]"></el-checkbox></td>
                                    <td>{{option}}</td>
                                    <td width="220">
                                        <div style="display: inline-flex">
                                            <label style="width: 150px; font-size: 0.9em; margin-top: 5px;">Position</label>
                                            <el-input class="w-25" type="number" size="mini" :min="-1" v-model="variation_options_index[option]"></el-input>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <div>
                            
                        </div>
                    </div>
                    <div id='optionEditor'>
                        <el-form-item label='Ajouter une option' size="small">
                            <el-input type='text' size="small" v-model='newOption' @keyup.enter.native='addNewOption'>
                                <el-button slot="append" size="small" type='primary' icon="el-icon-circle-plus-outline" @click="addNewOption"></el-button>
                            </el-input>
                        </el-form-item>
                    </div>
                </div>
                <div id='productVariationsButtons'>
                    <el-button type="success" @click="prev" :disabled='step === 0' icon='el-icon-arrow-left'>Précédent</el-button>
                    <el-button type="success" @click="next" v-if='hasNextStep' :disabled='!formIsValid'>Suivant <i class="el-icon-arrow-right"></i></el-button>
                    <el-button type="success" @click="save()" v-else :disabled='!formIsValid'><i class="el-icon-success"></i> Terminer</el-button>
                </div>
            </div>
        </el-form>
    </div>
</template>

<script>
import Vuex from 'vuex'
import Product from '@/lib/entities/Product'
import ProductVariation from '@/lib/entities/ProductVariation'
export default {
    name: 'ProductVariationEditor',
    props: {
        initialStep: {
            type: Number,
            required: false,
            default: () => 0
        },
        product: {
            type: Product,
            required: true
        },
        existingVariation: {
            type: ProductVariation,
            required: false
        }
    },
    data: () => ({
        variation_type: null,
        variation_index: null,
        variation_label: null,
        variation_options_data: {},
        variation_options: [],
        variation_options_index: {},
        variation_settings: {
            hasOptions: false,
            required: true,
            pricable: false,
        },
        types: ProductVariation.types,
        step: 0,
        copyFromExistingVariation: null,
        newOption: '',
        selectedOptions: {},
        displayableOptions: []
    }),
    computed: {
        ...Vuex.mapGetters(['products']),
        formIsValid() {
            if (this.step === 0) {
                return this.variation_label != '' && this.variation_label != null
            }
            return true
        },
        displayVariationTitle() {
            return !!this.variation_type
        },
        hasNextStep() {
            if (this.variation_settings.hasOptions) {
                return this.step < 2
            } else {
                return this.step < 1
            }
        },
        existingVariations() {
            const existingVariations = []
            const variationsHash = {}
            for (const product of this.products) {
                for (const variation of product.variations) {
                    if (!variationsHash[variation.label]) {
                        variationsHash[variation.label] = true
                        existingVariations.push(variation)
                    }
                }
            }
            return existingVariations
        }
    },
    mounted() {
        this.step = this.initialStep
        if (this.existingVariation !== null) {
            this.variation_options = this.existingVariation.options
            this.variation_label = this.existingVariation.label
            this.variation_type = this.existingVariation.type
            this.variation_settings = this.existingVariation.settings
            this.variation_options_data = this.existingVariation.options_data
            for (const option in this.existingVariation.options_data) {
                this.$set(this.variation_options_index, option, Number(this.existingVariation.options_data[option].index) ?? null)
            }
            this.variation_index = this.existingVariation.index || -1
            if (this.variation_options.length > 0) {
                this.updateOptionsList(true)
            }
        }
    },
    methods: {
        next() {
            this.step++
        },
        prev() {
            this.step--
        },
        exitEditor() {
            this.$emit('exit')
        },
        addNewOption() {
            if (this.newOption.length === 0) {
                return
            }
            this.variation_options_data[this.newOption] = {
                index: null
            }
            this.variation_options_index[this.newOption] = 0
            this.variation_options.push(this.newOption)
            this.updateOptionsList(true)
            this.newOption = ''
        },
        updateOptionsList(selected) {
            const options = []
            for (const option of this.variation_options) {
                if (!options.includes(option)) {
                    options.push(option)
                }
            }
            this.displayableOptions = options
            if (selected !== undefined) {
                for (const option of options) {
                    if (this.selectedOptions[option] === undefined) {
                        this.$set(this.selectedOptions, option, selected)
                    }
                }
            }
        },
        handleExistingVariationSelected(index) {
            if (index != null && index > -1) {
                const copyFrom = this.existingVariations[index]
                if (copyFrom) {
                    this.variation_type = copyFrom.type
                    this.variation_label = copyFrom.label
                    this.variation_index = copyFrom.index
                    this.variation_settings = copyFrom.settings
                    this.variation_options = copyFrom.options
                    this.variation_options_data = copyFrom.options_data
                    this.displayableOptions = copyFrom.options
                    this.updateOptionsList(true)
                }
            }
        },
        handleVariationTypeChanged(type) {
            this.variation_type = type
            this.updateOptionsList(true)
        },
        save() {
            let variation
            if (this.existingVariation) {
                variation = new ProductVariation(this.existingVariation)
            } else {
                variation = new ProductVariation()
            }
            variation.setVariationType(this.variation_type)
            variation.setLabel(this.variation_label)
            variation.setSettings(this.variation_settings)
            variation.setIndex(this.variation_index)

            for (const option in this.variation_options_index) {
                this.variation_options_data[option] = {
                    ...this.variation_options_data[option],
                    index: Number(this.variation_options_index[option])
                }
            }

            variation.setOptionData(this.variation_options_data)
            
            for (const key in this.selectedOptions) {
                if (this.selectedOptions[key] === true) {
                    variation.addOption(key)
                }
            }
            this.$emit('save', variation)
            this.step = 0
        }
    }
}
</script>

<style lang='scss' scoped>
#productVariationSteps {
    margin-top: 1em;

    .el-button-group {
        margin-top: 2em;
    }
}

#productVariationsSettings {
    .el-form-item {
        margin-bottom: 10px;
    }
 
    .variation-options {
        margin-left: 1em;
    }
}

#productVariationsButtons {
    margin-top: 2em;
    display: flex;
    justify-content: space-between;
}
#optionEditor {
    width: 170px;
}
</style>
