<template>
    <div class='product-variation' :class='{"is-expandable": expandable, "is-pricable": pricable}'>
        <div class='product-variation-info'>
            <div class='product-variation-title' @click='toogleOptions'>
                <el-button type='text' v-if='expandable' :class='{"expanded": view.options, "with-options": variation.options.length}' class="product-variation-btn" size="mini"></el-button>
                <!-- <span v-if='!variation.options.length'><i class="el-icon-caret-right"></i></span> -->
                <!-- <i v-else class='el-icon-fa-random'></i>&nbsp; -->
                {{variation.label}}
            </div>
            <div class='item-price' v-if='pricable && variation.settings.pricable' title="Prix"> 
                <div class="label" v-if='!showPriceEditor' @click='showPriceEditor = true'>
                    <span style='font-size: 0.8em;'>{{price|EUR}}</span>
                </div>
                <div v-else>
                    <el-button type="success" title='Enregister' circle size="mini" @click='handlePriceChanged' icon="el-icon-check"></el-button>
                    <el-input type="number" min="0" step="0.01" class="input" v-model='priceInput' :value='price'></el-input>
                </div>
            </div>
            <div v-if='statuable'>
                <el-button @click="handleVariationStatus" :type='currentStatus == "available" ? "success" : "light"' size="mini" title="Disponibilité de la variation" :icon='currentStatus == "available" ? "el-icon-fa-thumbs-up" : "el-icon-fa-thumbs-down"'>
                </el-button>
            </div>
            <div class='product-variation-actions' v-if='editable || droppable'>
                <a role='button' @click='editVariation' v-if='editable'><i class='el-icon-edit'></i></a>
                <a role='button' @click='dropVariation' v-if='droppable'><i class='el-icon-delete'></i></a>
            </div>
        </div>
        <div class='product-variation-options' v-if='view.options'>
            <div class='product-variation-option' v-for='(option, $index) in variation.options' :key='$index'>
                <div class="variation-option-label">{{option}}</div>
                <div style="display: inline-flex; justify-content: space-around; align-items: center;">
                    <div class='variation-option-price' v-if='pricable && variation.settings.pricable' title="Prix"> 
                        <div class="label" v-if='!showPriceEditor' @click='showPriceEditor = true'>
                            <span style='font-size: 0.8em;'>{{optionPrices[option]|EUR}}</span>
                        </div>
                        <div v-else class="variation-option-price-input">
                            <!-- <el-button type="success" title='Enregister' circle size="mini" @click='handleOptionPriceChanged' icon="el-icon-check"></el-button> -->
                            <input type="number" min="0" step="0.01" class="price-input" v-model='optionPrices[option]'>
                        </div>
                    </div>
                    <div v-if='statuable' class="variation-option-status">
                        <el-button @click="handleVariationOptionStatus(option)" :type='optionStatus[option] !== "unavailable" ? "success" : "light"' size="mini" title="Disponibilité de l'option" :icon='optionStatus[option] !== "unavailable" ? "el-icon-fa-thumbs-up" : "el-icon-fa-thumbs-down"'>
                        </el-button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import ProductVariation from '@/lib/entities/ProductVariation'
export default {
    name: 'ProductVariationItem',
    props: {
        variation: {
            type: ProductVariation,
            required: true
        },
        pricable: {
            type: Boolean,
            required: false,
            default: () => false
        },
        statuable: {
            type: Boolean,
            required: false,
            default: () => false
        },
        droppable: {
            type: Boolean,
            required: false,
            default: () => false
        },
        editable: {
            type: Boolean,
            required: false,
            default: () => false
        },
        expandable: {
            type: Boolean,
            required: false,
            default: () => false
        },
        status: {
            type: String,
            required: false
        },
        cardStatus: {
            type: Object,
            required: false,
            default: () => ({})
        },
        price: {
            type: Number,
            required: false,
            default: () => 0.0
        },
        prices: {
            type: Object,
            required: false,
            default: () => ({})
        }
    },
    data: () => ({
        priceInput: null,
        currentStatus: "",
        showPriceEditor: false,
        view: {
            options: false
        },
        optionStatus: {},
        optionPrices: {}
    }),
    mounted() {
        this.priceInput = this.price
        this.currentStatus = this.status
        this.optionStatus = this.cardStatus
        if (this.variation.options && this.variation.options.length) {
            for (const option of this.variation.options) {
                this.optionPrices[option] = this.prices[option] || 0
            }
        }
    },
    methods: {
        toogleOptions() {
            if (this.expandable) {
                this.view.options = !this.view.options
            }
        },
        editVariation() {
            this.$emit('edit', this.variation)
        },
        dropVariation() {
            this.$emit('drop', this.variation)
        },
        handlePriceChanged() {
            this.showPriceEditor = false
            const price = parseFloat(this.priceInput)
            this.$emit('priceChanged', this.variation, price, this.optionPrices)
        },
        handleVariationStatus() {
            if (this.currentStatus == "available") {
                this.currentStatus = "unavailable"
            } else {
                this.currentStatus = "available"
            }
            this.$emit('statusChanged', this.variation, this.currentStatus, this.optionStatus)
        },
        handleVariationOptionStatus(option) {
            if (this.optionStatus[option] == undefined) {
                this.$set(this.optionStatus, option, "available")
            } else if (this.optionStatus[option] == "available") {
                this.$set(this.optionStatus, option, "unavailable")
            } else {
                this.$set(this.optionStatus, option, "available")
            }
            this.$emit('itemStatusChanged', this.variation, option, this.optionStatus[option])
        }
    }
}
</script>

<style lang='scss'>
.product-variation {
    display: flex;
    flex-direction: column;
    // @version 2022-04-12
    max-width: 100%;
    // &.is-pricable {
    //     max-width: 100%;
    // }
    // &:not(.is-pricable) {
    //     max-width: 250px;
    // }
    
    &.is-expandable {
        .product-variation-title {
            cursor: pointer;
        }        
    }
}

// .product-variation + .product-variation {
//     margin-top: 0.5em;
// }

.product-variation-info {
    display: flex;
    flex-direction: row;
    align-items: center;
    // justify-content: space-between;
}

@media only screen and (max-width: 500px) {
    .product-variation-info {
        flex-direction: column;
    }
}


.product-variation-actions {
    padding: 7px 15px;
    color: #007AFF;
    cursor: pointer;
    a {
        vertical-align: middle;
    }
    a + a {
        margin-left: 10px;
    }
}

.product-variation-btn { 
    // &.expanded {
    //     i {
    //         transform: rotate(90deg);
    //     }
    // }
    &.with-options::before {
        content: "\e613";
        position: relative;
        right: 3px;
        display: block;
        font-family: "element-icons" !important;
    }
    &.with-options.expanded::before {
        transform: rotate(90deg);
    }
}

.product-variation-title {
    font-weight: 600;
    // padding-bottom: 3px;
    user-select: none;
    font-size: 0.9em;
    line-height: 40px;
    flex-grow: 1;
}
.product-variation-options {
    margin-left: 1em;
}
.product-variation-option {
    padding: 3px 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.variation-option-label {
    font-size: 0.8em;
    font-weight: bold;
}
.variation-option-price {
    justify-self: end;
}
.variation-option-price-input {
    width: 85px;    
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 3px;
    border-radius: 3px;
    cursor: pointer;
    
    input {
        display: block;
        width: 100%;
        border: 1px solid #c0c4cc;
        padding: 0.5px 3px;
        border-radius: 3px;
        color: rgb(96, 98, 102);
    }
}

.variation-option-status {
    margin-left: 5px;
}
</style>
