<template>
    <div id="cardEdit" class="view">
        <h2 class="subtitle"><el-icon icon="el-icon-fa-pencil"></el-icon>Modification du produit</h2>
        <div class="box">
            <product-form
                v-if='product'
                :product='product'
                :categories='categories'
                :pricable='true'
                :existingProducts='products'
                :isEditing='true'>
            </product-form>
        </div>
        <el-footer>
            <el-button type="info" @click="goBack">Annuler</el-button>
            <el-button type="primary" :disabled='!isProductValid' @click="saveProduct" :loading='loading' icon='el-icon-circle-check-outline'>Enregistrer</el-button>
        </el-footer>
    </div>
</template>

<script>
import Vuex from 'vuex'
import ProductForm from '@/components/Menu/ProductForm'
export default {
    name: 'ProductEdit',
    data: () => ({
        loading: false
    }),
    components: {
        ProductForm
    },
    computed: {
        ...Vuex.mapGetters(['products', 'categories']),
        product() {
            return this.products.find((product) => product.id === this.$route.params.id)
        },
        isProductValid() {
            return true
        },
        from() {
            return this.$route.query.from
        }
    },
    methods: {
        ...Vuex.mapActions(['saveStore']),
        async saveProduct() {
            this.loading = true
            if (this.product.category) {
                this.product.category.addProduct(this.product)
            }
            await this.saveStore({ products: [this.product], categories: [this.product.category] })
            this.$message({
                message: 'Modification enregistrée avec succès',
                type: 'success'
            })
            this.loading = false
            this.goBack()
        },
        goBack() {
            if (this.from) {
                this.$router.push({path: this.from, hash: this.product.id})
            } else {
                this.$router.back()
            }
        }
    }
}
</script>

<style>

</style>
