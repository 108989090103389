<template>
    <el-dialog
        :visible.sync="visible"
        width='55%'
        title='Prendre une photo'
        append-to-body
        :before-close="handleCloseModal"
        >
            <camera @picture='emitPicture' :enabled='visible'/>
            <!-- <span slot="footer" class="dialog-footer">
                <el-button @click="handleCloseModal" icon='el-icon-close'>Fermer</el-button>
            </span> -->
    </el-dialog>
</template>

<script>
import Camera from './Camera'
import Vuex from 'vuex'
export default {
    name: 'CameraModal',
    components: {
        Camera
    },
    props: {
        visible: {
            type: Boolean,
            required: true
        }
    },
    methods: {
        handleCloseModal() {
            this.$emit('close')
        },
        emitPicture(picture) {
            this.$emit('picture', picture)
        }
    }
}
</script>

<style>

</style>
