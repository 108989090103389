<template>
    <div id='imagePicker'>
        <p class="control has-icons-left" id="imagesSearchBox">
          <input class="input is-small" type="text" placeholder="Filtrer" v-model="query">
          <span class="icon is-small is-left">
            <i class="fa fa-search"></i>
          </span>
        </p>
        <div id="imagesLibrary">
          <div class="columns is-multiline is-mobile is-centered">
            <div class="column is-narrow" v-for="(image, $index) in filteredLibrary" :key='$index'>
              <figure @click="selectImage(image)" class="item image is-96x96" :class="{'item-selected': image == selectedImage}">
                <img :src="image.urls.thumb" :alt="image.name">
                <div class="item-description">{{image.name.replace(/_|-/gm, ' ')}}</div>
              </figure>
            </div>
            <div v-show="filteredLibrary.length == 0 && query.length > 0" class="notification is-warning">
              Aucun résultat - <a @click="clearQuery()">Effacer</a>
            </div>
          </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'ImagePicker',
    props: {
        library: {
            type: Array,
            required: true
        }
    },
    data: () => ({
        query: '',
        selectedImage: null
    }),
    computed: {
        filteredLibrary() {
            if(this.query.length == 0) {
                return this.library
            }
            return this.library.filter((el) => el.name.match(this.query))
        }
    },
    methods: {
        selectImage(image) {
            if(this.selectedImage == image) {
                this.selectedImage = null
            } else {
                this.selectedImage = image
            }
            this.$emit('select', this.selectedImage)
        },
        clearQuery() {
            this.query = ''
        }
    }
}
</script>

<style>
#imagesSearchBox {
  margin-bottom: 20px;
}

#imagesLibrary {
  margin-bottom: 20px;
}

.item {
  border: 1px solid #bdbdbd;
  border-radius: 5px;
  overflow: hidden;
  cursor: pointer;
  transition: all 0.12s ease;
  padding: 3px;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
   -khtml-user-select: none; /* Konqueror HTML */
     -moz-user-select: none; /* Firefox */
      -ms-user-select: none; /* Internet Explorer/Edge */
          user-select: none; /* Non-prefixed version, currently
                                supported by Chrome and Opera */
}

.item:hover .item-description {
    opacity: 0;
    top: 100px;
}

.item-description {
    position: absolute;
    text-align: center;
    font-size: 0.8em;
    font-weight: 600;
    background-color: #0000006b;
    color: white;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px;
    opacity: 1;
    transition: all 0.25s ease;
}

.item-selected {
    border: 2px solid #4caf50b3;
    box-shadow: 0px 0px 10px 0px rgb(76, 175, 80);
    transform: scale(1.05);
}
</style>
