<template>
    <div class="view">
        <h1>Intégrations</h1>
        <h3>Raccordez vos menus et vos commandes</h3>
        <div class="box">
            <div v-for='(group, $index) in providers' :key='$index' class="provider-group">
                <h3>{{group.title}}</h3>
                <div class="provider-list">
                    <div v-for='(provider, $j) in group.children' :key='$j' class="provider" :class='{"disabled": !provider.enabled}'>
                        <img :src='provider.logo'/>
                        <h4>{{provider.label}}</h4>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Vuex from 'vuex'
export default {
    name: 'Integrations',
    data: () => ({
        providers: []
    }),
    methods: {
        ...Vuex.mapActions(['getProviders'])
    },
    mounted() {
        this.getProviders().then((providers) => {
            this.providers = providers
        })
    }
}
</script>

<style lang='scss'>
    .provider-group {
        h2 {
            font-size: 2em;
        }
    }
    .provider-list {
        overflow-x: scroll;
        padding: 0.5em;
        width: 100%;
        display: flex;
        flex-direction: row;
    }

    .provider {
        cursor: pointer;
        background-color: white;
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
        text-align: center;
        box-shadow: 0 2px 3px rgba(10, 10, 10, 0.1), 0 0 0 1px rgba(10, 10, 10, 0.1);
        user-select: none;
        img {
            border-top-left-radius: 5px;
            border-top-right-radius: 5px;
            width: 200px;
            height: 200px;
            user-select: none;
        }

        &.disabled {
            opacity: 0.5;
            cursor: not-allowed;
        }
    }
    .provider + .provider {
        margin-left: 0.5em;
    }
</style>
