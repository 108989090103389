<template>
    <div id='cardLinkForm'>
        <p v-if='tooltip' class="notification info" style="max-width: 100%">Sélectionner l'établissement auquel associer cette carte</p>
        <el-select v-model="placeId" placeholder="Sélectionner" @change='handlePlaceUpdated'>
            <el-option :value="null" label="----"></el-option>
            <el-option
                v-for="place in places"
                :key="place.id"
                :label="place.name"
                :value="place.id">
            </el-option>
        </el-select>
    </div>
</template>

<script>
import Vuex from 'vuex'
import Card from '@/lib/entities/Card'
export default {
    name: 'CardLinkForm',
    props: {
        card: {
            type: Card,
            required: true
        },
        tooltip: {
            type: Boolean,
            required: false,
            default: () => true
        }
    },
    data: () => ({
        placeId: null
    }),
    computed: {
        ...Vuex.mapState(['places']),
    },
    created() {
        this.placeId = this.card.place ? this.card.place.id : null
    },
    methods: {
        handlePlaceUpdated(placeId) {
            const place = this.places.find((p) => p.id === placeId)
            this.$emit('placeSelected', place)
        }  
    }
}
</script>

<style>

</style>
