<template>
    <el-card class="order-item" :class='orderClass' shadow="hover" :body-style="{ padding: '0px'}">
        <div slot="header">
            <strong><i class="el-icon-fa-list-alt"></i>&nbsp;Commande #{{order.number}}</strong>
            <!-- <span style="float: right;" type="text" :class='paymentClass'>{{order.paymentStatus}}</span> -->
        </div>
        <div class='order-meta'>
            <span title='Heure de commande'><i class="el-icon-fa-clock-o"></i>&nbsp;{{order.createdAt.format('HH:mm')}}</span>
            <span title='Carte'><i class="el-icon-fa-list-alt"></i>&nbsp;{{orderCard}}</span>
            <span v-if='displayTTL'><i class="el-icon-fa-hourglass-1"></i>&nbsp;{{order.createdAt.toNow(true)}}</span>
            <span v-else title="Heure d'envoi"><i class="el-icon-fa-rocket"></i>&nbsp;{{order.completedAt.format('HH:mm')}}</span>
        </div>
        <div class='order-composition'>
            <div v-for="item in order.items" :key="item.id" class="order-comp">
                <div class='order-item-comp-item'><span style="font-size: 1.1em; font-weight: 600;">{{item.quantity}}x</span>&nbsp;<strong>{{item.label}}</strong> <b v-if='item.comment'>&nbsp;({{item.comment}})</b></div>
                <div class='order-item-comp-variations'>
                    <div v-for='(option, $v) in item.options' :key='$v' class='order-comp-variation'>
                        <small>- <strong>{{option.label}}</strong> <b v-if='option.comment'>&nbsp;({{option.comment}})</b></small>
                        <div class="order-item-comp-variations">
                            <div v-for='(sub, $s) in option.options' :key='$s' class='order-comp-variation'>
                                <small>    - <strong>{{sub.label}}</strong>  <b v-if='sub.comment'>&nbsp;({{sub.comment}})</b></small>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="order-total">
                <div><strong>TOTAL TTC</strong>&nbsp;<small>({{paymentMethods(order.paymentMethod)}})</small></div>
                <div>{{order.total|EUR}}</div>
            </div>
        </div>
        <div class="order-guests" v-if='order.guests' title='Réponse à la question (couverts)'>
            <i class="el-icon-fa-question-circle"></i>&nbsp;{{order.guests}}
        </div>
        <div class="order-comment" v-if='order.comment' title='Commentaire du client'>
            <i class="el-icon-fa-quote-right"></i>&nbsp;{{order.comment}}
        </div>
        <div class="order-delivery" v-if='order.settings.timeslot'>
            <i class="el-icon-fa-clock-o"></i>&nbsp;
            <span v-if='order.settings.timeslot.start === "asap"'>
                Dès que possible
            </span>
            <span v-else>
                {{order.settings.timeslot.start}}-{{order.settings.timeslot.end}}
            </span>
        </div>
        <div class="order-delivery" v-if='order.settings.pickupType === "delivery"' title='Livraison'>
            <h4>LIVRAISON</h4>
            <i class="el-icon-fa-map-marker"></i>&nbsp;{{order.settings.location.street}}<br>
            {{order.settings.location.zipcode}} {{order.settings.location.city}}
            <div v-if='order.settings.location.street2' style="font-size: 0.9em; border-top: 1px solid white; margin-top: 5px; padding-top: 5px;">
                {{order.settings.location.street2}}
            </div>
        </div>
        <div class="order-delivery" v-if='order.settings.pickupType === "roomService"' title='Service en salle'>
            <h4>SERVICE EN SALLE</h4>
            <i class="el-icon-fa-bell"></i>&nbsp;{{order.settings.location.place}}
        </div>
        <div class='order-customer'>
            <span><i class="el-icon-fa-user"></i>&nbsp;{{order.customer.firstname}}</span>
            <span><i class="el-icon-fa-phone"></i>&nbsp;<a :href="'tel:'+order.customer.phone">{{order.customer.phone}}</a></span>
        </div>
        <div class='order-actions'>
            <template v-if='order.status === "pending"'>
                <el-button type="danger" size="small" icon='el-icon-fa-times' @click='rejectOrder'>Refuser</el-button>
                <el-button type="success" size="small" icon='el-icon-fa-check' @click='acceptOrder'>Accepter</el-button>
            </template>
            <template v-if='order.status === "processing"'>
                <el-button type="danger" size="small" icon='el-icon-fa-times' @click='cancelOrder'>Annuler</el-button>
                <el-button type="success" size="small" icon='el-icon-fa-rocket' @click='sendOrder'>Envoyer</el-button>
            </template>
            <el-button v-if='printable' type="info" size="small" @click='printOrder'>Imprimer</el-button>
        </div>
    </el-card>
</template>

<script>
import Vuex from 'vuex'
export default {
    name: 'OrderItem',
    props: {
        order: {
            type: Object,
            required: true
        },
        printable: {
            type: Boolean,
            required: false,
            default: () => false
        }
    },
    computed: {
        ...Vuex.mapGetters(['cards']),
        orderCard() {
            if (this.order.card) {
                const card = this.cards.find((c) => c.id == this.order.card)
                if (card !== undefined) {
                    return card.name
                }
            }
            return ''
        },
        orderClass() {
            return `is-${this.order.status}`
        },
        paymentClass() {
            return `is-${this.order.paymentStatus}`
        },
        displayTTL() {
            return ['pending', 'processing'].includes(this.order.status)
        }
    },
    methods: {
        acceptOrder() {
            this.$emit('accepted', this.order)
        },
        rejectOrder() {
            this.$emit('rejected', this.order)
        },
        cancelOrder() {
            this.$emit('canceled', this.order)
        },
        sendOrder() {
            this.$emit('completed', this.order)
        },
        printOrder() {
            this.$emit('print', this.order)
        },
        paymentMethods(m) {
            const mapping = {
                creditcard : 'Carte bancaire',
                spot: 'Sur place',
                lydia: 'Lydia',
                lydia_cb: 'Carte bancaire',
                pg_trd: 'Ticket restau',
                pg_lunchr: 'Swile',
                pg_restoflash: 'Restoflash',
                edenred: 'Edenred',
                lyfpay: 'Lyfpay'
            }
            if (mapping[m]) {
                return mapping[m]
            }
            return m
        }
    }
}
</script>

<style lang='scss'>
.order-item {
    margin: 1em;
    width: 300px;
    max-width: 350px;
    min-width: 250px;
    background-color: white;
}

.order-item.is-pending .el-card__header {
    background-color: black;
    color: white;
}
.order-item.is-processing .el-card__header {
    background-color: #ff9800;
}
.order-item.is-completed .el-card__header {
    background-color: #67c23a;
}

.order-delivery {
    background-color: #1480AF;
    color: white;
    padding: 10px;
    text-align: center;
    h4 {
        margin-top: 0;
        margin-bottom: 0;
    }
}

.order-item .el-card__body {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
}

.order-composition {
    border-bottom: 1px solid #dedede;
    /* border-radius: 5px; */
    padding: 1em;
    background-color: #fdfdfd;
}
.order-comp {
    margin: 0.5em 0;
}
.order-meta {
    padding: 1em;
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #dedede;
}
.order-total {
    border-top: 1px solid #dedede;
    display: flex;
    justify-content: space-between;
    padding: 0.5em 0;
    font-weight: 800;
}
.order-customer {
    padding: 1em;
    font-size: 0.9rem;
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #dedede;
}
.order-comment {
    padding: 1em;
    background-color: black;
    color: white;
    font-family: monospace;
    border-bottom: 1px solid #dedede;
}

.order-guests {
    background-color: #2B3347;
    color: white;
    padding: 10px;
    text-align: center;
}

.order-comp-variations {
    margin-top: 0.5em;
    font-size: 0.9rem;
    margin-left: 0.5em;
}
.order-comp-variation {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .order-item-comp-variations {
        margin-left: 10px;
    }
}
.order-actions {
    width: 100%;
    padding: 1em;
    display: flex;
    justify-content: space-between;
}
</style>
