<template>
    <div id="placeDisplayForm">
        <el-row :gutter="10">
            <el-col :span="12" :xs="24" v-loading="loading">
                <h4>Informations</h4>
                <el-form label-width="120px">
                    <el-form-item label="Nom à afficher">
                        <el-input v-model='form.name'></el-input>
                    </el-form-item>
                    <el-form-item label="Présentation">
                        <el-input v-model='form.description'></el-input>
                    </el-form-item>
                    <el-form-item label="Adresse">
                        <el-input v-model='form.address' placeholder='ex: 11 rue du Paradis, 35000 RENNES'></el-input>
                    </el-form-item>
                    <el-form-item label="Téléphone">
                        <el-input v-model='form.phone'></el-input>
                    </el-form-item>
                    <el-form-item label="Email">
                        <el-input v-model='form.email'></el-input>
                    </el-form-item>
                </el-form>
                <div v-if="extra">
                    <h4>Réseaux sociaux</h4>
                    <el-form label-width="120px">
                        <el-form-item label="Site internet">
                            <el-input v-model='social.website' placeholder='http://www.monsite.com'></el-input>
                        </el-form-item>
                        <el-form-item label="Facebook">
                            <el-input v-model='social.facebook' placeholder=''></el-input>
                        </el-form-item>
                        <el-form-item label="Twitter">
                            <el-input v-model='social.twitter'></el-input>
                        </el-form-item>
                        <el-form-item label="Instagram">
                            <el-input v-model='social.instagram'></el-input>
                        </el-form-item>
                    </el-form>
                    <h4>Horaires d'ouverture de votre établissement</h4>
                    <place-hours-table :hours='hours'/>
                    <!-- <h4>Calendrier</h4>
                    <div class="notification is-success">
                        <p>La gestion du calendrier n'est pas disponible avec votre offre.</p>
                        <p>Pour changer d'offre, merci de nous contacter.</p>
                    </div> -->
                </div>
            </el-col>
            <el-col :span="12" :xs="24" v-loading="loading">
                <div style="margin-left: 1.5em;">
                    <h4>Logo</h4>
                    <el-upload
                        ref="logo"
                        :action='logoUploadUrl'
                        :auto-upload='false'
                        accept='image/*'
                        :on-change='uploadFile'
                        :drag='false'
                        list-type="picture-card"
                        :show-file-list="false">
                            <img v-if="form.logoUrl" :src="form.logoUrl" class="logo" width="145px" :style='{backgroundColor: palette.secondary}'>
                            <i v-else class="el-icon-plus"></i>
                    </el-upload>
                    <div v-if='extra'>
                        <el-row :gutter="10">
                            <el-col :span="12" :xs="24">
                                <h5><i class="el-icon-fa-tint"></i> Couleur principale</h5>
                                <sketch v-model="colors.headerBackground" />
                            </el-col>
                            <el-col :span="12" :xs="24">
                                <h5><i class="el-icon-fa-tint"></i> Couleur secondaire</h5>
                                <sketch v-model="colors.secondaryColor" />
                            </el-col>
                        </el-row>
                        <h4>Paramètres d'affichage</h4>
                        <el-form>
                            <el-form-item>
                                <span slot='label'>Afficher le 'header'</span>
                                <el-switch v-model="settings.display.displayHeader"></el-switch>
                            </el-form-item>
                            <el-form-item label="Afficher les informations de contact">
                                <el-switch v-model="settings.display.displayAside"></el-switch>
                            </el-form-item>
                        </el-form>
                    </div>
                </div>
            </el-col>
        </el-row>
    </div>
</template>

<script>
import Vuex from 'vuex'
import { Sketch } from 'vue-color'
import PlaceHoursTable from '@/components/Place/PlaceHoursTable'
export default {
    name: 'PlaceDisplayForm',
    components: {
        Sketch,
        PlaceHoursTable,
    },
    props: {
        place: {
            type: Object,
            required: true
        },
        extra: {
            type: Boolean,
            required: false,
            default: () => true
        }
    },
    data: () => ({
        loading: false,
        form: {},
        settings: {
            display: {
                displayHeader: true,
                displayAside: true
            }
        },
        social: {
            twitter: null,
            instagram: null,
            facebook: null,
            website: null
        },
        colors: {
            headerBackground: '#fff',
            secondaryColor: '#bbb'
        },
        logoUploadUrl: '',
        palette: {
            main: null,
            secondary: '#bbb'
        },
        hours: [],
    }),
    mounted() {
        const { id, name, description, phone, email, logoUrl, hours } = this.place
        this.form = {
            id,
            name,
            description,
            phone,
            email,
            logoUrl,
            address: this.place.address
        }
        this.hours = hours || []

        this.social = {
            ...this.social,
            ...this.place.social
        }

        this.colors = {
            ...this.colors,
            ...this.place.colors
        }

        this.$watch('settings', (settings) => {
            this.$emit('settingsUpdated', settings)
        }, {
            deep: true
        })

        this.$watch('social', (social) => {
            this.$emit('socialUpdated', social)
        }, {
            deep: true
        })

        this.$watch('hours', (hours) => {
            this.$emit('hoursUpdated', hours)
        }, {
            deep: true
        })

        this.$watch('colors', (colors) => {
            this.$emit('colorsUpdated', colors)
        }, {
            deep: true
        })

        this.$watch('form', (infos) => {
            this.$emit('infosUpdated', infos)
        }, {
            deep: true
        })
    },
    methods: {
        ...Vuex.mapActions(['storePlaceLogo', 'updatePlace', 'saveStore']),
        uploadFile(file) {
            this.loading = true

            const reader = new FileReader()
            
            reader.onerror = (error) => {
                this.$message({
                    type: 'warning',
                    message: 'Erreur lors de l\'enregistrement de l\'image, merci de réessayer'
                })
            }


            reader.onload = (event) => {
                this.form.logoUrl = reader.result

                this.storePlaceLogo({
                    placeId: this.place.id,
                    filename: file.name,
                    file: file.raw
                }).then(({url, colors}) => {
                    this.form.logoUrl = url
                    this.palette = colors.palette
                    this.$message({
                        type: 'success',
                        message: 'Image enregistrée avec succès'
                    })
                    this.loading = false
                }).catch((err) => {
                    this.$message({
                        type: 'error',
                        message: 'Une erreur s\'est produite, merci de réessayer'
                    })
                    this.loading = false
                })
            }

            reader.readAsDataURL(file.raw)
        },
    }
}
</script>

<style>

</style>
