<template>
    <el-form id="cardComposer" @submit.native.prevent>
        <el-row :gutter="10">
            <el-col :span="mainSpanSize" :xs="24">
                <div style='position: relative' v-if='showHeader'>
                    <div style='position: absolute; right: 10px; top: 3px;'>
                        <el-button circle size="small" @click='view.preview = true' icon='el-icon-fa-eye'></el-button>
                    </div>
                    <el-form-item style="margin-bottom: 9px; margin-right: 60px;">
                        <el-input type="text" ref='cardNameInput' autofocus="on" class="input-large" size="large" prefix-icon='el-icon-edit' placeholder='Nom de la carte' v-model='card.name' required/>
                    </el-form-item>
                </div>
                <div id="cardComposerProducts">
                    <h3>Catégories</h3>
                    <draggable v-model='card.categories' handle=".category-handle" @start='drag=true' @end='drag=false'>
                        <category-item
                            v-for='category in card.categories'
                            :key='category.id'
                            :deletable='true'
                            :editable='false'
                            :draggable='card.categories.length > 1'
                            :edit='editingCategory === category'
                            :withProducts='true'
                            :category='category'
                            @save='editingCategory = null'
                            @drop='dropCategory'
                            style='background-color: #fbfbfb'>
                            <template slot='products'>
                                <draggable style="margin-top: 1em;" handle='.product-handle' :options="{disabled: !(category.products.length >= 1)}" v-model='category.products' @start='drag=true' @end='drag=false'>
                                    <product-item
                                        v-for='product in category.products'
                                        :key='product.id'
                                        :product='product'
                                        :draggable="category.products.length > 1"
                                        :pricable='true'
                                        :deletable='true'
                                        :editable='false'
                                        :statuable='statuable'
                                        :stockable='false'
                                        :showVariations='true'
                                        :showItems='true'
                                        :price='prices[product.id]'
                                        :vat='vats[product.id]'
                                        :stock='stocks[product.id]'
                                        :status='editingCardStatus[product.id]'
                                        :cardStatus='editingCardStatus'
                                        :prices='prices'
                                        :extras='extras'
                                        :showOnlyPricableVariations='false'
                                        @edit='handleProductEdit'
                                        @drop='handleProductDrop(category, product)'
                                        @statusChanged='handleProductStatusChanged'
                                        @stockChanged='handleProductStockChanged'
                                        @priceChanged='handlePriceChanged'
                                        @itemExtraChanged='handleItemExtraChanged'
                                        @itemStatusChanged='handleItemStatusChanged'
                                        @vatChanged='handleProductVat'
                                    />
                                </draggable>
                                <el-button @click="openAddProduct(category)" size="mini" type="text" icon='el-icon-plus'>
                                    Ajouter un produit
                                </el-button>
                            </template>
                        </category-item>
                    </draggable>
                    <el-button @click="createCategory" size="mini" type="text" icon='el-icon-plus'>
                        Nouvelle catégorie
                    </el-button>
                </div>
            </el-col>
            <el-col :span="8" :xs="24" v-if='view.preview'>
                <div class="box">
                    <div style="position: relative;">
                        <div style='position: absolute; right: 0px; top: 10px;'>
                            <el-button @click='view.preview = false' type="text" size="small" circle icon='el-icon-close'></el-button>
                        </div>
                        <h3 style='line-height: 35px;'><i class="el-icon-fa-eye"></i>&nbsp;Aperçu</h3>
                    </div>
                    <div id='menuPreviewContainer'>
                        <div id='menuPreview'>
                            <iframe frameborder="0" height="100%" width="100%" :src='menuUrl'></iframe>
                        </div>
                        <div id='phonePreview' style="background-image: url('/img/iphonex.png')"></div>
                    </div>
                </div>
            </el-col>
        </el-row>
        <product-picker-modal v-if='view.picker'
            :products='avaiblableProducts'
            :visible='view.picker'
            :multiple='true'
            title='Sélectionner les produits à intégrer'
            @close='closePicker'
            @select='handleProductSelected'
        />
        <product-form-modal
            v-if='editingProduct'
            :product='editingProduct'
            @close='closeEditor'
            :visible='view.edit'
            :isEditing='true'
        />
    </el-form>
</template>

<script>
import Vuex from 'vuex'
import draggable from 'vuedraggable'
import CategoryItem from '@/components/Menu/CategoryItem'
import ProductItem from '@/components/Menu/ProductItem'
import ProductPickerModal from '@/components/Menu/ProductPickerModal'
import ProductFormModal from '@/components/Menu/ProductFormModal'

import Card from '@/lib/entities/Card'
import Category from '@/lib/entities/Category'
import CardCategory from '@/lib/entities/CardCategory'
export default {
    name: 'CardComposer',
    components: {
        ProductItem,
        CategoryItem,
        ProductPickerModal,
        ProductFormModal,
        draggable
    },
    props: {
        card: {
            type: Card,
            required: true
        },
        products: {
            type: Array,
            required: true
        },
        assemblies: {
            type: Array,
            required: true
        },
        categories: {
            type: Array,
            required: true
        },
        showHeader: {
            type: Boolean,
            required: false,
            default: () => true
        },
        statuable: {
            type: Boolean,
            required: false,
            default: () => false
        }
    },
    computed: {
        ...Vuex.mapState(['integrations', 'store', 'editingCardStatus']),
        menuUrl() {
            if (this.integrations && this.integrations.cards[this.card.id]) {
                return this.integrations.cards[this.card.id].find(int => int.type === 'iframe').url
            }
            return null
        },
        avaiblableProducts() {
            return [...this.assemblies, ...this.products]
        },
        isCardValid() {
            // @TODO Add some check on prices & vat ?
            return this.card.name
        },
        mainSpanSize() {
            if (this.view.preview) {
                return 16
            }
            return 24
        }
    },
    data: () => ({
        prices: {},
        vats: {},
        stocks: {},
        extras: {},
        optionPrices: {},
        view: {
            picker: false,
            preview: false,
            edit: false
        },
        editingCategory: null,
        editingProduct: null
    }),
    created() {
        // if (this.card.name === '') {
        //     this.$refs.cardNameInput.focus()
        // }
        this.$store.commit('SET_EDITING_CARD', this.card)
        this.$store.commit('SET_EDITING_CARD_STATUS', this.card.status)

        if (this.card.categories.length === 0) {
            this.card.bootFromCategories(this.categories)
            this.card.bootFromAssemblies(this.assemblies)
        }
        this.prices = this.card.prices
        this.vats = this.card.vats
        this.stocks = this.card.stocks
        // this.status = this.card.status
        this.extras = this.card.extras
    },
    methods: {
        handlePriceChanged(entity, price, options) {
            console.log('CardComposer.handlePriceChanged', {entity, price, options})
            this.$set(this.prices, entity.id, Number(price))
            if (options) {
                for (const option in options) {
                    const option_price = options[option]
                    // @todo this.prices devrait etre this.extras
                    this.$set(this.prices, entity.id + "|" + option, Number(option_price))
                }
            }
        },
        handleItemExtraChanged(item, parent, price) {
            const ref = `${parent.id}-${item.id}`
            this.$set(this.extras, ref, Number(price))
        },
        handleProductVat(product, vat) {
            this.$set(this.vats, product.id, Number(vat))
        },
        dropCategory (category) {
            this.$confirm('Supprimer cette catégorie ?', 'Confirmation', {
                confirmButtonText: 'Oui',
                cancelButtonText: 'Non',
                type: 'warning'
            }).then(() => {
                this.$message('Catégorie supprimée')
                this.card.dropCategory(category)
            }).catch(() => null)
        },
        openAddProduct(category) {
            this.editingCategory = category
            this.view.picker = true
        },
        closePicker() {
            this.view.picker = false
            this.editingCategory = null
        },
        handleProductEdit(product) {
            this.view.edit = true
            this.editingProduct = product
        },
        closeEditor() {
            this.view.edit = false
            this.editingProduct = null
        },
        handleProductDrop(category, product) {
            this.$confirm('Supprimer ce produit ?', 'Confirmation', {
                confirmButtonText: 'Oui',
                cancelButtonText: 'Non',
                type: 'warning'
            }).then(() => {
                category.dropProduct(product)
            }).catch(() => null)
        },
        handleProductSelected(product, selected) {
            if (selected) {
                this.editingCategory.addProduct(product)
            } else {
                this.editingCategory.dropProduct(product)
            }
        },
        handleProductStatusChanged(product, status) {
            console.log('CardComposer.handleProductStatusChanged', { product, status })
            this.$store.commit('UPDATE_PRODUCT_STATUS', {productId: product.id, status })

            this.store.updateCardProductStatus(this.card, product, status)
            this.$message({
                type: "success",
                message: `Statut du produit modifié avec succès`
            })
        },
        handleItemStatusChanged(key, status) {
            console.log('CardComposer.handleItemStatusChanged', { key, status })
            this.$store.commit('UPDATE_PRODUCT_STATUS', {productId: key, status })

            this.store.updateCardProductItemStatus(this.card, key, status)
            // this.$message({
            //     type: "success",
            //     message: `Statut du produit modifié avec succès`
            // })
        },
        handleProductStockChanged(product, stock) {
            this.$set(this.stocks, product.id, stock)
            // this.store.updateCardProductStock(this.card, product, stock)
            // this.$message({
            //     type: "success",
            //     message: `Stock du produit enregistré avec succès`
            // })
        },
        createCategory() {
            const category = new Category()
            const cCategory = new CardCategory(category)
            this.editingCategory = cCategory
            this.card.addCategory(cCategory)
        }
    }
}
</script>

<style lang='scss'>
#cardComposer {
    #cardComposerProducts {
        margin-top: 1em;
        margin-bottom: 1em;
    }

    #cardComposerSuggestions {
        margin-bottom: 1em;
    }

    .categoryItem + .categoryItem {
        margin-top: 1em;
    }

    #menuPreviewContainer {
        width: 100%;
        height: 700px;
        position: relative;
    }

    #menuPreview {

        border-radius: 40px;
        overflow: hidden;
        display: flex;
        justify-content: center;
        align-items: center;
        // position: absolute;
        // top: 0;
        // // top: 20px;
        // left: 0;
        margin: auto;
        // left: 60px;
        // height: 100%;
        height: 700px;
        // width: 100%;
        width: 300px;

    }

    #phonePreview {
        background-size: contain;
        background-position: top;
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
    }

    .product-variation-title {
        flex-basis: 20%;
    }
}
</style>
