<template>
    <el-form label-width="300px" id='placeSettingsForm' label-position='right'>
        <el-row :gutter="10">
            <el-col :span="12" :xs="24">
                <h4>Notifications</h4>
                <el-form-item>
                    <span slot='label'>Envoi de SMS au client</span>
                    <el-switch v-model="settings.notifications.sms"></el-switch>
                </el-form-item>
                <el-form-item v-if='settings.ordering.delivery'>
                    <span slot='label'>Envoi de SMS au livreur</span>
                    <el-switch v-model="settings.notifications.deliveryMenSMS"></el-switch>
                </el-form-item>
            </el-col>
            <el-col :span="12" :xs="24">
                <h4>Tarifs</h4>
                <el-form-item>
                    <span slot='label'>Autoriser l'usage de bons de réductions</span>
                    <el-switch v-model="settings.ordering.vouchers"></el-switch>
                </el-form-item>
                <el-form-item>
                    <span slot='label'>Appliquer une majoration sur les prix publiques</span>
                    <el-switch v-model="settings.fees.enabled"></el-switch>
                </el-form-item>
                <div v-if='settings.fees.enabled'>
                    <el-form-item>
                        <span slot='label'>Pourcentage de majoration</span>
                        <el-input placeholder="Majoration" :value="settings.fees.ratio" @input="handleRatioFeesUpdated">
                            <template slot="append">%</template>
                        </el-input>
                    </el-form-item>
                    <el-form-item>
                        <span slot='label'>Supplément par commande</span>
                        <el-input placeholder="Supplément" :value="settings.fees.upfront" @input="handleUpfrontFeesUpdated">
                            <template slot="append">&euro;</template>
                        </el-input>
                    </el-form-item>
                </div>
                <h4>Moyens de paiements</h4>
                <p style="color: red;">Merci de nous contacter avant de modifier vos moyens de paiement</p>
                <el-form-item>
                    <span slot='label'>Paiement sur place</span>
                    <el-switch v-model="settings.payment.spot"></el-switch>
                </el-form-item>
                <el-form-item>
                    <span slot='label'>Stripe</span>
                    <el-switch v-model="settings.payment.creditcard"></el-switch>
                </el-form-item>
                <el-form-item>
                    <span slot='label'>Ticket Restaurant Edenred</span>
                    <el-switch v-model="settings.payment.edenred"></el-switch>
                </el-form-item>
                <!-- <el-form-item>
                    <span slot='label'>LyfPay</span>
                    <el-switch v-model="settings.payment.lyfpay"></el-switch>
                </el-form-item> -->
                <el-form-item>
                    <span slot='label'>Lydia</span>
                    <el-switch v-model="settings.payment.lydia"></el-switch>
                </el-form-item>
            </el-col>
        </el-row>
    </el-form>
</template>

<script>
import Place from '@/lib/entities/Place'
export default {
    name: 'PlaceSettingsForm',
    props: {
        place: {
            type: Object,
            required: true
        }
    },
    data: () => ({
        settings: {
            ordering: {
                delivery: false
            },
            notifications: {
                sms: true,
                deliveryMenSMS: false
            },
            payment: {
                spot: false,
                creditcard: true,
                spot: false,
                edenred: false,
                lyfpay: false,
                lydia: false
            },
            fees: {
                enabled: false,
                ratio: 0,
                upfront: 0,
                delivery: 0
            }
        },
        emitterTimeout: null
    }),
    mounted () {
        this.settings = {
            ...this.settings,
            ...this.place.settings
        }
    },
    methods: {
        handleSettingsUpdate(kind, value) {
            this.settings.fees[kind] = Number(this.settings.fees.ratio)
            this.settings.fees.upfront = Number(this.settings.fees.upfront)
            this.settings.fees.delivery = Number(this.settings.fees.delivery)
            this.$emit('settingsUpdated', this.settings)
        },
        handleRatioFeesUpdated(value) {
            this.settings.fees.ratio = `${value}`.replace(',', '.').replace('%','').replace(/[a-z]*[A-Z]*/g,'')
            if (this.emitterTimeout) {
                clearTimeout(this.emitterTimeout)
            }
            this.emitterTimeout = setTimeout(() => {
                this.handleSettingsUpdate()
            }, 1000)
        },
        handleUpfrontFeesUpdated(value) {
            this.settings.fees.upfront = `${value}`.replace(',', '.')
            if (this.emitterTimeout) {
                clearTimeout(this.emitterTimeout)
            }
            this.emitterTimeout = setTimeout(() => {
                this.handleSettingsUpdate()
            }, 1000)
        },
        handleDeliveryFeesUpdated(value) {
            this.settings.fees.delivery = `${value}`.replace(',', '.')
            if (this.emitterTimeout) {
                clearTimeout(this.emitterTimeout)
            }
            this.emitterTimeout = setTimeout(() => {
                this.handleSettingsUpdate()
            }, 1000)
        },
    }
}
</script>

<style>
#placeSettingsForm .el-form-item {
    margin-bottom: 10px;
}
#placeSettingsForm .el-input-group {
    width: 180px;
}
</style>
