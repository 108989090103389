<template>
  <div class="view">
    <h1>Equipe</h1>
    <el-button type='primary' @click='displayCreateTeammate' size='small' style="float: right;" icon='el-icon-plus'>
      Ajouter un équipier
    </el-button>
    <h3>Gérez les membres de votre équipe</h3>
    <div class="box">
      <team-list></team-list>
    </div>
  </div>
</template>

<script>
import Vuex from 'vuex'
import { createUser } from '@/lib/Api'
import TeamList from '@/components/Team/TeamList'
export default {
  name: 'Settings',
  components: {
    TeamList
  },
  mounted() {
    this.getTeam()
  },
  methods: {
    ...Vuex.mapActions(['getTeam']),
    createNewUser() {
      this.createUser()
    },
    displayCreateTeammate() {
      this.$router.push({name: 'app.team.create'})
    }
  }
}
</script>

<style>

</style>
