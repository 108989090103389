<template>
    <el-header id="header">
        <small-nav :items='items' class="hidden-md-and-up"/>
        <router-link id='brand' :to='{name: "app.home"}' tag='div'>
          <i class='el-icon-fa-home hidden-sm-and-down'></i>&nbsp;{{merchant.name}}
        </router-link>
        <a class="hidden-sm-and-down" :href='domain' target="_blank">{{domain}}</a>
        <div class='right'>
            <el-menu mode="horizontal" :router='true'>
                <!-- <el-menu-item index='1' :route="{name: 'app.merchant', strict: true}" class="hidden-sm-and-down">
                    Offre Basique
                </el-menu-item> -->
                <el-menu-item index='1' id='notifications' @click='displayOrders'>
                    <el-badge :value="notificationsCount" v-if='hasNotifications'><i class="el-icon-fa-newspaper-o"></i></el-badge>
                    <i class="el-icon-fa-newspaper-o" v-else></i>
                </el-menu-item>
                <el-submenu index="2">
                    <template slot="title"><i class="el-icon-setting"></i></template>
                    <el-menu-item index='3' :route="{name: 'app.profile'}">Profil</el-menu-item>
                    <el-menu-item index='4' :route="{name: 'app.merchant'}">Compte</el-menu-item>
                    <el-menu-item index='5' @click='doLogout'>Déconnexion</el-menu-item>
                </el-submenu>
            </el-menu>
        </div>
      </el-header>
</template>

<script>
import Auth from '@/lib/Auth'
import Vuex from 'vuex'
import SmallNav from '@/components/SmallNav.vue'
export default {
    name: 'MainHeader',
    components: {
        SmallNav
    },
    props: {
        items: {
            type: Array,
            required: true
        }
    },
    computed: {
        ...Vuex.mapState(['notifications', 'merchant']),
        ...Vuex.mapGetters(['pendingOrders', '$intercom']),
        domain() {
            return `https://sioupla.it/${this.merchant.slug}`
        },
        notificationsCount() {
            return this.pendingOrders.length
        },
        hasNotifications() {
            return this.notificationsCount > 0
        },
    },
    methods: {
        showProfil() {
            this.$router.push({name: 'app.profile'})
        },
        displayOrders() {
            this.$router.push({name: 'app.orders'})
        },
        doLogout() {
            Auth.logout()
            this.$message('Vous avez été déconnecté')
            this.$intercom('shutdown')
            this.$router.push('/')
        }
    }
}
</script>

<style>

</style>
