<template>
    <div id='categoriesWizard'>
        <h2>Gestion des catégories</h2>
        <div class="notification is-primary" v-if='displayTooltip' style="margin-bottom: 1em">
            Nous avons créé pour vous 4 catégories de base, vous pouvez les modifier ou en ajouter selon vos besoins.
        </div>
        <draggable class="category-list" handle=".category-handle" style="margin-top: 1em" v-model='orderedCategories' @start='drag=true' @end='drag=false'>
            <category-item
                v-for='(category, $index) in categories'
                :key='$index'
                :category='category'
                :draggable='true'
                :deletable='true'
                :edit='editingCategory === category'
                :editable='true'
                @save='updateCategory'
                @drop='deleteCategory'/>
        </draggable>
        <p v-if='categories.length === 0' class='info'>
            <small>Merci de créer au moins une catégorie</small>
        </p>
        <div @click="createCategory" class='inline-button' style="margin-top: 2em; margin-left: 5px;">
            <el-button circle size="small" type="primary" icon='el-icon-plus'></el-button>
            Créer une catégorie
        </div>
        <div ref='help' style='display: none; visibility: hidden'> 
            La création de votre menu s'effectue en 4 étapes.
            <ul>
                <li>Création de vos catégories</li>
                <li>Création de vos produits</li>
                <li>Création de vos différentes formules (menus, etc)</li>
                <li>Création d'une carte et saisie des prix</li>
            </ul>
        </div>
    </div>
</template>

<script>
import Vuex from 'vuex'
import draggable from 'vuedraggable'
import CategoryItem from '@/components/Menu/CategoryItem.vue'
import Category from '@/lib/entities/Category'
import CategoryFormModal from '@/components/Menu/CategoryFormModal.vue'
export default {
    name: 'CategoriesWizard',
    components: {
        CategoryItem,
        CategoryFormModal,
        draggable
    },
    props: {
        categories: {
            type: Array,
            required: true
        },
        library: {
            type: Array,
            required: false
        },
        products: {
            type: Array,
            required: false
        }
    },
    computed: {
        displayTooltip() {
            try {
                return window.localStorage.getItem(`_hide_categories_tooltip`) !== '1'
            } catch (err) {
                return false
            }
        },
        orderedCategories: {
            get() {
                return this.categories
            },
            set(categories) {
                this.storeCategories(categories)
            }
        },
        tips() {
            return this.$route.query.firstLaunch === '1'
        }
    },
    data: () => ({
        view: {
            modal: false
        },
        editingCategory: null
    }),
    mounted() {
        if (this.tips) {
            this.$alert(this.$refs.help.innerHTML, 'Configuration de votre menu', {
                confirmButtonText: 'OK',
                dangerouslyUseHTMLString: true,
                callback: (action) => {
                    try {
                        window.localStorage.setItem(`_first_launch`, '1')
                    } catch (err) {
                        error(err)
                    }
                }
            })
        }
        const hasFakeCategories = this.categories.map((category) => category.id).filter((c) => c).length === 0
        if (hasFakeCategories) {
            this.storeCategories(this.categories)
        }
    },
    methods: {
        ...Vuex.mapActions(['storeCategories']),
        createCategory() {
            const category = new Category()
            this.categories.push(category)
            this.editingCategory = category
            this.$emit('create', category)
        },
        addCategory(category) {
            this.categories.push(category)
            this.closeModal()
        },
        editCategory(category) {
            this.editingCategory = category
            this.openModal()
        },
        deleteCategory(category) {
            const doDelete = () => {
                this.$emit('drop', category)
                this.$message({
                    type: 'success',
                    message: 'Catégorie supprimée'
                })
            }
            if (category.products.length > 0) {
                this.$confirm('Cette catégorie contient des produits, souhaitez-vous vraiment la supprimer ?', 'Confirmation', {
                    type: 'warning',
                    confirmButtonText: 'Oui',
                    cancelButtonText: 'Annuler',
                })
                .then((_) => doDelete())
                .catch((err) => {
                    error(err)
                })
            } else {
                doDelete()
            }
        },
        updateCategory(category) {
            this.$emit('update', category)
        },
        closeModal() {
            this.view.modal = false
        },
        openModal() {
            this.view.modal = true
        },
        saveTooltipState() {
            try {
                window.localStorage.setItem(`_hide_categories_tooltip`, '1')
            } catch (err) {
                error(err)
            }
        }
    }
}
</script>

<style lang='scss'>
#categoriesWizard {
    margin-bottom: 2em;
}
</style>
