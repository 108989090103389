import Parse, { Query } from 'parse'
import User from '@/lib/entities/User'
import Place from '@/lib/entities/Place'
import Card from '@/lib/entities/Card'
import Merchant from '@/lib/entities/Merchant'
import Order from '@/lib/entities/Order'
import Store from '@/lib/entities/Store'
import { base64FromFile } from '@/lib/Tools'
import io from 'socket.io-client'

// @ts-ignore
const SOCKET_URI = new URL(process.env.VUE_APP_API_URL)

export const subscribeToEventStream = (token: string|null) => {
    const socket = io(`//${SOCKET_URI.host}`, {
        path: '/stream',
        transports: ["websocket"]
    })
    socket.emit('authenticate', {
        strategy: 'jwt',
        accessToken: token
    })

    socket.on('reconnect', () => {
        socket.emit('authenticate', {
            strategy: 'jwt',
            accessToken: token
        })
    })

    return socket
}

export const getMerchant = async () => {
    const user = Parse.User.current()
    if (!user) {
        throw new Error('UNAUTHENTICATED')
    }
    const query = new Parse.Query('Merchant')
    const merchant = await query.get(user.get('merchant').id)
    return merchant
}

export const getPlaces = async (): Promise<Place[]> => {
    const user = Parse.User.current()
    if (!user) {
        throw new Error('UNAUTHENTICATED')
    }
    const places = []
    const placesQuery = new Parse.Query('Place')
    if (user.get('type') === 'merchant') {
        placesQuery.equalTo('merchant', user.get('merchant'))
    } else {
        placesQuery.containedIn('objectId', user.get('placesIds'))
    }
    const places_results = await placesQuery.find()
    // const results = await Parse.Cloud.run('getPlaces', null, { sessionToken: user.getSessionToken() })
    for (const p of places_results) {
        const place = await Place.boot(p)
        places.push(place)
    }
    return places
}

export const getOrders = async (): Promise<Order[]> => {
    const user = Parse.User.current()
    if (!user) {
        throw new Error('UNAUTHENTICATED')
    }
    const query = new Parse.Query('Order')
    const placeId = user.get('placeId')
    const placesIds = user.get('placesIds')
    query.descending("createdAt")
    let orders = []
    let results: any[] = []
    if (user.get('type') === 'merchant') {
        query.equalTo('merchant', user.get('merchant').id)
        query.limit(1000)
        results = await query.find()
    } else if (placesIds) {
        query.containedIn('place', placesIds)
        query.limit(1000)
        results = await query.find()
    } else if (placeId) {
        query.equalTo('place', placeId)
        query.limit(1000)
        results = await query.find()
    }

    for (const o of results) {
        const order = await Order.boot(o)
        orders.push(order)
    }
    return orders
}

export const getCard = async (id: string, store: Store) => {
    const query = new Parse.Query('Card')
    const res = await query.get(id)
    const card = Card.boot(res, store)
    return card
}

export const getTeam = async () => {
    const { team } = await Parse.Cloud.run('getTeam')
    return team
}

export const refreshIntegrations = async () => {
    await Parse.Cloud.run('refreshIntegrations')
}

export const updateOrder = async (order: Order, status: string) => {
    Parse.Cloud.run('updateOrderStatus', { orderId: order.orderId, status })
    const oq = new Parse.Query('Order')
    const model = await oq.get(order.id)
    model.set('status', status)
    model.save()
}

export const getNotifications = async () => {
    // @TODO
    return
}

export const updateProfile = async (user: User) => {
    const query = new Parse.Query('User')
    const model = await query.get(user.id)
    if (model) {
        model.set('firstname', user.firstname)
        model.set('lastname', user.lastname)
        model.set('email', user.email)
        model.set('phone', user.phone)
        model.set('mobilePhone', user.mobilePhone)
        await model.save()
    }
}


export const updateLead = async (lead: any) => {
    let model
    if (lead.__model) {
        model = lead.__model
    } else {
        let lid = lead.id
        try {
            lid = window.localStorage.getItem('__lid')
        } catch (err) {
            //
        }
        if (lid) {
            const q = new Parse.Query('Lead')
            model = await q.get(lid)
        }
        if (!model) {
            model = new Parse.Object('Lead')
        }
    }
    for (const key in lead) {
        if (lead.hasOwnProperty(key) && key !== '__model') {
            model.set(key, lead[key])
        }
    }
    const { id } = await model.save()
    try {
        window.localStorage.setItem('__lid', id)
    } catch (err) {
        //
    }
    lead.id = id
    lead.__model = model
    return lead
}

/**
 * @TOTO Move to MerchantEntity
 * @param merchant
 */
export const updateMerchant = async (merchant: Merchant) => {
    const query = new Parse.Query('Merchant')
    const model = await query.get(merchant.id)
    if (model) {
        model.set('name', merchant.name)
        model.set('email', merchant.email)
        model.set('phone', merchant.phone)
        model.set('location', merchant.location)
        // model.set('social', merchant.social)
        if (merchant.colors.headerBackground.hex) {
            merchant.colors.headerBackground = merchant.colors.headerBackground.hex
        }
        if (merchant.colors.secondaryColor.hex) {
            merchant.colors.secondaryColor = merchant.colors.secondaryColor.hex
        }
        model.set('colors', merchant.colors)
        model.set('logoUrl', merchant.logoUrl)
        await model.save()
    }
}

/**
 * @deprecrated Moved to PlaceEntity
 * @param place
 */
export const updatePlace = async (place: Place) => {
    throw new Error('DEPRECATED')
    const query = new Parse.Query('Place')
    const model = await query.get(place.id)
    if (model) {
        model.set('name', place.name)
        model.set('description', place.description)
        model.set('email', place.email)
        model.set('phone', place.phone)
        model.set('location', place.location)
        model.set('social', place.social)
        model.set('colors', place.colors)
        model.set('settings', place.settings)
        model.set('locations', place.locations)
        model.set('hours', place.hours)

        await model.save()
    }
}

/**
 * Upload a merchant logo
 * @param filename
 * @param file
 */
export const storeMerchantLogo = async (filename: string, file: File) => {
    const user = Parse.User.current()
    if (!user) {
        throw new Error('UNAUTHENTICATED')
    }
    const merchantId = user.get('merchant').id
    const buffer = await base64FromFile(file)
    const res = await Parse.Cloud.run('storeMerchantLogo', {filename, buffer, merchantId})
    return res
}

/**
 * Upload a place logo
 * @param filename
 * @param file
 * @param placeId
 */
export const storePlaceLogo = async (filename: string, file: File, placeId: any) => {
    const buffer = await base64FromFile(file)
    const res = await Parse.Cloud.run('storePlaceLogo', { filename, buffer, placeId })
    return res
}

export const storeProductImage = async (filename: string, file: File, productId?: any, resize: boolean = true) => {
    const buffer = await base64FromFile(file)
    const res = await Parse.Cloud.run('storeProductImage', { filename, buffer, productId })
    return res
}

export const getSuggestedTags = async (product: string) => {
    const { tags } = await Parse.Cloud.run('getProductTags', { product })
    return tags
}

export const getSuggestedProducts = async (category: string) => {
    const { products } = await Parse.Cloud.run('getSuggestedProducts', { category })
    return products
}

export const getOnboardingQuestions = async () => {
    const res = await Parse.Cloud.run('crmOnboardingQuestions')
    return res
}

export const getQualificationsQuestions = async () => {
    const { questions } = await Parse.Cloud.run('crmQualificationQuestions')
    return questions
}

export const submitQualificationAnswer = async (question: any, answer: any) => {
    Parse.Cloud.run('crmQualificationAnswer', { question, answer })
}

export const getIntegrations = async () => {
    const user = Parse.User.current()
    const base_url = "https://sioupla.it"
    let integrations = {
        places: {} as any,
        cards: {} as any
    }
    if (!user) {
        throw new Error('UNAUTHENTICATED')
    }
    const _merchant = user.get('merchant')
    
    const mq = new Parse.Query('Merchant')
    const merchant = await mq.get(_merchant.id)
    
    const merchant_slug = merchant.get('slug')

    const pq = new Parse.Query('Place')
    pq.equalTo('merchant', merchant)
    const places = await pq.find()
    const places_hash: any = {}
    for (const place of places) {
        const place_slug = place.get('slug')
        const url = `${base_url}/${merchant_slug}/${place_slug}.html`
        integrations.places[place.id] = [
            {
                type: 'iframe',
                url,
                label: 'Intégration sur votre site',
                code: `<iframe height='600' width='800' src='${url}' border='0' frameborder='0' allow='accelerometer; encrypted-media; gyroscope; geolocation; picture-in-picture' allowfullscreen><\/iframe>"`,
                help: 'Copier-coller le code ci-dessus dans le code HTML de votre site. Pour plus d\'informations, n\'hésitez pas à nous contacter.'
            }
        ]

        places_hash[place.id] = place_slug
    }

    const cq = new Parse.Query('Card')
    cq.equalTo('merchant', merchant)
    const cards = await cq.find()
    for (const card of cards) {
        const place = card.get('place')
        if (place) {
            if (places_hash[place.id]) {
                const placeSlug = places_hash[place.id]
                const cardSlug = card.get('slug')
                const url = `${base_url}/${merchant_slug}/${placeSlug}/${cardSlug}.html`
                integrations.cards[card.id] = [
                    {
                        type: 'iframe',
                        url,
                        label: 'Intégration sur votre site',
                        code: `<iframe height='600' width='800' src='${url}' border='0' frameborder='0' allow='accelerometer; encrypted-media; gyroscope; geolocation; picture-in-picture' allowfullscreen><\/iframe>"`,
                        help: 'Copier-coller le code ci-dessus dans le code HTML de votre site. Pour plus d\'informations, n\'hésitez pas à nous contacter.'
                    }
                ]
            }
        }    
    }
    return integrations
}

/**
 *
 * @param date
 * @param hour
 * @param comment
 */
export const sendMeetingRequest = async (date: Date, hour: string, comment: string) => {
    Parse.Cloud.run('crmMeetingRequest', {date, hour, comment})
}

export const getProviders = async () => {
    const { providers } = await Parse.Cloud.run('getProviders')
    return providers
}

export const printOrder = async (order: any) => {
    const res = await Parse.Cloud.run('printOrder', { orderId: order.id })
    return res
}

export const updateProductStatus = async (product: any, status: string) => {
    Parse.Cloud.run('updateProductStatus', { productId: product.id, status })
}

export const updateCardProductStock = async (merchant: any, card: any, product: any, stock: number) => {
    Parse.Cloud.run('updateCardProductStock', {
        merchantId: merchant.id,
        cardId: card.id,
        cardSlug: card.slug,
        productId: product.id,
        stock
    })
}

export const updateCardProductStatus = async (merchant: any, card: any, product: any, status: string) => {
    Parse.Cloud.run('updateCardProductStatus', {
        merchantId: merchant.id,
        cardId: card.id,
        cardSlug: card.slug,
        placeId: card.place.id,
        productId: product.id,
        status
    })
}

// @version feature/options-price
// @since 2022-05-26
export const updateCardProductItemStatus = async (merchant: any, card: any, key: any, status: string) => {
    Parse.Cloud.run('updateCardProductStatus', {
        merchantId: merchant.id,
        cardId: card.id,
        cardSlug: card.slug,
        placeId: card.place.id,
        productId: key,
        status
    })
}

export const updateCardProductPrice = async (merchant: any, card: any, product: any, price: number) => {
    Parse.Cloud.run('updateCardProductPrice', {
        merchantId: merchant.id,
        cardId: card.id,
        cardSlug: card.slug,
        productId: product.id,
        price
    })
}

export const updatePlaceStatus = async (place: any, status?: string) => {
    Parse.Cloud.run('updatePlaceStatus', { placeId: place.id, status })
}

export const getStats = async (data: any) => {
    const res = await Parse.Cloud.run('getOrdersStats', data)
    return res
}

export const getStatsPdf = async (data: any) => {
    const res = await Parse.Cloud.run('getStatsPdf', data)
    return res
}

export const getStatsExcel = async (data: any) => {
    const res = await Parse.Cloud.run('getStatsExcel', data)
    return res
}

export const createPlace = async (data: any) => {
    const { place } = await Parse.Cloud.run('createPlace', { place: data })
    return place
}

export const deletePlace = async (place: Place) => {
    const res = await Parse.Cloud.run('deletePlace', { placeId: place.id })
    return res
}

interface UserRequest {
    id?: string
    firstname: string
    lastname: string
    email: string
    phone: string
    companyRole?: string
    password?: string,
    placesIds?: string[]
}

export const createUser = async (payload: UserRequest) => {
    const res = await Parse.Cloud.run('createUser', payload)
    return res
}

export const updateUser = async (payload: UserRequest) => {
    const res = await Parse.Cloud.run('updateUser', payload)
    return res
}
