import Entity from './Entity'
import ProductableEntity from './ProductableEntity'
import ProductVariation from '@/lib/entities/ProductVariation'

export default class CardProduct extends Entity {
    public product: ProductableEntity
    public price: number = 0
    public vat: number = 10
    public status: string = 'available'
    public stock: number = 0
    public variationsPrices: any = {}

    constructor(product: ProductableEntity) {
        super()
        this.product = product
        if (product.vat) {
            this.setVat(product.vat)
        }
        if (product.stock) {
            this.setStock(product.stock)
        }

        if (product.status) {
            this.setStatus(product.status)
        }

        if (product.price) {
            this.setPrice(product.price)
        }
    }

    public setVariationPrice(variation: ProductVariation, price: number) {
        this.variationsPrices[variation.id] = price
    }

    public setPrice(price: string|number) {
        this.price = Number(price)
    }

    public setVat(vat: string|number) {
        this.vat = Number(vat)
    }

    public setStatus(status: string) {
        this.status = status
    }

    public setStock(stock: string|number) {
        this.stock = Number(stock)
    }

    public hasValidProps() {
        return this.product.hasValidProps()
    }

    public toJson() {
        return {
            id: this.id,
            price: this.price,
            vat: this.vat,
            status: this.status,
            stock: this.stock,
            variationsPrices: this.variationsPrices,
            productId: this.product.id
        }
    }

    // ** GETTERS ** 
    get name() {
        return this.product.name
    }

    get description() {
        return this.product.description
    }

    get image() {
        return this.product.image
    }

    get variations() {
        return this.product.variations
    }
}
