<template>
    <el-container id="app" v-loading.fullscreen="loading">
        <el-aside :width="asideWidth" class='hidden-sm-and-down'>
            <aside-menu :collapse="collapse" :items='items' @collapse='toggleCollapse'/>
        </el-aside>
        <el-main>
            <main class="content" v-if='!loading'>
                <main-header :items='items'/>
                <router-view></router-view>
            </main>
        </el-main>
        <slot name="extra"></slot>
        <help-wizard v-if='showHelpWizard'/>
    </el-container>
</template>

<script>
import Vuex from 'vuex'
import Auth from '@/lib/Auth'
import AsideMenu from '@/components/AsideMenu.vue'
import MainHeader from '@/components/MainHeader.vue'
import HelpWizard from '@/components/HelpWizard.vue'

import { debug, error } from '@/lib/Tools'

export default {
    name: 'App',
    components: {
        AsideMenu,
        MainHeader,
        HelpWizard
    },
    data: () => ({
        loading: true,
        collapse: false,
    }),
    computed: {
        ...Vuex.mapState(['user', 'merchant']),
        ...Vuex.mapGetters(['$intercom']),
        showHelpWizard() {
            return false
        },
        asideWidth() {
            return this.collapse === false ? '190px' : '60px'
        },
        items() {
            const defaultItems = [
                {
                    id: 'dashboard',
                    label: 'Accueil',
                    icon: 'el-icon-fa-dashboard',
                    roles: ['merchant', 'teammate'],
                    route: {
                        name: 'app.home'
                    }
                },
                {
                    id: 'cards',
                    label: 'Cartes',
                    icon: 'el-icon-fa-list-alt',
                    roles: ['merchant', 'teammate'],
                    route: {
                        name: 'app.menus',
                        query: {
                            tab: 'cards'
                        }
                    }
                },
                {
                    id: 'menus',
                    label: 'Produits',
                    icon: 'el-icon-fa-file-text',
                    roles: ['merchant', 'teammate'],
                    route: {
                        name: 'app.menus',
                        query: {
                            tab: 'products'
                        }
                    }
                },
                {
                    id: 'orders',
                    label: 'Commandes',
                    icon: 'el-icon-fa-newspaper-o',
                    roles: ['merchant', 'teammate', 'deliveryman'],
                    route: {
                        name: 'app.orders'
                    }
                },
                {
                    id: 'places',
                    label: 'Etablissements',
                    icon: 'el-icon-fa-building',
                    roles: ['merchant', 'teammate'],
                    route: {
                        name: 'app.places'
                    }
                },
                {
                    id: 'team',
                    label: 'Equipe',
                    icon: 'el-icon-fa-users',
                    roles: ['merchant'],
                    route: {
                        name: 'app.team'
                    }
                },
                // {
                //     id: 'stats',
                //     label: 'Statistiques',
                //     icon: 'el-icon-fa-area-chart',
                //     roles: ['merchant', 'teammate'],
                //     href: 'https://pilot.sioupla.it'
                // },
                // {
                //     id: 'integrations',
                //     label: 'Intégrations',
                //     icon: 'el-icon-fa-plug',
                //     route: {
                //         name: 'app.integrations'
                //     }
                // },
            ]

            return defaultItems.filter((item) => {
                return item.roles.includes(this.user.role)
            })
        }
    },
    methods: {
        ...Vuex.mapActions([
            'getUser',
            'getMerchant',
            'getStore',
            'getIntegrations',
            'getOrders',
            'getTeam',
            'subscribe',
            'handleRemoteProductStatusUpdated'
        ]),
        toggleCollapse() {
            this.collapse = !this.collapse
        },
        async startSubscription() {
            this.subscription = await this.subscribe()
            // this.subscription.on('connect', (socket) => {
            //     this.$message({
            //         message: 'Connecté au flux de commandes',
            //         type: 'success'
            //     })
            // })

            this.subscription.on('authentication.failed', () => {
                this.$router.push({name: 'auth.login'})
                this.$message({
                    message: 'Votre session a expiré, merci de vous reconnecter',
                    type: 'error'
                })
                Auth.logout()
            })

            this.subscription.on('error', (err) => {
                error(err)
                // this.$message({
                //     message: 'Erreur de connexion au flux de commandes',
                //     type: 'error'
                // })
            })

            this.subscription.on('place.updated', (event) => {
                if (event.userId !== this.user.id) {
                    this.$root.$emit('place.updated', event)
                }
            })

            this.subscription.on('product.updated', (event) => {
                if (event.userId !== this.user.id) {
                    this.$root.$emit('product.updated', event)
                }
            })

            this.subscription.on('order.created', (order) => {
                this.$root.$emit('order.created', order)
            })

            this.subscription.on('order.updated', (event) => {
                if (event.userId !== this.user.id) {
                    this.$root.$emit('order.updated', event)
                }
            })

            this.subscription.on('store.updated', () => {
                this.$root.$emit('store.updated')
            })

            this.subscription.on('reload', () => {
                this.$root.$emit('reload')
            })

        },
        stopSubscription() {
            this.subscription.close()
        },
        ringMyBell() {
            setTimeout(() => {
                this.$bell.play()
            }, 3000)
        },
        askForBrowserNotification() {
            if (!window.Notification) {
                return
            }
            if (window.Notification.permission !== 'granted') {
                window.Notification.requestPermission()
            }
        },
        createBrowserNotification(title, body, callback) {
            if (window.Notification.permission !== 'granted') {
                window.Notification.requestPermission()
            } else {
                const notification = new window.Notification(title, {
                    icon: 'https://assets.sioupla.it/logo_siouplait_v1.png',
                    body,
                })
                if (callback) {
                    notification.onclick = callback
                }
            }
        }
    },
    created() {
        this.$bell = new window.Audio('https://assets.sioupla.it/bell.mp3')
    },
    async mounted() {
        this.askForBrowserNotification()

        /*
        // this.$root.$on('order.updated', (order) => {
        //    return
        //})
        */

        this.$root.$on('place.updated', ({ placeId, status }) => {
            const place = this.$store.dispatch('handleRemotePlaceUpdated', { placeId, status})
            this.$message({
                type: status === 'closed' ? 'warning' : 'success',
                message: `Prise de commande ` + (status === 'closed' ? 'désactivée' : 'activée')
            })
        })

        this.$root.$on('product.updated', ({ cardId, productId, status, stock, price }) => {
            const product = this.$store.dispatch('handleRemoteCardProductUpdated', {
                cardId,
                productId,
                status,
                price,
                stock
            })
            if (status) {
                this.$message({
                    type: status === 'unavailable' ? 'warning' : 'success',
                    message: `Produit ${product.name} ` + (status === 'unavailable' ? 'désactivé' : 'activé')
                })
            }
        })

        this.$root.$on('order.created', (order) => {
            this.ringMyBell()
            const title = `Nouvelle commande #${order.number}`
            const message = `Total: ${Number(order.total).toFixed(2)}€`
            this.createBrowserNotification(title, message, () => {
                this.$router.push({name: 'app.orders'})
            })
            this.$notify.success({
                title,
                message,
                showClose: true,
                duration: 30000,
                onClick: () => {
                    this.$router.push({name: 'app.orders'})
                }
            })
        })

        this.$root.$on('store.updated', () => {
            this.$message({
                type: 'info',
                message: `Mise à jour du menu réalisée avec succès`
            })
        })

        this.$root.$on('reload', () => {
            location.reload(true)
        })

        this.startSubscription()

        try {
            // Fetching env data
            const promises = [
                this.getUser(),
                this.getMerchant()
            ]

            await Promise.all(promises)
            // Fetching store
            await this.getStore()
            this.loading = false
            // Fetching other stuff
            this.getIntegrations()
            this.getOrders()

            // External int
            try {
                // this.$intercom('update', {
                //     "hide_default_launcher": false
                // })
                // this.$intercom('show')
                this.$intercom('boot', {
                    user_id: this.user.id,
                    email: this.user.email,
                    name: this.user.firstname,
                    phone: this.user.phone,
                    utm_source: 'siouplait-bo'
                })
            } catch (err) {
                error(err)
            }

        } catch (err) {
            if (err.code === 404 || err.code === 401 || err.code === 209 || err.message === 'UNAUTHENTICATED') {
                this.$router.push({name: 'auth.login'})
            } else {
                error(err)
                this.$router.push({name: 'error'})
            }
        }
    },
    destroyed() {
        this.stopSubscription()
    }
}
</script>

<style lang="scss">
#notifications {
    .el-badge__content {
        top: 20px;
    }
}
</style>
