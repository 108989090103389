<template>
  <div class="view">
    <h1>Etablissements</h1>
    <el-button type='primary' @click='createPlace' size='small' style="float: right;" icon='el-icon-plus'>
      Créer un nouvel établissement
    </el-button>
    <h3>Gérez ici vos différents établissements</h3>
    <div class="box">
      <div class='places-list'>
          <place-item v-for='(place, $p) in orderedPlaces' :key='$p' :place='place'/>
      </div>
    </div>
  </div>
</template>

<script>
import Vuex from 'vuex'
import PlaceItem from '@/components/Place/PlaceItem'
export default {
  name: 'Places',
  components: {
    PlaceItem
  },
  computed: {
    ...Vuex.mapState(['places']),
    orderedPlaces() {
      return this.places.sort((a, b) => {
        return a.index - b.index
      })
    }
  },
  methods: {
    createPlace() {
      this.$router.push({name: 'app.places.create'})
    }
  }
}
</script>

<style lang='scss'>
.places-list {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  .place-item {
    margin: 10px;
  }
}

@media only screen and (max-width: 991px) {
  .places-list {
    flex-direction: column;
    align-items: center;
  }
}
</style>
