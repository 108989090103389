<template>
    <div class="place-item">
        <div class="place-header" :style='{backgroundColor , color}'>
            <div class="place-index">#{{place.index}}</div>
            <div class="place-alert" v-if='!place.enabled'>
                <i class="el-alert__icon el-icon-warning"></i>&nbsp;Etablissement désactivé
            </div>
            <div class='place-meta'>
                <el-dropdown trigger="click" @command="handleDropdown">
                    <span class="el-dropdown-link">
                        <i class="el-icon-more"></i>
                    </span>
                    <el-dropdown-menu slot="dropdown">
                        <el-dropdown-item v-if='place.enabled' command='toogle' icon='el-icon-fa-eye-slash'>&nbsp;Masquer</el-dropdown-item>
                        <el-dropdown-item v-if='!place.enabled' command='toogle' icon='el-icon-fa-eye'>&nbsp;Afficher</el-dropdown-item>
                        <el-dropdown-item command='setIndex' icon='el-icon-fa-list'>&nbsp;Modifier l'ordre</el-dropdown-item>
                        <el-dropdown-item command='drop' icon='el-icon-delete'>&nbsp;Supprimer</el-dropdown-item>
                    </el-dropdown-menu>
                </el-dropdown>
            </div>
            <div class="place-image" v-if='place.logoUrl' :style="'background-image: url('+place.logoUrl+')'">
            </div>
            <div class="place-title">
                <h3>{{place.name}}</h3>
            </div>
        </div>
        <div class="place-content">
            <!-- <p><i class="el-icon-fa-map-marker"></i>&nbsp;<span v-if='place.address'>{{place.address}}</span><em v-else>Aucune adresse renseignée</em></p> -->
            <!-- <h4>{{place.team.length}} équipier(s)</h4> -->
            <div v-if='place.cards.length > 1'>{{place.cards.length}} cartes associées</div>
            <div v-else-if='place.cards.length == 1'>{{place.cards.length}} carte associée</div>
            <div v-else style="color: #f56c6c;">Aucune carte associée</div>
            <div v-if='status === "closed"' style="color: #f56c6c;">Prise de commande désactivée</div>
        </div>
        <div class='place-buttons'>
            <el-button icon='el-icon-fa-pencil' size="small" type='primary' @click='editPlace'>Modifier</el-button>
            <el-button icon='el-icon-fa-window-close' v-if='status === "open"' size="small" type='danger' @click='closePlace' title="Fermer la prise de commande">Fermer</el-button>
            <el-button icon='el-icon-fa-rocket' v-if='status === "closed"' size="small" type='success' @click='openPlace' title="Ouvrir la prise de commande">Ouvrir</el-button>
        </div>
    </div>
</template>

<script>
import Vuex from 'vuex'
import { updatePlaceStatus } from '@/lib/Api'
export default {
    name: 'PlaceItem',
    props: {
        place: {
            type: Object,
            required: true
        },
        editable: {
            type: Boolean,
            required: false,
            default: () => true
        },
        previewable: {
            type: Boolean,
            required: false,
            default: () => true
        },
        sharable: {
            type: Boolean,
            required: false,
            default: () => true
        }
    },
    computed: {
        ...Vuex.mapState(['store']),
        backgroundColor() {
            if (this.place.colors && this.place.colors.palette) {
                return this.place.colors.palette.secondary
            }
            return 'transparent'
        },
        color() {
            if (this.place.colors && this.place.colors.palette) {
                return this.place.colors.palette.main
            }
            return 'black'
        },
        status() {
            return this.place.status
        }
    },
    methods: {
        editPlace() {
            this.$router.push({name: 'app.places.edit', params: {id: this.place.id}})
        },
        openPlace() {
            updatePlaceStatus(this.place, 'open')
            this.place.status = 'open'
            this.$message({
                type: 'success',
                message: `Prise de commande activée pour '${this.place.name}'`
            })
        },
        closePlace() {
            updatePlaceStatus(this.place, 'closed')
            this.place.status = 'closed'
            this.$message({
                type: 'warning',
                message: `Prise de commande désactivée pour '${this.place.name}'`
            })
        },
        handleDropdown(action) {
            if (action === 'drop') {
                this.$confirm('Supprimer définitivement cet emplacement ?', 'Confirmation', {
                    type: 'warning',
                    confirmButtonText: 'Oui',
                    cancelButtonText: 'Annuler',
                })
                .then((_) => {
                    this.store.deletePlace(this.place)
                    this.$message({
                        type: 'success',
                        message: `Etablissement supprimé avec succès`
                    })
                }).catch((err) => {
                    if (err.message !== 'cancel') {
                        this.$message({
                            type: 'error',
                            message: `Une erreur est survenue, merci de réessayer`
                        })
                    }
                })
            } else if (action === 'toogle') {
                this.place.enabled = this.place.enabled === true ? false : true
                this.place.save().then(() => {
                    this.$message({
                        type: 'success',
                        message: `Etablissement '${this.place.name}' ${this.place.enabled ? 'masqué': 'visible'}`
                    })
                })

            } else if (action === 'setIndex') {
                this.$prompt('Merci de saisir l\'index de cet établissement', 'Ordre d\'affichage', {
                    confirmButtonText: 'Enregistrer',
                    cancelButtonText: 'Annuler',
                    inputPattern: /\d/,
                    inputValue: this.place.index,
                    inputErrorMessage: 'Merci de saisir un nombre'
                }).then(({ value }) => {
                    this.place.index = Number(value)
                    this.place.save().then(() => {
                        this.$message({
                            type: 'success',
                            message: `Etablissement enregistré avec succès`
                        })
                    })
                }).catch(() => {
                    // this.$message({
                    //     type: 'info',
                    //     message: 'Input canceled'
                    // });
                });
            }
        }
    }
}
</script>

<style lang='scss'>
.place-item {
    box-shadow: 0 2px 3px rgba(10, 10, 10, 0.1), 0 0 0 1px rgba(10, 10, 10, 0.1);
    width: 250px;
    border-radius: 5px;
    /* background-color: #031025; color: white;*/
    background-color: #fdfdfd;
    display: flex;
    flex-direction: column
}
.place-image {
    width: 250px;
    height: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    position: absolute;
    top: 0px;
    left: 0px;
    z-index: 0;
}
.place-header {
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    background-color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 260px;
    position: relative;
}
.place-alert {
    position: absolute;
    top: 0px;
    width: 100%;
    background-color: #fdf6ec;
    color: #e6a23c;
    padding: 15px 10px;
    text-align: left;
    font-weight: 600;
    border-radius: 3px 3px 0px 0px;
}
.place-title {
    z-index: 2;
    width: 100%;
    text-align: center;
    background-color: rgba(255,255,255,0.25);
    bottom: 0px;
    position: absolute;
    color: black;
}
.place-index {
    position: absolute;
    top: 15px;
    left: 15px;
    padding: 5px 10px;
    background-color: black;
    color: white;
    border-radius: 5px;
    z-index: 2;
}
.place-meta {
    position: absolute;
    top: 15px;
    right: 15px;
    box-shadow: 0 2px 3px rgba(10, 10, 10, 0.1), 0 0 0 1px rgba(10, 10, 10, 0.1);
    background-color: white;
    color: black;
    padding: 3px 5px;
    border-radius: 3px;
    z-index: 2;
}
.place-content {
    padding: 1em;
    flex-grow: 1;
    text-align: center;
    font-weight: 800;
}
.place-buttons {
    padding: 1em;
    display: flex;
    justify-content: space-evenly;
}
</style>
