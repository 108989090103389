<template>
    <div id="menusAssemblyList" v-loading.lock='loading'>
        <el-button type="primary" size="small" icon='el-icon-plus' @click="displayAddAssembly" style="float: right;">
            Ajouter
        </el-button>
        <h2>Liste des formules</h2>
        <el-alert type="info" title="Aucune formule" v-if='assemblies.length === 0'></el-alert>
        <div class='assemblies-list'>
            <assembly-item
                v-for='(assembly, $index) in assemblies'
                :key='$index'
                :assembly='assembly'
                :editable='true'
                :deletable='true'
                @edit='handleAssemblyEdit'
                @drop='handleAssemblyDrop'
            />
        </div>
        <assembly-modal v-if='editingAssembly'
            :products='products'
            :categories='categories'
            :visible='view.createAssemblyModal'
            :isEditing='!!editingAssembly.model'
            :id='editingAssembly.id'
            @save='handleAssemblyCreated'
            @close='handleCloseAssemblyModal'
        />
    </div>
</template>

<script>
import Vuex from 'vuex'
import { error } from '@/lib/Tools'
import Assembly from '@/lib/entities/Assembly'
import AssemblyModal from '@/components/Menu/AssemblyModal'
import AssemblyItem from '@/components/Menu/AssemblyItem'
export default {
    name: 'AssemblyList',
    components: {
        AssemblyItem,
        AssemblyModal
    },
    data: () => ({
        loading: true,
        assemblies: [],
        editingAssembly: null,
        view: {
            createAssemblyModal: false
        }
    }),
    computed: {
        ...Vuex.mapState(['store']),
        ...Vuex.mapGetters(['products', 'categories'])
    },
    methods: {
        ...Vuex.mapActions(['getAssemblies', 'saveStore', 'deleteAssembly']),
        displayAddAssembly() {
            this.editingAssembly = new Assembly()
            this.view.createAssemblyModal = true
        },
        handleAssemblyEdit(assembly) {
            this.editingAssembly = assembly
            this.view.createAssemblyModal = true
        },
        handleAssemblyDrop(assembly) {
            this.$confirm('Supprimer définitivement cette formule ?', 'Confirmation', {
                type: 'warning',
                confirmButtonText: 'Oui',
                cancelButtonText: 'Annuler',
            })
            .then((_) => {
                this.deleteAssembly(assembly).then((success) => {
                    if (success) {
                        const index = this.assemblies.findIndex((a) => a.id === assembly.id)
                        this.assemblies.splice(index, 1)
                        this.$message({
                            message: 'Formule supprimée avec succès',
                            type: 'success'
                        })
                        setTimeout(() => {
                            this.boot()
                        }, 5000)
                    }
                })

            }).catch((err) => error(err))
        },
        handleAssemblyCreated(assembly) {
            this.saveStore({
                assemblies: [ assembly ]
            }).then(() => {
                // this.store.addAssembly(assembly)
                this.$emit('update')
                this.editingAssembly = null
                this.boot()
            })
        },
        handleCloseAssemblyModal() {
            this.view.createAssemblyModal = false
            this.editingAssembly = null
        },
        boot() {
            this.loading = true
            this.getAssemblies().then((assemblies) => {
                this.assemblies = assemblies
                this.loading = false
            })
        }
    },
    mounted() {
        this.boot()
    }
}
</script>

<style>

</style>
