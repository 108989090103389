<template>
    <el-dialog
        :visible.sync="visible"
        width='55%'
        :title='title'
        :before-close="closeModal"
        >
            <card-link-form :card='card' @placeSelected='handlePlaceSelected'/>    
            <span slot="footer" class="dialog-footer">
                <el-button type="primary" @click="save" icon='el-icon-circle-check-outline'>Enregistrer</el-button>
                <el-button @click="closeModal" icon='el-icon-close'>Fermer</el-button>
            </span>
    </el-dialog>
</template>

<script>
import Vuex from 'vuex'
import Card from '@/lib/entities/Card'
import CardLinkForm from '@/components/Menu/CardLinkForm'
export default {
    name: 'CardShareModal',
    components: {
        CardLinkForm
    },
    props: {
        card: {
            type: Card,
            required: true
        },
        visible: {
            type: Boolean,
            required: true
        }
    },
    data: () => ({
        selectedPlace: null
    }),
    computed: {
        title() {
            return `Associer la carte "${this.card.name}" à un établissement`
        }
    },
    methods: {
        closeModal() {
           this.$emit('close')
        },
        handlePlaceSelected(place) {
            if (place === undefined) place = null
            this.selectedPlace = place
        },
        save() {
            this.card.setPlace(this.selectedPlace)
            this.card.save().then(() => {
                this.$message({
                    message: 'Mise à jour de la carte réalisée avec succès',
                    type: 'success'
                })
            }).catch((err) => {
                this.$message({
                    message: 'Une erreur est survenue, merci de réessayer',
                    type: 'error'
                })
                this.card.setPlace(null)
            })
            this.$emit("cardPlaceUpdated")
            this.closeModal()
        }
    }
}
</script>

<style>

</style>
