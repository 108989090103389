<template>
    <el-form id="productForm" v-loading.fullscreen="loading">
        <el-row :gutter="2">
            <el-col :span="6" :xs="24">
                <div class='product-image-block'>
                    <el-upload
                        ref='uploadField'
                        :action='""'
                        :auto-upload='false'
                        list-type="picture-card"
                        accept="image/*"
                        :headers='{"x-product-id": product.id}'
                        :on-change="handleImageChanged"
                        :show-file-list="false">
                            <img v-if="productImage" :src="productImage" class="product-image-preview" width="145px">
                            <i v-else class="el-icon-plus"></i>
                    </el-upload>
                    <div class='product-image-actions'>
                        <!-- <el-button type="primary" disabled @click='displayImageLibrary' size='small' icon='el-icon-picture'>Phototèque</el-button> -->
                        <el-button type="primary" @click='triggerUploadField' size='small' icon='el-icon-upload'>Télécharger</el-button>
                        <!-- <el-button type="primary" @click='takePicture' size='small' icon='el-icon-fa-camera'>Prendre une photo</el-button> -->
                    </div>
                </div>
            </el-col>
            <el-col :span="18" :xs="24">
                <h4 style='margin-top: 0'><i class="el-icon-fa-cube"></i> Informations du produit</h4>
                <el-form-item style="margin-bottom: 9px;">
                    <el-input type="text" ref='productNameInput' autofocus="on" class="input-large" size="large" prefix-icon='el-icon-edit' placeholder='Nom du produit *' v-model='product.name' required/>
                </el-form-item>
                <el-form-item style="margin-bottom: 9px;">
                    <el-input type="text" ref='productDescriptionInput' size="large" prefix-icon='el-icon-edit' placeholder='Description du produit' v-model='product.description' required/>
                </el-form-item>
                <el-row>
                    <el-col :span="12">
                        <el-form-item label='Catégorie *'>
                            <el-select v-model='productCategory' size="small" placeholder='Catégorie'>
                                <el-option
                                    v-for="(category, $c) in categories"
                                    :key="$c"
                                    :label="category.name"
                                    :value="category.name">
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :offset="5" :span="7">
                        <el-form-item label='TVA'>
                            <el-select v-model="product.vat" placeholder="TVA" size="small" width="80px">
                                <el-option v-for='rate in vatsRates' :key='rate' :label='`${rate} %`' :value='rate'></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                </el-row>
                <div class="product-tags">
                    <div class='block-header'>
                        <h4><i class="el-icon-fa-tags"></i> Tagguez votre produit</h4>
                        <el-tooltip effect="dark" placement="right">
                            <div slot='content'>Ajouter des tags à votre produit.<br>Exemple : ses ingrédients, les allergènes</div>
                            <i class="el-icon-question"></i>
                        </el-tooltip>
                    </div>
                    <el-tag
                        v-for="(tag, $index) in product.tags"
                        :key="$index"
                        closable
                        :disable-transitions="false"
                        @close="removeTag(tag)">
                        {{tag}}
                    </el-tag>
                    <el-button v-for='tag in filteredSuggestedTags' :key='tag' title="Ajouter ce tag" type="text" class="button-new-tag" size="small" @click="addSuggestedTag(tag)">+ {{tag}}</el-button>
                    <span v-if="view.tagEditor" style="margin-left: 0.5em">
                        <el-input
                            class="input-new-tag"
                            v-model="newTag"
                            ref="saveTagInput"
                            size="small"
                            placeholder='Ingrédients / allergènes'
                            @keyup.enter.native="addTag">
                            <el-button slot="append" type="primary" size="small" icon="el-icon-circle-plus-outline" @click="addTag"></el-button>
                        </el-input>
                    </span>
                    <el-button v-else class="button-new-tag" size="small" @click="view.tagEditor = true">+ Ajouter</el-button>
                </div>
                <div id="productInfos" v-if="product.isExternal()">
                    <div class='block-header'>
                        <h4 style="margin-bottom: 10px"><i class="el-icon-fa-refresh"></i> Synchronisation <span v-if='product.settings.externalProvider'>avec {{product.settings.externalProvider}}</span></h4>
                    </div>
                    <div>
                        <el-alert :closable="false" show-icon icon="fa-info-circle" type="warning" :title="'Les informations de ce produit proviennent de ' + product.settings.externalProvider"></el-alert>
                        <div style="margin-left: 0px; margin-top: 10px;">
                            <el-checkbox v-model="product.settings.override">Ecraser ces informations avec celles de {{product.settings.externalProvider}} lors de la prochaine synchronisation ?</el-checkbox>
                        </div>
                    </div>
                </div>
                <div class="product-extra-toggle">
                    <a role="button" @click='toggleExtra()'>
                        <span v-if='!view.extra'>
                            <span class="icon is-small"><i class="el-icon-fa-chevron-right"></i></span>
                            <span>Afficher toutes les options</span>
                        </span>
                    </a>
                </div>
                <div id='productFormSlider'>
                    <div class='product-settings'>
                        <div id='productVariations' v-show='view.extra || product.variations.length'>
                            <div class='block-header'>
                                <h4><i class="el-icon-fa-sitemap"></i> Variations du produit</h4>
                                <el-tooltip effect="dark" placement="right">
                                    <div slot='content'>Saisissez les variations possible pour votre produit<br>Exemple : un mode de cuisson, une option, un supplément...</div>
                                    <i class="el-icon-question"></i>
                                </el-tooltip>
                            </div>
                            
                            <product-variation-item
                                v-for='variation in product.variations'
                                :key='variation.id'
                                :variation='variation'
                                :editable='true'
                                :droppable='true'
                                :expandable='true'
                                @edit='handleVariationEdited'
                                @drop='handleVariationDropped'
                            />
                            <div>
                                <el-button type="text" @click='displayVariationsEditor' size='small' icon='el-icon-plus'>Ajouter une variation</el-button>
                            </div>
                        </div>
                        <div id='productProperties' v-show='view.extra'>
                            <div class='block-header'>
                                <h4><i class="el-icon-fa-flask"></i> Propriétés du produit</h4>
                                <el-tooltip effect="dark" placement="right">
                                    <div slot='content'>Ajouter des propriétés à votre produit<br>Exemple : son poid, son volume</div>
                                    <i class="el-icon-question"></i>
                                </el-tooltip>
                            </div>
                            <table>
                                <thead>
                                    <tr>
                                        <th>Propriété</th>
                                        <th>Valeur</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for='(property, $index) in product.properties' :key='$index'>
                                        <td>{{property.name}}</td>
                                        <td>
                                            <el-input type="text" size="small" v-model="property.value"></el-input>
                                        </td>
                                        <td>
                                            <el-button size="small" type="text" circle icon="el-icon-fa-times" @click='dropProperty(property)'></el-button>
                                        </td>
                                    </tr>
                                </tbody>
                                <tfoot>
                                    <tr>
                                        <td>
                                            <el-autocomplete ref='newPropertyInput' type="text" size="small" placeholder="Propriété" v-model="newProperty.name" :fetch-suggestions='getPropertiesSuggestions'></el-autocomplete>
                                        </td>
                                        <td>
                                            <el-input type="text" size="small" v-model="newProperty.value" placeholder="Valeur" @keyup.enter.native='addProperty'/>
                                        </td>
                                        <td>
                                            <el-button @click="addProperty" size="small" circle type='text' icon='el-icon-fa-plus'/>
                                        </td>
                                    </tr>
                                </tfoot>
                            </table>
                        </div>
                        <!-- <div id='productOtherSettings'>
                            <div class='block-header'>
                                <h4><i class="el-icon-fa-sliders"></i> Paramètres du produit</h4>
                            </div>
                            <el-form-item>
                                <el-input type="text" prefix-icon='el-icon-fa-print' :value='product.sku' @change='handleSkuUpdate' placeholder='(SKU) Affiché sur le ticket'/>
                            </el-form-item>
                            <el-form-item label='Prix variable'>
                                <el-switch v-model="product.hasPariablePrice"></el-switch>
                            </el-form-item>
                        </div> -->
                    </div>
                    
                    <product-variation-modal
                        v-if='view.variationsEditor'
                        :product='product'
                        :variation='editingVariation'
                        @save='handleVariationSaved'
                        @close='handleVariationEditorClosed'>
                    </product-variation-modal>
                </div>
            </el-col>
        </el-row>
       
        <!-- <image-picker-modal 
            :visible='view.picker'
            :item='product'
            :library='library'
            @select='setProductImage'
            @close='closeImagePicker'/> -->

        <camera-modal :visible='view.camera' @close='closeCameraModal' @picture='handlePictureTaken'/>
    </el-form>
</template>

<script>
import Vuex from 'vuex'
import Category from '@/lib/entities/Category'
import Product from '@/lib/entities/Product'
import CardProduct from '@/lib/entities/CardProduct'
import ImagePickerModal from './ImagePickerModal'
import CameraModal from '../CameraModal'
import ProductVariation from '@/lib/entities/ProductVariation'
import ProductVariationItem from './ProductVariationItem'
import ProductVariationModal from './ProductVariationModal'
import RecommandationService from '@/lib/RecommandationService'
import slugify from 'slugify'
export default {
    name: 'ProductForm',
    components: {
        CameraModal,
        ImagePickerModal,
        ProductVariationItem,
        ProductVariationModal
    },
    props: {
        library: {
            type: Array,
            required: false
        },
        existingProducts: {
            type: Array,
            required: false
        },
        categories: {
            type: Array,
            required: false
        },
        product: {
            type: Object,
            required: true
        },
        category: {
            type: Category,
            required: false
        },
        isEditing: {
            type: Boolean,
            required: false,
            default: () => false
        }
    },
    data: () => ({
        view: {
            extra: false,
            editing: false,
            picker: false,
            tagEditor: true,
            imageLibrary: false,
            variationsEditor: false,
            camera: false
        },
        newProperty: {
            name: '',
            value: ''
        },
        newTag: '',
        editingVariation: null,
        suggestedTags: [],
        productCategory: null,
        defaultTreeProps: {
          children: 'children',
          label: 'label',
          disabled: 'disabled'
        },
        customProductSku: null,
        productImage: null,
        loading: false
    }),
    computed: {
        ...Vuex.mapState(['vatsRates']),
        hasCategories() {
            return this.categories && this.categories.length > 0
        },
        formValid() {
            return this.product && this.product.name && this.product.vat && this.product.category
        },
        tags() {
            const tags = this.suggestedTags
            if (this.existingProducts) {
                for (const product of this.existingProducts) {
                    for (const tag of product.tags) {
                        if (!tags.includes(tag)) {
                            tags.push(tag)
                        }
                    }
                }
            }
            return tags
        },
        filteredSuggestedTags() {
            const tags = []
            for (const tag of this.suggestedTags) {
                if (!this.product.tags.includes(tag)) {
                    tags.push(tag)
                }
            }
            return tags
        },
        productVariationsAsTree() {
            const tree = []
            let id = 1
            for (const variation of this.product.variations) {
                const treeEl = {
                    id,
                    label: variation.label,
                    disabled: false,
                    children: variation.options.map((option) => ({
                        id: id++,
                        label: option,
                        disabled: false
                    }))
                }
                tree.push(treeEl)
                id++
            }
            return tree
        }
    },
    methods: {
        ...Vuex.mapActions(['storeProductImage']),
        addProperty() {
            if (this.newProperty.value && this.newProperty.name) {
                this.product.addProperty(this.newProperty)
                this.newProperty = {name: '', value: ''}
                this.$refs.newPropertyInput.focus()
            }
        },
        dropProperty(property) {
            this.product.dropProperty(property)
        },
        toggleExtra() {
            this.view.extra = !this.view.extra
        },
        setProductImage(image) {
            this.product.image = image
        },
        openImagePicker() {
            this.view.picker = true
        },
        closeImagePicker() {
            this.view.picker = false
        },
        addTag() {
            if (this.newTag.length === 0) {
                return
            }
            this.product.addTag(this.newTag)
            this.newTag = ''
        },
        addSuggestedTag(tag) {
            this.product.addTag(tag)
        },
        removeTag(tag) {
            this.product.removeTag(tag)
        },
        handleImageChanged(file) {
            this.loading = true
            const reader = new FileReader()
            
            reader.onerror = (error) => {
                this.$message({
                    type: 'warning',
                    message: 'Erreur lors de l\'enregistrement de l\'image, merci de réessayer'
                })
            }
            

            reader.onload = (event) => {
                this.productImage = reader.result

                this.storeProductImage({
                    filename: slugify(file.name, { lower: true, remove: /[*+~()'"!:@]/g }),
                    file: file.raw,
                    productId: this.product.id
                }).then(({url}) => {
                    this.productImage = url
                    this.product.image = url
                    this.$message({
                        type: 'success',
                        message: 'Image enregistrée avec succès'
                    })
                    this.loading = false
                }).catch((err) => {
                    this.$message({
                        type: 'error',
                        message: 'Une erreur s\'est produite, merci de réessayer'
                    })
                    this.loading = false
                })
            }

            reader.readAsDataURL(file.raw)
        },
        showInput() {
            // @TODO
        },
        displayVariationsEditor() {
            this.editingVariation = null
            //new ProductVariation()
            this.view.variationsEditor = true
        },
        handleVariationEditorClosed() {
            this.editingVariation = null
            this.view.variationsEditor = false
        },
        displayImageLibrary() {
            this.view.imageLibrary = true
        },
        handleVariationSaved(product, variation) {
            if (variation) {
                if (this.product.hasVariation(variation)) {
                    this.product.updateVariation(variation)
                } else {
                    this.product.addVariation(variation)
                }
                this.editingVariation = null
                this.view.variationsEditor = false
            }
        },
        handleVariationEdited(variation) {
            this.editingVariation = variation
            this.view.variationsEditor = true
        },
        handleVariationDropped(variation) {
            this.product.dropVariation(variation)
        },
        getPropertiesSuggestions(query, cb) {
            const suggestions = []
            if (this.existingProducts) {
                for (const product of this.existingProducts) {
                    for (const property of product.properties) {
                        if (suggestions.indexOf(property.name) === -1) {
                            suggestions.push(property.name)
                        }     
                    }
                }
            }
            const preparedSuggestions = suggestions.map((value) => ({ value }))
            cb(preparedSuggestions)
        },
        handleSkuUpdate(value) {
            this.customProductSku = value
        },
        closeCameraModal() {
            this.view.camera = false
        },
        takePicture () {
            this.view.camera = true
        },
        handlePictureTaken (url) {
            this.productImage = url
            this.view.camera = false
            const filename = Math.floor(Date.now() * 1000)
            const blobBin = atob(url.split(',')[1])
            const dataArray = [];
            for(var i = 0; i < blobBin.length; i++) {
                dataArray.push(blobBin.charCodeAt(i))
            }
            const file = new Blob([new Uint8Array(dataArray)], {type: 'image/png'})
            this.storeProductImage({
                filename: `${filename}.png`,
                file,
                productId: this.product.id
            }).then(({url}) => {
                this.productImage = url
                this.product.image = url
            })
            
        },
        triggerUploadField () {
            this.$refs.uploadField.$el.children[0].click()
        }
    },
    mounted() {
        
        this.$watch('productCategory', (categoryName) => {
            const selectedCategory = this.categories.find((c) => c.name === categoryName)
            if (selectedCategory && selectedCategory !== this.product.category) {
                /** @version 28/03/2019 commented */
                // if (this.product.hasValidProps()) {
                //     this.$emit('categoryUpdated', this.product, selectedCategory)
                // }
                this.$emit('categoryUpdated', this.product, selectedCategory)
                this.product.setCategory(selectedCategory)
            }
        })
        
        let tagThrottle = null
        const vm = this
        this.$watch(() => this.product.name + ' ' + this.product.description, async (value) => {
            if (tagThrottle) {
                clearTimeout(tagThrottle)
            }
            tagThrottle = setTimeout(() => {
                RecommandationService.suggestProductTags(value).then((tags) => {
                    vm.suggestedTags = tags
                })
            }, 1000)
            
        })

        this.$watch(() => this.product.name, async (value) => {
            if (this.customProductSku) {
                this.product.sku = this.customProductSku
            } else {
                this.product.sku = this.product.name.toLowerCase().replace(/\s\S{2}\s/g, ' ')
            }
            
        })

        if (this.product.category) {
            this.productCategory = this.product.category.name
        } else if (this.category) {
            this.productCategory = this.category.name
        }

        this.productImage = this.product.image
    }
}
</script>

<style lang='scss'>

.block-header {
    display: flex;
    align-items: center;
    
    i.el-tooltip {
        margin-left: 1.33em;
    }
}

.image-picker {
    cursor: pointer;
}
.image-picker-placeholder {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.categories-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
}

.product-image-block {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 1em;
    
    .product-image-actions {
        margin-top: 1em;
        justify-content: center;
        display: flex;
        flex-direction: column;
        align-items: center;

        .el-button {
            width: 100%;
        }

        .el-button + .el-button {
            margin-top: 5px;
            margin-left: 0px !important;
        }
    }
}

.product-tags {
    // padding: 0 1em;
    .el-tag {
        margin-right: 10px;
    }
    .button-new-tag {
        height: 32px;
        line-height: 30px;
        padding-top: 0;
        padding-bottom: 0;
    }
    .input-new-tag {
        width: 200px;
        vertical-align: bottom;
    }
}

.product-extra-toggle {
    margin-top: 1.5em;
    font-weight: 600;
    cursor: pointer;
    text-decoration: underline;
}

#productVariations, #productProperties, #productInfos, #productOtherSettings {
    margin-top: 1.5em;
}

@media only screen and (max-width: 991px) {
    .product-image-block {
        flex-direction: row;
        align-items: flex-end;
        justify-content: space-around;
    }
}

</style>
