<template>
    <div class="view" id="placeCreate">
        <h1>Création d'un établissement</h1>
        <div class="box">
            <div class='step-navigation hidden-sm-and-down'>
                <el-steps :active="step" finish-status="success" align-center>
                    <el-step title="Informations"></el-step>
                    <el-step title="Prise de commande"></el-step>
                    <el-step title="Paramètres"></el-step>
                </el-steps>
            </div>
            <div class='step-content'>
                <div id='step0' v-if='step === 0'>
                    <place-display-form
                        @settingsUpdated='handleSettingsUpdated'
                        @infosUpdated='handleInfosUpdated'
                        :place='place'
                        :extra='false'>
                    </place-display-form>
                </div>
                <div id='step1' v-if='step === 1'>
                    <place-ordering-form :place='place'></place-ordering-form>
                </div>
                <div id='step2' v-if='step === 2'>
                    <place-settings-form :place='place'></place-settings-form>
                </div>
            </div>
            <div id='wizardFooter'>
                <el-button type="success" @click="prev" :disabled='step === 0' icon='el-icon-arrow-left'>Précédent</el-button>
                <el-button type="success" @click="exitWizard" v-if='step === 2'><i class="el-icon-success"></i> Terminer</el-button>
                <el-button type="success" @click="next" v-else :disabled='!canGoNext'>Continuer <i class="el-icon-arrow-right el-icon-right"></i></el-button>
            </div>
        </div>
    </div>
</template>

<script>
import Vuex from 'vuex'
import Place from '@/lib/entities/Place'
import { parseAddress, error } from '@/lib/Tools'
import PlaceDisplayForm from '@/components/Place/PlaceDisplayForm'
import PlaceOrderingForm from '@/components/Place/PlaceOrderingForm'
import PlaceSettingsForm from '@/components/Place/PlaceSettingsForm'
export default {
    name: 'PlaceCreate',
    components: {
        PlaceDisplayForm,
        PlaceOrderingForm,
        PlaceSettingsForm,
    },
    data: () => ({
        step: 0,
        place: new Place()
    }),
    computed: {
        ...Vuex.mapState(['store']),
        canGoNext() {
            if (this.step === 0) {
                return true
            }
            if (this.step === 1) {
                return true
            }
            return true
        }
    },
    methods: {
        ...Vuex.mapActions(['getPlaces', 'getIntegrations', 'createPlace']),
        prev() {
            this.step--
        },
        next() {
            this.step++
        },
        async exitWizard() {
            try {
                const place = await this.createPlace(this.place)
                this.$message({
                    message: 'Etablissement créé avec succès',
                    type: 'success'
                })
                this.getIntegrations()
                this.$router.push({name: 'app.places.edit', params: {id: place.id }})
            } catch (err) {
                error(err)
                this.$message({
                    message: 'Une erreur est survenue, merci de réessayer',
                    type: 'error'
                })
            }
        },
        handleSettingsUpdated(settings) {
            this.place.settings = {
                ...this.place.settings,
                ...settings
            }
        },
        handleInfosUpdated(infos) {
            this.place.email = infos.email
            this.place.logoUrl = infos.logoUrl
            this.place.location = parseAddress(infos.address)
            this.place.phone = infos.phone
            this.place.name = infos.name
            this.place.description = infos.description
        }
    }
}
</script>

<style>

</style>
