<template>
    <div id='merchant' class="view" v-loading="loading">
        <el-button type="primary" style="float: right;" @click='updateMerchantProfile'>Mettre à jour</el-button>
        <h2>Informations &amp; Facturation</h2>
        <div class="box">
            <el-form label-width="120px">
                <el-row :gutter="10" id='merchant'>
                    <el-col :span="12" :xs="24">
                        <h4>Logo</h4>
                        <el-upload
                            ref="logo"
                            :action='logoUploadUrl'
                            :auto-upload='false'
                            accept='image/*'
                            :on-change='uploadFile'
                            :drag='false'
                            list-type="picture-card"
                            :show-file-list="false">
                                <img v-if="form.logoUrl" :src="form.logoUrl" class="logo" width="145px">
                                <i v-else class="el-icon-plus"></i>
                        </el-upload>
                        <h4>Informations</h4>
                        <el-form-item label="Raison sociale">
                            <el-input v-model='form.identifier' disabled></el-input>
                        </el-form-item>
                        <el-form-item label="SIREN">
                            <el-input v-model='form.registrationNumber' disabled></el-input>
                        </el-form-item>
                        <el-form-item label="Enseigne">
                            <el-input v-model='form.name' :value='merchant.name'></el-input>
                        </el-form-item>
                        <el-form-item label="Adresse">
                            <el-input v-model='form.address' :value='merchant.address'></el-input>
                        </el-form-item>
                        <el-form-item label="Téléphone">
                            <el-input v-model='form.phone' :value='merchant.phone'></el-input>
                        </el-form-item>
                        <el-form-item label="Email">
                            <el-input v-model='form.email' :value='merchant.email'></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12" :xs="24">
                        <h4>Factures</h4>
                        <div class="notification is-light">
                            Aucune factures disponibles
                        </div>
                        <h4>Couleurs</h4>
                        <el-row :gutter="10" id='merchant' v-if='!loading'>
                            <el-col :span="12" :xs="24">
                                <h5><i class="el-icon-fa-tint"></i> Couleur principale</h5>
                                <sketch v-model="form.colors.headerBackground" />
                            </el-col>
                            <el-col :span="12" :xs="24">
                                <h5><i class="el-icon-fa-tint"></i> Couleur secondaire</h5>
                                <sketch v-model="form.colors.secondaryColor" />
                            </el-col>
                        </el-row>
                        <!-- <h4>Votre offre</h4>
                        <div>
                            <div class="notification is-success">
                                <p>Vous bénéficier actuellement de l'offre <strong>Basique</strong>.</p>
                                <p>Pour changer d'offre, merci de nous contacter.</p>
                                <el-button type='primary' icon='el-icon-fa-calendar' style='margin-top: 1em' @click='view.rdv = true'>Prendre Rendez-Vous</el-button>
                            </div>
                        </div> -->
                        <rdv-modal
                            :visible='view.rdv'
                            @close='view.rdv = false'
                        />
                    </el-col>
                </el-row>
            </el-form>
        </div>
    </div>
</template>

<script>
import { parseAddress, error } from '@/lib/Tools'
import { Sketch } from 'vue-color'
import Vuex from 'vuex'
import RDVModal from '@/components/RDVModal'
export default {
    name: 'Merchant',
    computed: {
        ...Vuex.mapState(['merchant']),
    },
    components: {
        'rdv-modal': RDVModal,
        Sketch
    },
    data: () => ({
        loading: true,
        form: {},
        logoUploadUrl: '',
        view: {
            rdv: false
        }
    }),
    mounted() {
        this.form = Object.assign({}, this.merchant)
        this.form.address = this.merchant.address
        this.form.colors = {...{
            headerBackground: '#fff',
            secondaryColor: '#bbb'
        }, ...this.merchant.colors}
        this.loading = false
    },
    methods: {
        ...Vuex.mapActions(['updateMerchant', 'storeMerchantLogo']),
        async updateMerchantProfile() {
            this.form.location = parseAddress(this.form.address)
            try {
                this.loading = true
                await this.updateMerchant(this.form)
                this.$message({
                    message: 'Modification enregistrée',
                    type: 'success'
                })
            } catch (err) {
                error(err)
                this.$message({
                    message: 'Une erreur est survenue, merci de réessayer',
                    type: 'error'
                })
            }
            this.loading = false
        },
        uploadFile(file) {
            this.loading = true
            const reader = new FileReader()
            reader.onerror = (err) => {
                this.$message({
                    type: 'warning',
                    message: 'Erreur lors de l\'enregistrement de l\'image, merci de réessayer'
                })
            }
            reader.onload = (event) => {
                this.form.logoUrl = reader.result

                this.storeMerchantLogo({
                    filename: file.name,
                    file: file.raw
                }).then(({url, colors}) => {
                    this.form.logoUrl = url
                    this.$message({
                        type: 'success',
                        message: 'Image enregistrée avec succès'
                    })
                    this.loading = false
                }).catch((err) => {
                    this.$message({
                        type: 'error',
                        message: 'Une erreur s\'est produite, merci de réessayer'
                    })
                    this.loading = false
                })
            }
            reader.readAsDataURL(file.raw)
        },
    }
}
</script>

<style>

</style>
