<template>
    <div id="cardEdit" class="view" v-loading.lock="loading">
        <div style='float: right; margin-top: 15px;'>
            <el-button type="primary" :disabled='!isCardValid' @click="saveCard()" icon='el-icon-circle-check-outline'>Enregistrer</el-button>
            <el-button @click="$router.back()">Annuler</el-button>
            <el-button circle size="small" @click='previewCard' icon='el-icon-fa-eye'></el-button>
        </div>
        <div v-if='loaded'>
            <h2><el-icon icon="el-icon-fa-pencil"></el-icon>Modification de la carte "{{card.name}}"</h2>
            <h3 v-if='card.place'>
                Associée à
                <router-link tag="span" style="cursor: pointer; text-decoration: underline;" :to='{name: "app.places.edit", params: {id: card.place.id}}'>{{card.place.name}}</router-link>
            </h3>
            <h3 v-else>Non associée</h3>
            <el-tabs v-model="tab" type="border-card" @tab-click="handleTabSelected">
                <el-tab-pane name='products'>
                    <span slot="label"><i class="el-icon-fa-list-alt"></i> Produits</span>
                    <el-alert v-if='pricesOverrides.length > 0' title="" type="warning" show-icon :closable='false'>
                        <div slot="title">
                            Les prix publiques de la carte sont majorés
                        </div>
                        <div v-for='(override, $o) in pricesOverrides' :key="$o">
                            <span v-if='override.ratio > 0'>+ {{override.ratio.toFixed(2)}} %</span>
                            <span v-if='override.upfront > 0'>+ {{override.upfront|EUR}}</span>
                        </div>
                    </el-alert>
                    <card-composer 
                        v-if='loaded'
                        :show-header='false'
                        :products='products'
                        :card='card'
                        :categories='categories'
                        :assemblies='assemblies'
                        :statuable='true'
                        :isEditing='true'
                    />
                </el-tab-pane>
                <el-tab-pane name='display'>
                    <span slot="label"><i class="el-icon-fa-paint-brush"></i> Affichage</span>
                    <el-form label-width="170px">
                        <el-form-item label="Index">
                            <el-input type="number" min="0" placeholder='Index de la carte' v-model='card.index'/>
                        </el-form-item>
                        <el-form-item label="Titre de la carte">
                            <el-input type="text" placeholder='Le titre de la carte sera affiché sur le module de commande' v-model='card.title'/>
                        </el-form-item>
                        <el-form-item label="Description de la carte">
                            <el-input type="text" placeholder='La description de la carte sera affichée sur le module de commande' v-model='card.description'/>
                        </el-form-item>
                    </el-form>
                </el-tab-pane>
                <el-tab-pane name='settings'>
                    <span slot="label"><i class="el-icon-fa-gears"></i> Paramètres</span>
                    <el-form label-width="170px">
                        <el-form-item label="Nom de la carte">
                            <el-input type="text" ref='cardNameInput' autofocus="on" class="input-large" size="large" placeholder='Nom de la carte' v-model='card.name' required/>
                        </el-form-item>
                        <el-form-item label="Etablissement associé">
                            <card-link-form :tooltip='false' :card='card' @placeSelected='handlePlaceSelected'></card-link-form>
                        </el-form-item>
                    </el-form>
                    <!-- <pre>{{card.settings}}</pre> -->
                </el-tab-pane>
            </el-tabs>
            <card-share-modal
                v-if='card'
                :card='card'
                :visible='view.shareModal'
                @close='view.shareModal = false'
            />
            <card-preview-modal
                v-if='card'
                :card='card'
                :visible='view.previewModal'
                @close='view.previewModal = false'
            />
        </div>
        <div v-else>
            <h4>  Chargement en cours ... </h4>
        </div>
    </div>
</template>

<script>
import Vuex from 'vuex'

import CardLinkForm from '@/components/Menu/CardLinkForm'
import CardComposer from '@/components/Menu/CardComposer'
import CardShareModal from '@/components/Menu/CardShareModal'
import CardPreviewModal from '@/components/Menu/CardPreviewModal'
import { ensureNumber, error } from '@/lib/Tools'

export default {
    name: 'CardEdit',
    components: {
        CardLinkForm,
        CardComposer,
        CardShareModal,
        CardPreviewModal,
    },
    data: () => ({
        tab: 'products',
        card: null,
        loaded: false,
        loading: true,
        selectedPlace: null,
        view: {
            previewModal: false,
            shareModal: false
        }
    }),
    computed: {
        ...Vuex.mapState(['user', 'places']),
        ...Vuex.mapGetters(['cards', 'categories', 'assemblies', 'products']),
        isCardValid() {
            if (this.card) {
                return true
            }
            return false
        },
        pricesOverrides() {
            const overrides = []
            if (this.card.place && this.card.place.settings.fees.enabled) {
                const upfront = ensureNumber(this.card.place.settings.fees.upfront)
                const ratio = ensureNumber(this.card.place.settings.fees.ratio)
                if (upfront > 0 || ratio > 0) {
                    overrides.push({
                        placeId: this.card.place.id,
                        placeName: this.card.place.name,
                        upfront,
                        ratio
                    })
                }
            }
            return overrides
        }
    },
    mounted() {
        // this.card = this.cards.find((card) => card.id === this.$route.params.id)
        this.loading = true
        this.getCard(this.$route.params.id).then((card) => {
            this.card = card
            this.loading = false
            this.loaded = true
        })
    },
    methods: {
        ...Vuex.mapActions(['saveStore', 'getCard']),
        handleTabSelected(tag) {
            return
        },
        handlePlaceSelected(place) {
            if (place === undefined) {
                place = null
            }
            this.selectedPlace = place
        },
        previewCard() {
            this.view.previewModal = true
        },
        saveCard() {
            if (this.selectedPlace) {
                this.card.setPlace(this.selectedPlace)
            }
            console.log(this.card)
            this.saveStore({
                cards: [ this.card ]
            }).then(() => {
                this.$message({
                    message: 'Modification enregistrée avec succès',
                    type: 'success'
                })
                this.$router.back()
            }).catch((err) => {
                this.$message({
                    message: 'Une erreur est survenue, merci de réessayer',
                    type: 'error'
                })
                error(err)
            })
        }
    }
}
</script>

<style>

</style>
