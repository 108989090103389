<template>
    <el-dialog
        :visible.sync="visible"
        width='55%'
        title='Partager une carte'
        :before-close="closeModal"
        >
            <div v-if='!loading'>
                <template v-for='(int, $i) in cardIntegrations'>
                    <div class='integration-snippet' :key='$i'>
                        <h3>{{int.label}}</h3>
                        <code>{{int.code}}</code>
                        <p>
                            <el-icon icon="el-icon-fa-globe"></el-icon>
                            <a :href='int.url' target='blank'>{{int.url}}</a>
                        </p>
                        <div class="notification is-primary" style='margin-top: 20px;' v-if='int.help'>
                            <small>{{int.help}}</small>
                        </div>
                    </div>
                </template>
                <el-alert type='warning' title='' v-if='cardIntegrations.length == 0' class='notification'>
                    <span slot='title'>Cette carte n'est associée à aucun établissement</span>
                </el-alert>
            </div>
            <div v-else>
                Chargement...
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button @click="closeModal" icon='el-icon-close'>Fermer</el-button>
            </span>
    </el-dialog>
</template>

<script>
import Card from '@/lib/entities/Card'
import { error } from '@/lib/Tools'
import Vuex from 'vuex'
export default {
    name: 'CardShareModal',
    components: {},
    props: {
        card: {
            type: Card,
            required: true
        },
        visible: {
            type: Boolean,
            required: true
        }
    },
    data: () => ({
        loading: true
    }),
    computed: {
        ...Vuex.mapState(['integrations']),
        cardIntegrations() {
            if (this.integrations && this.integrations.cards) {
                return this.integrations.cards[this.card.id] || []
            }
            return []
        }
    },
    created() {
        this.getIntegrations()
        .then(() => {
            this.loading = false
        })
        .catch((err) => {
            this.loading = false
            error(err)
        })
    },
    methods: {
        ...Vuex.mapActions(['getIntegrations']),
        closeModal() {
            this.$emit('close')
        }
    }
}
</script>

<style>

</style>
