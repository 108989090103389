import Vue from 'vue'
import Router, { Route } from 'vue-router'

import Register from './views/Register.vue'
import Login from './views/Login.vue'
import ResetPassword from './views/ResetPassword.vue'
// import Dispatcher from './Dispatcher.vue'
import App from './views/App.vue'
import AuthView from './views/AuthView.vue'
import OnboardingWizard from './views/OnboardingWizard.vue'
import MenuWizard from './views/MenuWizard.vue'
import Profile from './views/Profile.vue'
import Merchant from './views/Merchant.vue'
import Settings from './views/Settings.vue'
import Team from './views/Team.vue'
import TeamCreate from './views/TeamCreate.vue'
import TeamEdit from './views/TeamEdit.vue'
import Integrations from './views/Integrations.vue'
import Dashboard from './views/Dashboard.vue'
import Notifications from './views/Notifications.vue'
import ProductEdit from './views/ProductEdit.vue'
import CardEdit from './views/CardEdit.vue'
import CardSettings from './views/CardSettings.vue'
import Menus from './views/Menus.vue'
import Places from './views/Places.vue'
import Stats from './views/Stats.vue'
import PlaceCreate from './views/PlaceCreate.vue'
import PlaceEdit from './views/PlaceEdit.vue'
import Ordering from './views/Ordering.vue'
import OrderDetails from './views/OrderDetails.vue'
import Page404 from './views/Page404.vue'

Vue.use(Router)

import Auth from '@/lib/Auth'

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      name: 'root',
      redirect: '/auth/login'
    },
    {
      path: '/auth',
      component: AuthView,
      children: [
        {
          path: 'register',
          name: 'auth.register',
          component: Register
        },
        {
          path: 'login',
          name: 'auth.login',
          component: Login
        },
        {
          path: 'reset',
          name: 'auth.reset',
          component: ResetPassword
        }
      ]
    },
    {
      path: '/app',
      component: App,
      meta: { requiresAuth: true },
      children: [
        {
          path: 'dashboard',
          name: 'app.home',
          component: Dashboard
        },
        {
          path: 'profile',
          name: 'app.profile',
          component: Profile
        },
        {
          path: 'merchant',
          name: 'app.merchant',
          component: Merchant
        },
        {
          path: 'notifications',
          name: 'app.notifications',
          component: Notifications
        },
        {
          path: 'settings',
          name: 'app.settings',
          component: Settings
        },
        {
          path: 'places',
          name: 'app.places',
          component: Places
        },
        {
          path: 'places/create',
          name: 'app.places.create',
          component: PlaceCreate
        },
        {
          path: 'places/:id',
          name: 'app.places.edit',
          component: PlaceEdit
        },
        {
          path: 'integrations',
          name: 'app.integrations',
          component: Integrations
        },
        {
          path: 'menus',
          name: 'app.menus',
          component: Menus
        },
        {
          path: 'menus/product/:id',
          name: 'app.menus.product.edit',
          component: ProductEdit
        },
        {
          path: 'menus/wizard',
          name: 'app.menu.wizard',
          component: MenuWizard
        },
        {
          path: 'orders',
          name: 'app.orders',
          component: Ordering
        },
        {
          path: 'orders/details/:id',
          name: 'app.orders.details',
          component: OrderDetails
        },
        {
          path: 'team',
          name: 'app.team',
          component: Team
        },
        {
          path: 'team/edit/:id',
          name: 'app.team.edit',
          component: TeamEdit
        },
        {
          path: 'team/create',
          name: 'app.team.create',
          component: TeamCreate
        },
        {
          path: 'stats',
          name: 'app.stats',
          component: Stats
        },
        {
          path: 'card/edit/:id',
          name: 'app.card.edit',
          component: CardEdit
        },
        {
          path: 'card/settings/:id',
          name: 'app.card.settings',
          component: CardSettings
        },
      ]
    },
    {
      path: '/wizard',
      name: 'onboarding.wizard',
      component: OnboardingWizard,
      // component: () => import(/* webpackChunkName: "resetPassword" */ './views/OnboardingWizard.vue'),
      meta: { requiresAuth: false }
    },
    {
      path: '/reset-password',
      name: 'doResetPassword',
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "resetPassword" */ './views/DoResetPassword.vue'),
    },
    {
      path: '/error',
      name: 'error',
      component: () => import(/* webpackChunkName: "p502" */ './views/Page502.vue'),
    },
    {
      path: '*',
      name: '404',
      component: Page404
    }
  ],
});

router.beforeEach((to: Route, from: Route, next: any) => {
  if (to.matched.some((record: any) => record.meta.requiresAuth)) {
    if (Auth.isConnected() === false) {
      next({
        name: 'auth.login',
        query: { redirect: to.fullPath }
      })
    } else {
      next()
    }
  } else {
    next()
  }
})

export default router
