import Parse from 'parse'
import Merchant from './Merchant'

export default class User {

    public id: any
    public firstname?: string
    public lastname?: string
    public email?: string
    public phone?: string
    public role?: string
    public mobilePhone?: string
    public merchant?: Merchant
    public placesIds?: string[]

    constructor(data?: Parse.User) {
        if (data) {
            this.id = data.id
            this.firstname = data.get('firstname')
            this.lastname = data.get('lastname')
            this.email = data.get('email')
            this.phone = data.get('phone')
            this.mobilePhone = data.get('mobilePhone')
            this.merchant = data.get('merchant')
            this.placesIds = data.get('placesIds')
            this.role = data.get('type')
        }
    }
}
