import Entity from '@/lib/entities/Entity'
import Store from '@/lib/entities/Store'
import Category from '@/lib/entities/Category'
import CardProduct from '@/lib/entities/CardProduct'

export default class CardCategory extends Entity {

    public static boot(model: Parse.Object, store: Store): CardCategory {
        const cat = Category.boot(model, store)
        const cardCategory = new CardCategory(cat)
        return cardCategory
    }

    public products: CardProduct[]
    public name?: string
    public fresh?: boolean
    public vat?: number
    public category?: Category

    constructor(category?: Category) {
        super()
        this.products = []
        if (category) {
            this.category = category
            this.name = category.name
            this.fresh = category.fresh
            this.vat = category.vat
            for (const product of category.products) {
                this.addProduct(product)
            }
        }
    }

    /**
     * Return underlying category products
     */
    public getCategoryProducts() {
        if (this.category) {
            return this.category.products
        }
        return []
    }

    public addProduct(product: any) {
        this.products.push(new CardProduct(product))
    }

    public dropProduct(product: CardProduct) {
        const index = this.products.indexOf(product)
        if (index > -1) {
            this.products.splice(index, 1)
        }
    }

    public toJson() {
        return {
            id: this.id,
            name: this.name,
            vat: this.vat,
            categoryId: this.category ? this.category.id : null,
            products: this.products.map((product) => product.toJson())
        }
    }
}