var render = function render(){var _vm=this,_c=_vm._self._c;return _c('el-dialog',{attrs:{"visible":_vm.visible,"width":"55%","title":"Créneau de commande","before-close":_vm.close},on:{"update:visible":function($event){_vm.visible=$event}}},[_c('el-row',{attrs:{"gutter":50}},[(_vm.isEditing)?_c('el-col',{attrs:{"span":12,"xs":24}},[_c('el-form',{attrs:{"id":"timeslotForm","label-width":"150px"}},[_c('h3',[_vm._v("Modifier un créneau")]),_c('el-form-item',{attrs:{"label":"Début"}},[_c('el-time-select',{attrs:{"placeholder":"Début","required":"","picker-options":{
                                start: '08:30',
                                step: '00:15',
                                end: '23:59',
                                maxTime: _vm.timeslot.end && _vm.timeslot.end.length > 0 ? _vm.timeslot.end : '23:59'
                            }},model:{value:(_vm.timeslot.start),callback:function ($$v) {_vm.$set(_vm.timeslot, "start", $$v)},expression:"timeslot.start"}})],1),_c('el-form-item',{attrs:{"label":"Fin"}},[_c('el-time-select',{attrs:{"placeholder":"Fin","required":"","picker-options":{
                                start: '08:30',
                                step: '00:15',
                                end: '24:00',
                                minTime: _vm.timeslot.start
                            }},model:{value:(_vm.timeslot.end),callback:function ($$v) {_vm.$set(_vm.timeslot, "end", $$v)},expression:"timeslot.end"}})],1)],1)],1):_vm._e(),(!_vm.isEditing)?_c('el-col',{attrs:{"span":12,"xs":24}},[_c('h3',[_vm._v("Générer des créneaux")]),_c('el-form',{attrs:{"id":"timeslotGenerator","label-width":"100px"}},[_c('el-form-item',{attrs:{"label":"Durée"}},[_c('el-time-select',{attrs:{"placeholder":"Durée (minutes)","required":"","picker-options":{
                                start: '00:05',
                                step: '00:05',
                                end: '01:00'
                            }},model:{value:(_vm.generator.duration),callback:function ($$v) {_vm.$set(_vm.generator, "duration", $$v)},expression:"generator.duration"}})],1),_c('el-form-item',{attrs:{"label":"De"}},[_c('el-time-select',{attrs:{"placeholder":"De","required":"","picker-options":{
                                start: '00:00',
                                step: '00:15',
                                end: '23:59',
                                maxTime: _vm.generator.to && _vm.generator.to.length > 0 ? _vm.generator.to : '23:59'
                            }},model:{value:(_vm.generator.from),callback:function ($$v) {_vm.$set(_vm.generator, "from", $$v)},expression:"generator.from"}})],1),_c('el-form-item',{attrs:{"label":"A"}},[_c('el-time-select',{attrs:{"placeholder":"A","required":"","picker-options":{
                                start: '00:00',
                                step: '00:15',
                                end: '24:00',
                                minTime: _vm.generator.from
                            }},model:{value:(_vm.generator.to),callback:function ($$v) {_vm.$set(_vm.generator, "to", $$v)},expression:"generator.to"}})],1)],1)],1):_vm._e()],1),(_vm.overlaping)?_c('el-alert',{attrs:{"type":"warning","show-icon":"","closable":false,"title":"Vos créneaux se chevauchent"}}):_vm._e(),_c('span',{staticClass:"dialog-footer",attrs:{"slot":"footer"},slot:"footer"},[_c('el-button',{attrs:{"icon":"el-icon-close"},on:{"click":_vm.close}},[_vm._v("Annuler")]),_c('el-button',{attrs:{"disabled":!_vm.formIsValid,"type":"primary","icon":"el-icon-save"},on:{"click":_vm.save}},[_vm._v("Enregistrer")])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }