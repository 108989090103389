import * as Api from '@/lib/Api'
import Product from '@/lib/entities/Product'
import Category from '@/lib/entities/Category'

export default class RecommandationService {

    public static async suggestProductTags(description: string): Promise<string[]> {
        return await Api.getSuggestedTags(description)
    }

    public static async suggestProducts(category: Category): Promise<any[]> {
        return await Api.getSuggestedProducts(category.name)
    }
}
