<template>
    <el-form ref="onboardingForm" :model='model' @submit.native.prevent>
        <label class="label">{{label}}{{required ? '*' : ''}}</label>
        <input type="text" v-if='type === "input"' ref='us' class="input" @keyup.enter='handleFormSubmitted' :placeholder='placeholder' v-model='answer' autofocus>
        <template v-if='type === "checkbox"'>
            <div class="checkboxes">
                <el-radio v-for='(option, $index) in options' :key='$index' v-model='answer' :label="option">{{option}}</el-radio>
            </div>
            <div v-if='details && answer === options[0]'>
                <input v-for='(detail, $j) in details' :key='$j' type="text" class="input" :placeholder='detail.placeholder' @change='handleDetailProvided($event, detail)'>
            </div>
        </template>
        <el-button type='primary' :disabled='!answerIsValid' @click='submitQuestion'>Continuer</el-button>
        <el-button type='info' id='skipBtn' plain v-if='!required' @click='submitQuestion'>Passer</el-button>
    </el-form>
</template>

<script>
export default {
    name: 'OnboardingQuestion',
    props: {
        model: {
            required: true
        },
        identifier: {
            required: true
        },
        label: {
            type: String,
            required: true
        },
        placeholder: {
            type: String,
            required: false
        },
        required :{
            type: Boolean,
            required: false,
            default: () => false
        },
        options: {
            type: Array,
            required: false
        },
        details: {
            type: Array,
            required: false
        },
        type: {
            type: String,
            required: true
        },
        initial: {
            type: String,
            required: false
        }
    },
    data: () => ({
        answer: null
    }),
    computed: {
        answerIsValid() {
            if (this.answer && this.answer.length > 0) {
                return true
            }
            return false
        }
    },
    mounted() {
        if (this.initial) {
            this.answer = this.initial
        }
        if (this.$refs.us) {
            this.$refs.us.focus()
        }
    },
    methods: {
        handleDetailProvided($event, detail) {
            const value = $event.target.value
            if (detail.bindTo) {
                this.__injectBinding(detail.bindTo, value)
            }
        },
        handleFormSubmitted() {
            if (this.answerIsValid) {
                this.submitQuestion()
            }
            return false
        },
        submitQuestion() {
            this.$emit('submit', this.answer, this.identifier)
        },
        __injectBinding(str, value) {
            const fragments = str.split('.')
            const obj = fragments[0]
            const key = fragments[1]
            this.$set(this.model, key, value)
        }
    }
}
</script>

<style>

</style>
