<template>
    <div class="box">
        <slot name="title" style="margin-bottom: 1em;"></slot>
        <div class="field">
            <label class="label">Nom de la catégorie</label>
            <p class="control">
                <input type="text" class="input" v-model='category.name' placeholder="ie: Entrées, Plats, ...">
            </p>
        </div>
        <div class="field">
            <label class="label">TVA</label>
            <p class="control">
                <input type="number" step="0.01" min="0" max="1" class="input" v-model='category.vat'>
            </p>
        </div>
        <div class="field">
            <label class="label">Couleur</label>
            <p class="control">
                <input type="color" class="input" v-model='category.color'>
            </p>
        </div>
        <div class="field">
            <p class="control is-grouped">
                <span v-if='!view.editing'>
                    <button class="button is-success" @click='saveCategory' :disabled='!formValid'>
                        <span class="icon is-small"><i class="fa fa-plus-circle"></i></span>
                        <span>Ajouter</span>
                    </button>
                </span>
                <span v-else>
                    <button class="button is-success" @click='updateCategory' :disabled='!formValid'>
                        <span class="icon is-small"><i class="fa fa-save"></i></span>
                        <span>Enregister</span>
                    </button>
                </span>
                <slot name="footer"></slot>
            </p>
        </div>
    </div>
</template>

<script>
import Category from '@/lib/entities/Category'
export default {
    name: 'CategoryForm',
    props: {
        editingCategory: {
            type: Object,
            required: false
        }
    },
    data: () => ({
        category: {},
        view: {
            editing: false
        }
    }),
    computed: {
        formValid() {
            return this.category && this.category.name
        }
    },
    mounted() {
        if (this.editingCategory) {
            this.category = this.editingCategory
        } else {
            this.category = new Category()
        }
        this.$watch('editingCategory', (category) => {
            if (category) {
                this.category = category
                this.view.editing = true
            } else {
                this.category = new Category()
                this.view.editing = false
            }
        })
    },
    methods: {
        saveCategory() {
            this.$emit('save', this.category)
            this.category = new Category()
        },
        updateCategory() {
            this.$emit('update', this.editingCategory, this.category)
            this.category = new Category()
            this.view.editing = false
        }
    }
}
</script>

