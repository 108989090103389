<template>
    <el-main class='fly' v-loading='loading'>
        <img src='/img/logo_white.png' width="40px" id="logo"/>
        <div id="onboarding">
            <div class="left-col hidden-sm-and-down">
                <div>
                    <h1>Bienvenue</h1>
                    <h2>Merci de compléter ce formulaire pour vous inscrire</h2>
                    <div class="steps">
                        <ul>
                            <li v-for='(group, $index) in groups' :key='$index' :class='{"active" : $index === groupIndex}'>{{group}}</li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="right-col">
                <h2 class='hidden-md-and-up'>Merci de compléter ce formulaire pour vous inscrire</h2>
                <div v-if='questionIndex < questions.length'>
                    <template v-for='(question, $index) in questions'>
                        <onboarding-question
                            v-if='questionIndex === $index'
                            :type='question.type'
                            :key='$index'
                            :placehoder='question.placeholder'
                            :identifier='question.identifier'
                            :label='question.label'
                            :initial='model[question.identifier]'
                            :model='model'
                            :options='question.options'
                            :details='question.details'
                            :required='question.required'
                            @submit='handleAnswer'
                        />
                    </template>
                </div>
                <div v-else>
                    <!-- <h3>Merci !</h3>
                    <h4>Nous allons vous contacter très rapidement pour prendre rendez-vous.</h4> -->
                    <h3>Pour terminer la création de votre compte, merci de saisir un mot de passe</h3>
                    <el-form :rules='passwordRules' status-icon ref="passwordForm" label-width="120px" :model='passwordFormData'>
                        <el-form-item label='Mot de passe' prop="password">
                            <el-input type="password" prefix-icon='el-icon-fa-lock' autocomplete='off' required v-model='passwordFormData.password' placeholder="Min. 6 caractères"></el-input>
                        </el-form-item>
                        <el-form-item label='Confirmation' prop="passwordConfirmation">
                            <el-input type="password" prefix-icon='el-icon-fa-lock' autocomplete='off' required v-model='passwordFormData.passwordConfirmation' placeholder="Confirmation"></el-input>
                        </el-form-item>
                        <el-form-item>
                            <el-button type='primary' @click='gotoDashboard' icon='el-icon-fa-paper-plane'>Créer mon compte</el-button>
                        </el-form-item>
                        <el-alert show-icon v-if='passwordFormData.password.length > 0 && passwordFormData.password !== passwordFormData.passwordConfirmation' title="Les mots de passe ne correspondent pas" type="warning" :closable='false'></el-alert>
                        <el-alert show-icon v-if='hasError' :title='errorMessage' type="error" :closable='false'></el-alert>
                    </el-form>
                </div>
            </div>
        </div>
    </el-main>
</template>

<script>
import Vuex from 'vuex'
import { error } from '@/lib/Tools'
import OnboardingQuestion from '@/components/OnboardingQuestion'
export default {
    name: 'OnboardingWizard',
    components: {
        OnboardingQuestion
    },
    data: () => ({
        loading: true,
        groups: [],
        questions: [],
        questionIndex: 0,
        groupIndex: 0,
        model: {},
        passwordFormData: {
            password: '',
            passwordConfirmation: ''
        },
        passwordRules: {
            password: [
                { required: true, message: 'Merci de saisir un mot de passe', trigger: 'blur' },
                { min: 6, message: 'Merci de saisir un mot de passe d\'au moins 6 caractères', trigger: 'blur' }
            ],
            passwordConfirmation: [
                { required: true, message: 'Merci de confirmer votre mot de passe', trigger: 'blur' }
            ]
        },
        hasError: false,
        errorMessage: ''
    }),
    computed: {
        ...Vuex.mapState(['lead'])
    },
    watch: {
        questionIndex(i) {
            this.$router.push({query: { ...this.$router.query, i } })
            if (this.questions[i]) {
                this.groupIndex = this.questions[i].groupIndex
            }
        },
        $route(r) {
            if (r.query.i) {
                this.$data.questionIndex = Number(r.query.i)
            }
        }
    },
    mounted() {
        this.model = Object.assign({}, this.lead)
        this.getOnboardingQuestions().then(({questions, groups}) => {
            this.loading = false
            this.questions = questions
            this.groups = groups
        })

        if (this.user && this.user.id) {
            this.$router.push({name: 'app.home', query: {from: 'onboarding'}})
            return
        }
    },
    methods: {
        ...Vuex.mapActions(['getOnboardingQuestions', 'saveOnboardingProgress', 'registerUser']),
        handleAnswer(value, identifier) {
            this.model[identifier] = value
            this.saveOnboardingProgress(this.model)
            this.questionIndex++
        },
        async gotoDashboard() {
            this.hasError = false
            try {
                await this.$refs.passwordForm.validateField('password')
                await this.$refs.passwordForm.validateField('passwordConfirmation')
                if (this.passwordFormData.password.length > 6
                    && this.passwordFormData.password === this.passwordFormData.passwordConfirmation
                ) {
                    await this.registerUser({
                        ...this.model,
                        password: this.passwordFormData.password
                    })
                    this.$router.push({name: 'app.home', query: {pk: this.model.pk, showWizard: true}})
                }
            } catch (err) {
                error(err)
                this.errorMessage = err.message
                this.hasError = true
                return false
            }
        }
    }
}
</script>

<style lang='scss'>
.fly {
    #logo {
        position: fixed;
        top: 10px;
        left: 10px;
        opacity: 0.8;
    }
}

#onboarding {
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: row;
    background-color: white;
    .left-col {
        h1, h2 {
            color: white;
            font-family: 'League Spartan', 'Avenir', Arial, Helvetica, sans-serif;
        }
        text-align: left;
        padding: 2em;
        justify-content: center;
        // background-color: #03e2ad; // #05ab84;
        background: rgb(3,226,173);
        background: linear-gradient(135deg, rgba(3,226,173,1) 0%, rgba(5,171,132,1) 100%);

    }
    .right-col {
        padding: 2em;
        // box-shadow: inset 1px 1px 2px #c3c3c3;
        border-left: 1px solid #c3c3c363;
    }
    
    .left-col, .right-col {
        display: flex;
        flex-basis: 50%;
        align-items: center;
    }

    @media only screen and (max-width: 991px) {
        .right-col {
            flex-basis: 100%;
            flex-direction: column;
        }
    }

    .label {
        color: #4c4a4a;
        font-weight: 600;
    }

    .input {
        width: 100%;
        color: #4c4a4a;
        margin-top: 0.5em;
        margin-bottom: 2em;
        border-left: none;
        border-top: none;
        border-right: none;
        border-bottom: 2px solid #aaa;
        background-color: transparent;
        padding: 8px 3px;
        border-radius: 0px;
        transition: all .5s ease;
        &:focus {
            outline: none;
            border-bottom: 2px solid #03e2ad;
        }
        
    }

    .checkboxes {
        margin-top: 1em;
        margin-bottom: 1em;
    }

    .steps {
        ul {
            list-style-type: none;
            text-align: left;
            font-family: 'League Spartan', 'Avenir', Arial, Helvetica, sans-serif;
            color: white;
            font-weight: 400;
            margin-block-start: 0;
            padding-inline-start: 10px;
            li {
                border-left: 2px solid transparent;
                padding-left: 10px;
                margin-bottom: 15px;
                animation: all .5s ease;
                &.active {
                    color: black;
                    border-left: 2px solid black;
                }
            }
        }
    }

    #skipBtn {
        position: absolute;
        bottom: 10px;
        right: 10px;
    }
}

</style>
