<template>
    
</template>

<script>
export default {
    name: 'HelpWizard',
    mounted() {
        this.$watch('$route', (route) => {
            this.$alert('This is a message', 'Title', {
                confirmButtonText: 'OK',
                callback: (action) => {
                    
                }
            })
        })
    }
}
</script>

<style>

</style>
