<template>
    <el-dialog
        :visible.sync="visible"
        :fullscreen='true'
        :before-close="closeModal">
            <template slot="title">
                <h2 class="subtitle" v-if='isEditing'><i class="fa fa-pencil"></i>&nbsp;Modification d'une formule</h2>
                <h2 class="subtitle" v-else><i class="fa fa-pencil"></i>&nbsp;Création d'une formule</h2>
            </template>
            <assembly-form 
                v-if='loaded'
                :assembly='assembly'
                :products='products'
                :categories='categories'>
            </assembly-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="closeModal" icon='el-icon-close'>Fermer</el-button>
                <el-button type="primary" :disabled='!isAssemblyValid' @click="saveAndClose()" icon='el-icon-circle-check-outline'>Enregistrer</el-button>
            </span>
    </el-dialog>
</template>

<script>
import Vuex from 'vuex'
import Assembly from '@/lib/entities/Assembly'
import AssemblyForm from '@/components/Menu/AssemblyForm'
export default {
    name: 'AssemblyModal',
    components: {
        AssemblyForm
    },
    data: () => ({
        loaded: false,
        assembly: {},
    }),
    props: {
        products: {
            type: Array,
            required: true
        },
        categories: {
            type: Array,
            required: true
        },
        visible: {
            type: Boolean,
            required: true
        },
        isEditing: {
            type: Boolean,
            required: false,
            default: () => false
        },
        id: {
            type: String,
            requried: false
        }
        // assembly: {
        //     type: Assembly,
        //     required: true
        // }
    },
    computed: {
        isAssemblyValid() {
            return this.assembly.name && this.assembly.products.length > 0
        }
    },
    methods: {
        ...Vuex.mapActions(['getAssembly']),
        closeModal() {
            this.$emit('close')
        },
        saveAndClose() {
            this.$emit('save', this.assembly)
            this.closeModal()
        }
    },
    mounted() {
        this.loaded = false
        if (this.isEditing) {
            this.getAssembly(this.id).then((assembly) => {
                this.assembly = assembly
                this.loaded = true
            })
        } else {
            this.assembly = new Assembly()
            this.loaded = true
        }
        
    }
}
</script>

<style>

</style>
