import Entity from '@/lib/entities/Entity'
import Product from '@/lib/entities/Product'

interface VariationType {
    name: string
    label?: string
    suggestedOptions?: string[]
    type?: string
}

export default class ProductVariation extends Entity {

    public static types: any = {
        CUISSON: {
            name: 'Cuisson',
            label: 'Cuisson',
            suggestedOptions: [
                'Bleu', 'Saignant', 'A point', 'Bien cuit'
            ],
            type: 'hasOne'
        } as VariationType,
        ACCOMPAGNEMENT: {
            name: 'Accompagnement',
            label: 'Accompagnement'
        } as VariationType,
        OPTION: {
            name: 'Option supplémentaire'
        } as VariationType,
        CUSTOM: {
            name: 'Variation personalisée'
        } as VariationType
    }

    public static getType(typeKey: string): VariationType {
        return ProductVariation.types[typeKey]
    }

    public label?: string
    public index?: number
    public type: VariationType = { name: '', label: '' }
    public options: any = []
    public product?: Product
    public options_data?: any = {}
    public externalPrice?: number
    public externalId?: string
    public externalProvider?: string
    
    public settings: any = {
        hasOptions: true,
        required: true,
        unique: true,
        pricable: false,
        stockable: false
    }

    constructor(props: any = {}) {
        super()
        if (props.id) {
            this.id = props.id
        }
        if (props.type) {
            this.type = props.type
        }
        if (props.options) {
            this.options = props.options
        }
        if (props.settings) {
            this.settings = props.settings    
        } else {
            this.settings = {
                hasOptions: true,
                required: true,
                unique: true,
                pricable: false,
                stockable: false
            }
        }
        if (this.options.length > 0) {
            this.settings.hasOptions = true
        }
        if (props.label) {
            this.label = props.label
        }
        if (props.options_data) {
            this.options_data = props.options_data
        }
        this.index = props.index || -1
        this.externalId = props.externalId
        this.externalPrice = props.externalPrice || 0.0
        this.externalProvider = props.externalProvider
    }

    public setProduct(product: Product) {
        this.product = product
    }

    public setSettings(settings: any) {
        this.settings = settings
    }
    
    public setType(type: VariationType) {
        this.type = type
    }
    
    public setIndex(index: number) {
        this.index = index
    }

    public setOptionData(data: object) {
        this.options_data = data
    }

    public setVariationType(typeKey: string): VariationType|null {
        if (typeKey in ProductVariation.types) {
            this.type = ProductVariation.getType(typeKey)
            if (this.type.suggestedOptions) {
                for (const opt of this.type.suggestedOptions) {
                    this.options.push(opt)
                }
            }
            if (this.type.label) {
                this.setLabel(this.type.label)
            } else {
                this.setLabel('')
            }
            return this.type
        }
        this.options = []
        return null
    }

    public setLabel(label: string) {
        this.label = label
    }

    public addOption(option: string) {
        this.options.push(option)
    }
}
