<template>
  <el-dialog
        :visible.sync="visible"
        width='55%'
        title='Zone de livraison'
        :before-close="close"
        >
            <el-form-item>
                <span slot='label'>Distance minimale (km)</span>
                <el-input-number placeholder="Distance minimale" v-model="zone.min" step='0.1' :min='0' :max='max + 1'></el-input-number>
            </el-form-item>
            <el-form-item>
                <span slot='label'>Distance maximale (km)</span>
                <el-input-number placeholder="Distance maximale" v-model="zone.max" step='0.1' :min='min + 1'></el-input-number>
            </el-form-item>
            <el-form-item>
                <span slot='label'>Minimum de commande (incluant la livraison)</span>
                <el-input placeholder="Minimum de commande" v-model="zone.min_amount"></el-input>
            </el-form-item>
            <el-form-item>
                <span slot='label'>Frais de livraison</span>
                <el-input placeholder="Frais de livraison" v-model="zone.price"></el-input>
            </el-form-item>
            <el-alert style="margin-bottom: 10px" :closable="false" show-icon icon="fa-info-circle" type="info" title="Toutes les distances sont calculées à vol d'oiseau"></el-alert>
            <span slot="footer" class="dialog-footer">
                <el-button type="primary" @click="save" icon='el-icon-fa-save'>Enregistrer</el-button>
                <el-button @click="close" icon='el-icon-close'>Fermer</el-button>
            </span>
    </el-dialog>
</template>

<script>
export default {
    name: 'DeliveryZoneModal',
    props: {
        visible: {
            type: Boolean
        },
        zone: {
            type: Object
        },
        zones: {
            type: Array
        }
    },
    computed: {
        existing() {
            return this.zone.index !== null
        },
        min() {
            return this.zone.min
        },
        max() {
            return this.zone.max
        }
    },
    methods: {
        close() {
            this.$emit('close')
        },
        save() {
            this.$emit('save', this.zone, this.existing)
        }
    }
}
</script>

<style>

</style>