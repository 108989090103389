<template>
    <div id="userForm">
        <el-form label-width="200px">
            <el-form-item label="Prénom *">
                <el-input @change='handleUpdate' name='uf988' v-model='user.firstname' required autocomplete="off"></el-input>
            </el-form-item>
            <el-form-item label="Nom *">
                <el-input @change='handleUpdate' name='ul988' v-model='user.lastname' required autocomplete="off"></el-input>
            </el-form-item>
            <el-form-item label="Email *">
                <el-input @change='handleUpdate' type="email" name='ue988' v-model='user.email' required autocomplete="off"></el-input>
            </el-form-item>
            <el-form-item label="Téléphone">
                <el-input @change='handleUpdate' name='ut988' v-model='user.phone' autocomplete="off"></el-input>
            </el-form-item>
            <el-form-item label="Rôle">
                <el-select v-model="user.role" placeholder="Sélectionner" @change='handleUpdate'>
                    <el-option
                        v-for="role in roles"
                        :key="role.id"
                        :label="role.label"
                        :value="role.id">
                    </el-option>
                </el-select>
            </el-form-item>
            
            <el-form-item label="Etablissement(s) rattaché(s) *">
                <!-- <el-select v-model="user.placeId" placeholder="Sélectionner" @change='handleUpdate'>
                    <el-option
                        v-for="place in places"
                        :key="place.id"
                        :label="place.name"
                        :value="place.id">
                    </el-option>
                </el-select> -->
                
                <!-- <el-checkbox-group v-model="user.placeIds" @change='handleUpdate'>
                    <el-checkbox v-for='place in places' :key='place.id' :label='place.name' :value='place.id'>{{place.name}}</el-checkbox>
                </el-checkbox-group> -->
                <label v-for='place in places' :key='place.id' >
                    <input type="checkbox" :value='place.id' :checked='user.placesIds.includes(place.id)' @change='handlePlaceSelected($event, place.id)'>
                    {{place.name}}
                </label>
            </el-form-item>
            <el-form-item label="Mot de passe *" v-if='!editingUser'>
                <el-input @change='handleUpdate' name='up988' type='password' v-model='user.password'></el-input>
            </el-form-item>
        </el-form>
    </div>
</template>

<script>
import Vuex from 'vuex'
export default {
    name: 'UserForm',
    props: {
        editingUser: {
            type: Object,
            required: false
        }
    },
    data: () => ({
        user: {
            id: '',
            firstname: '',
            lastname: '',
            email: '',
            phone: '',
            role: 'teammate',
            placesIds: []
        },
        roles: [
            {
                id: 'merchant',
                label: 'Gérant'
            },
            {
                id: 'teammate',
                label: 'Equipier'
            },
            {
                id: 'deliveryman',
                label: 'Livreur'
            }
        ]
    }),
    computed: {
        ...Vuex.mapState(['places'])
    },
    methods: {
        handleUpdate(value) {
            this.$emit('updated', this.user)
        },
        handlePlaceSelected(selected, placeId) {
            const index = this.user.placesIds.indexOf(placeId)
            if (index === -1) {
                this.user.placesIds.push(placeId)
            } else {
                this.user.placesIds.splice(index, 1)
            }
            this.handleUpdate()
        }
    },
    mounted() {
        if (this.$props.editingUser) {
            this.user = {...this.user, ...this.$props.editingUser}
        }
    }
}
</script>

<style>

</style>
