<template>
    <el-dialog
        :visible.sync="visible"
        width='55%'
        title='Créneau de commande'
        :before-close="close">
            <el-row :gutter="50">
                <el-col :span="12" :xs="24" v-if='isEditing'>
                    <el-form id="timeslotForm" label-width="150px">
                        <h3>Modifier un créneau</h3>
                        <el-form-item label="Début">
                            <el-time-select
                                placeholder="Début"
                                required
                                v-model="timeslot.start"
                                :picker-options="{
                                    start: '08:30',
                                    step: '00:15',
                                    end: '23:59',
                                    maxTime: timeslot.end && timeslot.end.length > 0 ? timeslot.end : '23:59'
                                }">
                            </el-time-select>
                        </el-form-item>
                        <el-form-item label="Fin">
                            <el-time-select
                                placeholder="Fin"
                                required
                                v-model="timeslot.end"
                                :picker-options="{
                                    start: '08:30',
                                    step: '00:15',
                                    end: '24:00',
                                    minTime: timeslot.start
                                }">
                            </el-time-select>
                        </el-form-item>
                        <!-- <el-form-item label="Commandes max.">
                            <el-input type="number" min="0" step="10" max="1000" placeholder='Nombre de commandes maximum sur ce créneau' v-model='timeslot.slots' style='width: 220px;'/>
                        </el-form-item> -->
                    </el-form>
                </el-col>
                <el-col :span="12" :xs="24" v-if='!isEditing'>
                    <h3>Générer des créneaux</h3>
                    <el-form id="timeslotGenerator" label-width="100px">
                        <el-form-item label="Durée">
                            <el-time-select
                                placeholder="Durée (minutes)"
                                required
                                v-model="generator.duration"
                                :picker-options="{
                                    start: '00:05',
                                    step: '00:05',
                                    end: '01:00'
                                }">
                            </el-time-select>
                        </el-form-item>
                        <el-form-item label="De">
                            <el-time-select
                                placeholder="De"
                                required
                                v-model="generator.from"
                                :picker-options="{
                                    start: '00:00',
                                    step: '00:15',
                                    end: '23:59',
                                    maxTime: generator.to && generator.to.length > 0 ? generator.to : '23:59'
                                }">
                            </el-time-select>
                        </el-form-item>
                        <el-form-item label="A">
                            <el-time-select
                                placeholder="A"
                                required
                                v-model="generator.to"
                                :picker-options="{
                                    start: '00:00',
                                    step: '00:15',
                                    end: '24:00',
                                    minTime: generator.from
                                }">
                            </el-time-select>
                        </el-form-item>
                    </el-form>
                </el-col>
            </el-row>
            <el-alert v-if='overlaping' type="warning" show-icon :closable='false' title="Vos créneaux se chevauchent"></el-alert>
            <span slot="footer" class="dialog-footer">
                <el-button @click="close" icon='el-icon-close'>Annuler</el-button>
                <el-button @click="save" :disabled='!formIsValid' type='primary' icon='el-icon-save'>Enregistrer</el-button>
            </span>
    </el-dialog>
</template>

<script>
import { getOverlaps, generateTimeslots } from '@/lib/Tools'


export default {
    name: 'PlaceTimeslotModal',
    props: {
        visible: {
            type: Boolean,
            required: true,
        },
        index: {
            type: Number,
            required: false
        },
        timeslots: {
            type: Array,
            required: false,
            default: () => []
        },
        timeslot: {
            type: Object,
            required: false,
            default: () => ({
                start: '',
                end: '',
                slots: 0,
                fresh: true
            })
        },
        type: {
            type: String,
            required: true
        }
    },
    data : () => ({
        generator: {
            duration: 0,
            from: null,
            to: null
        }
    }),
    computed: {
        aggregatedTimeslots() {
            const timeslots = [...this.timeslots]
            if (this.index !== null) {
                timeslots.splice(this.index, 1)
            }
            timeslots.push(this.timeslot)
            return timeslots
        },
        overlaping() {
            if (this.timeslots && this.timeslot.start && this.timeslot.end) {
                const overlaps = getOverlaps(this.aggregatedTimeslots)
                return overlaps.length > 0
            }
            return false
        },
        formIsValid() {
            return (this.timeslot.start
                && this.timeslot.end
                && this.overlaping === false)
                || (this.generator.duration && this.generator.from && this.generator.to)
        },
        isEditing() {
            return this.$props.timeslot && this.$props.timeslot.fresh !== true
        }
    },
    methods: {
        close() {
            this.$emit('close')
        },
        save() {
            if (this.generator.duration && this.generator.from && this.generator.to) {
                const timeslots = generateTimeslots(this.generator.duration, this.generator.from, this.generator.to, 70)
                if (timeslots) {
                    for (const index in timeslots) {
                        this.$emit('save', {...timeslots[index], type: this.type}, index)
                    }
                }
            } else {
                this.$emit('save', {...this.timeslot, type: this.type}, this.index)
            }
        }
    }
}
</script>

<style>

</style>
