<template>
    <div id='menuWizard' class="view">
        <h1>Configuration de votre menu</h1>
        <div class="box">
            <div class='step-navigation hidden-sm-and-down'>
                <el-steps :active="step" finish-status="success" align-center>
                    <el-step title="Catégories"></el-step>
                    <!-- <el-step title="Allergènes"></el-step> -->
                    <el-step title="Produits"></el-step>
                    <el-step title="Formules"></el-step>
                    <el-step title="Cartes"></el-step>
                </el-steps>
            </div>
            <div class='step-content'>
                <div id='step0' v-if='step === 0'>
                    <categories-wizard
                        :categories='categories'
                        @update='handleCategoryUpdated'
                        @drop='handleCategoryDropped'
                        @create='handleCategoryCreated'
                    />
                </div>
                <div id='step1' v-if='step === 1'>
                    <products-wizard
                        :products='products'
                        :categories='categories'
                        :library='imageLibrary'
                        @save='handleProductCreated'
                        @drop='handleProductDropped'
                    />
                </div>
                <div id='step2' v-if='step === 2'>
                    <assemblies-wizard
                        :products='products'
                        :categories='categories'
                        :assemblies='assemblies'
                        @save='handleAssemblySaved'
                        @drop='handleAssemblyDropped'
                    />
                </div>
                <div id='step3' v-if='step === 3'>
                    <card-wizard
                        :cards='cards'
                        :products='products'
                        :categories='categories'
                        :assemblies='assemblies'
                        @save='handleCardCreated'
                        @drop='handleCardDropped'
                    />
                </div>
                <div id='wizardFooter'>
                    <el-button type="success" @click="prev" :disabled='step === 0' icon='el-icon-arrow-left'>Précédent</el-button>
                    <el-button type="success" @click="exitWizard" v-if='step === 3'><i class="el-icon-success"></i> Terminer</el-button>
                    <el-button type="success" @click="next" v-else :disabled='!canGoNext'>Continuer <i class="el-icon-arrow-right el-icon-right"></i></el-button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import CategoriesWizard from '@/components/MenuComposer/CategoriesWizard.vue'
import ProductsWizard from '@/components/MenuComposer/ProductsWizard.vue'
import AssembliesWizard from '@/components/MenuComposer/AssembliesWizard.vue'
import CardWizard from '@/components/MenuComposer/CardWizard.vue'
import Vuex from 'vuex'
export default {
    name: 'MenuWizard',
    components: {
        CategoriesWizard,
        ProductsWizard,
        AssembliesWizard,
        CardWizard
    },
    data: () => ({
        step: 0,
        steps: [
            'categories',
            'products',
            'assemblies',
            'cards',
        ],
        imageLibrary: []
    }),
    computed: {
        ...Vuex.mapGetters(['products', 'cards', 'categories', 'assemblies']),
        canGoNext() {
            if (this.step === 0) {
                return this.categories.length > 0
            }
            if (this.step === 1) {
                return this.products.length > 0
            }
            return true
        }
    },
    mounted() {
        if (this.$route.query.step) {
            this.step = this.steps.indexOf(this.$route.query.step)
        } else {
            this.step = 0
        }

        this.$watch('$route.query.step', (step) => {
            if (step) {
                this.step = this.steps.indexOf(step)
            } else {
                this.step = 0
            }
        })
    },
    methods: {
        ...Vuex.mapActions([
            'saveStore',
            'deleteProduct',
            'deleteCategory',
            'deleteAssembly',
            'deleteCard'
        ]),
        next() {
            let nextStep = this.step + 1
            if (nextStep > 4) {
                nextStep = 0
            }
            const step = this.steps[nextStep]
            this.$router.push({name: 'app.menu.wizard', query: {step}})
        },
        prev() {
            let previousStep = this.step
            if (previousStep > 0) {
                previousStep = previousStep - 1
            }
            const step = this.steps[previousStep]
            this.$router.push({name: 'app.menu.wizard', query: {step}})
        },
        handleProductCreated(product) {
            if (this.products.indexOf(product) === -1) {
                this.products.push(product)
                product.category.addProduct(product)
            }
            this.saveStore({
                products: [ product ]
            })
        },
        handleProductDropped(product) {
            product.category.deleteProduct(product)
            this.deleteProduct(product)
            this.saveStore({
                products: this.products
            })
        },
        handleCategoryUpdated(category) {
            this.saveStore({
                categories: [ category ]
            })
        },
        handleCategoryDropped(category) {
            this.deleteCategory(category)
            this.saveStore({
                categories: this.categories
            })
        },
        handleCategoryCreated(category) {
            this.saveStore({
                categories: [category]
            })
        },
        exitWizard() {
            // this.saveStore({
            //     cards: this.cards,
            //     products: this.products,
            //     categories: this.categories,
            //     assemblies: this.assemblies
            // })
            this.$router.push({name: 'app.home'})
        },
        handleAssemblySaved(assembly) {
            if (this.assemblies.indexOf(assembly) === -1) {
                this.assemblies.push(assembly)
            }
            this.saveStore({
                assemblies: [ assembly ]
            })
        },
        handleAssemblyDropped(assembly) {
            this.deleteAssembly(assembly)
            this.saveStore({
                assemblies: this.assemblies
            })
        },
        handleCardCreated(card) {
            this.saveStore({
                cards: [card]
            })
        },
        handleCardDropped(card) {
            this.deleteCard(card)
        }
    }
}
</script>

<style lang='scss'>
#menuWizard {
    margin-bottom: 2em;
}
.step-navigation {
    margin-top: 1em;
    padding: 0 10px;
}

.step-content {
    margin-top: 1em;
    // padding: 10px;
    
    h1, h2, h3 {
        margin-top: 0;
    }
}

#wizardFooter {
    display: flex;
    margin-top: 2em;
    justify-content: space-between;
}
</style>
