<template>
    <el-dialog
        :visible.sync="visible"
        width='55%'
        title='Nouvel emplacement'
        :before-close="close">
            <el-form id="locationForm">
                <el-form-item label="Nom de l'emplacement">
                    <el-input type="text" placeholder='' v-model='location.name' required/>
                </el-form-item>
                <el-form-item label="Localisation de l'emplacement">
                    <el-input type="text" placeholder='' v-model='location.location' required/>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="close" icon='el-icon-close'>Annuler</el-button>
                <el-button @click="save" type='primary' icon='el-icon-save'>Enregistrer</el-button>
            </span>
    </el-dialog>
</template>

<script>
export default {
    name: 'PlaceLocationModal',
    props: {
        visible: {
            type: Boolean,
            required: true,
        },
        location: {
            type: Object,
            required: false,
            default: () => ({
                name: '',
                location: ''
            })
        }
    },
    methods: {
        close() {
            this.$emit('close')
        },
        save() {
            this.$emit('save', this.location)
        }
    }
}
</script>

<style>

</style>
