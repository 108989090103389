/* tslint:disable:no-console */

import { register } from 'register-service-worker';
let refreshing: boolean;
if (process.env.NODE_ENV === 'production') {
  register(`${process.env.BASE_URL}service-worker.js`, {
    ready() {
      console.log(
        'App is being served from cache by a service worker.\n' +
        'For more details, visit https://goo.gl/AFskqB',
      );
    },
    cached() {
      console.log('Content has been cached for offline use.');
    },
    updated() {
      console.log('New content is available; please refresh.');
      // alert("Une nouvelle version est disponible, merci d'actualiser cette page");
      // window.location.reload();
      if (refreshing) {
        return
      };
      refreshing = true
      window.location.reload()
    },
    offline() {
      alert('Merci de vérifier votre connexion internet');
      console.log('No internet connection found. App is running in offline mode.');
    },
    error(error) {
      console.error('Error during service worker registration:', error);
    },
  });
}
