<template>
    <div class="categoryItem" :class="{'is-editable': editable}">
        <div class="category">
            <el-button v-if='draggable' size="mini" circle type="text" style='cursor: grab;' class="category-handle"><i class="el-icon-d-caret"></i></el-button>
            <div class="category-actions">
                <el-button type="primary" title='Modifier' circle size="mini" @click='editCategory' v-if='editable && !editing' icon='el-icon-edit'/>
                <el-button type="success" title='Enregister' circle size="mini" @click='saveCategory' v-if='editing' :disabled='category.name.length === 0' icon="el-icon-check"></el-button>
            </div>
            <div class="category-name">
                <div v-if='editing'>
                    <el-input autofocus="on" @keyup.enter.native='saveCategory' @keyup.escape.native='handleEsc' ref='editCategoryInput' class="input-large" v-model='category.name' placeholder='Nom de la catégorie' required/>
                    <div v-if='displayVat'>
                        TVA
                        <el-select v-model="category.vat" placeholder="TVA" label='TVA'>
                            <el-option label="5%" value="5">5%</el-option>
                            <el-option label="10%" value="10">10%</el-option>
                            <el-option label="20%" value="20"></el-option>
                        </el-select>
                    </div>
                </div>
                <span v-else @click='editCategory' class="f5">{{category.name}}</span>
                <span v-if='withProductsCount' style="font-size: 0.8em; margin-left: 10px">{{category.products.length}} produit(s)</span>
            </div>
            <div class="category-actions" style='margin-left: auto'>
                <el-button type="danger" title='Supprimer' circle size="mini" @click='dropCategory' v-if='deletable' icon='el-icon-close'/>
            </div>
        </div>
        <div class='category-products'>
            <slot name='products'></slot>
        </div>
    </div>
</template>

<script>
export default {
    name: 'CategoryItem',
    props: {
        category: {
            type: Object,
            required: true
        },
        editable: {
            type: Boolean,
            required: false,
            default: () => false
        },
        deletable: {
            type: Boolean,
            required: false,
            default: () => false
        },
        draggable: {
            type: Boolean,
            required: false,
            default: () => false
        },
        edit: {
            type: Boolean,
            required: false,
            default: () => false
        },
        displayVat: {
            type: Boolean,
            required: false,
            default: () => false
        },
        withProductsCount: {
            type: Boolean
        }
    },
    data: () => ({
        editing: false
    }),
    mounted() {
        this.$watch('editing', (val) => {
            if (this.$refs.editCategoryInput) this.$refs.editCategoryInput.focus()
        })
        if (this.edit === true) {
            this.editing = true
        }
    },
    methods: {
        saveCategory() {
            if (this.category.name.length > 0) this.editing = false
            this.$emit('save', this.category)
        },
        editCategory() {
            this.editing = true
            this.$emit('edit', this.category)
        },
        dropCategory() {
            this.$emit('drop', this.category)
        },
        handleEsc() {
            if (this.editing && this.category.name.length === 0) {
                this.dropCategory()
            } else {
                this.editing = false
            }
        }
    }
}
</script>

<style lang='scss'>
.categoryItem {
    padding: 1em;
    border: 1px solid rgba(0, 122, 255, 0.33);
    border-radius: 5px;
    margin: 2px;
}
.category {
    display: flex;
    user-select: none;
    position: relative;
    // justify-content: space-between;

    .el-form-item {
        margin-bottom: 0;
    }

    .el-form, .el-form-item__content, .el-input, .el-input__inner {
        height: 30px;
        line-height: 30px;
    }

    .el-input__suffix {
        top: 4px;
    }
}
.category-name {
    font-weight: 600;
    line-height: 30px;
}

.category-actions {
    max-width: 150px;
    margin-right: 0.5em;
}

.edit {
    content: '\f040';
    margin-left: 5px;
    font-family: 'FontAwesome';
    cursor: pointer;
}

.category-handle {
    position: absolute;
    left: -20px;
    top: 3px;
    cursor: grab;
}
</style>
