<template>
    <div class='container is-fluid'>
        <div class="box" id="filteringBox" style="display: flex; justify-content: flex-start; flex-direction: row;">
            <div class="filter">
                <el-select placeholder="Etablissement" icon="building" class="mute" v-model='currentPlace'>
                    <el-option
                        v-for="(place, id) in merchantPlaces"
                        :value="place.id"
                        :label='place.name'
                        :key="id">
                    </el-option>
                </el-select>
            </div>
            <div class="filter">
                <el-select placeholder="Période" icon="calendar" class="mute" v-model='period'>
                    <el-option value='today' label="Aujourd'hui"></el-option>
                    <el-option value='all' label="Depuis le début"></el-option>
                    <el-option value='P1M' label="Ce mois"></el-option>
                    <el-option value='P1W' label="Cette semaine"></el-option>
                    <el-option value='specific' label="Par période"></el-option>
                </el-select>
            </div>
            <div class="filter" v-if='period === "specific"'>
                <el-date-picker
                    class="mute"
                    placeholder="Du"
                    v-model='date'
                    type="daterange"
                    format='dd/MM/yyyy'
                    :firstDayOfWeek='1'
                    icon="calendar">
                </el-date-picker>
            </div>
            <!-- <div class="filter">
                <el-date-picker
                    class="mute"
                    placeholder="Du"
                    v-model='from'
                    format='dd/MM/yyyy'
                    :firstDayOfWeek='1'
                    icon="calendar">
                </el-date-picker>
            </div>
            <div class="filter">
                <el-date-picker
                    class="mute"
                    placeholder="Au"
                    format='dd/MM/yyyy'
                    :firstDayOfWeek='1'
                    v-model='to'
                    icon="calendar">
                </el-date-picker>
            </div> -->
            <div class="ml3">
                <el-button type="branded" @click='render' icon='el-icon-fa-rocket'>
                    Générer
                </el-button>
            </div>
            <div style="display: flex; margin-left: auto;">
                <div class="ml3">
                    <el-button v-if='ready' type="primary" @click='print' icon="el-icon-fa-print">
                        Imprimer
                    </el-button>
                </div>
                <div class="ml3">
                    <el-button v-if='ready' type="primary" @click='download' icon="el-icon-fa-download">
                        Télécharger
                    </el-button>
                </div>
            </div>
        </div>
        <div id="statsContainer" ref="renderer">
            <stats-renderer v-if='ready' :placeId='currentPlace' :orders='orders' :aggregate='aggregate' :from='from' :to='to'></stats-renderer>
        </div>
    </div>
</template>

<script>
import moment from 'moment'
import Vuex from 'vuex'
import StatsRenderer from '@/components/Stats/StatsRenderer'
export default {
    name: 'StatsContainer',
    components: {
        StatsRenderer
    },
    props: [
        'aggregate',
        'orders'
    ],
    computed: {
        ...Vuex.mapState(['places']),
        merchantPlaces() {
            return [
                {name: 'Tous les établissements', id: null},
                ...Object.values(this.places)
            ]
        }
    },
    data: () => ({
        currentPlace: null,
        from: null,
        to: null,
        date: [],
        period: 'today',
        ready: false,
        stats: {},
        defaultDate: []
    }),
    methods: {
        render() {
            this.ready = false
            this.$emit('change', {
                place: this.currentPlace,
                from: this.from,
                to: this.to,
                period: this.period
            })
        },
        download() {
            this.ready = false
            this.$emit('download', {
                place: this.currentPlace,
                from: this.from,
                to: this.to,
                period: this.period
            })
        },
        async print() {
            const html = this.$refs.renderer.innerHTML
            this.$emit('print', {
                html,
                place: this.currentPlace,
                from: this.from,
                to: this.to,
                period: this.period
            })
        }
    },
    mounted() {
        this.date[0] = moment()
        this.date[0].hour(0)
        this.date[0].minute(0)
        this.date[0].second(0)
        this.date[1] = moment()
        this.date[1].hour(24)
        this.date[1].minute(59)
        this.date[1].second(59)

        this.$watch('currentPlace', (v) => {
            this.ready = false
        })
        this.$watch('date', (v) => {
            this.from = v[0]
            this.to = v[1]
        })
        this.$watch('period', (period) => {
            if (period === 'specific') {
                this.from = this.date[0]
                this.to = this.date[1]
                this.ready = false
                return
            }
            this.date[1] = moment()
            this.date[1].hour(24)
            this.date[1].minute(59)
            this.date[1].second(59)
            this.date[1].milliseconds(0)
            if (period === 'all') {
                this.date[0] = moment('1970-01-01')
            } else if (period === 'today') {
                this.date[0] = moment()
                this.date[0].hour(0)
                this.date[0].minute(0)
                this.date[0].second(0)
                this.date[0].milliseconds(0)
            } else if (period === 'P1M') {
                this.date[0] = moment()
                this.date[0].date(0)
                this.date[0].hour(0)
                this.date[0].minute(0)
                this.date[0].second(0)
                this.date[0].milliseconds(0)
            } else if (period === 'P1W') {
                this.date[0] = moment()
                this.date[0].day(1)
                this.date[0].hour(0)
                this.date[0].minute(0)
                this.date[0].second(0)
                this.date[0].milliseconds(0)
                this.date[1] = moment()
                this.date[1].day(0)
                this.date[1].add(7, 'days')
                this.date[1].hour(0)
                this.date[1].minute(0)
                this.date[1].second(0)
                this.date[1].milliseconds(0)
            } else {
                return
            }
            this.from = this.date[0].toDate()
            this.to = this.date[1].toDate()
            this.ready = false
        })
        this.$watch('aggregate', (a) => {
            if (a) {
                this.ready = true
            }
        })
    }
}
</script>

<style>

#filteringBox {
    display: flex;
    justify-content: flex-start;
}
.filter + .filter {
    margin-left: 1em;
}
.apexcharts-canvas {
    margin: auto;
}


#statsContainer table th {
    color: #363636;
}
</style>