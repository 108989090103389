<template>
<div id="placeEdit" class="view">
    <div style="float: right; margin-top: 15px; margin-bottom: 10px;">
        <el-button icon='el-icon-fa-window-close' v-if='place.status === "open"' size="small" type='danger' @click='closePlace' title="Fermer la prise de commande">Fermer la prise de commande</el-button>
        <el-button icon='el-icon-fa-rocket' v-if='place.status === "closed"' size="small" type='success' @click='openPlace' title="Ouvrir la prise de commande">Ouvrir la prise de commande</el-button>
        <el-button type="info" size="small" icon='el-icon-fa-eye' @click='previewPlace'>Aperçu du mini-site</el-button>
        <el-button type="primary" icon='el-icon-fa-save' @click='handlePlaceUpdated'>Enregistrer</el-button>
    </div>
    <h2>Détails de l'établissement {{place.name}}</h2>
    <div class='view-nav'>
        <el-breadcrumb separator="/">
            <el-breadcrumb-item :to='{ name: "app.home"} '>{{merchant.name}}</el-breadcrumb-item>
            <el-breadcrumb-item :to='{ name: "app.places" }'>Etablissements</el-breadcrumb-item>
            <el-breadcrumb-item>{{place.name}}</el-breadcrumb-item>
        </el-breadcrumb>
    </div>
    <el-tabs v-model="tab" type="border-card" @tab-click="handleTabSelected">
        <el-tab-pane name="ordering">
            <span slot="label"><i class="el-icon-fa-shopping-basket"></i> Prise de commande</span>
            <place-ordering-form :place='place' @settingsUpdated='handleSettingsUpdated'></place-ordering-form>
        </el-tab-pane>
        <el-tab-pane name='display'>
            <span slot="label"><i class="el-icon-fa-paint-brush"></i> Apparence du module de commande</span>
            <div class="notification is-primary">
                <i class="el-icon-fa-info-circle"></i>
                Les informations suivantes sont publiques et seront affichées sur votre module de commande
            </div>
            <place-display-form
                :place='place'
                @settingsUpdated='handleSettingsUpdated'
                @infosUpdated='handleInfosUpdated'
                @socialUpdated='handleSocialUpdated'
                @hoursUpdated='handleHoursUpdated'
                @colorsUpdated='handleColorsUpdated'
            ></place-display-form>
        </el-tab-pane>
        <!-- <el-tab-pane name='team'>
            <span slot="label"><i class="el-icon-fa-users"></i> Equipe</span>
            <el-row :gutter="10">
                <el-col :span="12" :xs="24">
                    <h4>Gestion de votre équipe</h4>
                </el-col>
            </el-row>
        </el-tab-pane> -->
        <!-- <el-tab-pane name='cards'>
            <span slot="label"><i class="el-icon-fa-list-alt"></i> Cartes</span>
            <el-row :gutter="10">
                <el-col :span="12" :xs="24">
                    <h4>Liste des cartes</h4>
                    <card-list :displayAddButton='false' :displayTitle='false'/>
                </el-col>
                <el-col :span="12" :xs="24">
                    <h4>Cartes associées à l'établissement</h4>
                    <el-alert type="info" title="Aucune carte à associer" v-if='cards.length === 0'></el-alert>
                    <el-checkbox v-for='card in cardsList' :key='card.key' @change='toggleCard(card)' :value="form.cards.indexOf(card.key) > -1" :label='card.label' border></el-checkbox>
                </el-col>
            </el-row>
        </el-tab-pane> -->
        <el-tab-pane name="settings">
            <span slot="label"><i class="el-icon-fa-gears"></i> Paramètres</span>
            <place-settings-form :place='place' @settingsUpdated='handleSettingsUpdated'></place-settings-form>
        </el-tab-pane>
    </el-tabs>
    
    
    <el-dialog
        :visible.sync="view.previewSite"
        width='55%'
        title='Aperçu du module de commande'
        >
            <iframe v-if='placeUrl' frameborder="0" height="500px" width="100%" :src='placeUrl'></iframe>
            <span slot="footer" class="dialog-footer">
                <el-button @click="view.previewSite = false" icon='el-icon-close'>Fermer</el-button>
            </span>
    </el-dialog>
    
    

    
    
</div>
</template>

<script>
import Vuex from 'vuex'
import { updatePlaceStatus } from '@/lib/Api'
import { parseAddress } from '@/lib/Tools'
import PlaceOrderingForm from '@/components/Place/PlaceOrderingForm'
import PlaceSettingsForm from '@/components/Place/PlaceSettingsForm'
import PlaceDisplayForm from '@/components/Place/PlaceDisplayForm'
import PlaceLocation from '@/components/Place/PlaceLocation'

import CardList from '@/views/Menus/CardList'

export default {
    name: 'PlaceEdit',
    components: {
        CardList,
        PlaceLocation,
        PlaceOrderingForm,
        PlaceDisplayForm,
        PlaceSettingsForm
    },
    data: () => ({
        tab: 'ordering',
        loading: false,
        // DEFAULT SETTINGS
        settings: {
            display: {
                title: 'Menu',
                subtitle: 'Découvrez notre menu',
                displayHeader: true,
                displayAside: true
            },
            notifications: {
                sms: true,
            },
            processing: {},
            ordering: {
                directOrdering: true,
                preOrdering: false,
            },
            payment: {
                creditCard: true
            },
            fees: {}
        },
        form: {
            cards: []
        },
        social: {},
        colors: {},
        locations: [],
        hours: [],
        selectedCard: null,
        view: {
            previewModal: false,
            shareModal: false,
            locationForm: false,
            hourForm: false,
            previewSite: false
        }
    }),
    computed: {
        ...Vuex.mapState(['places', 'merchant', 'store', 'integrations']),
        ...Vuex.mapGetters(['cards']),
        place() {
            return this.places.find((place) => place.id === this.$route.params.id)
        },
        cardsList() {
            return this.cards.map((card, index) => ({
                key: card.id,
                label: card.name
            }))
        },
        placeUrl() {
            if (this.integrations && this.integrations.places && this.integrations.places[this.place.id]) {
                return this.integrations.places[this.place.id]
                    .find((integration) => integration.type === 'iframe').url
            }
        },
    },
    mounted() {
        this.tab = this.$route.query.tab || 'ordering'
        this.$watch('$route.query', (query) => {
            if (this.tab !== query.tab) {
                this.tab = query.tab
            }
        })

        const { id, name, description, phone, email, logoUrl, cards } = this.place
        this.form = {
            id,
            name,
            description,
            phone,
            email,
            logoUrl,
            cards,
            address: this.place.address
        }
        this.hours = this.place.hours || []
        this.settings = {
            ...this.settings,
            ...this.place.settings
        }

        this.colors = {
            ...this.place.colors
        }

        this.social = {
            ...this.place.social
        }

        this.locations = this.place.locations || []
    },
    methods: {
        ...Vuex.mapActions(['updatePlace']),
        previewPlace() {
            this.view.previewSite = true
        },
        handleTabSelected(tab) {
            this.$router.push({query: {tab: tab.name}})
        },
        toggleCard(card) {
            const index = this.form.cards.indexOf(card.key)
            if (index === -1) {
                this.form.cards.push(card.key)
            } else {
                this.form.cards.splice(index, 1)
            }
        },
        handleSettingsUpdated(settings) {
            this.settings = {
                ...this.settings,
                ...settings
            }
        },
        handleInfosUpdated(infos) {
            this.form = {
                ...this.form,
                ...infos
            }
        },
        handleHoursUpdated(hours) {
            this.hours = hours
        },
        handleColorsUpdated(colors) {
            if (colors.headerBackground.hex) {
                colors.headerBackground = colors.headerBackground.hex
            }
            if (colors.secondaryColor.hex) {
                colors.secondaryColor = colors.secondaryColor.hex
            }
            this.colors = {
                ...this.colors,
                ...colors
            }
        },
        handleSocialUpdated(social) {
            // Cleanup
            if (social.facebook) {
                social.facebook = social.facebook.replace('https://www.facebook.com/', '')
                    .replace(/\//g, '').trim()
            }
            if (social.twitter) {
                social.twitter = social.twitter.replace('https://www.twitter.com/', '')
                    .replace(/\//g, '').replace('@', '').trim()
            }
            this.social = {
                ...this.social,
                ...social
            }
        },
        handlePlaceUpdated() {
            // Reassigning new values
            this.place.hours = this.hours
            this.place.locations = this.locations
            this.place.social = { ...this.social }
            this.place.settings = { ...this.settings }
            this.place.colors = { ...this.colors }
            this.place.phone = this.form.phone
            this.place.email = this.form.email
            this.place.location = parseAddress(this.form.address)
            this.place.description = this.form.description
            this.place.name = this.form.name
            this.place.logoUrl = this.form.logoUrl

            try {
                this.updatePlace(this.place)
                this.$message({
                    message: 'Modifications enregistrées',
                    type: 'success'
                })
            } catch (err) {
                this.$message({
                    message: 'Une erreur est survenue, merci de réessayer',
                    type: 'error'
                })
            }
        },
        openPlace() {
            updatePlaceStatus(this.place, 'open')
            this.place.status = 'open'
            this.$message({
                type: 'success',
                message: 'Prise de commande activée'
            })
        },
        closePlace() {
            updatePlaceStatus(this.place, 'closed')
            this.place.status = 'closed'
            this.$message({
                type: 'warning',
                message: 'Prise de commande désactivée'
            })
        }
    }
}
</script>

<style>

</style>
