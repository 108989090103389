<template>
    <div class="control is-flex">
        <el-table
            :data="orders"
            :paginated="false"
            :per-page="100"
            :current-page.sync="currentPage"
            :pagination-simple="true"
            aria-next-label="Next page"
            aria-previous-label="Previous page"
            aria-page-label="Page"
            aria-current-label="Current page">

            <el-table-column prop="paymentMethod" label="Paiement" sortable></el-table-column>
            <el-table-column prop="placeId" label="Etablissement" sortable>
               <template slot-scope="scope">
                {{ placesHash[scope.row.placeId].name }}
                </template>
            </el-table-column>
            <el-table-column prop="status" label="Statut" sortable>
                <template slot-scope="scope">
                    {{scope.row.status}}
                </template>
            </el-table-column>
            <el-table-column prop="createdAt" label="Date" sortable centered>
                <template slot-scope="scope">
                    {{new Date(scope.row.createdAt).toLocaleDateString()}}
                </template>
            </el-table-column>
            <el-table-column prop="total" label="Total" sortable numeric>
                <template slot-scope="scope">
                {{ scope.row.total | EUR }}
                </template>
            </el-table-column>
            <el-table-column label="Client">
                <template slot-scope="scope">
                    {{ scope.row.customer.firstname }}
                </template>
            </el-table-column>
        </el-table>
    </div>
</template>

<script>
import Vuex from 'vuex'
export default {
    name: 'OrdersTable',
    props: [
        'orders',
        'perPage',
    ],
    computed: {
        ...Vuex.mapState(['places']),
        placesHash() {
            const hash = {}
            for (const p of this.places) {
                hash[p.id] = p
            }
            return hash
        }
    },
    data: () => ({
        currentPage: 1
    })
}
</script>

<style>

</style>