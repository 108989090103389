<template>
    <el-dialog
        :visible.sync="visible"
        width='55%'
        :title='title'
        :before-close="closeModal"
        >
            <el-form label-width="170px">
                <el-form-item label="Order d'affichage">
                    <el-input type="number" min="0" placeholder='Index' v-model='index'/>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button type="primary" @click="save" icon='el-icon-circle-check-outline'>Enregistrer</el-button>
                <el-button @click="closeModal" icon='el-icon-close'>Fermer</el-button>
            </span>
    </el-dialog>
</template>

<script>
import Vuex from 'vuex'
import Card from '@/lib/entities/Card'
export default {
    name: 'CardIndexModal',
    props: {
        card: {
            type: Card,
            required: true
        },
        visible: {
            type: Boolean,
            required: true
        }
    },
    data: () => ({
        index: null
    }),
    mounted() {
        if (this.card.index) {
            this.index = this.card.index
        }
    },
    computed: {
        title() {
            return `Ordre d'affichage de la carte "${this.card.name}"`
        }
    },
    methods: {
        closeModal() {
           this.$emit('close')
        },
        save() {
            this.card.index = this.index
            this.card.save().then(() => {
                this.$message({
                    message: 'Mise à jour de la carte réalisée avec succès',
                    type: 'success'
                })
            }).catch((err) => {
                this.$message({
                    message: 'Une erreur est survenue, merci de réessayer',
                    type: 'error'
                })
            })
            this.closeModal()
        }
    }
}
</script>

<style>

</style>
