<template>
  <div class="modal" :class='{"is-active": visible}'>
    <div class="modal-background" style="background-color: black"></div>
    <div class="modal-card">
      <header class="modal-card-head">
        <p class="modal-card-title">Sélection d'une image <span v-if='item.name'> pour <strong>{{item.name}}</strong></span></p>
        <button class="delete" aria-label="close" @click="closeModal()"></button>
      </header>
      <section class="modal-card-body">
        <image-picker :library='library' @select='select'/>
      </section>
      <footer class="modal-card-foot">
        <button class="button is-success" @click="save()">
            <span class="icon is-small"><i class="fa fa-plus-circle"></i></span>
            <span>Sélectionner</span>
        </button>
        <button class="button is-default" @click="closeModal()">
            <span class="icon is-small"><i class="fa fa-times-circle"></i></span>
            <span>Fermer</span>
        </button>
      </footer>
    </div>
  </div>
</template>

<script>
import ImagePicker from './ImagePicker'
export default {
  name: 'ImagePickerModal',
  components: {
    ImagePicker
  },
  props: {
    item: {
      required: true,
      type: Object
    },
    visible: {
      required: true,
      type: Boolean,
      default: false
    },
    library : {
      required: true,
      type: Array
    }
  },
  data: () => ({
    saving: false,
    query: ''
  }),
  methods: {
    closeModal() {
      this.$emit('close')
    },
    select(image) {
      this.$emit('select', image)
      this.closeModal()
    }
  }
}
</script>

<style lang="css">


</style>
