import Parse from 'parse'
import Card from './Card'
import Category from './Category'
import Assembly from './Assembly'
import Product from './Product'
import Place from './Place'
import Merchant from './Merchant'
import {
    updateProductStatus,
    updateCardProductStatus,
    updateCardProductItemStatus,
    updateCardProductStock,
    updateCardProductPrice,
    getPlaces,
    deletePlace,
    createPlace
} from '../Api'
import { DEFAULT_CATEGORIES } from '@/lib/entities/default'
import ProductableEntity from './ProductableEntity'

export default class Store {

    /**
     * Build a new store from database objects
     */
    public static async boot(): Promise<Store> {
        const user = Parse.User.current()
        if (!user) {
            throw new Error('UNAUTHENTICATED')
        }
        const merchant = user.get('merchant')
        const store = new Store(user)
        store.setMerchant(merchant)

        try {
            // Associating places
            await store.getPlaces()

            // Fetching categories
            await store.getCategories()

            // Fetching products
            await store.getProducts()

            // Fetching assemblies
            await store.getAssemblies()

            // Fetching cards
            await store.getCards()
        } catch (err) {
            console.error(err)
            //
        }

        return store
    }

    public categories: Category[] = []
    public products: Product[] = []
    public places: Place[] = []
    public assemblies: Assembly[] = []
    public cards: Card[] = []
    public merchant?: Merchant
    public acl: Parse.ACL
    public user?: Parse.User

    constructor(user?: Parse.User) {
        this.acl = new Parse.ACL()
        if (user) {
            this.user = user
            this.acl.setReadAccess(user.id, true)
            this.acl.setWriteAccess(user.id, true)
        }
    }

    public setMerchant(merchant: Merchant) {
        this.merchant = merchant
    }

    public async getPlaces() {
        let places = await getPlaces()
        places = places.sort((a, b) => b.index - a.index)
        this.places = places
        return places
    }

    public async createPlace(data: any) {
        const placeModel = await createPlace(data)
        const place = await Place.boot(placeModel)
        this.places.push(place)
        return place
    }

    public setPlaces(places: Place[]) {
        this.places = places
    }

    public deletePlace(place: Place) {
        const index = this.places.indexOf(place)
        if (index > -1) {
            for (const card of place.cards) {
                card.setPlace(null)
            }
            this.places.splice(index, 1)
            deletePlace(place)
        }
    }

    public async save({ cards, products, categories, assemblies }: {
        cards?: Card[],
        products?: Product[],
        categories?: Category[],
        assemblies?: Assembly[]
    }) {

        if (cards) {
            for (const card of cards) {
                card.setStore(this)
                await card.save()
            }
        }
        if (products) {
            for (const product of products) {
                product.setStore(this)
                await product.save()
            }
        }
        if (categories) {
            for (const category of categories) {
                if (category) {
                    category.setStore(this)
                    await category.save()
                }
            }
        }
        if (assemblies) {
            for (const assembly of assemblies) {
                assembly.setStore(this)
                await assembly.save()
            }
        }
    }

    public async getCards() {
        const cardsQuery = new Parse.Query('Card')
        cardsQuery.equalTo('merchant', this.merchant)
        const cards = await cardsQuery.find()
        this.cards = cards.map((card) => Card.boot(card, this))
        return this.cards
    }

    public setCards(cards: Card[]) {
        this.cards = cards
    }

    public async getProducts() {
        const productsQuery = new Parse.Query('Product')
        productsQuery.equalTo('merchant', this.merchant)
        productsQuery.limit(1000)
        const products = await productsQuery.find()
        this.products = products.map((product) => Product.boot(product, this))
        return this.products
    }

    public deleteProduct(product: Product) {
        const index = this.products.indexOf(product)
        if (index > -1) {
            this.products.splice(index, 1)
            product.delete()
        }
    }

    public addProduct(product: Product) {
        const index = this.products.indexOf(product)
        if (index === -1) {
            product.setStore(this)
            product.save()
            if (product.category) {
                product.category.addProduct(product)
                product.category.save()
            }
            this.products.push(product)
        }
    }

    public updateProductStatus(product: Product, status: string) {
        product.status = status
        updateProductStatus(product, status)
    }

    public updateCardProductPrice(card: Card, product: ProductableEntity, price: number) {
        updateCardProductPrice(this.merchant, card, product, price)
    }

    public updateCardProductStock(card: Card, product: ProductableEntity, stock: number) {
        updateCardProductStock(this.merchant, card, product, stock)
    }

    public updateCardProductStatus(card: Card, product: ProductableEntity, status: string) {
        updateCardProductStatus(this.merchant, card, product, status)
        card.status[product.id] = status // @version 2020-12-30
    }
    
    public updateCardProductItemStatus(card: Card, key: string, status: string) {
        updateCardProductItemStatus(this.merchant, card, key, status)
        card.status[key] = status // @version 2020-12-30
    }

    public setProducts(products: Product[]) {
        this.products = products
    }

    public async getCategories() {
        const categoryQuery = new Parse.Query('Category')
        categoryQuery.equalTo('merchant', this.merchant)
        categoryQuery.limit(1000)
        const categories = await categoryQuery.find()
        if (categories.length > 0) {
            this.categories = categories
                .map((category) => Category.boot(category, this))
                .sort((a, b) => a.index - b.index)
        } else {
            // tslint:disable-next-line:forin
            for (const i in DEFAULT_CATEGORIES) {
                const category = new Category(DEFAULT_CATEGORIES[i])
                category.setStore(this)
                // tslint:disable-next-line:radix
                category.index = parseInt(i)
                await category.save()
                this.categories.push(category)
            }
        }
        return this.categories
    }

    public setCategories(categories: Category[]) {
        // tslint:disable-next-line:forin
        for (let i = 0; i < categories.length; i++) {
            const category = categories[i]
            category.index = i
            category.save()
        }
        this.categories = categories
    }

    public addCategory(category: Category) {
        const index = this.categories.indexOf(category)
        if (index === -1) {
            this.categories.push(category)
            category.save()
        }
    }

    public deleteCategory(category: Category) {
        const index = this.categories.indexOf(category)
        if (index > -1) {
            this.categories.splice(index, 1)
            category.delete()
        }
    }

    public addCard(card: Card) {
        this.cards.push(card)
        card.save()
    }

    public async deleteCard(card: Card) {
        const index = this.cards.findIndex((c) => c.id === card.id)
        if (index > -1) {
            await card.delete()
            this.cards.splice(index, 1)
            return true
        }
        return false
    }

    public async getAssemblies() {
        const assembliesQuery = new Parse.Query('Assembly')
        assembliesQuery.equalTo('merchant', this.merchant)
        const assemblies = await assembliesQuery.find()
        this.assemblies = assemblies.map((assembly) => Assembly.boot(assembly, this))
        return this.assemblies
    }

    public async getAssembly(id: string): Promise<Assembly> {
        const q = new Parse.Query('Assembly')
        const data = await q.get(id)
        return Assembly.boot(data, this)
    }

    public addAssembly(assembly: Assembly) {
        if (this.assemblies.indexOf(assembly) === -1) {
            this.assemblies.push(assembly)
        }
    }

    public setAssemblies(assemblies: Assembly[]) {
        this.assemblies = assemblies
    }

    public async deleteAssembly(assembly: Assembly) {
        const index = this.assemblies.findIndex((a) => a.id === assembly.id)
        if (index > -1) {
            await assembly.delete()
            this.assemblies.splice(index, 1)
            return true
        }
        return false
    }

}
