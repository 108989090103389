<template>
    <div class="landing">
        <div class='full-box'>
            <router-view></router-view>
        </div>
    </div>
</template>

<script>
export default {
  name: 'AuthView'
}
</script>

<style>

</style>
