<template>
    <el-dialog
        :title="title"
        :visible.sync="visible"
        :fullscreen='true'
        :append-to-body='appendToBody'
        :before-close="handleCloseModal">
            <template slot="title">
                <h2 class="subtitle" v-if='isEditing'><i class="fa fa-pencil"></i>&nbsp;Modification du produit</h2>
                <h2 class="subtitle" v-else><i class="fa fa-pencil"></i>&nbsp;Création d'un produit</h2>
            </template>
            <div v-if='suggestedProducts.length > 0 && view.suggestions' class="suggested-products">
                <el-button type="text" circle size="mini" icon="el-icon-close" style="margin-left: auto; float: right" @click='hideSuggestionBox'/>
                <h3>Suggestion de produits</h3>
                <div class='products-grid'>
                    <product-picker
                        v-for='product in suggestedProducts'
                        :key='product.id'
                        :product='product'
                        @select='selectSuggestedProduct(product)'/>
                </div>
            </div>
            <product-form
                @categoryUpdated='handleCategoryUpdated'
                :product='product'
                :category='category'
                :categories='categories'
                :pricable='pricable'
                :existingProducts='existingProducts'
                :library='library'
                :isEditing='isEditing'>
            </product-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="closeModal" icon='el-icon-close'>Fermer</el-button>
                <el-button type="primary" :disabled="!productIsValid" @click="saveAndClose()" icon='el-icon-circle-check-outline'>Enregistrer</el-button>
            </span>
    </el-dialog>
</template>

<script>
import ProductForm from './ProductForm'
import CardProduct from '@/lib/entities/CardProduct'
import Product from '@/lib/entities/Product'
import Category from '@/lib/entities/Category'
import ProductPicker from './ProductPicker'
export default {
    name: 'ProductFormModal',
    components: {
        ProductForm,
        ProductPicker
    },
    props: {
        categories: {
            type: Array,
            required: false
        },
        product: {
            type: CardProduct|Product,
            required: true
        },
        category: {
            type: Category,
            required: false
        },
        isEditing: {
            type: Boolean,
            required: false,
            default: () => false
        },
        library: {
            type: Array,
            required: false
        },
        existingProducts: {
            type: Array,
            required: false
        },
        visible: {
            type: Boolean,
            required: true,
            default: () => false
        },
        pricable: {
            type: Boolean,
            required: false,
            default: () => false
        },
        appendToBody: {
            type: Boolean,
            required: false,
            default: () => true
        }
    },
    computed: {
        title() {
            return this.isEditing ? 'Modification du produit' : 'Création d\'un produit'
        },
        productIsValid() {
            if (this.isEditing) {
                return this.product.hasValidProps()
            } else {
                return this.product.hasValidProps() && this.selectedCategory !== null
            }
        }
    },
    data: () => ({
        selectedCategory: null,
        suggestedProducts: [],
        view: {
            suggestions: false
        }
    }),
    mounted() {
        if (this.isEditing) {
            this.view.suggestions = false
        } else if (this.category) {
            this.view.suggestions = true
            this.category.getSuggestedProducts().then((products) => {
                this.suggestedProducts = products
            })
        }
    },
    methods: {
        saveAndClose() {
            this.$emit('save', this.product, this.category)
            this.selectedCategory = null
            this.closeModal()
        },
        closeModal() {
            this.$emit('close')
        },
        handleCloseModal() {
            if (this.product.hasValidProps()) {
                this.$confirm('Etes-vous certain de fermer cette fenêtre ? Les modifications ne seront pas enregistrées.', 'Attention', {
                    type: 'warning',
                    confirmButtonText: 'Oui',
                    cancelButtonText: 'Annuler',
                })
                .then((_) => {
                    this.closeModal()
                })
                .catch((err) => {
                    error(err)
                })
            } else {
                this.closeModal()
            }
        },
        hideSuggestionBox() {
            this.view.suggestions = false
        },
        selectSuggestedProduct(selectedProduct) {
            this.$emit('suggestionSelected', selectedProduct, this.category)
            this.hideSuggestionBox()
        },
        handleCategoryUpdated(product, category) {
            this.selectedCategory = category
            this.$emit('categoryUpdated', product, category)
        }
    }
}
</script>

<style lang='scss'>
.suggested-products {
    .products-grid {
        display: flex;
        align-items: start;
    }
    padding: 1em;
    margin-bottom: 1em;
    border: 1px solid #e0e3e9;
    border-radius: 5px;
}
</style>
