import Entity from '@/lib/entities/Entity'
import Product from '@/lib/entities/Product'
import Category from '@/lib/entities/Category'
import Store from '@/lib/entities/Store'
import ProductableEntity from './ProductableEntity'
import Parse from 'parse'
// tslint:disable-next-line:max-line-length
const DEFAULT_ASSEMBLY_IMAGE = 'https://assets.sioupla.it/product/5bc75cb2e1396/small/small_tx3Y5y.png'

export default class Assembly extends Entity implements ProductableEntity {

    public static boot(model: Parse.Object, store: Store) {
        const preparedProps = {
            name: model.get('name'),
            description: model.get('description'),
            image: model.get('image'),
            vat: model.get('vat'),
            optionals: model.get('optionals'),
            multiples: model.get('multiples'),
            indexes: model.get('indexes'),
            settings: model.get('settings'),
            items: [] as any[]
        }

        if (model.has('items') && model.get('items').length ) {
            for (const i of model.get('items')) {
                const category = store.categories.find((cat) => cat.id === i.categoryId)
                if (category) {
                    const assemblyItem = {
                        category: {...category},
                        products: [] as Product[]
                    }
                    if (i.products.length) {
                        for (const pid of i.products) {
                            const product = store.products.find((prod) => prod.id === pid)
                            if (product) {
                                assemblyItem.products.push(product)
                            }
                        }
                    }
                    // @version 2020-06-08
                    if (assemblyItem.products.length > 0) {
                        preparedProps.items.push(assemblyItem)
                    }
                }
            }
        }

        const assembly = new Assembly(preparedProps)
        assembly.setModel(model)

        return assembly
    }

    public name: string = ''
    public description: string = ''
    public vat?: number = 10
    public optionals: any = {}
    public multiples: any = {}
    public indexes: any = {}
    public settings: any = {}
    public override = false
    public products: Product[] = []
    public categories: Category[] = []
    public items: any[] = []
    public image: string = DEFAULT_ASSEMBLY_IMAGE

    constructor(props: any = {}) {
        super()
        if (props.name) {
            this.name = props.name
        }
        if (props.vat !== undefined) {
            this.vat = props.vat
        }
        if (props.description) {
            this.description = props.description
        }
        if (props.products) {
            this.products = props.products
        }
        if (props.categories) {
            this.categories = props.categories
        }
        if (props.optionals) {
            this.optionals = props.optionals
        }
        if (props.multiples) {
            this.multiples = props.multiples
        }
        if (props.indexes) {
            this.indexes = props.indexes
        }
        if (props.items) {
            this.items = props.items
            for (const item of props.items) {
                this.addCategory(item.category)
                for (const product of item.products) {
                    this.addProduct(product)
                }
            }
        }
        if (props.image) {
            this.image = props.image
        }

        if (props.settings) {
            this.settings = props.settings
        }
    }

    /**
     *
     * @param name
     */
    public setName(name: string) {
        this.name = name
    }

    /**
     *
     * @param category
     */
    public addCategory(category: Category) {
        if (this.categories.indexOf(category) === -1) {
            this.categories.push(category)
        }
    }

    /**
     *
     * @param category
     */
    public removeCategory(category: Category) {
        const index = this.categories.indexOf(category)
        if (index > -1) {
            this.categories.splice(index, 1)
        }
    }

    /**
     * Add the specified product
     * @param product
     */
    public addProduct(product: Product) {
        if (this.products.indexOf(product) === -1) {
            this.products.push(product)
        }
    }

    /**
     * Remove the specified product
     * @param product
     */
    public removeProduct(product: Product) {
        const index = this.products.indexOf(product)
        if (index > -1) {
            this.products.splice(index, 1)
        }
    }

    public isExternal() {
        return this.settings && this.settings.externalId
    }

    public hasValidProps(): boolean {
        return true
    }

    public async save() {
        if (!this.model) {
            this.model = new Parse.Object('Assembly')
        }
        if (this.store) {
            this.model.set('merchant', this.store.merchant)
        }

        this.model.set('name', this.name)
        this.model.set('description', this.description)
        this.model.set('image', this.image)
        this.model.set('vat', this.vat)
        this.model.set('optionals', this.optionals)
        this.model.set('multiples', this.multiples)
        this.model.set('indexes', this.indexes)
        this.model.set('settings', this.settings)

        if (this.settings.hasOwnProperty("externalProvider") == false || this.settings.externalProvider == null) {
            const items = []
            for (const cat of this.categories) {
                let products = [] as any[]

                items.push({
                    categoryId: cat.id,
                    index: cat.index,
                    products
                })
            }
            for (const prod of this.products) {
                if (prod.category) {
                    const cid = prod.category.id
                    let assemblyCat : any = items.find((c) => c.categoryId === cid)
                    if (assemblyCat == null) {
                        assemblyCat = {
                            categoryId: cid,
                            index: 0,
                            products: []
                        }
                        assemblyCat.products.push(prod.id)
                        items.push(assemblyCat)
                    } else {
                        assemblyCat.products.push(prod.id)
                    }
                }
            }
            this.model.set('items', items)
        }
        
        await this.model.save()
        this.setId(this.model.id)
    }

    public async delete() {
        if (this.model) {
            this.model.destroy()
        }
    }
}
