import Vue from 'vue'
import Dispatcher from './Dispatcher.vue'
import router from '@/router'
import store from '@/store'
import ElementUI from 'element-ui'
import { locale } from '@/i18n'
import Parse from 'parse'
import Raven from 'raven-js'
import '@/registerServiceWorker'
import './assets/siouplait.scss'

if (process.env.NODE_ENV === 'production') {
  Raven
    .config('https://d4c153e11a1841d2b88ad20ae630d10d@sentry.io/1442050')
    .install()
}

Parse.initialize('svp')
// @ts-ignore
Parse.serverURL = process.env.VUE_APP_API_URL

Vue.use(ElementUI, { locale })
Vue.config.productionTip = false

Vue.filter('EUR', (value: string|number) => {
  if (!value) {
    value = 0
  }
  value = Number(value)
  return value.toLocaleString('fr-FR', { style: 'currency', minimumFractionDigits: 2, currency: 'EUR' })
})

new Vue({
  router,
  store,
  render: (h) => h(Dispatcher),
  name: 'Siouplait'
}).$mount('#mgmt')
