<template>
    <div class="category-picker" :class="{'selected': selected}" @click='handleSelectCategory'>
        <el-card :body-style="{ padding: '0px' }" shadow='hover'>
            <div style="padding: 5px; text-align: center;">
                <span style="font-size: 1.1em; font-weight: 600;">{{category.name}}</span>
                <div class="bottom clearfix">
                    <el-button v-if='!selected' type="text" class="button">Sélectionner</el-button>
                    <el-button v-else size="big" type="text" class="button" icon="el-icon-success"></el-button>
                </div>
            </div>
        </el-card>
    </div>
</template>

<script>
import Category from '@/lib/entities/Category'
export default {
    name: 'CategoryPicker',
    props: {
        category: {
            type: Category,
            required: true
        },
        selected: {
            type: Boolean,
            required: false,
            default: () => false
        },
        index: {
            type: Number,
            required: false
        }
    },
    data: () => ({
        categoryIndex: null
    }),
    methods: {
        handleSelectCategory() {
            this.$emit('select', this.category)
        },
        handleIndexChanged(index) {
            this.$emit('index', { category: this.category, index })
        }
    },
    mounted() {
        this.categoryIndex = this.index
    }
}
</script>

<style lang="scss">
.category-picker {
    cursor: pointer;
    border-radius: 3px;
    transition: all 250ms ease;
    user-select: none;
    .el-card {
        height: 80px;
        width: 120px;
        min-height: 80px;
    }
}

.category-picker.selected {
    .el-card {
        border: 1px solid #007AFF; // Use variables
    }
}
</style>
