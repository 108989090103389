<template>
    <div class='view'>
        <h1>Gestion des Produits</h1>
        <el-tabs v-model="tab" type="border-card" @tab-click="handleTabSelected">
            <el-tab-pane label="Cartes" name='cards'>
                <card-list @update='displaySuccessMessage'/>
            </el-tab-pane>
            <el-tab-pane label="Produits" name='products'>
                <product-list @update='displaySuccessMessage'/>
            </el-tab-pane>
            <el-tab-pane label="Catégories" name='categories'>
                <category-list @update='displaySuccessMessage'/>
            </el-tab-pane>
            <el-tab-pane label="Formules" name='assemblies'>
                <assembly-list @update='displaySuccessMessage'/>
            </el-tab-pane>
            <!-- <el-tab-pane label="Stock" name='stock'></el-tab-pane> -->
            <!-- <el-tab-pane label="Statistiques" name='stats'></el-tab-pane> -->
        </el-tabs>
        <!-- <p style='margin-top: 1rem;'>
            <el-button type="primary" icon='el-icon-fa-save' @click='save'>Enregistrer</el-button>
        </p> -->
    </div>
</template>

<script>
import Vuex from 'vuex'
import ProductList from '@/views/Menus/ProductList.vue'
import CategoryList from '@/views/Menus/CategoryList.vue'
import CardList from '@/views/Menus/CardList.vue'
import AssemblyList from '@/views/Menus/AssemblyList.vue'

export default {
    name: 'Menus',
    components: {
        ProductList,
        CategoryList,
        AssemblyList,
        CardList
    },
    data: () => ({
        tab: 'cards',
    }),
    computed: {
        ...Vuex.mapState(['store']),
        ...Vuex.mapGetters(['cards', 'products', 'categories', 'assemblies'])
    },
    methods: {
        ...Vuex.mapActions(['saveStore', 'getStore']),
        async save() {
            try {
                await this.saveStore({
                    products: this.products
                })
                await this.saveStore({
                    categories: this.categories
                })
                await this.saveStore({
                    assemblies: this.assemblies,
                })
                await this.saveStore({
                    cards: this.cards
                })
                this.displaySuccessMessage()
            } catch (err) {
                this.displayErrorMessage(err)
            }
        },
        displaySuccessMessage() {
            this.$message({
                type: 'success',
                message: 'Modifications enregistrées'
            })
        },
        displayErrorMessage(message) {
            this.$message({
                type: 'error',
                message: 'Une erreur est survenue, merci de réessayer'
            })
        },
        handleTabSelected(tab, event) {
            this.$router.push({query: {tab: tab.name}})
        }
    },
    mounted() {
        this.tab = this.$route.query.tab || 'cards'
        this.getStore()
        this.$watch('$route.query', (query) => {
            if (this.tab !== query.tab) {
                this.tab = query.tab
            }
        })
    }
}
</script>

<style>

</style>
