<template>
    <div id='menusCategoryList'>
        <el-button type="primary" size="small" icon='el-icon-plus' @click="displayAddCategory" style="float: right;">
            Ajouter
        </el-button>
        <h2>Liste des catégories</h2>
        <p>
            <el-checkbox v-model="displayEmpty">Afficher les catégories vides</el-checkbox>
        </p>
        <div id="categories">
            <category-item
                v-for='(category, $index) in filteredCategories'
                :key='$index'
                :category='category'
                :draggable='false'
                :deletable='true'
                :edit='editingCategory === category'
                :editable='true'
                with-products-count
                @save='handleCategoryEdit'
                @drop='handleCategoryDrop'/>
        </div>    
    </div>    
</template>

<script>
import Vuex from 'vuex'
import { error } from '@/lib/Tools'
import Category from '@/lib/entities/Category'
import CategoryItem from '@/components/Menu/CategoryItem.vue'
export default {
    name: 'CategoryList',
    components: {
        CategoryItem
    },
    computed: {
        ...Vuex.mapState(['store']),
        ...Vuex.mapGetters(['categories']),
        filteredCategories() {
            if (this.displayEmpty === true) {
                return this.categories
            }
            return this.categories.filter((c) => c.products.length > 0)
        }
    },
    data: () => ({
        displayEmpty: false,
        editingCategory: null
    }),
    methods: {
        displayAddCategory() {
            const category = new Category()
            this.editingCategory = category
            this.store.addCategory(category)
        },
        handleCategoryEdit(category) {
            category.save().then(() => {
                this.$message({
                    type: 'success',
                    message: 'Modifications enregistrées'
                })
            })
        },
        handleCategoryDrop(category) {
            const doDelete = () => {
                this.store.deleteCategory(category)
                this.$message({
                    type: 'success',
                    message: 'Catégorie supprimée'
                })
            }
            if (category.products.length > 0) {
                this.$confirm('Cette catégorie contient des produits, souhaitez-vous vraiment la supprimer ?',
                    'Confirmation',
                    {
                        type: 'warning',
                        confirmButtonText: 'Oui',
                        cancelButtonText: 'Annuler',
                    }
                )
                .then((_) => doDelete())
                .catch((err) => {
                    error(err)
                })
            } else {
                doDelete()
            }
        },
    }
}
</script>

<style>

</style>
