<template>
    <div id='menusProductList'>
        <el-button :loading='loading' type="primary" size="small" icon='el-icon-refresh' @click="doRefreshIntegration" style="float: right; margin-left: 10px" v-if='has_external_integrations'>
            Synchroniser
        </el-button>
        <el-button type="primary" size="small" icon='el-icon-plus' @click="displayAddProduct" style="float: right;">
            Ajouter
        </el-button>
        <h2>Liste des produits</h2>
        <p>
            <el-checkbox v-model="displayEmpty">Afficher les catégories vides</el-checkbox>
        </p>
        <el-alert type="info" title="Aucune produit" v-if='products.length === 0'></el-alert>
        <div id="categories">
            <div v-for='(category, c) in filteredCategories' :key='category.id' class="category-editor">
                <h3>{{category.name}}</h3>
                <div class="category-products">
                    <div class='products-list'>
                        <product-item
                            v-for='(product, j) in category.products'
                            :id='product.id'
                            :key='c + "-" + j'
                            :product='product'
                            :editable='true'
                            :pricable='false'
                            :deletable='true'
                            :statuable='false'
                            @statusChanged="handleProductStatus"
                            @edit='handleProductEdit'
                            @drop='handleProductDrop'
                        />
                    </div>
                </div>
            </div>
            <div class="category-editor" v-if='orphan_products.length > 0'>
                <h3>Aucune catégorie</h3>
                <div class="category-products">
                    <div class='products-list'>
                         <product-item
                            v-for='(product, j) in orphan_products'
                            :id='product.id'
                            :key='"empty-" + j'
                            :product='product'
                            :editable='true'
                            :pricable='false'
                            :deletable='true'
                            :statuable='false'
                            @statusChanged="handleProductStatus"
                            @edit='handleProductEdit'
                            @drop='handleProductDrop'
                        />
                    </div>
                </div>
            </div>
        </div>
        <!-- Modals -->
        <product-form-modal
            v-if='editingProduct !== null'
            @save='handleProductCreated'
            @close='handleModalClosed'
            :product='editingProduct'
            :categories='categories'
            :appendToBody='false'
            :visible='view.createProductModal'
        />
    </div>
</template>

<script>
import Vuex from 'vuex'
import Product from '@/lib/entities/Product'
import ProductItem from '@/components/Menu/ProductItem'
import ProductFormModal from '@/components/Menu/ProductFormModal.vue'
export default {
    name: 'ProductList',
    components: {
        ProductItem,
        ProductFormModal
    },
    computed: {
        ...Vuex.mapState(['store']),
        ...Vuex.mapGetters(['products', 'categories', 'has_external_integrations']),
        filteredCategories() {
            if (this.displayEmpty === true) {
                return this.categories
            }
            return this.categories.filter((c) => c.products.length > 0)
        },
        orphan_products() {
            const pids = this.products.map((e) => e.id)
            for (const cat of this.categories) {
                for (const p of cat.products) {
                    const idx = pids.indexOf(p.id)
                    if (idx > -1) {
                        pids.splice(idx, 1)
                    }
                }
            }
            return this.products.filter((p) => pids.includes(p.id))
        }
    },
    data: () => ({
        loading: false,
        editingProduct: null,
        displayEmpty: false,
        lastProductId: null,
        view: {
            createProductModal: false
        }
    }),
    methods: {
        ...Vuex.mapActions(['refreshIntegrations']),
        async doRefreshIntegration() {
            this.loading = true
            await this.refreshIntegrations()
            setTimeout(() => {
                this.loading = false
            }, 30 * 1000) // 30s
        },
        handleProductCreated(product) {
            this.store.addProduct(product)
            this.editingProduct = null
            this.$message({
                message: 'Produit enregistré avec succès',
                type: 'success'
            })
        },
        handleProductEdit(product) {
            this.$router.push({
                name: 'app.menus.product.edit',
                params: {
                    id: product.id
                },
                query: {
                    from: this.$route.fullPath
                }
            })
        },
        handleProductStatus(product, status) {
            this.store.updateProductStatus(product, status)
            this.$emit('update');
        },
        handleProductDrop(product) {
            this.$confirm('Supprimer définitivement ce produit ?', 'Confirmation', {
                type: 'warning',
                confirmButtonText: 'Oui',
                cancelButtonText: 'Annuler',
            })
            .then((_) => {
                this.store.deleteProduct(product)
                this.$message({
                    message: 'Produit supprimé avec succès',
                    type: 'success'
                })
            })
        },
        displayAddProduct(category) {
            this.editingProduct = new Product()
            this.view.createProductModal = true
        },
        handleModalClosed() {
            this.view.createProductModal = false
            window.location.hash = this.editingProduct.id
        },
        scrollToProduct() {
            try {
                const el = document.querySelector(this.lastProductId)
                if (el) {
                    el.scrollIntoView({
                        behavior: 'smooth',
                        block: 'start'
                    })
                }
            } catch (err) {
                //
            }
        }
    },
    watch: {
        lastProductId() {
            this.scrollToProduct()
        }
    },
    mounted() {
        if (this.$route.hash) {
            this.lastProductId = this.$route.hash
        }
    }
}
</script>

<style>

</style>
