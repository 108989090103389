<template>
    <div class="card-item">
        <div class="card-header">
            <div class="card-title">
                <h3>{{card.name}}</h3>
                <small>{{card.description}}</small>
            </div>
            <div class='card-meta'>
                <el-dropdown trigger="click" @command="handleDropdown">
                    <span class="el-dropdown-link">
                        <i class="el-icon-more el-icon--right"></i>
                    </span>
                    <el-dropdown-menu slot="dropdown">
                        <el-dropdown-item command='editIndex' icon='el-icon-fa-list'>&nbsp;Ordre d'affichage</el-dropdown-item>
                        <el-dropdown-item v-if='editable' command='edit' icon='el-icon-edit'>&nbsp;Modifier</el-dropdown-item>
                        <el-dropdown-item v-if='clonable' command='clone' icon='el-icon-fa-copy'>&nbsp;Dupliquer</el-dropdown-item>
                        <el-dropdown-item v-if='sharable' command='share' icon='el-icon-share'>&nbsp;Partager</el-dropdown-item>
                        <el-dropdown-item v-if='previewable' command='preview' icon='el-icon-fa-eye'>&nbsp;Aperçu</el-dropdown-item>
                        <el-dropdown-item v-if='linkable' command='link' icon='el-icon-fa-link'>&nbsp;Associer</el-dropdown-item>
                        <el-dropdown-item v-if='deletable' command='drop' icon='el-icon-delete'>&nbsp;Supprimer</el-dropdown-item>
                    </el-dropdown-menu>
                </el-dropdown>
            </div>
        </div>
        <div style="text-align: center; padding: 0.9em 0.5em;">
            <el-button v-if='!displayProducts' @click="displayProducts = true" size="mini" type="text" icon='el-icon-fa-eye'>
                Afficher les produits
            </el-button>
            <el-button v-if='displayProducts' @click="displayProducts = false" size="mini" type="text" icon='el-icon-fa-eye-slash'>
                Masquer les produits
            </el-button>
        </div>
        <div class="card-categories" v-if='displayProducts'>
            <div v-if='card.categories.length === 0'>
                Aucun produit
            </div>
            <el-tree v-if='asTree'
                :data="productsTree"
                :props="defaultProps"
                :default-expand-all='true'
                :expand-on-click-node='false'>
            </el-tree>
            <template v-else>
                <div class='card-category' v-for='category in card.categories' :key='category.id'>
                    <h4>{{category.name}}</h4>
                    <div v-for='product in category.products' :key='product.id' class='card-category-product'>
                        <div class='card-category-product-label' :class='status[product.id]'>{{product.name}}</div>
                        <div class='card-category-product-price'>{{prices[product.id]|EUR}}</div>
                    </div>
                </div>
            </template>
        </div>
        <el-alert v-if='pricesOverrides.length > 0' title="" type="warning" show-icon :closable='false' style="margin-bottom: 10px;">
            <div slot="title">Prix publiques majorés</div>
        </el-alert>
        <div class='card-buttons'>
            <div v-if='editable'>
                <el-button type='primary' size="small" @click='edit' icon='el-icon-edit'>Modifier</el-button>
            </div>
            <div v-if='tooglable'>
                <el-button :type='card.enabled ? "info" : "success"' size="small" @click='toogle' :icon='card.enabled ? "el-icon-fa-times": "el-icon-fa-check"'>
                    <span v-if='card.enabled'>Désactiver</span>
                    <span v-else>Activer</span>
                </el-button>
            </div>
            <!-- <div v-if='deletable'>
                <el-button type='danger' size="small" @click='drop' icon='el-icon-delete'>Supprimer</el-button>
            </div> -->
        </div>
    </div>
</template>

<script>
import Vuex from 'vuex'
import Card from '@/lib/entities/Card'
export default {
    name: 'CardItem',
    props: {
        card: {
            type: Card,
            required: true
        },
        editable: {
            type: Boolean,
            required: false,
            default: () => false
        },
        deletable: {
            type: Boolean,
            required: false,
            default: () => false
        },
        clonable: {
            type: Boolean,
            required: false,
            default: () => true
        },
        sharable: {
            type: Boolean,
            required: false,
            default: () => false
        },
        previewable: {
            type: Boolean,
            required: false,
            default: () => true
        },
        linkable: {
            type: Boolean,
            required: false,
            default: () => false
        },
        tooglable: {
            type: Boolean,
            required: false,
            default: () => true
        },
        asTree: {
            type: Boolean,
            required: false,
            default: () => false
        }
    },
    data: () => ({
        defaultProps: {
            children: 'children',
            label: 'label'
        },
        displayProducts: false
    }),
    computed: {
        ...Vuex.mapState(['places']),
        prices() {
            return this.card.prices || {}
        },
        vats() {
            return this.card.vats || {}
        },
        status() {
            return this.card.status || {}
        },
        productsTree () {
            const tree = []
            for (const category of this.card.categories) {
                tree.push({
                    id: category.id,
                    label: category.name,
                    children: category.products.map((p) => ({label: p.name, id: p.id}))
                })
            }
            return tree
        },
        pricesOverrides() {
            const overrides = []
            for (const place of this.places) {
                if (place.cards.indexOf(this.card.id) > -1) {
                    if (place.settings.hasOwnProperty('fees') && place.settings.fees.enabled) {
                        let upfront = 0
                        if (place.settings.fees.hasOwnProperty('upfront')) {
                            if (place.settings.fees.upfront.constructor.name == 'String') {
                                upfront = Number(place.settings.fees.upfront.replace(/,/, '.'))
                            } else {
                                upfront = place.settings.fees.upfront
                            }
                        }
                        let ratio = 0
                        if (place.settings.fees.hasOwnProperty('ratio') ) {
                            if (place.settings.fees.ratio.constructor.name == 'String') {
                                ratio = Number(place.settings.fees.ratio.replace(/,/, '.'))
                            } else {
                                ratio = place.settings.fees.ratio
                            }
                        }
                        if (upfront > 0 || ratio > 0) {
                            overrides.push({
                                placeId: place.id,
                                placeName: place.name,
                                upfront,
                                ratio
                            })
                        }
                    }
                }
            }
            return overrides
        }
    },
    methods: {
        handleDropdown (command) {
            if (this[command]) return this[command]()
        },
        editIndex () {
            this.$emit('edit-index', this.card)
        },
        edit () {
            this.$emit('edit', this.card)
        },
        drop() {
            this.$emit('drop', this.card)
        },
        clone() {
            this.$emit('clone', this.card)
        },
        share() {
            this.$emit('share', this.card)
        },
        preview() {
            this.$emit('preview', this.card)
        },
        link() {
            this.$emit('link', this.card)
        },
        toogle() {
            this.$emit('toogle', this.card)
        },
        openCardComposer (category) {
            this.$emit('compose', this.card, category)
        }
    }
}
</script>

<style lang='scss'>

.card-item {
    box-shadow: 0 2px 3px rgba(10, 10, 10, 0.1), 0 0 0 1px rgba(10, 10, 10, 0.1);
    padding: 1em;
    width: 100%;
    max-width: 300px;
    border-radius: 5px;
    /* background-color: #031025; color: white;*/
    background-color: #fdfdfd;
    margin: 5px;
    display: flex;
    flex-direction: column;
}

.card-meta {
    flex-grow: 1;
    text-align: right;
    margin-top: 8px;
}
.card-header {
    margin-left: 5px;
    display: flex;
    flex-direction: row;
}
.card-title {
    h3 {
        margin-bottom: 0px;
    }
}

.card-categories {
    margin: 1em 0em;
    flex-grow: 1;
}

.card-buttons {
    display: flex;
    justify-content: space-between;
}

.card-category {
    display: flex;
    flex-direction: column;
    padding-left: 5px;
    padding-top: 5px;
    padding-bottom: 5px;
    border-left: 1px solid #e7e5e5;
    h4 {
        margin-top: 0;
        margin-bottom: 10px;
    }
    margin-bottom: 0.5em;
}

.card-category-product {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
}
.card-category-product-label {
    text-align: left;
    flex-basis: 75%;
    flex-grow: 1;
    font-size: 0.9rem;

    &.unavailable {
        text-decoration: line-through;
    }
}
.card-category-product-price {
    font-weight: 800;
}

.card-warning {
    color: #f56c6c;
    font-size: 0.9rem;
    text-align: center;
}
</style>
