<template>
    <div class='view' v-loading.lock='loading'>
        <div v-if='loading'>
            <h4>Chargement en cours...</h4>
        </div>
        <div v-else>
            <h1>Commande #{{order.number}}</h1>
            <order-item :order='order'></order-item>
        </div>
    </div>
</template>

<script>
import Vuex from 'vuex'
import OrderItem from '@/components/Order/OrderItem'
export default {
    name: 'OrderDetails',
    components: {
        OrderItem
    },
    computed: {
        ...Vuex.mapState(['_orders']),
        orderId() {
            return this.$route.params.id
        }
    },
    data: () => ({
        loading: true,
        order: null
    }),
    mounted() {
        const order = this._orders[this.orderId] || null
        if (order === undefined) {
            alert('Commande inexistante')
        } else {
            this.order = order
        }
        this.loading = false
    }
}
</script>

<style>

</style>