<template>
    <div id="cardSettings" class="view" v-loading='!loaded' v-if='card'>
        <h2 class="subtitle"><el-icon icon="el-icon-fa-pencil"></el-icon>Paramètres de la carte '{{card.name}}'</h2>
        <div class="box">
            <el-form label-width="250px">
                
            </el-form>
        </div>
        <el-footer>
            <el-button type="info" @click="$router.back()">Annuler</el-button>
            <el-button type="primary" :disabled='!isCardValid' @click="saveCard()" icon='el-icon-circle-check-outline'>Enregistrer</el-button>
        </el-footer>
    </div>
</template>

<script>
import Vuex from 'vuex'
import Card from '@/lib/entities/Card'
export default {
    name: 'CardSettings',
    components: {},
    data: () => ({
        card: null
    }),
    computed: {
        ...Vuex.mapGetters(['cards']),
        loaded() {
            return this.card !== null
        },
        isCardValid() {
            if (this.card) {
                return true
            }
            return false
        }
    },
    mounted() {
        this.card = this.cards.find((card) => card.id === this.$route.params.id)
    },
    methods: {
        ...Vuex.mapActions(['saveStore']),
        saveCard() {
            this.saveStore({
                cards: this.cards
            })
            this.$message({
                message: 'Modification enregistrée avec succès',
                type: 'success'
            })
            this.$router.push({name: 'app.home'})
        }
    }
}
</script>

<style>

</style>
