import Parse from 'parse'
import moment from 'moment'
import Entity from '@/lib/entities/Entity'

export default class Order extends Entity {

    public static async boot(model: Parse.Object) {
        const order = new Order()
        order.setModel(model)
        order.id = model.id
        order.orderId = model.get('orderId')
        order.total = model.get('total')
        order.place = model.get('place')
        order.card = model.get('card')
        order.customer = model.get('customer')
        order.items = model.get('items')
        order.status = model.get('status')
        order.paymentStatus = model.get('paymentStatus')
        order.paymentMethod = model.get('paymentMethod')
        order.number = model.get('number')
        order.comment = model.get('comment')
        order.guests = model.get('guests')
        order.createdAt = moment(model.get('createdAt'))
        order.completedAt = moment(model.get('completedAt'))
        order.settings = model.get('settings')
        return order
    }

    declare public id: any
    public orderId: any
    public number?: string
    public items?: any[]
    public total?: number
    public comment?: string
    public guests?: string
    public place?: any
    public card?: any
    public customer?: any
    public settings?: any
    public status?: string
    public paymentStatus?: string
    public paymentMethod?: string
    public createdAt?: moment.Moment
    public completedAt?: moment.Moment

    public async save() {
        if (!this.model) {
            this.model = new Parse.Object('Order')
        }
        this.model.set('status', this.status)
        await this.model.save()
    }
}
