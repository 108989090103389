<template>
    <el-card class="__stats box-card">
        <div slot="header" class="clearfix card-header">
            <p class="card-header-title tracked-tight ttu">
                {{title}}
            </p>
        </div>
        <div class="__stats-content pa2">
            <slot></slot>
        </div>
        <footer class="card-footer">
            <slot name='footer'></slot>
        </footer>
    </el-card>
</template>

<script>
export default {
    name: 'Card',
    props: [
        'title'
    ]
}
</script>

<style>
.__stats .el-card__header {
    background-color: #03E2AD;
    color: white;
    padding: 0;
}
.__stats .card-header {
    margin: 0;
}
.__stats .card-header-title {
    padding: 1rem 0.75rem;
    margin: 0;
}

.__stats .el-card__body {
    padding: 0;
}

.ttu {
    text-transform: uppercase;
}
</style>