<template>
    <div>
        <div class="place-locations">
            <div class="place-location" v-for='(l, $l) in locations' :key='$l'>
                <div>
                    <h4>{{l.name}}</h4>
                    <i class='el-icon-fa-map-marker'></i>&nbsp;<small>{{l.location}}</small>
                </div>
                <div class="place-location-actions">
                    <el-button type='primary' size='small' @click='editLocation(l)' title='Modifier cet emplacement' icon='el-icon-fa-edit' circle></el-button>
                    <el-button type='dark' size='small' @click='dropLocation(l)' title='Supprimer cet emplacement' icon='el-icon-fa-times' circle></el-button>
                </div>
            </div>
        </div>
        <div style="margin-top: 1em;">
            <el-button type="primary" size="small" icon='el-icon-plus' @click="view.locationForm = true">
                Ajouter
            </el-button>
        </div>

        <place-location-modal
            :location='currentLocation'
            :visible='view.locationForm'
            @close='view.locationForm = false'
            @save='handleSaved'
        />
    </div>
</template>

<script>
import PlaceLocationModal from '@/components/Place/PlaceLocationModal'
export default {
    name: 'PlaceLocation',
    components: {
        PlaceLocationModal
    },
    props: {
        locations: {
            type: Array,
            required: true
        }
    },
    data: () => ({
        currentLocation: {},
        view: {
            locationForm: false
        }
    }),
    methods: {
        handleSaved(location) {
            const index = this.locations.indexOf(location)
            if (index === -1) {
                this.locations.push(location)
            } else {
                this.locations[index] = location
            }
            this.view.locationForm = false
        },
        editLocation(location) {
            this.currentLocation = location
            this.view.locationForm = true
        },
        dropLocation(location) {
            const index = this.locations.indexOf(location)
            if (index > -1) {
                this.locations.splice(index, 1)
            }
        }
    }
}
</script>

<style>
.place-location {
    padding: 1em 0.5em;
    display: flex;
    justify-content: space-between;
}
.place-location h4 {
    margin: 0;
}
.place-location + .place-location {
    border-top: 1px dashed #dedede;
}
</style>
