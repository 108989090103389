<template>
    <div class='assembly-item'>
        <div class="assembly-data" :draggable="draggable ? 'on' : 'off'">
            <div class="assembly-picker" v-if='pickable'>
                <el-checkbox :checked="selected" @change='handleAssemblySelected'></el-checkbox>
            </div>
            <div class="assembly-info">
                <p style="font-weight: 600"><span style="margin-right: 5px;"><i class="el-icon-fa-cubes"></i></span>{{assembly.name}}</p>
                <small>{{assembly.description}}</small>
            </div>
        </div>
        <div class="assembly-actions">
            <div v-if='editable'>
                <el-button @click="edit" size="small" type="primary" title="Modifier" circle icon="el-icon-fa-edit"></el-button>
            </div>
            <div v-if='deletable'>
                <el-button @click="drop" size="small" type="danger" title="Supprimer" circle icon="el-icon-fa-times"></el-button>
            </div>
        </div>
    </div>
</template>

<script>
import Assembly from '@/lib/entities/Assembly'
export default {
    name: 'AssemblyItem',
    props: {
        assembly: {
            type: Assembly,
            required: true
        },
        editable: {
            type: Boolean,
            required: false,
            default: () => false
        },
        pickable: {
            type: Boolean,
            required: false,
            default: () => false
        },
        deletable: {
            type: Boolean,
            required: false,
            default: () => false
        },
        draggable: {
            type: Boolean,
            required: false,
            default: () => false
        },
        selected: {
            type: Boolean,
            required: false,
            default: () => false
        }
    },
    methods: {
        edit() {
            this.$emit('edit', this.assembly)
        },
        drop() {
            this.$emit('drop', this.assembly)
        },
        handleAssemblySelected() {
            this.$emit('select', this.assembly)
        },
        handleClick() {
            if (this.pickable && !this.editable) {
                return this.handleAssemblySelected()
            }
            if (this.editable) {
                return this.edit()
            }
        }
    }
}
</script>

<style lang='scss'>
.assembly-item {
    border: 1px solid #d1d0d0;
    border-radius: 5px;
    padding: 0.5em;
    display: flex;
    min-height: 45px;
    justify-content: space-between;
    background-color: #fbfbfb;
}

.assembly-item + .assembly-item {
    margin-top: 10px;
}

.assembly-item:hover {
    background-color: #efefef;
}

.assembly-data {
    display: flex;
}
.assembly-picker {
    display: flex;
    align-items: center;
    margin-right: 15px;
}

.assembly-info {
    p {
        margin-top: 0px;
        margin-bottom: 0px;
    }
    margin-left: 15px;
}

.assembly-actions {
    display: flex;
    div + div {
        margin-left: 5px;
    }
}
</style>
