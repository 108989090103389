<template>
    <div id='cardWizard'>
        <h2>Gestion des cartes</h2>
        <div class="notification is-primary" v-if='displayTooltip' style="margin-bottom: 1em">
            Créez maintenant votre carte en y intégrant vos produits et formules, et en saisissant les prix associés.<br>
            Vous pourrez ensuite publier votre menu.
        </div>
        <div id='cardWizardsList' class='cards-list'>
            <card-item
                v-for='card in cards'
                :key='card.id'
                :card='card'
                :sharable='false'
                :previewable='false'
                :editable='true'
                :deletable='true'
                :tooglable='false'
                @clone='handleCardClone'
                @drop='handleCardDrop'
                @edit='handleCardEdit'
            />
        </div>
        <div @click="displayCreateCard" class='inline-button'>
            <el-button circle size="small" type="primary" icon='el-icon-fa-list'></el-button>
            Créer une carte
        </div>
        <card-composer-modal v-if='editingCard'
            :card='editingCard'
            :visible='view.modal'
            :products='products'
            :categories='categories'
            :assemblies='assemblies'
            @close='closeModal'
            @save='createCard'
        />
    </div>
</template>

<script>
import Card from '@/lib/entities/Card'
import CardItem from '@/components/Menu/CardItem'
import CardComposerModal from '@/components/Menu/CardComposerModal'
export default {
    name: 'CardWizard',
    components: {
        CardItem,
        CardComposerModal
    },
    props: {
        cards: {
            type: Array,
            required: true
        },
        products: {
            type: Array,
            required: true
        },
        categories: {
            type: Array,
            required: true
        },
        assemblies: {
            type: Array,
            required: true
        }
    },
    data: () => ({
        defaultNewCard: null,
        editingCard: null,
        view: {
            modal: false
        }
    }),
    computed: {
        displayTooltip() {
            try {
                return window.localStorage.getItem(`_hide_cards_tooltip`) !== '1'
            } catch (err) {
                return false
            }
        }
    },
    methods: {
        displayCreateCard() {
            this.editingCard = new Card()
            this.view.modal = true
        },
        handleCardEdit(card) {
            this.editingCard = card
            this.view.modal = true
        },
        closeModal() {
            this.view.modal = false
            setTimeout(() => {
                this.editingCard = null
            }, 1000)
        },
        createCard(card) {
            card.save()
            this.editingCard = null
            if (this.cards.indexOf(card) === -1) {
                this.cards.push(card)
            }
        },
        handleCardDrop(card) {
            this.$confirm('Supprimer cette carte ?', 'Confirmation', {
                type: 'warning',
                confirmButtonText: 'Oui',
                cancelButtonText: 'Annuler',
            })
            .then((_) => {
                this.$emit('drop', card)
            }).catch((err) => {
                error(err)
            })
        },
        handleCardClone(card) {
            const newCard = card.clone()
            this.createCard(newCard)
            this.handleCardEdit(newCard)
        }
    }
}
</script>

<style lang='scss'>
#cardWizardsList {
    margin-bottom: 1em;
}

</style>
