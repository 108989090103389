<template>
    <div id="register">
        <div v-if='!loading'>
            <h3>Merci de saisir votre adresse email pour réinitialiser votre mot de passe</h3>
            <p style='margin-bottom: 1em;'>
                <el-input v-model='email' type='email' placeholder='Adresse email'></el-input>
            </p>
            <el-button type='primary' @click='doReset' :disabled='!formValid'>Continuer</el-button>
            <p>
                <small><router-link :to='{name: "auth.login"}'>Retour</router-link></small>
            </p>
        </div>
        <div v-else>
            <div class="notification is-success">Si votre adresse email est correcte, nous vous enverrons un email pour réinitialiser votre mot de passe</div>
        </div>
    </div>
</template>

<script>
import Auth from '@/lib/Auth'
export default {
    name: 'ResetPassword',
    data: () => ({
        email: '',
        loading: false
    }),
    computed: {
        formValid() {
            return this.email.length > 3
        }
    },
    methods: {
        async doReset() {
            if (this.formValid) {
                await Auth.resetPassword(this.email)
                this.loading = true
                setTimeout(() => {
                    this.$router.push({name: 'auth.login'})
                }, 4000)
            }
        }
    }
}
</script>

<style>

</style>
