<template>
    <div class="box">
        <h1>Notifications</h1>
        <el-alert v-if='!hasNotifications' type='info' :closable='false' title='Aucune notification'></el-alert>
    </div>
</template>

<script>
import Vuex from 'vuex'
export default {
    name: 'Notifications',
    computed: {
        ...Vuex.mapState(['notifications']),
        hasNotifications() {
            return this.notifications.length > 0
        }
    }
}
</script>

<style>

</style>
