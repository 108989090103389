<template>
    <el-form id='loginForm' :model="credentials" status-icon :rules="rules" ref="loginForm" :show-message='false'>
      <el-form-item prop="email">
        <el-input type="email" v-model="credentials.email" autocomplete="off" placeholder='Adresse email' prefix-icon='el-icon-fa-user'></el-input>
      </el-form-item>
      <el-form-item prop="password">
        <el-input type="password" v-model="credentials.password" autocomplete="off" placeholder="Mot de passe" prefix-icon='el-icon-fa-lock'></el-input>
      </el-form-item>
      <p style="margin-top: 22px;">
        <el-form-item>
          <el-button type="primary" icon='el-icon-fa-rocket' @click="submitForm()">Connexion</el-button>
        </el-form-item>
      </p>
      <el-alert v-if='error'
        title="Identifiants incorrects"
        type="error"
        show-icon
        :closable='false'>
      </el-alert>
      <div class="login-actions">
        <router-link :to='{name: "auth.reset"}'>
          <small>Mot de passe oublié ?</small>
        </router-link>
        <slot name='footer'></slot>
      </div>
    </el-form>
</template>

<script>
import Vuex from 'vuex'
import Auth from '@/lib/Auth'
const email = ''
const password = ''
export default {
  name: 'LoginForm',
  data: () => ({
    error: false,
    credentials: {
      email,
      password
    },
    rules: {
      email: [
        { required: true, type: 'email', message: 'Merci de saisir votre adresse email', trigger: 'blur' }
      ],
      password: [
        { required: true, type: 'string', message: 'Merci de saisir votre de passe', trigger: 'blur' }
      ]
    }
  }),
  methods: {
    ...Vuex.mapActions(['setToken', 'login']),
    async submitForm() {
      try {
        await this.$refs.loginForm.validate()
        try {
          const { token, sessionToken } = await Auth.login(this.credentials.email, this.credentials.password)
          if (token) {
            this.setToken(token)
            this.error = false
            this.$emit('auth')
          } else {
            this.error = true
          }
        } catch (err) {
          if (err.code === 101) {
            this.$message({
              type: 'error',
              message: 'Email ou mot de passe incorrect'
            })
          } else {
            this.$message({
              type: 'error',
              message: err.message
            })
          }
        }
      } catch (err) {}
    }
  }
}
</script>

<style>
#loginForm .el-form-item {
  margin-bottom: 10px;
}

.login-actions {
  margin-top: 1rem;
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
}
</style>
