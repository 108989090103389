import Parse from 'parse'

export default class Merchant {
    public id: any
    public name: string = ''
    public identifier: string = ''
    public registrationNumber: string = ''
    public email: any
    public slug: any
    public phone?: string
    public logoUrl?: string
    public colors: any = {
        headerBackground: '#bbb',
        secondaryColor: '#ggg'
    }
    public settings: any = {}
    public location: any = {
        default: null,
        street: '',
        zipcode: '',
        city: ''
    }
    public social: any = {
        website: '',
        facebook: '',
        twitter: '',
        instagram: ''
    }

    constructor(data?: Parse.Object) {
        if (data) {
            this.id = data.id
            this.name = data.get('name')
            this.identifier = data.get('identifier')
            this.phone = data.get('phone')
            this.email = data.get('email')
            this.slug = data.get('slug')
            const settings = data.get('settings')
            this.settings = {}
            if (settings.pongo_id) {
                this.settings['pongo_id'] = settings.pongo_id
            }
            if (settings.config) {
                this.settings['config'] = settings.config
            }
            this.registrationNumber = data.get('registrationNumber')
            if (data.has('colors')) {
                this.colors = data.get('colors')
            }
            if (data.has('social')) {
                this.social = data.get('social')
            }
            if (data.has('location')) {
                this.location = data.get('location')
            }
            if (data.has('logoUrl')) {
                this.logoUrl = data.get('logoUrl')
            }
        }
    }

    get address() {
        return [this.location.default, this.location.street, this.location.zipcode, this.location.city].join(' ').trim()
    }
}
