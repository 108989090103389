<template>
    <div id="teamList">
        <el-table id="teamListTable" :data='teamList' style="width: 100%">
            <el-table-column label="Nom">
                <template slot-scope="scope">
                    {{scope.row.firstname}} {{scope.row.lastname}}
                </template>
            </el-table-column>
            <el-table-column prop="email" label="Email"></el-table-column>
            <el-table-column prop="phone" label="Téléphone"></el-table-column>
            <el-table-column label="Etablissement(s)">
                <template slot-scope="scope">
                    <div v-for='(pId, $index) of scope.row.placesIds' :key='$index'>
                        {{ placesHash[pId] }}
                    </div>
                </template>
            </el-table-column>
            <el-table-column prop="role" label="Rôle">
                <template slot-scope="scope">
                    {{roles[scope.row.role]}}
                </template>
            </el-table-column>
            <el-table-column label="">
                <template slot-scope="scope">
                    <el-button
                        size="mini"
                        type="info"
                        @click="updateTeamMate(scope.$index, scope.row)"
                        v-if='scope.row.id !== user.id'>
                        Modifier
                    </el-button>
                </template>
            </el-table-column>
        </el-table>
    </div>
</template>

<script>
import Vuex from 'vuex'
export default {
    name: 'TeamList',
    data: () => ({
        roles: {
            teammate: 'Equipier',
            merchant: 'Gérant',
            deliveryman: 'Livreur'
        }
    }),
    computed: {
        ...Vuex.mapState(['user', 'team', 'places']),
        teamList() {
            if (this.team) {
                return this.team
            }
            return []
        },
        placesHash() {
            const hash = {}
            for (const place of this.places) {
                hash[place.id] = place.name
            }
            return hash
        }
    },
    methods: {
        updateTeamMate(index, el) {
            this.$router.push({name: 'app.team.edit', params: {id: el.id}})
        }
    }
}
</script>

<style>

</style>
