<template>
  <div id="register">
    <div id='llogo'></div>
    <p>
      L'outil pour designer et compiler vos menus comme un chef !
    </p>
    <div class="box-card">
      <h3>Inscrivez-vous pour accéder à notre outil</h3>
      <register-form @valid='doRegister'/>
      <p>
        <small>Déjà inscrit ? <router-link :to="{name:'auth.login'}">Se connecter</router-link></small>
      </p>
    </div>
  </div>
</template>

<script>
import Vuex from 'vuex'
import RegisterForm from '@/components/RegisterForm'

export default {
  name: 'Register',
  components: {
    RegisterForm
  },
  methods: {
    ...Vuex.mapActions(['storeLeadData']),
    async doRegister(lead) {
      await this.storeLeadData(lead)
      this.$router.push({name: 'onboarding.wizard', params: {}})
    }
  }
}
</script>

<style lang="scss">
#register {
  text-align: center;
  width: 100%;
  
  p {
    margin-bottom: 0;
  }
  h2 {
    margin-top: 0;
  }

  .box-card {
    padding: 20px;
    margin-top: 10px;
    
    h3 {
      font-weight: 300;
      margin-top: 0px;
    }
  }
}
</style>
